import React, { useContext } from "react";
import {
  Buttons,
  Card,
  HistoryOfPath,
} from "../../../components"
import { ComponentContext } from "../../../contexts";
import {
  IFormsShema,
  IOpenAccountShema,
  ISubPage, 
} from "../../../interfaces"
import CardCardHolderUpload from "./cardCardHolderUpload"
import CardUploadID from "./cardIDCardUpload"
import CardIDInformation from "./cardIDInformation"

interface props {
  page: string
  data?: IOpenAccountShema[]
  dataOfPage: IFormsShema[]
}

export default ({
  page,
  data,
  dataOfPage,
}: props) => {
  const index = +page.split(".")[1] || 0
  const dataDisplay = dataOfPage[index]

  const componentContext = useContext(ComponentContext);

  const onChange = (val: {key?: string, value?: string | boolean | number}) => {
    componentContext?.onChange({ key: val.key || "", value: val.value })
  }

  if (dataDisplay?.type === ISubPage.STEP_ID_CARD_INFO) {
    return (
      <CardIDInformation
        page={page}
        dataOfPage={dataOfPage}
        data={data}
      />
    )
  }

  if (dataDisplay?.type === ISubPage.STEP_IMAGE_ID_CARD) {
    return (
      <CardUploadID
        page={page}
        dataOfPage={dataOfPage}
        data={data}
      />
    )
  }

  if (dataDisplay?.type === ISubPage.STEP_IMAGE_SELFIE_WITH_ID_CARD) {
    return (
      <CardCardHolderUpload
        page={page}
        dataOfPage={dataOfPage}
        data={data}
      />
    )
  }

  return null
}