import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux"
import {
  navigateNext, sendMobileOtp, setMobileOtpAnswer, submitSaga, 
} from "../../../actions";
import {
  Buttons,
  Card,
  Images,
  Texts,
  HistoryOfPath,
} from "../../../components"
import { Chat } from "../../../components/Images"
import InputWithButton from "../../../components/Inputs/inputWithButton"
import { fonts, mainFontSize } from "../../../config/themes";
import {
  IFormsShema,
  IOpenAccountShema,
  IRule,
  IRuleEnum,
  IStore, 
} from "../../../interfaces";
import { SecureString, textInputValidate } from "../../../utilities"

interface props {
  page: string
  data?: IOpenAccountShema[],
  dataOfPage: IFormsShema[],
}

export default ({
  page, data, dataOfPage,
}: props) => {
  const [errorMessages, setErrorMessages] = React.useState<Array<string>>(["", ""])
  const [inputType, setInputType] = useState<string>("")
  const { mobileNo, mobileOtp, mobileOtpAnswer } = useSelector((state: IStore) => state.AuthReducer)
  const { t } = useTranslation()
  const dispatch = useDispatch()
  
  useEffect(() => {
    dispatch(sendMobileOtp())
    dispatch(setMobileOtpAnswer(""))
  }, [])

  const values = [mobileOtpAnswer]

  const otpvalidates: Array<IRule> = [
    { type: IRuleEnum.REQUIRED }, 
    { 
      type: IRuleEnum.FORMAT, 
      regex: /^\d{6}$/,
    }, 
  ]
  
  const validate = (): { errors: Array<string>, validateFlag: boolean } => {
    const errors: Array<string> = []
    let validateFlag = true
    
    for (let i = 0; i < values.length; i += 1) {
      const val = values[i] as string
      let error = ""
      error = textInputValidate(otpvalidates, val)
      if (error) validateFlag = false
      
      errors.push(error)
    }
    return { errors, validateFlag }
  }
  
  const onClick = () => {
    const validateRes = validate()
    setErrorMessages(validateRes.errors)
    if (validateRes.validateFlag) dispatch(submitSaga())
  }

  return (
    <Card style={{ width: "500px" }}>
      <div style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between", 
      }}
      >
        <h3 style={{ color: "#0C67B1" }}>
          การตรวจสอบความปลอดภัย
        </h3>
        {/* <Buttons href="/">X</Buttons> */}
      </div>

      <div style={{ marginBottom: 28 }} />
      <div>
        <Texts style={{ 
          color: "#666666", 
          padding: "5px 0px 5px 0px", 
          fontSize: mainFontSize.s, 
          fontFamily: fonts.SarabunLight,
          textAlign: "left",
        }}
        >
          {t("checkSecurity.subTitle")}
        </Texts>

        <div style={{ display: "flex", flexDirection: "column" }}>
          <InputWithButton 
            title={inputType === "EMAIL" ? "การยืนยันด้วยอีเมล" : "การยืนยันด้วยเบอร์มือถือ"}
            titleImage={Chat} 
            ImageWidth="34px"
            ImageHeight="42px"
            subtitles={[
              `กรอกรหัส 6 หลักที่ได้รับทาง${inputType === "EMAIL" ? "อีเมล" : "เบอร์มือถือ"} ${SecureString(mobileNo as string)}`,
              `ref: ${mobileOtp?.otpRequest?.reference || "-"}`,
            ]} 
            buttonTitle="ส่ง OTP" 
            onClick={() => dispatch(sendMobileOtp())}
            errorMessage={errorMessages[0]}
            maxlength={6}
            onChange={(val) => dispatch(setMobileOtpAnswer(val.value))}
          />
        </div>
      </div>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <Buttons test-id="otp-button-register" onClick={onClick}>ยืนยัน</Buttons>
      </div>
    </Card>
  )
}