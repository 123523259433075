import React, { CSSProperties, ReactNode } from "react"
import Autocomplete from "react-autocomplete"
import { useDispatch } from "react-redux"
import { getAddressSaga, getCountry, getReferralPeopleSaga } from "../../../actions"
import { mainFontSize } from "../../../config/themes"
import { TBold } from "../../Texts"
import styles from "./style.module.css"

interface props {
  placeholder?: string
  value: string
  onChange: (obj: {key: string, value: any }) => void
  errorMessage?: string | Array<string>
  disabled?: boolean
  field?: string
}

type renderItemsProps =
  | "subDistrict" | "nameMaketingRecomment" | "nameIbaRecomment" | "country"

interface selectedItems {
  "display_en": string
  "display_th": string
  "district_en": string
  "district_th": string
  "province_en": string
  "province_th": string
  "subdistrict_en": string
  "subdistrict_th": string
  zipcode: number
  id: number
}

export default ({
  placeholder = "",
  value,
  disabled,
  onChange,
  errorMessage,
  field,
}: props) => {
  const dispatch = useDispatch()
  const [text, setText] = React.useState(" ")
  const [enableSelect, setEnableSelect] = React.useState(false)
  const [items, setItems] = React.useState([{ display_th: "" }])

  // Check field for render Items
  const renderItems = (fieldInput: renderItemsProps, val?: string) => {
    switch (fieldInput) {
      case "subDistrict":
        if (val) {
          dispatch(getAddressSaga({
            text: val,
            callback: (response: any) => {
              if (response.data.GetThaiAddress.length > 0) {
                setEnableSelect(true)
                setItems(response.data.GetThaiAddress)
              } else {
                setEnableSelect(false)
                setItems([{ display_th: "ไม่มีข้อมูล" }])
              }
            },
          }))
        }
        break
      case "nameMaketingRecomment":
        if (val) {
          dispatch(getReferralPeopleSaga({
            text: val,
            type: ["MARKETING"],
            callback: (response: any) => {
              if (response.data.GetReferralPeople.length > 0) {
                setEnableSelect(true)
                setItems(
                  response.data.GetReferralPeople.map(
                    (item: any) => ({ ...item, display_th: `${item.id} ${item.nameTh}` }),
                  ),
                )
              } else {
                setEnableSelect(false)
                setItems([{ display_th: "ไม่มีข้อมูล" }])
              }
            },
          }))
        }
        break
      case "nameIbaRecomment":
        if (val) {
          dispatch(getReferralPeopleSaga({
            text: val,
            type: ["IBA"],
            callback: (response: any) => {
              if (response.data.GetReferralPeople.length > 0) {
                setEnableSelect(true)
                setItems(
                  response.data.GetReferralPeople.map(
                    (item: any) => ({ ...item, display_th: `${item.id} ${item.nameTh}` }),
                  ),
                )
              } else {
                setEnableSelect(false)
                setItems([{ display_th: "ไม่มีข้อมูล" }])
              }
            },
          }))
        }
        break
      case "country":
        if (val) {
          dispatch(getCountry({
            contain: val,
            callback: (data) => {
              setEnableSelect(true)
              setItems(
                data.map(
                  (country) => ({ ...country, display_th: country.nameTh }),
                ),
              )
            },
          }))
        } else {
          setEnableSelect(false)
          setItems([{ display_th: "ไม่มีข้อมูล" }])
        }

        break
      default:
        setEnableSelect(false)
        setItems([{ display_th: "please assign items" }])
        break
    }
  }

  // Check field for onSelected to redux
  const onSelected = (val: string) => {
    if (field === "subDistrict") {
      const obj: selectedItems = JSON.parse(val)
      onChange({ key: "subDistrict", value: obj.subdistrict_th })
      onChange({ key: "district", value: obj.district_th })
      onChange({ key: "province", value: obj.province_th })
      onChange({ key: "postalCode", value: obj.zipcode.toString() })
      onChange({ key: "codeAddress", value: obj.id.toString() })
    } else if (field === "nameMaketingRecomment") {
      const obj: selectedItems = JSON.parse(val)
      onChange({ key: "nameMaketingRecomment", value: obj.display_th })
      onChange({ key: "codeMaketingRecomment", value: obj.id.toString() })
    } else if (field === "nameIbaRecomment") {
      const obj: selectedItems = JSON.parse(val)
      onChange({ key: "nameIbaRecomment", value: obj.display_th })
      onChange({ key: "codeIbaRecomment", value: obj.id.toString() })
    } else if (field === "country") {
      const obj: selectedItems = JSON.parse(val)
      onChange({ key: "country", value: obj })
    }
  }

  const onHandleChange = (val: string) => {
    setText(val)
    renderItems(field as renderItemsProps, val)

    if (!val) {
      if (field === "subDistrict") {
        onChange({ key: "subDistrict", value: "" })
        onChange({ key: "district", value: "" })
        onChange({ key: "province", value: "" })
        onChange({ key: "postalCode", value: "" })
        onChange({ key: "codeAddress", value: "" })
      } else if (field === "nameMaketingRecomment") {
        onChange({ key: "nameMaketingRecomment", value: "" })
        onChange({ key: "codeMaketingRecomment", value: "" })
      } else if (field === "nameIbaRecomment") {
        onChange({ key: "nameIbaRecomment", value: "" })
        onChange({ key: "codeIbaRecomment", value: "" })
      } else if (field === "country") {
        onChange({ key: "country", value: null })
      }
    }
  };
  React.useEffect(() => {
    setText(value)
  }, [value])
  React.useEffect(() => { renderItems(field as renderItemsProps) }, [])
  return (
    <Autocomplete
      items={items}
      value={text}
      getItemValue={(val: string) => JSON.stringify(val)}
      onChange={(e) => onHandleChange(e.target.value)}
      onSelect={(v) => enableSelect && onSelected(v)}
      renderMenu={(menuItems: ReactNode[], menuVal: string, menuStyle: CSSProperties) => (
        <div style={{ height: "100%", position: "absolute" }}>
          <div style={{
            ...menuStyle,
            borderRadius: "3px",
            boxShadow: "0 2px 12px rgba(0, 0, 0, 0.1)",
            background: "rgba(255, 255, 255, 0.9)",
            padding: "2px 0",
            fontSize: "90%",
            overflow: "auto",
            position: "absolute",
            top: 0,
            left: 0,
            minHeight: 56,
            maxHeight: 224,
            zIndex: "2",
          }}
          >
            {menuItems}
          </div>
        </div>
      )}
      renderItem={(val, isHighlighted) => (
        <div
          key={val.display_th}
          style={{
            cursor: "pointer",
            background: isHighlighted ? "lightgray" : "white",
            padding: "12px 12px 12px 20px",
          }}
        >
          <TBold fontSize={mainFontSize.m}>
            {val.display_th || "กรุณากรอกข้อมูล"}
          </TBold>
        </div>
      )}
      inputProps={{
        placeholder,
        style: {
          fontSize: mainFontSize.m,
          width: "100%",
          padding: "10px 10px",
          boxSizing: "border-box",
          border: `1px solid ${errorMessage ? "red" : "#cccccc"}`,
          borderRadius: "4px",
        },
        disabled,
      }}
      renderInput={(props: any) => (
        <div style={{ flexDirection: "row", display: "flex" }}>
          <input className={styles.input} {...props} />
        </div>
      )}
    />

  )
}
