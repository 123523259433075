import React from "react"
import { IInputsShema, IInputChoiceEnum } from "../../../interfaces";
import { useWindowDimensions } from "../../../utilities";
import { ButtonChoice } from "../../Buttons";
import { TLight } from "../../Texts";

interface props {
  type: keyof typeof IInputChoiceEnum
  value: IInputsShema[]
  onChange: (obj: {value: Array<string>}) => void;
}

export default ({ type, value, onChange }: props) => {
  const { width } = useWindowDimensions()
  const [data, setData] = React.useState<IInputsShema[]>([])
  const onChangeValue = (index: number) => {
    if (IInputChoiceEnum.INPUT_CHOICE_CHECKBOX === type) {
      const result = data.map((val, indexItem) => (index === indexItem 
        ? ({ ...val, selected: !val.selected }) 
        : val))

      setData(result)
      onChange({ value: result.filter((item) => item.selected === true).map((item) => item.title) })
    } else if (IInputChoiceEnum.INPUT_CHOICE_RADIO === type) {
      const result = data.map((val, indexItem) => (index === indexItem 
        ? ({ ...val, selected: true }) 
        : ({ ...val, selected: false })))

      setData(result)
      onChange({ value: result.filter((item) => item.selected === true).map((item) => item.title) })
    }
  }
  
  React.useEffect(() => {
    if (typeof value === "object" && value !== null) {
      setData(value)
    }
  }, [value])

  return (
    <div style={width < 540 ? {
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      padding: "30px 0px",
    } : {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      flexWrap: "wrap",
      padding: "30px 0px",
    }}
    >
      {data.map(({ title, selected }, index) => (
        <ButtonChoice
          onClick={() => onChangeValue(index)} 
          style={selected ? { 
            width: width < 540 ? "100%" : "49%",
            border: "1px solid #0067b1",
            boxShadow: "2px 2px 3px #033d66", 
            transition: "all .2s",
          } : { width: width < 540 ? "100%" : "49%" }}
        >
          <TLight>
            {title}
          </TLight>
        </ButtonChoice>
      ))}
    </div>
  )
}