/* eslint-disable max-len */
import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useParams } from "react-router-dom"
import styled from "styled-components"
import {
  setEmailOtpAnswer,
  setMobileOtpAnswer,
  register,
  setOpenAccountReducer,
  getSuitQuestionSaga,
  navigateSetWithKey,
  getTFexQuestion,
  getAllDataSaga,
  sendEmailOtp,
  sendMobileOtp,
  getSaveAoStep,
  navigateNext,
  setOaStatus,
} from "../../actions"
import { GetUsers } from "../../apis";
import Header from "../../components/Header"
import MobileStepper from "../../components/MobileStepper"
import { styledMedia } from "../../components/PageWrapper"
import { showDialog } from "../../components/Provider/dialog"
import Stepper from "../../components/Stepper"
import { ComponentContext } from "../../contexts"
import {
  IComponentContextProps,
  IDialogTypeEnum, IGetUsers,
  IInputValueResponse,
  IOpenAccountShema,
  IStore, ISubPage,
  onChangeState, StatusOpenAccount,
} from "../../interfaces"
import { handleStatusError } from "../../sagas";
import {
  auth,
  encode, GetCurrentPage, usePageScreen, useWindowDimensions,
} from "../../utilities"
import Forms from "../../view/forms"
import { dataOpenAccount } from "../__mock__"

const Container = styled.div`${styledMedia}`

interface onChangeParams {
  key: string,
  value?: IInputValueResponse
}

export default () => {
  const { width } = useWindowDimensions()
  const dispatch = useDispatch()
  const { id } = useParams<{id: string}>();
  const {
    step,
    data,
    allowEdit,
    oaStatus,
  } = useSelector((state: IStore) => state.StepsReducer)
  const [dataLayout, setDataLayout] = useState<IOpenAccountShema[]>(
      [...dataOpenAccount.opanAccount] as IOpenAccountShema[],
  )

  const isVerifyingNdid = (curClientStep: any) => curClientStep?.type !== ISubPage.NDID_CONFIRM_IDP_INFO &&
      oaStatus !== StatusOpenAccount.VERIFIED &&
      oaStatus !== ""

  const onSetSteps = (value: string, type?: string) => {
    // get step
    const currentPage = GetCurrentPage(value)
    let allow = allowEdit[+currentPage.page]?.subStep?.[+currentPage.subPage]
    const currentClientStep = data[+currentPage.page]?.form?.[+currentPage.subPage]

    if (currentClientStep?.type === ISubPage.STEP_PREVIEW_DATA_FROM_NDID) {
      allow = allowEdit.find((elem: any) => elem.stepName === "STEP_VERIFY_NDID")?.subStep
        .find((elem: any) => elem.stepName === ISubPage.STEP_PREVIEW_DATA_FROM_NDID)
    }

    if (allow) {
      if (!isVerifyingNdid(currentClientStep) && (!allow.allowEdit && allow.isPass)) {
        dispatch(navigateNext())
        return
      }

      if (allow?.allowEdit) {
        dispatch(navigateSetWithKey({
          page: +GetCurrentPage(`${value}`).page,
          subPage: +GetCurrentPage(`${value}`).subPage,
          step: value,
        }))
        const encoded = encode(`${GetCurrentPage(`${value}`).page.concat(`.${GetCurrentPage(`${value}`).subPage}`)}`)
        window.history.pushState("", "", `/openAccount/${encoded}`);
        // eslint-disable-next-line no-useless-return
        return
      }

      if (type !== onChangeState.STEPPER) {
        dispatch(navigateSetWithKey({
          page: +GetCurrentPage(`${value}`).page,
          subPage: +GetCurrentPage(`${value}`).subPage,
          step: value,
        }))
      }

      if (type === onChangeState.STEPPER) {
        showDialog(
          IDialogTypeEnum.ERROR_DIALOG,
          { message: "ทำการยืนยันรายการแล้ว ไม่สามารถกดย้อนกลับได้" },
          false,
        )
      }
    } else {
      dispatch(navigateSetWithKey({
        page: +GetCurrentPage(`${value}`).page,
        subPage: +GetCurrentPage(`${value}`).subPage,
        step: value,
      }))
      const encoded = encode(`${GetCurrentPage(`${value}`).page.concat(`.${GetCurrentPage(`${value}`).subPage}`)}`)
      window.history.pushState("", "", `/openAccount/${encoded}`);
    }
  }

  const {
    page,
    index,
    nextPage,
    backPage,
  } = usePageScreen({
    data: dataLayout,
    initRout: step,
    onChange: onSetSteps,
    allowEdit,
  })

  const onChange = ({ key, value }: onChangeParams) => {
    const currentPage = GetCurrentPage(page)

    if (key === onChangeState.NEXT) {
      nextPage()
    } else if (key === onChangeState.BACK) {
      backPage()
    } else if (key === onChangeState.STEPPER) {
      onSetSteps(value as string, onChangeState.STEPPER)
    }

    // auth
    if (currentPage.page === "0") {
      if (key === onChangeState.SUBMIT) {
        dispatch(register({
          callback: () => {
            setTimeout(() => { dispatch(getAllDataSaga()) });
          },
        }))
      } else if (key === "emailOtp") {
        dispatch(setEmailOtpAnswer(value))
      } else if (key === "mobileOtp") {
        dispatch(setMobileOtpAnswer(value))
      }
    }

    // save info users
    if (+currentPage.page > 0 && (key !== onChangeState.NEXT) && (key !== onChangeState.BACK) && (key !== onChangeState.STEPPER)) {
      dispatch(setOpenAccountReducer({
        page: dataLayout[+currentPage.page].type,
        subPage: dataLayout[+currentPage.page].form[+currentPage.subPage].type,
        addValue: {
          [key]: value,
        },
      }))
    }
  }

  const contextValue: IComponentContextProps = { onChange };

  useEffect(() => {
    if (!auth.isAuthenticated()) {
      return
    }

    GetUsers()
      .then((res: IGetUsers) => {
        if (res?.errors) {
          handleStatusError(res?.errors)
          return
        }
        dispatch(setOaStatus({ oaStatus: res.data.GetUserInfo.oaStatus }))
      })
      .catch((error: any) => {
        console.error(error)
      })
  }, [])
  useEffect(() => {
    onSetSteps(id)
  }, [id])
  useEffect(() => { setDataLayout(data as IOpenAccountShema[]) }, [data])
  useEffect(() => {
    dispatch(navigateSetWithKey({
      data: dataOpenAccount.opanAccount,
    }))
    dispatch(getSuitQuestionSaga())
    dispatch(getTFexQuestion())

    if (id !== "0") {
      dispatch(getSaveAoStep())
      setTimeout(() => { dispatch(getAllDataSaga()) });
    }

    if (id === "0.0" || `${id}` === "0") {
      dispatch(sendEmailOtp())
      dispatch(sendMobileOtp())
    }
  }, [])

  return (
    <Container>
      <ComponentContext.Provider value={contextValue}>
        <Header />
        <div>
          {
            width < 540 ? (
              <MobileStepper
                steps={dataLayout.map((val) => ({ title: val.title }))}
                maxPageOfStep={dataLayout.length}
                maxSubPageOfStep={dataLayout[index].form.length}
                activeStep={page}
              />
            ) : (
              <Stepper
                steps={dataLayout.map((val) => ({ title: val.title }))}
                maxPageOfStep={dataLayout[index].form.length}
                activeStep={page}
                onChange={onChange}
              />
            )
          }

        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Forms
            page={page}
            data={dataLayout}
            onChange={onChange}
          />
        </div>
      </ComponentContext.Provider>
    </Container>
  )
}
