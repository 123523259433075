import React from "react"
import { CircularProgressbarWithChildren } from "react-circular-progressbar";

export default ({
  an,
  mn,
  percent,
}: {
  an: number
  mn: number
  percent: number
}) => (
  <div style={{ width: 56, height: 56 }}>
    <CircularProgressbarWithChildren 
      value={percent}
      styles={{
        root: {},
        path: {
          strokeLinecap: "round",
          strokeLinejoin: "round",
          stroke: "#70CDE3",
          strokeWidth: 9,
          transition: "stroke-dashoffset 0.5s ease 0s",
        },
        trail: {
          stroke: "#CDCDCD",
          strokeWidth: 3,
          strokeLinecap: "butt",
          opacity: 0.5,
        },
      }}
    >
      <div style={{ fontSize: 20, marginTop: -13, color: "#0067B1" }}>
        <strong>
          {an}
          /
          {mn}
        </strong>
      </div>
    </CircularProgressbarWithChildren>
  </div>
)