import React from "react"
import PageNotFoundImg from "../../assets/images/404_page_not_found.png"

const PageNotFoundStyle = {
  display: "flex",
  width: "50%",
  marginLeft: "25%",
}

const PageNotFound = () => (
  <>
    <img src={PageNotFoundImg} style={PageNotFoundStyle} alt="" />
  </>
)

export default PageNotFound