import { ITFexQuestion } from "../interfaces/tfex.interface"
import { useQueryAuthen } from "./apiCall"

export const GetTfexQuestionApi = () => {
  const gql = `query getTfexQuestion {
    GetTfexQuestion {
      tfexQuestionId
      group
      questionTh
      questionEn
      answer
      order
    }
  }`

  return useQueryAuthen(gql)
}

export const PreviewTfexTestApi = (input: Array<ITFexQuestion>) => {
  const gql = `mutation previewTfexTest($input: TfexTestInput) {
    PreviewTfexTest(input: $input) {
      percent
      isPass
      correct
      incorrect
      tfex {
        tfexQuestionId
        group
        questionTh
        questionEn
        answer
        order
      }
      createdAt
      updatedAt
    }
  }`
  const variables = {
    input: {
      tfex: input,
    },
  }
  return useQueryAuthen(gql, variables)
}

export const SaveTfexTestApi = (input: Array<ITFexQuestion>) => {
  const gql = `mutation saveTfexTest($input: TfexTestInput) {
    SaveTfexTest(input: $input) {
      isSuccess
      message
    }
  }`
  const variables = {
    input: {
      tfex: input,
    },
  }
  return useQueryAuthen(gql, variables)
}