import React, { useContext } from "react"
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components"
import { getAllDataSaga, getSuitAnswerSaga, submitSaga } from "../../../actions";
import {
  Buttons,
  Card,
  Texts,
  Divider,
  TExtraLight,
  TMedium,
  SuittestRiskLevel,
  Inputs,
  TBold,
} from "../../../components"
import Image, { PdfSVG } from "../../../components/Images"
import { fonts, mainFontSize } from "../../../config/themes";
import { ComponentContext } from "../../../contexts"
import {
  fileProps,
  ICheckBoxs,
  IFormsShema,
  InputEnum,
  IOpenAccountShema,
  IStore,
} from "../../../interfaces";
import { DateFormat } from "../../../utilities";

interface props {
  page: string
  data?: IOpenAccountShema[],
  dataOfPage: IFormsShema[],
}

const ImageHolder = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`
const IconHolder = styled.div`
  display: flex;
  margin-top: 20px;
  margin-bottom: 5px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex:1;
  height: 100%;
  width: 100%;
`

const ImageHoler = styled.img`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex:1;
  height: 100%;
  width: 100%;
`

interface SchemaPreviewData {
  header: string
  component?: () => void
  items?: {
    header: string
    subHeader: string
    rows: boolean | number
    isHidden?: any,
    items: {
      type?: keyof typeof InputEnum
      label: string
      value: any
      images: Array<fileProps>
    }[]
  }[]
}

export default ({
  page,
  data,
  dataOfPage,
}: props) => {
  const dispatch = useDispatch()
  const openAccount = useSelector((state: IStore) => state.OpeningAccountReducer)
  const suitAnswer = useSelector((state: IStore) => state.SuitReducer.answer)
  const { previewTestScore } = useSelector((state: IStore) => state.SuitReducer)

  const { t } = useTranslation()
  const index = +page.split(".")[1] || 0
  const dataDisplay = dataOfPage[index]

  const componentContext = useContext(ComponentContext);

  const onChange = (val: {key?: string, value?: string | number}) => {
    componentContext?.onChange({ key: val.key || "", value: val.value })
  };

  React.useEffect(() => {
    dispatch(getAllDataSaga())
    dispatch(getSuitAnswerSaga())
  }, [])

  const dataLayout: Array<SchemaPreviewData> = [
    {
      header: t("informationPreview.title"),
      items: [
        {
          header: t("informationPreview.subtitle1"),
          subHeader: t("informationPreview.header1"),
          rows: true,
          items: [
            {
              label: t("informationPreview.subheader1.1"),
              value: openAccount.idCardInformation.STEP_ID_CARD_INFO?.citizenId,
              images: [],
            },
            {
              label: t("informationPreview.subheader1.3"),
              value: DateFormat(openAccount.idCardInformation.STEP_ID_CARD_INFO?.expiryDate, " "),
              images: [],
            },
            {
              label: t("informationPreview.subheader1.5"),
              value: openAccount.idCardInformation.STEP_ID_CARD_INFO?.title,
              images: [],
            },
            {
              label: t("informationPreview.subheader1.6"),
              value: `${openAccount.idCardInformation.STEP_ID_CARD_INFO?.firstNameTh} ${ 
                openAccount.idCardInformation.STEP_ID_CARD_INFO?.lastNameTh}`,
              images: [],
            },
            {
              label: t("informationPreview.subheader1.7"),
              value: openAccount.idCardInformation.STEP_ID_CARD_INFO.titleEn,
              images: [],
            },
            {
              label: t("informationPreview.subheader1.8"),
              value: `${openAccount.idCardInformation.STEP_ID_CARD_INFO?.firstNameEn} ${
                openAccount.idCardInformation.STEP_ID_CARD_INFO?.lastNameEn}`,
              images: [],
            },
            {
              label: t("informationPreview.subheader1.9"),
              value: openAccount.idCardInformation.STEP_ID_CARD_INFO?.birthDate,
              images: [],
            },
            {
              label: t("informationPreview.subheader1.10"),
              value: openAccount.idCardInformation.STEP_ID_CARD_INFO?.gender,
              images: [],
            },
            {
              label: t("informationPreview.subheader1.11"),
              value: openAccount.idCardInformation.STEP_ID_CARD_INFO?.nationality,
              images: [],
            },
          ],
        },
        {
          header: "",
          subHeader: "ภาพถ่ายบัตรประชาชน",
          rows: 1,
          items: [
            {
              label: "",
              value: "",
              images: openAccount.idCardInformation.STEP_IMAGE_ID_CARD?.IMAGE_ID_CARD
              && openAccount.idCardInformation.STEP_IMAGE_ID_CARD?.IMAGE_ID_CARD,
            },
          ],
        },
        {
          header: "",
          subHeader: "ภาพถ่ายบัตรประชาชนพร้อมเจ้าของบัตร",
          rows: 1,
          items: [
            {
              label: "",
              value: "",
              images: openAccount.idCardInformation
                .STEP_IMAGE_SELFIE_WITH_ID_CARD?.IMAGE_SELFIE_WITH_ID_CARD
              && openAccount.idCardInformation
                .STEP_IMAGE_SELFIE_WITH_ID_CARD?.IMAGE_SELFIE_WITH_ID_CARD,
            },
          ],
        },
      ],
    },
    {
      header: "",
      items: [
        {
          header: t("informationPreview.subtitle2"),
          subHeader: t("informationPreview.header2.1"),
          rows: true,
          items: [
            {
              label: t("informationPreview.subheader2.1"),
              value: openAccount.termAndConditoin.STEP_VERIFY_FATCA?.isAmerican,
              images: [],
            },
          ],
        },
        {
          header: "",
          subHeader: t("informationPreview.header2.2"),
          rows: true,
          items: [
            {
              label: t("informationPreview.subheader2.2"),
              value: openAccount.personalInformation?.STEP_MARITAL_STATUS?.isSpouse,
              images: [],
            },
            {
              label: t("informationPreview.subheader2.3"),
              value: `${openAccount.personalInformation?.STEP_MARITAL_STATUS?.firstNameTh} ${ 
                openAccount.personalInformation?.STEP_MARITAL_STATUS?.lastNameTh}`,
              images: [],
            },
            {
              label: t("informationPreview.subheader2.4"),
              value: `${openAccount.personalInformation?.STEP_MARITAL_STATUS?.firstNameEn} ${ 
                openAccount.personalInformation?.STEP_MARITAL_STATUS?.lastNameEn}`,
              images: [],
            },
          ],
        },
        {
          header: "",
          subHeader: t("informationPreview.header2.3"),
          rows: false,
          items: [
            {
              label: t("informationPreview.subheader2.6"),
              value: openAccount.termAndConditoin?.STEP_VERIFY_PEP?.isPep,
              images: [],
            },
            {
              label: t("informationPreview.subheader2.7"),
              value: openAccount.termAndConditoin?.STEP_VERIFY_PEP?.otherPep,
              images: [],
            },
          ],
        },
        {
          header: "",
          subHeader: t("informationPreview.header2.4"),
          rows: true,
          items: [
            {
              label: t("informationPreview.subheader2.8"), /// ประเทศของแหล่งที่มาของรายได้/เงินทุน
              value: openAccount.personalInformation?.STEP_INCOME?.countryOfIncome,
              images: [],
            },
            {
              label: "ประเทศของแหล่งที่มาของรายได้/เงินทุนอื่นๆ", /// ประเทศของแหล่งที่มาของรายได้/เงินทุน
              value: openAccount.personalInformation?.STEP_INCOME?.otherCountryOfIncome,
              images: [],
            },
            {
              label: t("informationPreview.subheader2.9"),
              value: openAccount.personalInformation?.STEP_INCOME?.sourceOfIncome,
              images: [],
            },
            {
              label: "แหล่งที่มาของรายได้อื่นๆ",
              value: openAccount.personalInformation?.STEP_INCOME?.otherSourceOfIncome,
              images: [],
            },
            {
              label: t("informationPreview.subheader2.11"),
              value: openAccount.personalInformation?.STEP_INCOME?.purposeOfInvest,
              images: [],
            },
            {
              label: `${t("informationPreview.subheader2.11")}อื่นๆ`,
              value: openAccount.personalInformation?.STEP_INCOME?.otherPurposeOfInvest,
              images: [],
            },
            {
              label: t("informationPreview.subheader2.10"),
              value: openAccount.personalInformation?.STEP_INCOME?.salary,
              images: [],
            },
            {
              label: "มูลค่าทรัพย์สิน (บาท)",
              value: openAccount.personalInformation?.STEP_INCOME?.propertyValue,
              images: [],
            },
          ],
        },
        {
          header: "",
          subHeader: t("informationPreview.header2.5"),
          rows: true,
          items: [
            {
              label: t("informationPreview.address1"),
              value: openAccount.personalInformation?.STEP_ID_CARD_ADDRESS?.houseNumber,
              images: [],
            },
            {
              label: t("informationPreview.address2"),
              value: openAccount.personalInformation?.STEP_ID_CARD_ADDRESS?.moo,
              images: [],
            },
            {
              label: t("informationPreview.address3"),
              value: openAccount.personalInformation?.STEP_ID_CARD_ADDRESS?.building,
              images: [],
            },
            {
              label: t("informationPreview.address4"),
              value: openAccount.personalInformation?.STEP_ID_CARD_ADDRESS?.roomNumber,
              images: [],
            },
            {
              label: t("informationPreview.address5"),
              value: openAccount.personalInformation?.STEP_ID_CARD_ADDRESS?.floor,
              images: [],
            },
            {
              label: t("informationPreview.address6"),
              value: openAccount.personalInformation?.STEP_ID_CARD_ADDRESS?.alley,
              images: [],
            },
            {
              label: t("informationPreview.address7"),
              value: openAccount.personalInformation?.STEP_ID_CARD_ADDRESS?.road,
              images: [],
            },
            {
              label: t("informationPreview.address8"),
              value: openAccount.personalInformation?.STEP_ID_CARD_ADDRESS?.subDistrict,
              images: [],
            },
            {
              label: t("informationPreview.address9"),
              value: openAccount.personalInformation?.STEP_ID_CARD_ADDRESS?.district,
              images: [],
            },
            {
              label: t("informationPreview.address10"),
              value: openAccount.personalInformation?.STEP_ID_CARD_ADDRESS?.province,
              images: [],
            },
            {
              label: t("informationPreview.address11"),
              value: openAccount.personalInformation?.STEP_ID_CARD_ADDRESS?.postalCode,
              images: [],
            },
          ],
        },
        {
          header: "",
          subHeader: "ภาพถ่ายทะเบียนบ้าน",
          rows: 1,
          items: [
            {
              label: "",
              value: "",
              images: openAccount.personalInformation
                .STEP_IMAGE_HOUSE_REGISTRATION?.IMAGE_HOUSE_REGISTRATION
              && openAccount.personalInformation
                .STEP_IMAGE_HOUSE_REGISTRATION?.IMAGE_HOUSE_REGISTRATION,
            },
          ],
        },
        {
          header: "",
          subHeader: t("informationPreview.header2.6"),
          rows: true,
          items: [
            {
              label: "",
              type: "inputRadio",
              value: openAccount.personalInformation?.STEP_CURRENT_ADDRESS?.isSameHomeAddress,
              images: [],
            },
            {
              label: t("informationPreview.address1"),
              value: openAccount.personalInformation?.STEP_CURRENT_ADDRESS?.houseNumber,
              images: [],
            },
            {
              label: t("informationPreview.address2"),
              value: openAccount.personalInformation?.STEP_CURRENT_ADDRESS?.moo,
              images: [],
            },
            {
              label: t("informationPreview.address3"),
              value: openAccount.personalInformation?.STEP_CURRENT_ADDRESS?.building,
              images: [],
            },
            {
              label: t("informationPreview.address4"),
              value: openAccount.personalInformation?.STEP_CURRENT_ADDRESS?.roomNumber,
              images: [],
            },
            {
              label: t("informationPreview.address5"),
              value: openAccount.personalInformation?.STEP_CURRENT_ADDRESS?.floor,
              images: [],
            },
            {
              label: t("informationPreview.address6"),
              value: openAccount.personalInformation?.STEP_CURRENT_ADDRESS?.alley,
              images: [],
            },
            {
              label: t("informationPreview.address7"),
              value: openAccount.personalInformation?.STEP_CURRENT_ADDRESS?.road,
              images: [],
            },
            {
              label: t("informationPreview.address8"),
              value: openAccount.personalInformation?.STEP_CURRENT_ADDRESS?.subDistrict,
              images: [],
            },
            {
              label: t("informationPreview.address9"),
              value: openAccount.personalInformation?.STEP_CURRENT_ADDRESS?.district,
              images: [],
            },
            {
              label: t("informationPreview.address10"),
              value: openAccount.personalInformation?.STEP_CURRENT_ADDRESS?.province,
              images: [],
            },
            {
              label: t("informationPreview.address11"),
              value: openAccount.personalInformation?.STEP_CURRENT_ADDRESS?.postalCode,
              images: [],
            },
          ],
        },
        {
          header: "",
          subHeader: t("informationPreview.header2.7"),
          rows: true,
          items: [
            {
              label: "อาชีพ",
              value: openAccount.personalInformation?.STEP_WORKPLACE_ADDRESS?.career,
              images: [],
            },
            {
              label: "ตำแหน่งงาน",
              value: openAccount.personalInformation?.STEP_WORKPLACE_ADDRESS?.position,
              images: [],
            },
            {
              label: "ประเภทธุรกิจ",
              value: openAccount.personalInformation?.STEP_WORKPLACE_ADDRESS?.typeBusiness,
              images: [],
            },
            {
              label: "ชื่อสถานที่ทำงาน",
              value: openAccount.personalInformation?.STEP_WORKPLACE_ADDRESS?.nameWorkplace,
              images: [],
            },

            {
              label: t("informationPreview.address1"),
              value: openAccount.personalInformation?.STEP_WORKPLACE_ADDRESS?.houseNumber,
              images: [],
            },
            {
              label: t("informationPreview.address2"),
              value: openAccount.personalInformation?.STEP_WORKPLACE_ADDRESS?.moo,
              images: [],
            },
            {
              label: t("informationPreview.address3"),
              value: openAccount.personalInformation?.STEP_WORKPLACE_ADDRESS?.building,
              images: [],
            },
            {
              label: t("informationPreview.address4"),
              value: openAccount.personalInformation?.STEP_WORKPLACE_ADDRESS?.roomNumber,
              images: [],
            },
            {
              label: t("informationPreview.address5"),
              value: openAccount.personalInformation?.STEP_WORKPLACE_ADDRESS?.floor,
              images: [],
            },
            {
              label: t("informationPreview.address6"),
              value: openAccount.personalInformation?.STEP_WORKPLACE_ADDRESS?.alley,
              images: [],
            },
            {
              label: t("informationPreview.address7"),
              value: openAccount.personalInformation?.STEP_WORKPLACE_ADDRESS?.road,
              images: [],
            },
            {
              label: t("informationPreview.address8"),
              value: openAccount.personalInformation?.STEP_WORKPLACE_ADDRESS?.subDistrict,
              images: [],
            },
            {
              label: t("informationPreview.address9"),
              value: openAccount.personalInformation?.STEP_WORKPLACE_ADDRESS?.district,
              images: [],
            },
            {
              label: t("informationPreview.address10"),
              value: openAccount.personalInformation?.STEP_WORKPLACE_ADDRESS?.province,
              images: [],
            },
            {
              label: t("informationPreview.address11"),
              value: openAccount.personalInformation?.STEP_WORKPLACE_ADDRESS?.postalCode,
              images: [],
            },
          ],
        },
        {
          header: "",
          subHeader: t("informationPreview.header2.8"),
          rows: true,
          items: [
            {
              label: "",
              type: "inputRadio",
              value: openAccount.personalInformation?.STEP_MAILING_ADDRESS?.sameSomeAddress,
              images: [],
            },
            {
              label: "",
              type: "inputRadio",
              value: openAccount.personalInformation?.STEP_MAILING_ADDRESS?.isSameHomeAddress,
              images: [],
            },
            {
              label: t("informationPreview.address1"),
              value: openAccount.personalInformation?.STEP_MAILING_ADDRESS?.houseNumber,
              images: [],
            },
            {
              label: t("informationPreview.address2"),
              value: openAccount.personalInformation?.STEP_MAILING_ADDRESS?.moo,
              images: [],
            },
            {
              label: t("informationPreview.address3"),
              value: openAccount.personalInformation?.STEP_MAILING_ADDRESS?.building,
              images: [],
            },
            {
              label: t("informationPreview.address4"),
              value: openAccount.personalInformation?.STEP_MAILING_ADDRESS?.roomNumber,
              images: [],
            },
            {
              label: t("informationPreview.address5"),
              value: openAccount.personalInformation?.STEP_MAILING_ADDRESS?.floor,
              images: [],
            },
            {
              label: t("informationPreview.address6"),
              value: openAccount.personalInformation?.STEP_MAILING_ADDRESS?.alley,
              images: [],
            },
            {
              label: t("informationPreview.address7"),
              value: openAccount.personalInformation?.STEP_MAILING_ADDRESS?.road,
              images: [],
            },
            {
              label: t("informationPreview.address8"),
              value: openAccount.personalInformation?.STEP_MAILING_ADDRESS?.subDistrict,
              images: [],
            },
            {
              label: t("informationPreview.address9"),
              value: openAccount.personalInformation?.STEP_MAILING_ADDRESS?.district,
              images: [],
            },
            {
              label: t("informationPreview.address10"),
              value: openAccount.personalInformation?.STEP_MAILING_ADDRESS?.province,
              images: [],
            },
            {
              label: t("informationPreview.address11"),
              value: openAccount.personalInformation?.STEP_MAILING_ADDRESS?.postalCode,
              images: [],
            },
          ],
        },
      ],
    },
    {
      header: "",
      items: [
        {
          header: t("informationPreview.header3"),
          subHeader: t("informationPreview.subtitle3"),
          rows: true,
          items: [
            {
              label: t("informationPreview.subheader3.1"),
              value: openAccount.accountInformation?.STEP_SAVING_ACCOUNT_SUBSCRIPTION?.bankName,
              images: [],
            },
            {
              label: t("informationPreview.subheader3.2"),
              value: openAccount.accountInformation?.STEP_SAVING_ACCOUNT_SUBSCRIPTION?.accountNo,
              images: [],
            },
            {
              label: t("informationPreview.subheader3.3"),
              value: openAccount.accountInformation?.STEP_SAVING_ACCOUNT_SUBSCRIPTION?.branch,
              images: [],
            },
            {
              label: t("informationPreview.subheader3.4"),
              value: openAccount.accountInformation?.STEP_SAVING_ACCOUNT_SUBSCRIPTION?.accountName,
              images: [],
            },
            {
              label: t("informationPreview.subheader3.5"),
              value: "",
              images: openAccount.accountInformation?.STEP_SAVING_ACCOUNT_SUBSCRIPTION
                ?.IMAGE_BOOKBANK_SUBSCRIPTION && openAccount
                .accountInformation?.STEP_SAVING_ACCOUNT_SUBSCRIPTION?.IMAGE_BOOKBANK_SUBSCRIPTION,
            },
          ],
        },
        {
          header: "",
          subHeader: "บัญชีธนาคารสําหรับรับเงินค่าขายหลักทรัพย์ดอกเบี้ย เงินปันผล",
          rows: true,
          // isHidden: openAccount.accountInformation?.STEP_SAVING_ACCOUNT_REDEMPTION?.political
          //   .find((val: any) => !val.requireOther && val.selected),
          items: [
            {
              label: "",
              type: "inputRadio",
              value: openAccount.accountInformation?.STEP_SAVING_ACCOUNT_REDEMPTION?.political,
              images: [],
            },
            {
              label: t("informationPreview.subheader3.1"),
              value: openAccount.accountInformation?.STEP_SAVING_ACCOUNT_REDEMPTION?.bankName,
              images: [],
            },
            {
              label: t("informationPreview.subheader3.2"),
              value: openAccount.accountInformation?.STEP_SAVING_ACCOUNT_REDEMPTION?.accountNo,
              images: [],
            },
            {
              label: t("informationPreview.subheader3.3"),
              value: openAccount.accountInformation?.STEP_SAVING_ACCOUNT_REDEMPTION?.branch,
              images: [],
            },
            {
              label: t("informationPreview.subheader3.4"),
              value: openAccount.accountInformation?.STEP_SAVING_ACCOUNT_REDEMPTION?.accountName,
              images: [],
            },
            {
              label: t("informationPreview.subheader3.5"),
              value: "",
              images: openAccount.accountInformation?.STEP_SAVING_ACCOUNT_REDEMPTION
                ?.IMAGE_BOOKBANK_REDEMPTION && openAccount
                .accountInformation?.STEP_SAVING_ACCOUNT_REDEMPTION?.IMAGE_BOOKBANK_REDEMPTION,
            },
          ],
        },
        {
          header: "",
          subHeader: "ภาพถ่าย statement",
          rows: 1,
          items: [
            {
              label: "",
              value: "",
              images: openAccount.accountInformation
                .STEP_IMAGE_STATEMENT?.IMAGE_STATEMENT
              && openAccount.accountInformation
                .STEP_IMAGE_STATEMENT?.IMAGE_STATEMENT,
            },
          ],
        },
      ],
    },
    {
      header: "แบบประเมินความเหมาะสมในการลงทุนสำหรับบุคคลธรรมดา",
      component: () => <SuittestRiskLevel title="ผลประเมินความเสี่ยง" level={suitAnswer?.investorLevel} />,
    },
    {
      header: "",
      items: [
        {
          header: "ผู้แนะนำ หรือเจ้าหน้าที่ผู้แนะนำการลงทุน",
          subHeader: "",
          rows: true,
          items: [
            {
              label: "รหัส Trader ID",
              value: openAccount
                .termAndConditoin
                .STEP_SELECT_OPEN_TYPE_ACCOUNT
                .codeMaketingRecomment,
              images: [],
            },
            {
              label: "ชื่อ นามสกุล",
              value: openAccount
                .termAndConditoin
                .STEP_SELECT_OPEN_TYPE_ACCOUNT
                .noCodeMarketingName,
              images: [],
            },
          ],
          isHidden: false,
        },
      ],
    },
  ]

  const adepterPreviewInfo = (value: string | ICheckBoxs[]) => (
    (Array.isArray(value))
      ? value.filter((val) => val.selected).map((val) => val.title).toString()
      : value
  )

  const onNext = () => {
    dispatch(submitSaga())
  }

  const onPdfClick = (file: fileProps) => {
    if (!file?.data) return
    const fileURL = URL.createObjectURL(file?.data as File);
    const pdfWindow = window.open(fileURL);
  }

  const FileRender = ({ file }: {file: fileProps}) => (
    <div
      style={{
        position: "relative",
        borderRadius: 10,
        padding: 10,
        paddingTop: 15,
        marginTop: 20,
        marginBottom: 36,
        marginLeft: 30,
        marginRight: 30,
      }}
    >
      {
        file.contentType?.includes("pdf") ? (
          <button
            onClick={() => onPdfClick(file)}
            style={{
              width: "100%", height: "100%", background: "transparent", border: "0px solid", cursor: "pointer",
            }}
          >
            <IconHolder>
              <Image style={{ width: "150px", height: "150px" }} src={PdfSVG} />
              <TBold color="#666666" fontSize={mainFontSize.l} mt="2px">
                {file.name}
              </TBold>
            </IconHolder>
          </button>
        ) : (
          <button
            aria-label="Open PDF file"
            onClick={() => onPdfClick(file)}
            style={{
              width: "100%", height: "100%", background: "transparent", border: "0px solid", cursor: "pointer",
            }}
          >
            <ImageHoler src={URL.createObjectURL(file.data as File)} />
          </button>
        )
      }

    </div>
  )

  return (
    <Card>

      <div style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
      }}
      >
        <Texts style={{
          color: "#0C67B1",
          padding: "5px 0px 5px 0px",
          fontSize: mainFontSize.l,
          fontFamily: fonts.SarabunMedium,
        }}
        >
          {t("cardSuccess.title")}
        </Texts>
      </div>

      {
        dataLayout.map((groupItems) => (
          <>
            <Texts style={{ color: "#0c67b1", padding: "5px 0px 5px 0px" }} />

            {
              groupItems?.component ?
                (
                  <>
                    <Texts style={{ color: "#0c67b1", padding: "5px 0px 5px 0px", fontSize: mainFontSize.m }}>{groupItems.header}</Texts>
                    {groupItems?.component()}
                  </>
                )
                :
                groupItems?.items?.map((groupSubItems, groupSubIndex) => (
                  groupSubItems.isHidden ? null : (
                    <>
                      <Texts style={{ color: "#0c67b1", padding: "5px 0px 5px 0px", fontSize: mainFontSize.m }}>{groupSubItems.header}</Texts>
                      <Texts style={{ color: "#0c67b1", padding: "5px 0px 5px 0px", fontSize: mainFontSize.xs }}>{groupSubItems.subHeader}</Texts>

                      {
                        groupSubItems.items.map((val) => val.type && (
                          <div style={{ display: "flex", flex: 1 }}>
                            <Inputs
                              config={{
                                type: "inputRadio",
                                disabled: true,
                              }}
                              value={val.value as any}
                            />
                          </div>
                        ))
                      }

                      {
                        groupSubItems.items
                          .filter((val) => val.type && val.value?.find((vv: {
                            requireOther: boolean; selected: boolean;
                          }) => !vv.requireOther && vv.selected))
                          .some((val) => val) ? null : (
                            <div style={{ display: "flex", flexDirection: groupSubItems.rows ? "row" : "column", flexWrap: "wrap" }}>
                              {
                                groupSubItems.items
                                  .filter((val) => !val.type)
                                  .map((items: any) => (
                                    <div key={`items-${items.label}`} style={{ flex: "50%", margin: "10px 0px" }}>
                                      <TMedium color="#333333" mt="5px" mb="5px" fontSize={mainFontSize.s}>{items.label}</TMedium>
                                      <TExtraLight
                                        color="#666666"
                                        mt="5px"
                                        mb="5px"
                                        fontSize={mainFontSize.s}
                                      >
                                        {items.images.length > 0 ? "" : adepterPreviewInfo(items.value) || "-"}
                                      </TExtraLight>

                                      {
                                        items?.images?.map((file: fileProps, key: number) => (
                                          <>
                                            <FileRender
                                              file={file}
                                              key={`${key.toString()}-${file.name}`}
                                            />
                                            {(items?.images?.length > 1 && items?.images?.length - 1 !== key) && <Divider type="THICK" />}
                                          </>
                                        ))
                                      }
                                    </div>
                                  ))
                              }
                            </div>
                          )
                      }

                      { ((groupItems.items && groupItems.items.length - 1) !== groupSubIndex) && <Divider type="LIGHT" />}
                    </>
                  )
                ))
            }
            <Divider type="THICK" />
          </>
        ))
      }

      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <Buttons
          style={{
            backgroundColor: "#ffffff",
            color: "#0067b1",
            borderWidth: 1,
            borderColor: "#0067b1",
          }}
          onClick={() => onChange({ key: "BACK", value: 0 })}
        >
          {t("informationPreview.buttonBack")}
        </Buttons>

        <Buttons onClick={onNext}>
          {t("informationPreview.buttonNext")}
        </Buttons>
      </div>
    </Card>
  )
}
