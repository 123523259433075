import Swal, { SweetAlertIcon } from "sweetalert2"
import { errorCallApi } from "../config/alert.config.json"

export const alertCallErrorApi = () => {
  const alertConfig = { 
    icon: errorCallApi.icon as SweetAlertIcon, 
    title: errorCallApi.title, 
    text: errorCallApi.text,
  }
  Swal.fire(alertConfig)
}
