import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  sendEmailOtp,
  sendMobileOtp,
  setEmailOtpAnswer,
  setMobileOtpAnswer,
} from "../../../actions";
import {
  Buttons,
  Card,
  Texts,
} from "../../../components"
import { Email, Chat } from "../../../components/Images";
import InputWithButton from "../../../components/Inputs/inputWithButton";
import { fonts, mainFontSize } from "../../../config/themes";
import { ComponentContext } from "../../../contexts"
import { IStore, IRule, IRuleEnum } from "../../../interfaces";
import { SecureString, textInputValidate } from "../../../utilities";

interface props {
  page: string
  data?: any
  dataOfPage?: any
}

export default ({
  page, dataOfPage, data, 
}: props) => {
  const [errorMessages, setErrorMessages] = React.useState<Array<string>>(["", ""])

  const dispatch = useDispatch()
  const index = +page.split(".")[1] || 0
  const dataDisplay = dataOfPage[index]
  const { t } = useTranslation()
  const Auth = useSelector((state: IStore) => state.AuthReducer)
  const componentContext = useContext(ComponentContext);
  const {
    email,
    mobileNo,
    emailOtp,
    mobileOtp,
    mobileOtpAnswer,
    emailOtpAnswer,
  } = Auth

  const onChange = (val: {key?: string, value?: string | number}) => {
    componentContext?.onChange({ key: val.key || "", value: val.value })
  }

  const onSendMobileOtp = () => {
    dispatch(sendMobileOtp())
    dispatch(setMobileOtpAnswer(""))
  }

  const onSendEmailOtp = () => {
    dispatch(sendEmailOtp())
    dispatch(setEmailOtpAnswer(""))
  }

  const otpvalidates: Array<IRule> = [
    { type: IRuleEnum.REQUIRED }, 
    { 
      type: IRuleEnum.FORMAT, 
      regex: /^\d{6}$/,
    }, 
  ]
  
  const values = [emailOtpAnswer, mobileOtpAnswer]

  const validate = (): { errors: Array<string>, validateFlag: boolean } => {
    const errors: Array<string> = []
    let validateFlag = true
    
    for (let i = 0; i < values.length; i += 1) {
      const val = values[i] as string
      let error = ""
      error = textInputValidate(otpvalidates, val)
      if (error) validateFlag = false
      
      errors.push(error)
    }
    return { errors, validateFlag }
  }

  const onClick = () => {
    const validateRes = validate()
    setErrorMessages(validateRes.errors)
    if (validateRes.validateFlag) onChange({ key: "SUBMIT", value: 0 })
  }

  return (
    <Card>
      <div style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between", 
      }}
      >
        <Texts style={{ 
          color: "#0C67B1", 
          padding: "5px 0px 5px 0px", 
          fontSize: mainFontSize.xl, 
          fontFamily: fonts.SarabunMedium,
        }}
        >
          {dataDisplay.title}
        </Texts>
      </div>

      <div style={{ marginBottom: 28 }} />
      <div>
        
        <Texts style={{ 
          color: "#333333", 
          padding: "0px 10px 5px 0px", 
          lineHeight: 2,
          fontSize: mainFontSize.s, 
          fontFamily: fonts.SarabunLight,
        }}
        >
          {t("checkSecurity.subTitle")}
        </Texts>

        <div style={{ display: "flex", flexDirection: "column" }}>
          <InputWithButton
            value={emailOtpAnswer}
            title="การยืนยันด้วยอีเมล"
            titleImage={Email} 
            ImageWidth="40px"
            ImageHeight="28px"
            subtitles={[`กรอกรหัส 6 หลักที่ได้รับทางอีเมล ${SecureString(email || "")}`, `ref: ${emailOtp?.otpRequest?.reference || "-"}`]}
            buttonTitle="ส่งอีเมล" 
            onClick={onSendEmailOtp}
            errorMessage={errorMessages[0]}
            maxlength={6}
            onChange={(val) => onChange({ key: "emailOtp", value: val.value })} 
          />
          <InputWithButton
            value={mobileOtpAnswer}
            title="การยืนยันด้วยเบอร์มือถือ"
            titleImage={Chat} 
            ImageWidth="34px"
            ImageHeight="42px"
            subtitles={[`กรอกรหัส 6 หลักที่ได้รับทางเบอร์มือถือ ${SecureString(mobileNo || "")}`, `ref: ${mobileOtp?.otpRequest?.reference || "-"}`]} 
            buttonTitle="ส่ง OTP" 
            onClick={onSendMobileOtp}
            errorMessage={errorMessages[1]}
            maxlength={6}
            onChange={(val) => onChange({ key: "mobileOtp", value: val.value })} 
          />
        </div>
        
        <div>
          <div style={{ display: "flex", justifyContent: "flex-start", alignItems: "center" }} />
        </div>
      </div>

      <div style={{ display: "flex", justifyContent: "center" }}>
        <Buttons onClick={onClick}>ยืนยัน</Buttons>
      </div>
    </Card>
  )
}