import React from "react"
import { Buttons, Images } from "../.."
import { IDialogProps } from "../../../interfaces"
import { ApproveFail, Success } from "../../Images"
import { TMedium } from "../../Texts"

const ButtonDecision = ({ params, onResolve }: any) => {
  if (params?.type === "cancelNdid") {
    return <Buttons onClick={() => onResolve(1, true)}>ทำรายการใหม่</Buttons>
  }
  if (params?.errCode === "1130") {
    return <Buttons onClick={() => onResolve(5, true)}>ลองใหม่</Buttons>
  }

  /*
   Alert OTP and send OTP don't reload window 
   Error 1119 1120 is mean OTP mobile or email incorrect
   Error 0410 is mean send OTP > 2 time per minute
   Error 0411 is mean Invaild OTP
   Error 1100 is mean can't register because data dup at register page
  */
 
  if (params?.errCode === "1119" || 
    params?.errCode === "0410" || 
    params?.errCode === "0411" || 
    params?.errCode === "1120" ||
    params?.errCode === "1100") {
    return <Buttons onClick={() => onResolve(1, true)}>ลองใหม่</Buttons>
  }
  
  return <Buttons onClick={() => onResolve(10, true)}>ลองใหม่</Buttons>
}

export default ({ onResolve, params }: IDialogProps) => (
  <div style={{
    width: 350,
    height: 250,
    background: "#FFFFFF",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    paddingLeft: 40,
    paddingRight: 40,
    paddingTop: 20,
    paddingBottom: 20,
    borderRadius: 15,
  }}
  >
    <div style={{ flex: 0.4, display: "flex", alignItems: "center" }}>
      <Images
        style={{
          width: "80px", 
          height: "80px", 
          padding: "5px", 
          flex: 1, 
          justifyContent: "center", 
        }}
        src={ApproveFail}
      />
      {/* <Images
        style={{
          width: "80px", 
          height: "80px", 
          padding: "5px", 
          flex: 1, 
          justifyContent: "center", 
        }}
        src={ApproveFail}
      /> */}
    </div>
    <div style={{ 
      flex: 0.4, textAlign: "center", fontWeight: "normal", display: "flex", alignItems: "center",
    }}
    >
      <TMedium>
        {params?.message}
      </TMedium>
    </div>
    <div style={{
      display: "flex",
      alignItems: "center", 
      flex: 0.2, 
    }}
    >
      {/* <Buttons onClick={() => onResolve(1, true)}>ลองใหม่</Buttons> */}
      {/* {
        params?.type === "cancelNdid"
          ? <Buttons onClick={() => onResolve(1, true)}>ทำรายการใหม่</Buttons>
          : <Buttons onClick={() => onResolve(1, true)}>ลองใหม่</Buttons>
      } */}
      <ButtonDecision params={params} onResolve={onResolve} />
    </div>
  </div>
)