import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import styled from "styled-components"
import { resetAoSteps } from "../../../actions";
import {
  Buttons,
  Card,
  Images,
  Texts, TMedium,
} from "../../../components"
import { SuccessLogo } from "../../../components/Images"
import { showDialog } from "../../../components/Provider/dialog";
import { fonts, mainFontSize } from "../../../config/themes";
import { IDialogTypeEnum, IFormsShema, IOpenAccountShema } from "../../../interfaces";
import { routerName } from "../../../interfaces/router.enum";

interface props {
  page: string
  data?: IOpenAccountShema[],
  dataOfPage: IFormsShema[],
}

const TextView = styled.div`
  background-color: rgba(204,204,204,0.15);
  border-radius: 10px;
  padding: 20px 10px 20px 10px;
  font-size: 11pt;
  line-height: 20pt;
  color: "#333333";
  font-weight: 0.6;
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
  margin-top: 30px;
  margin-right: 50px;
  margin-left: 50px;
  justify-content: center;
  align-items: center;
`

const ButtonHolder = styled.div`
  display: flex;
  justify-content: space-between;
  margin-right: 30px;
`

enum ResolvedCode {
  "CANCELLED",
  "EDIT",
}

const customButtons = ({ onResolve }: {onResolve: (code: any) => void}) => (
  <div style={{
    flexDirection: "row",
    display: "flex",
    justifyContent: "space-between",
    paddingTop: "45px",
  }}
  >
    <div style={{
      display: "flex",
      alignItems: "center",
      marginRight: "25px",
    }}
    >
      <Buttons
        style={{
          backgroundColor: "#ffffff",
          border: "1px solid #0067b1",
        }}
        onClick={() => onResolve(ResolvedCode.CANCELLED)}
      >
        <TMedium style={{ color: "#0C67B1" }}>ยกเลิก</TMedium>
      </Buttons>
    </div>
    <div style={{
      display: "flex",
      alignItems: "center",
    }}
    >
      <Buttons onClick={() => onResolve(ResolvedCode.EDIT)}>
        <TMedium>ตกลง</TMedium>
      </Buttons>
    </div>
  </div>
)

export default ({ page, dataOfPage, data }: props) => {
  const history = useHistory()
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const onNext = () => {
    history.push(routerName.approvalStatus)
  }

  const onBack = () => {
    showDialog(
      IDialogTypeEnum.NOTICE_DIALOG,
      {
        message: "หากลูกค้ายืนยันทำการแก้ไขข้อมูล ระบบจะเข้าสู่หน้าการเปิดประเภทบัญชีหลักทรัพย์อื่นๆ",
        ButtonComp: customButtons,
      },
    ).then((res) => {
      if (res === ResolvedCode.EDIT) {
        dispatch(resetAoSteps())
      }
    })
  }

  return (
    <Card>
      <div style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        marginBottom: 30,
      }}
      >
        <Texts style={{
          color: "#0C67B1",
          padding: "5px 0px 5px 0px",
          fontSize: mainFontSize.l,
          fontFamily: fonts.SarabunMedium,
        }}
        >
          {t("cardSuccess.title")}
        </Texts>
      </div>

      <TextView style={{
        width: "90%", height: "80%", margin: "auto", flex: 1,
      }}
      >
        <Images
          style={{
            width: "40%",
            height: "40%",
            padding: "",
            flex: 1,

          }}
          src={SuccessLogo}
        />

        <Texts style={{
          color: "#666666",
          padding: "5px 0px 5px 0px",
          fontSize: mainFontSize.s,
          fontFamily: fonts.SarabunLight,
        }}
        >
          {t("cardSuccess.message")}
        </Texts>
      </TextView>

      <ButtonHolder style={{ marginTop: 30 }}>
        <Buttons
          style={{
            backgroundColor: "#ffffff",
            color: "#0067b1",
            borderWidth: 1,
            borderColor: "#0067b1",
          }}
          onClick={onBack}
        >
          แก้ไขข้อมูล
        </Buttons>
        <Buttons onClick={onNext}>{t("cardSuccess.buttonNext")}</Buttons>

      </ButtonHolder>

    </Card>
  )
}
