import parse from "html-react-parser";
import React, { useContext } from "react"
import { color, fonts, mainFontSize } from "../../config/themes"
import { ComponentContext } from "../../contexts"
import {
  InputEnum,
  IInputsChoiceShema,
  IInputsShema,
  IInputValueResponse,
  IRuleEnum,
} from "../../interfaces"
import { TBold, TLight } from "../Texts"
import InputCommon from "../inputCommon"
import InputUploadMultipleFiles from "./InputUploadMultipleFiles"
import InputAutoComplete from "./inputAutocomplete"
import InputCheckbox from "./inputCheckbox"
import InputChoice from "./inputChoices"
import InputDatePicker from "./inputDatePicker"
import InputDropdown from "./inputDropdown"
import InputRadio from "./inputRadio"
import InputTermsAndCons from "./inputTermsAndCons"
import InputUploadFile from "./inputUploadFile"
import InputWithButton from "./inputWithButton"
import OTPInput from "./otpInput"
import { inputOptions } from "./type"
import "./otpInput/index.css"

export default ({
  style,
  value,
  config,
  onChange,
}: inputOptions) => {
  const componentContext = useContext(ComponentContext);

  if (!onChange) {
    onChange = (val: {key?: string, value: IInputValueResponse}) => {
      let tmpValue = val.value
      if (tmpValue && typeof tmpValue === "string") {
        tmpValue = tmpValue.trim()
      }

      componentContext?.onChange({ key: val?.key || config?.field || "", value: tmpValue })
    };
  }

  const hasRequired = () => {
    const isRequired =
      config?.rules?.some((validate) => validate.type === IRuleEnum.REQUIRED)
    return isRequired
  }

  return (
    <div id={config?.field} style={{ display: "flex", flexDirection: "column" }}>
      {config?.title && (
        <div
          style={{
            marginTop: 10, display: "flex", flexDirection: "row", alignItems: "center",
          }}
        >
          { hasRequired() && <TBold color="red" style={{ marginRight: 5 }}>*</TBold>}
          <TLight
            style={{
              textAlign: "left",
              color: config?.titleColor,
              fontFamily: style?.fontFamily,
            }}
          >
            {config?.title}
          </TLight>
        </div>
      )}

      {config?.subTitle && (
        <TLight
          fontSize={mainFontSize.xs}
          style={{
            textAlign: "left",
            marginTop: 0,
            color: config?.subTitleColor,
            fontFamily: style?.fontFamily,
          }}
          mt="10px"
          mb="10px"
        >
          {parse(config.subTitle)}
        </TLight>
      )}

      {(() => {
        switch (config?.type) {
          case InputEnum.inputDropdown:
            return (
              <InputDropdown
                disabled={config?.disabled}
                errorMessage={config?.errorMessage}
                field={config?.field}
                value={value as string}
                onChange={onChange}
                placeholder={config?.placeholder}
              />
            )

          case InputEnum.inputAutoComplete:
            return (
              <InputAutoComplete
                placeholder={config?.placeholder}
                disabled={config?.disabled}
                errorMessage={config?.errorMessage}
                field={config?.field}
                value={value as string}
                onChange={onChange}
              />
            )

          case InputEnum.inputCommon:
            return (
              <InputCommon
                fontSize={config.fontSize}
                placeholder={config?.placeholder}
                maxlength={config?.maxlength}
                errorMessage={config?.errorMessage}
                onChange={onChange}
                disabled={config?.disabled}
                upperCaseOnly={config?.upperCaseOnly}
                style={style}
                value={typeof value === "string" ? value : null}
              />
            )

          case InputEnum.inputOTP:
            return (
              <OTPInput
                autoFocus
                isNumberInput
                length={6}
                className="otpContainer"
                inputClassName="otpInput"
                onChangeOTP={onChange}
              />
            )

          case InputEnum.inputChoiceMulti:
            return (
              typeof value === "object" && (
                <InputChoice
                  type="INPUT_CHOICE_CHECKBOX"
                  value={value as IInputsShema[]}
                  onChange={onChange}
                />
              )
            )

          case InputEnum.inputChoiceSingle:
            return (
              typeof value === "object" && (
                <InputChoice
                  type="INPUT_CHOICE_RADIO"
                  value={value as IInputsShema[]}
                  onChange={onChange}
                />
              )
            )

          case InputEnum.inputCheckbox:
            return (
              <div
                style={{
                  background: config.background,
                  display: "flex",
                  flex: 1,
                  marginTop: value && !(config.title) && value?.length < 2 ? 45 : 10,
                  marginBottom: 10,
                }}
              >
                <InputCheckbox
                  onChange={onChange}
                  disabled={config?.disabled}
                  value={typeof value === "object" ? value as IInputsChoiceShema[] : []}
                />
              </div>
            )

          case InputEnum.inputRadio:
            return (
              typeof value === "object" && (
                <div style={{ marginTop: 8 }}>
                  <InputRadio
                    onChange={onChange}
                    field={config.field || ""}
                    disabled={config?.disabled}
                    value={typeof value === "object" ? value as IInputsChoiceShema[] : []}
                  />
                </div>
              )
            )

          case InputEnum.inputTermsAndCons:
            return (
              <>
                {Array.isArray(value) && value.some((item: any) => item.subTitle) && (
                  <TLight color={color.primary} mb="10px" fontSize={mainFontSize.s}>
                    {value.map((item: any) => item.subTitle).toString()}
                  </TLight>
                )}
                <div
                  style={{
                    background: config.background,
                    display: "flex",
                    flex: 1,
                    marginBottom: 10,
                  }}
                >
                  <InputTermsAndCons
                    onChange={onChange}
                    description={config?.description}
                    field={config?.field}
                    value={typeof value === "object" ? value as IInputsChoiceShema[] : []}
                  />
                </div>
              </>
            )

          case InputEnum.inputUploadFile:
            return (
              <div style={{
                width: "100%",
                height: "50%",
                marginBottom: "10px",
                marginTop: "20px",
              }}
              >
                <InputUploadFile
                  onChange={onChange}
                  value={value as any}
                />
                <div style={{
                  color: "#666666",
                  padding: "5px 0px 5px 0px",
                  fontSize: mainFontSize.s,
                  fontFamily: fonts.SarabunLight,
                  marginTop: "8px",
                }}
                >
                  รองรับไฟล์ :  JPEG , JPG , PNG
                </div>
              </div>
            )

          case InputEnum.inputUploadMultipleFiles:
            return (
              <div style={{
                width: "100%",
                // height: "50%",
                marginBottom: "10px",
                marginTop: "20px",
              }}
              >
                <InputUploadMultipleFiles
                  onChange={onChange}
                  maxFiles={config?.maxFiles}
                  values={value as any}
                  errorMessage={config?.errorMessage}
                />
                <div style={{
                  color: "#666666",
                  padding: "5px 0px 5px 0px",
                  fontSize: mainFontSize.s,
                  fontFamily: fonts.SarabunLight,
                  marginTop: "8px",
                }}
                >
                  รองรับไฟล์ :  JPEG , JPG , PNG, PDF
                </div>
              </div>
            )
          case InputEnum.inputWithButton:
            return (
              <div style={{ background: config.background, marginBottom: 28 }}>
                <InputWithButton
                  title={config?.title}
                  onChange={onChange}
                />
              </div>
            )
          case InputEnum.inputDatePicker:
            return (
              <InputDatePicker
                errorMessage={config?.errorMessage}
                onChange={onChange}
                value={typeof value === "string" ? value : null}
              />
            )

          default:
            return null
        }
      })()}
      { config?.errorMessage && typeof config?.errorMessage === "string" && (
        <div style={{ marginTop: 4, display: "flex", flexDirection: "row" }}>
          <TLight color="red" fontSize={mainFontSize.xs}>{config?.errorMessage}</TLight>
        </div>
      )}
      <div>
        {config?.BelowComp}
      </div>
    </div>
  )
}
