import React, { useState, useEffect, CSSProperties } from "react"
import { useTranslation } from "react-i18next"
import { useHistory } from "react-router-dom"
import styled from "styled-components"
import { GetUsers } from "../../apis"
import { color, mainFontSize } from "../../config/themes"
import { IGetUsers, StatusOpenAccount } from "../../interfaces"
import { routerName } from "../../interfaces/router.enum"
import { handleStatusError } from "../../sagas"
import { auth, useWindowDimensions } from "../../utilities"
import Buttons from "../Buttons"
import Hamburger from "../Hamburger"
import Image, { logo } from "../Images"

interface props {
  flex?: string
  bgColor?: string
  height?: string
}
const Header = styled("div")<props>`
  display: flex;
  flex: ${(props) => props.flex || "none"};
  height: ${(props) => props.height || "80px"};
  background-color: ${(props) => props.bgColor || "transparent"};
  justify-content: space-between;
  align-items: center;
`

export default ({
  style,
}: { style?: CSSProperties }) => {
  const { t } = useTranslation()
  const [status, SetStatus] = useState<StatusOpenAccount | null>(null)

  const { width } = useWindowDimensions()
  
  useEffect(() => {
    if (auth.isAuthenticated()) {
      GetUsers()
        .then((res: IGetUsers) => {
          if (res?.errors) {
            handleStatusError(res?.errors)
            return
          }
          SetStatus(res.data.GetUserInfo.oaStatus as StatusOpenAccount)
        })
        .catch((error) => {
          console.error(error)
        })
    }
  }, [])

  const handleLogoClick = () => {
    window.location.replace("https://th.rhbtradesmart.com/")
  }

  return (
    <Header style={width < 540 ? { paddingLeft: "5%", paddingRight: "5%" } : { }} height="80px">
      <Image
        hoverCursor="pointer"
        onClick={handleLogoClick}
        src={logo}
        {...((widthNumber) => (widthNumber < 540 ?
          ({
            height: "30px",
            width: "83px",
          }) : 
          ({
            height: "40px",
            width: "110px",
          }))
        )(width)}
      />
      <p style={{ color: "white" }}>v1</p>
      {
        width < 540 ? (
          <Hamburger auth={auth} />
        ) : (
          <Header flex="1">
            <div style={{
              display: "flex",
              flex: 1,
              flexDirection: "row",
              justifyContent: "flex-end",
              alignItems: "center", 
              textShadow: "0px 0px 50px #fff",
            }}
            >
              {
                auth.isAuthenticated() && 
                window.location.pathname === routerName.contact && 
                (status === StatusOpenAccount.REGISTERED || status === StatusOpenAccount.VERIFIED)
                && (
                  <Buttons
                    style={{ padding: "0.25em 1em", color: color.primary }} 
                    href="/"
                  >
                    เปิดบัญชีกองทุนต่อ
                  </Buttons>
                )
              }
              {
                auth.isAuthenticated() && 
                window.location.pathname === routerName.contact && 
                status !== StatusOpenAccount.REGISTERED && 
                status !== StatusOpenAccount.VERIFIED && (
                  <Buttons
                    style={{ padding: "0.25em 1em", color: color.primary }} 
                    href={routerName.approvalStatus}
                  >
                    สถานะการเปิดบัญชี
                  </Buttons>
                ) 
              }
              <Buttons
                style={{
                  padding: "0.25em 1em",
                  color: color.primary,
                  fontFamily: style?.fontFamily,
                  fontSize: style?.fontSize,
                }}
                href={routerName.contact}
              >
                {t("headerAccountOpening.contact")}
              </Buttons>
              {
                auth.isAuthenticated() && (
                  <Buttons
                    style={{ color: color.primary }}
                    onClick={() => auth.signOut()}
                    href={routerName.root}
                  >
                    ออกจากระบบ
                  </Buttons>
                )
              }
            </div>
          </Header>
        )
      }
      
    </Header>
  )
}