import { all } from "redux-saga/effects"
import { showDialog } from "../components/Provider/dialog"
import { IDialogTypeEnum, IGraphqlError } from "../interfaces"
import { auth } from "../utilities"
import AuthSaga from "./auth.saga"
import LoginSaga from "./login.saga"
import MasterDataSaga from "./masterdata.saga"
import Ndid from "./ndid.saga"
import OpeningAccount from "./openingAccount.saga"

const errorMessages: any = {
  400: "ข้อมูลไม่ถูกต้อง",
  401: "Session หมดอายุ",
  403: "ไม่ได้รับอนุญาต",
  404: "ไม่พบข้อมูลในระบบ",
  500: "เกิดข้อผิดพลาดภายในระบบ กรุณาติดต่อผู้ดูแลระบบ",
  503: "ระบบไม่สามารถใช้งานได้ชั่วคราว กรุณาลองอีกครั้งภายหลัง",
  "0410": "มีการส่ง OTP เกินจำนวนครั้งที่กำหนด (2 ครั้ง ต่อนาที)",
  "0411": "OTP หมดอายุ กรุณาส่งคำขอ OTP ใหม่อีกครั้ง",
  1100: "ข้อมูลไม่ถูกต้อง",
  1101: "ข้อมูลไม่ถูกต้อง",
  1102: "อีเมลหรือเบอร์มือถือที่ระบุไม่ถูกต้องในการเข้าสู่ระบบ",
  1103: "พบหมายเลข CIF ในระบบแล้ว",
  1104: "Service ไม่สามารถใช้งานได้",
  1105: "Service ไม่สามารถใช้งานได้",
  1106: "ไม่พบข้อมูลจากฐานข้อมูลทะเบียนราษฎร์",
  1107: "พบข้อมูลหมายเลขบัตรประชาชนนี้แล้วในระบบ",
  1108: "พบข้อมูลของท่านในฐานข้อมูลบุคคลต้องห้าม",
  1109: "ผู้ลงทุนต้องมีอายุมากกว่า 20 ปี",
  1110: "ข้อมูลไม่ถูกต้อง",
  1111: "ไม่พบหมายเลข Verification ID",
  1112: "หมายเลข Device ID ไม่ถูกต้อง",
  1113: "ผู้ใช้นี้ถูกบล๊อค",
  1114: "มีการ Login เกินจำนวนครั้งที่กำหนด",
  1115: "เบอร์โทรศัพท์นี้เคยถูกใช้ลงทะเบียนแล้ว",
  1116: "อีเมลนี้เคยถูกใช้ลงทะเบียนแล้ว",
  1117: "ไม่สามารถเปิดบัญชีกองทุนได้ หากมีข้อสงสัยกรุณาติดต่อ RHB Contact Center 02 088 9896 หรือ 02 088 9897 หรือ 02 088 9797",
  1118: "คุณไม่ผ่านการตรวจสอบข้อมูลที่เกี่ยวข้องกับบุคคลทางการเมือง",
  1119: "หมายเลข OTP ของเบอร์มือถือไม่ถูกต้อง",
  1120: "หมายเลข OTP ของอีเมลไม่ถูกต้อง",
  1121: "คุณไม่ผ่านการประเมิน Risk Level",
  1122: "ไม่พบ", // for fund connext
  1123: "พบข้อมูลซ้ำ",
  1124: "อีเมลหรือเบอร์มือถือที่ระบุไม่ถูกต้องในการเข้าสู่ระบบ",
  1125: "เบอร์โทรศัพท์นี้เคยถูกใช้ลงทะเบียนแล้ว",
  1126: "อีเมลนี้เคยถูกใช้ลงทะเบียนแล้ว",
  1127: "หมายเลขบัตรประชาชนนี้ถูกใช้ลงทะเบียนแล้ว",
  1128: "ไม่พบสถานะ",
  1129: "ไม่สามารถอัพโหลดไฟล์ขนาดเกินกว่า 4MB",
  1130: "มีการลงทะเบียนผิดขั้นตอน",
}

const errorMessageMapping = (errorCode: string): string => {
  const errorMessage = errorMessages[errorCode];
  return errorMessage || "เกิดข้อผิดพลาดบางประการ"
}

export const handleStatusError = (errors: Array<IGraphqlError>, clearAll?: boolean) => {
  for (let i = 0; i < errors.length; i += 1) {
    showDialog(
      IDialogTypeEnum.ERROR_DIALOG, 
      { 
        message: errorMessageMapping(errors[i]?.extensions?.code),
        errCode: errors[i]?.extensions?.code,
      },
      clearAll,
    ).then(() => {
      const codeErr = +errors[i]?.extensions?.code
      if (
        codeErr === 401 ||
        codeErr === 404 ||
        codeErr === 500 ||
        codeErr === 503
      ) {
        auth.signOut()
      }
    })
  }
}

export default function* rootSaga() {
  yield all([
    LoginSaga(),
    OpeningAccount(),
    AuthSaga(),
    Ndid(),
    MasterDataSaga(),
  ])
}
