import React from "react"
import { Buttons } from "../.."
import { IDialogProps } from "../../../interfaces"

export default ({ onResolve, params }: IDialogProps) => (
  <div style={{
    width: 400,
    height: 250,
    border: "1px solid yellow",
    background: "green",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  }}
  >
    {params?.name}
    <div style={{
      marginTop: 20, display: "flex", justifyContent: "center", marginBottom: 20, 
    }}
    >
      <Buttons
        style={{
          backgroundColor: "#ffffff", color: "#0067b1", borderWidth: 1, borderColor: "#0067b1",
        }}
        onClick={() => onResolve(0)}
      >
        ยกเลิก
      </Buttons>

      <Buttons onClick={() => onResolve(0)}>ตกลง</Buttons>
    </div>
  </div>
)