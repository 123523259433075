import React from "react";
import { IFormsShema, IOpenAccountShema, ISubPage } from "../../../interfaces";
import CardInformationPreview from "./cardInformationPreview"
import CardSignature from "./cardSignature"
import CardSubmitData from "./cardSubmitData"
import CardSuccess from "./cardSuccess"

interface props {
  page: string
  data: IOpenAccountShema[],
  dataOfPage: IFormsShema[],
}

export default ({
  page, data, dataOfPage,
}: props) => {
  const index = +page.split(".")[1] || 0
  const dataDisplay = dataOfPage[index]

  if (dataDisplay?.type === ISubPage.STEP_IMAGE_SIGNATURE) {
    return (
      <CardSignature
        page={page}
        dataOfPage={dataOfPage}
        data={data}
      />
    )
  }
  if (dataDisplay?.type === ISubPage.STEP_OVERVIEW_ALL_DATA) {
    return (
      <CardInformationPreview
        page={page}
        dataOfPage={dataOfPage}
        data={data}
      />
    )
  }
  if (dataDisplay?.type === ISubPage.STEP_STATUS_AO) {
    return (
      <CardSuccess
        page={page}
        dataOfPage={dataOfPage}
        data={data}
      />
    )
  }
  if (dataDisplay?.type === ISubPage.STEP_SUBMIT_DATA) {
    return (
      <CardSubmitData
        page={page}
        dataOfPage={dataOfPage}
        data={data}
      />
    )
  }

  return null
}