/* eslint-disable no-plusplus */
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  navigateBack,
  navigateNext,
  saveSavingAccountToMutualFund,
  saveUploadFileSaga, 
} from "../../../actions";
import {
  Buttons,
  Card,
  Texts,
  Inputs,
  HistoryOfPath,
} from "../../../components"
import { fonts, mainFontSize } from "../../../config/themes";
import { ComponentContext } from "../../../contexts"
import {
  IFormsShema,
  InputEnum,
  IOpenAccountShema,
  IStore,
  ISubPage,
  UploadFileType,
  UploadFileTypeEnum, 
} from "../../../interfaces";
import { getErrorMessage, handleErrorScreen, resizeArrayWithNull } from "../../../utilities";
import CardTermConditionSavingAccount from "./cardTermConditionSavingAccount"
import CardUploadImage from "./cardUploadImage";

interface props {
  page: string
  data?: IOpenAccountShema[],
  dataOfPage: IFormsShema[],
}

const eachRows = [
  [1],
  [2, 2, 1, 1],
  [1, 2, 2, 1],
];

export default ({
  page, data, dataOfPage,
}: props) => {
  const [errorMessages, setErrorMessages] = 
    React.useState<Array<{ field: string, message: Array<string> | string }>>([])
  const componentContext = useContext(ComponentContext);
  const dispatch = useDispatch()
  const openAccount = useSelector((state: IStore) => state.OpeningAccountReducer)
  const { t } = useTranslation()
  const index = +page.split(".")[1] || 0
  const dataDisplay = dataOfPage[index]
  const mainData = openAccount.accountInformation[dataDisplay.type]
  const onChange = (val: {key?: string, value?: string | number}) => {
    componentContext?.onChange({ key: val.key || "", value: val.value })
  }

  const onValidate = (): { 
    isRequireUpload: (false | { type: string; field: string; })[],
    validateButton: boolean[]
  } => {
    const dataInputs = dataDisplay.inputs
    const dataFromReducer = Object.keys(mainData)

    // Get KeyTarget other all subpage.
    const otherField = dataInputs.map((val, otherIndex) => dataInputs
      .map((input) => input.keyTarget)
      .filter((inputFilter) => inputFilter)
      .flat()
      .map((targetField) => ((val.field === targetField) && otherIndex))
      .filter((fil) => fil))
      .flat()

    const isRequireUpload = dataInputs.map((val) => (val.type === "inputUploadFile" || val.type === "inputUploadMultipleFiles")
      && { type: val.type, field: val.field }).filter((val) => val)
  
    // Check data no field and check click required and selected yet?
    const addOtherTargetField: any[] = []
    const result = dataFromReducer.map((field, keyindex) => {
      if (Array.isArray(mainData[field])) {
        return mainData[field].map((val: any) => {
          if (val.selected && val.requireOther) {
            dataInputs.map((vv, iii) => iii === keyindex && addOtherTargetField.push(vv.keyTarget))
            return true
          }
          if (val.selected) return true
          
          return false
        }).some((someval: boolean) => someval)
      }
      return !!mainData[field]
    })

    // Trigger other field when other buttons are pressed.
    dataFromReducer
      .map((field, keyindex) => addOtherTargetField
        .flat()
        .map((itemTest1) => ((field === itemTest1) ? keyindex : false))
        .filter((val) => val)
        .toString())
      .forEach((vall) => vall && otherField.splice(otherField.indexOf(+vall), 1))

    return {
      isRequireUpload,
      validateButton: result.filter((val, inde) => !(otherField.some((vall) => inde === vall))),
    }
  }

  let initialSelectOtherField = false
  const onHandleOtherField = ({ field, isHidden }: { field: string, isHidden: boolean }) => {
    const isCheckbox = dataDisplay.inputs?.find((input, _) => (input.type === "inputRadio" || input.type === "inputCheckbox") && field === input.field)

    if (Array.isArray(mainData[field]) && isCheckbox) {
      initialSelectOtherField = mainData[field].some(({ requireOther, selected }: { 
          requireOther: boolean
          selected: boolean
        }) => (requireOther && selected))
    }

    const result = dataDisplay.inputs.some((tar) => (
      tar.keyTarget) && tar.keyTarget.some((key) => (initialSelectOtherField && key === field)))

    return !result && isHidden
  }

  const onNext = () => {
    const { errors, validateFlag } = handleErrorScreen(dataDisplay.inputs, mainData)
    setErrorMessages(errors)
    if (!validateFlag) return
    
    const isRedeemtion = mainData?.political?.some((val: {
      requireOther: boolean
      selected: boolean
    }) => val.requireOther && val.selected)

    if (onValidate().isRequireUpload.length) {
      dispatch(saveSavingAccountToMutualFund({
        callback: onValidate().isRequireUpload.forEach(({ field }: any) => {
          const file = [...mainData[field]]
          
          console.log("onValidate before", file);
          
          if (UploadFileTypeEnum.IMAGE_BOOKBANK_REDEMPTION === field && !isRedeemtion) {
            file.map((key : any) => {
              key.isDelete = true
              return key
            })
          } else if (UploadFileTypeEnum.IMAGE_BOOKBANK_REDEMPTION === field && isRedeemtion) {
            file.map((key : any) => {
              key.isDelete = false
              return key
            })
          }

          console.log("onValidate after", file);
          dispatch(saveUploadFileSaga({
            files: file,
            field, 
          }))
        }),
      }))
    }
  }
  const onBack = () => {
    dispatch(navigateBack())
  }

  if (dataDisplay.type === ISubPage.STEP_TERM_CONDITION_SAVING_ACCOUNT) {
    return (
      <CardTermConditionSavingAccount
        page={page}
        dataOfPage={dataOfPage}
        data={data as IOpenAccountShema[]}
      />
    )
  }

  if (dataDisplay.type === ISubPage.STEP_IMAGE_STATEMENT) {
    return <CardUploadImage page={page} data={data} dataOfPage={dataOfPage} />
  }

  return (
    <Card>
      <HistoryOfPath 
        data={dataOfPage.map((val) => val.title)}
        page={page}
        activeIndex={index}
      />
      <div style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between", 
      }}
      >
        <Texts style={{ 
          color: "#0C67B1", 
          padding: "5px 0px 5px 0px", 
          fontSize: mainFontSize.l, 
          fontFamily: fonts.SarabunMedium,
        }}
        >
          {dataDisplay.title}
        </Texts>
      </div>

      <div style={{ marginBottom: 28 }}>
        {
          resizeArrayWithNull(dataDisplay.inputs, eachRows[index]).map((vals, key) => (
            <div key={key.toString()} style={{ display: "flex", justifyContent: "inherit" }}>
              {
                vals && vals.map((val, i) => (
                  val && (
                    <div 
                      key={`items-personal-${key.toString()} ${i.toString()}`}
                      style={{
                        display: "flex",
                        flex: 1,
                        flexDirection: "column",
                        marginRight: i % 2 ? 0 : 15,
                        marginLeft: i % 2 ? 15 : 0,
                      }}
                    >
                      { val && onHandleOtherField(val) ? null : (
                        <Inputs 
                          config={{
                            type: val.type as InputEnum,
                            title: val.title,
                            field: val.field,
                            errorMessage: getErrorMessage(val.field, errorMessages),
                            maxlength: val.maxlength,
                            maxFiles: val.maxFiles,
                            rules: val.rules,
                          }}
                          value={mainData[val.field]}
                        />
                      ) }
                    </div>
                  )))
              }
            </div>
          ))
        }
      </div>
      
      <div style={{
        display: "flex", 
        flexDirection: "row", 
        justifyContent: "space-between", 
      }}
      >
        <Buttons
          style={{
            backgroundColor: "#ffffff",
            color: "#0067b1",
            borderWidth: 1,
            borderColor: "#0067b1",
          }}
          onClick={onBack}
        >
          กลับ
        </Buttons>
        <Buttons
          // disabled={!onValidate().validateButton.every((val) => val)}
          style={{ marginLeft: 16 }} 
          onClick={onNext}
        >
          ยืนยัน
        </Buttons>
      </div>
    </Card>
  )
}
