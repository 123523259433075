/* eslint-disable no-plusplus */
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  navigateNext,
  saveBackgroundInfoSaga,
  saveContactInfoSaga,
  saveMutualFundAccountSaga,
  savePersonalInfoSaga,
  savePersonalContactsSaga,
} from "../../../actions";
import {
  Buttons,
  Card,
  Inputs,
  HistoryOfPath,
  Texts,

} from "../../../components"
import { fonts, mainFontSize } from "../../../config/themes";
import { ComponentContext } from "../../../contexts"
import {
  IFormsShema,
  IInputsShema,
  InputEnum,
  IOpenAccountShema,
  IRuleEnum,
  IStore,
  ISubPage,
} from "../../../interfaces"
import {
  chunk, resizeArrayWithNull, handleErrorScreen, useWindowDimensions, getErrorMessage,
} from "../../../utilities";
import CardUploadImage from "./cardUploadImage";

interface props {
  page: string
  data?: IOpenAccountShema[]
  dataOfPage: IFormsShema[]
}

export default ({
  page,
  data,
  dataOfPage,
}: props) => {
  const [errorMessages, setErrorMessages] =
    React.useState<Array<{ field: string, message: Array<string> | string }>>([])
  const { width, height } = useWindowDimensions()
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { personalInformation, termAndConditoin } =
    useSelector((state: IStore) => state.OpeningAccountReducer)
  const index = +page.split(".")[1] || 0
  const [dataDisplay, setDataDisplay] = useState(dataOfPage[index]);
  const componentContext = useContext(ComponentContext);
  const mainData = personalInformation[dataDisplay.type]
  const onChange = (val: {key?: string, value?: string | number}) => {
    componentContext?.onChange({ key: val.key || "", value: val.value })
  }

  useEffect(() => {
    setDataDisplay(dataOfPage[index])
  }, [dataOfPage, page])

  useEffect(() => {
    if (personalInformation.STEP_WORKPLACE_ADDRESS.codeTypeBusiness === "BS019") {
      const businessTypeIdx = dataDisplay.inputs.findIndex(
        (input) => input.field === "typeBusiness",
      );

      if (businessTypeIdx >= 0) {
        const newDataDisplay = { ...dataDisplay };
        dataDisplay.inputs.splice(
          businessTypeIdx + 1,
          1,
          {
            type: "inputCommon",
            field: "businessTypeOther",
            title: "ประเภทธุรกิจอื่นๆ",
            placeholder: "ประเภทธุรกิจอื่นๆ",
            rules: [
              { type: IRuleEnum.REQUIRED },
            ],
          },
        )

        setDataDisplay(newDataDisplay)
      }
    } else {
      const businessTypeIdx = dataDisplay.inputs.findIndex(
        (input) => input.field === "typeBusinessOther",
      );

      if (businessTypeIdx >= 0) {
        const newDataDisplay = { ...dataDisplay };
        newDataDisplay.inputs.splice(
          businessTypeIdx,
          1,
          {
            field: "",
            title: "",
            type: "",
          },
        )

        setDataDisplay(newDataDisplay)
      }
    }
  }, [personalInformation.STEP_WORKPLACE_ADDRESS.codeTypeBusiness])

  const eachRows = [
    [1, 2, 2, 1, 2],
    [1, 2, 2],
    [1, 1, 1, 1, 1, 1, 1, 1],
    [2, 2, 2, 2, 2, 2],
    [1, 2, 2, 2, 2, 2, 1],
    [2, 2, 2, 2, 2, 2, 2, 1],
    [1, 2, 2, 2, 2, 2, 1],
    [1, 2, 2, 2, 2, 2, 1],
    [1, 2, 2, 1, 2, 1, 1, 2, 2, 1, 1, 2, 2, 1, 1, 2, 2],
  ];

  const whiteListFullRows = (val: { field: string }) => val && (
    val.field === "currentAddress" ||
    val.field === "country" ||
    val.field === "DocAddress" ||
    val.field === "isRelatedPEP" ||
    val.field === "cpLabel" ||
    val.field === "beneficialOwner"
  )

  let initialSelectOtherField = false
  const onHandleOtherField = ({ field, isHidden }: { field: string, isHidden: boolean }) => {
    if (Array.isArray(mainData[field])) {
      initialSelectOtherField = mainData[field]
        .some((
          { requireOther, selected }:
          { requireOther: boolean, selected:boolean },
        ) => requireOther && selected)
    }
    const result = dataDisplay.inputs.some((tar) => (
      tar.keyTarget) && tar.keyTarget.some((key) => (initialSelectOtherField && key === field)))
    return !result && isHidden
  }

  const onValidate = (): {
    isRequireUpload: (false | { type: string; field: string; })[],
    validateButton: boolean[]
  } => {
    const dataInputs = dataDisplay.inputs.filter((item) => item.type)
    const dataFromReducer = Object.keys(mainData)
    // Get KeyTarget other all subpage.
    const otherField = dataInputs.map((val, otherIndex) => dataInputs
      .map((input) => input.keyTarget)
      .filter((inputFilter) => inputFilter)
      .flat()
      .map((targetField) => ((val.field === targetField) && otherIndex))
      .filter((fil) => fil))
      .flat()

    const isRequireUpload = dataInputs.map((val) => val.type === "inputUploadFile"
      && { type: val.type, field: val.field }).filter((val) => val)

    // Check data no field and check click required and selected yet?
    const addOtherTargetField: any[] = []
    const result = dataFromReducer.map((field, keyindex) => {
      if (Array.isArray(mainData[field])) {
        return mainData[field].map((val: any) => {
          if (val.selected && val.requireOther) {
            dataInputs.map((vv, iii) => iii === keyindex && addOtherTargetField.push(vv.keyTarget))
            return true
          }
          if (val.selected) return true
          return false
        }).some((someval: boolean) => someval)
      }
      return !!mainData[field]
    })

    // Trigger other field when other buttons are pressed.
    dataFromReducer
      .map((field, keyindex) => addOtherTargetField
        .flat()
        .map((itemTest1) => ((field === itemTest1) ? keyindex : false))
        .filter((val) => val)
        .toString())
      .forEach((vall) => vall && otherField.splice(otherField.indexOf(+vall), 1))

    return {
      isRequireUpload,
      validateButton: result.filter((val, inde) => !(otherField.some((vall) => inde === vall))),
    }
  }

  const onNext = () => {
    const inputs: IInputsShema[] = JSON.parse(JSON.stringify(dataDisplay.inputs))
    const inputClones = inputs.map(
      (clone: IInputsShema, key: number) => ({
        ...clone,
        rules: (clone.field === "nameWorkplace"
                || clone.field === "position" || clone.field === "typeBusiness") &&
          (mainData.codeCareer === "O012" || mainData.codeCareer === "O013") ? [] : dataDisplay.inputs[key].rules,
      }),
    )

    const { errors, validateFlag } = handleErrorScreen(inputClones, mainData)

    setErrorMessages(errors)
    if (!validateFlag) return

    if (dataDisplay.type === ISubPage.STEP_MARITAL_STATUS) {
      dispatch(savePersonalInfoSaga())
      return
    }
    if (dataDisplay.type === ISubPage.STEP_FRAUD
      || dataDisplay.type === ISubPage.STEP_INCOME) {
      dispatch(saveBackgroundInfoSaga({ disabledNext: false }))
      return
    }
    if (dataDisplay.type === ISubPage.STEP_ID_CARD_ADDRESS
      || dataDisplay.type === ISubPage.STEP_CURRENT_ADDRESS
      || dataDisplay.type === ISubPage.STEP_WORKPLACE_ADDRESS) {
      dispatch(saveContactInfoSaga({ dataDisplayType: dataDisplay.type }))
      dispatch(saveBackgroundInfoSaga({ disabledNext: true }))
      return
    }
    if (dataDisplay.type === ISubPage.STEP_MAILING_ADDRESS) {
      dispatch(saveMutualFundAccountSaga())
      return
    }
    if (dataDisplay.type === ISubPage.STEP_EMERGENCY_CONTACT) {
      dispatch(savePersonalContactsSaga())
      return
    }
    dispatch(navigateNext())
  }

  if (dataDisplay.type === ISubPage.STEP_IMAGE_HOUSE_REGISTRATION) {
    return <CardUploadImage page={page} data={data} dataOfPage={dataOfPage} />
  }

  const dataInput = width < 540
    ? chunk(dataDisplay.inputs, 1)
    : resizeArrayWithNull(dataDisplay.inputs, eachRows[index])

  return (
    <Card>

      <HistoryOfPath
        data={dataOfPage.map((val) => val.title)}
        page={page}
        activeIndex={index}
      />
      <div style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
      }}
      >
        <Texts style={{
          color: "#0C67B1",
          padding: "5px 0px 5px 0px",
          // fontSize: mainFontSize.l,
          // fontFamily: fonts.SarabunMedium,
        }}
        >
          {dataDisplay.title}
        </Texts>
      </div>

      <div style={{ marginBottom: 28 }}>
        {
          dataInput.map((vals, key) => (
            <div key={key.toString()} style={{ display: "flex" }}>
              {
                vals && vals.map((val, i) => (
                  <div
                    key={`items-personal-${key.toString()} ${i.toString()}`}
                    style={{
                      display: "flex",
                      flex: whiteListFullRows(val) ? 10 : 1,
                      flexDirection: "column",
                      marginRight: i % 2 ? 0 : 15,
                      marginLeft: i % 2 ? 15 : 0,
                    }}
                  >
                    {val && onHandleOtherField(val) ? null : val && (

                      <>
                        <Inputs
                          config={{
                            type: val.type as InputEnum,
                            title: val.title,
                            titleColor: val.titleColor,
                            field: val.field,
                            disabled: val.disabled,
                            requireField: val.requireField,
                            errorMessage: getErrorMessage(val.field, errorMessages),
                            maxlength: val.maxlength,
                            rules: val.rules,

                          }}
                          value={mainData[val.field]}
                        />
                      </>
                    )}
                  </div>
                ))
              }
            </div>
          ))
        }
      </div>

      {
        dataDisplay.type === ISubPage.STEP_EMERGENCY_CONTACT && (
          <div style={{
            color: "#cccccc",
            marginBottom: 40,
            fontSize: mainFontSize.s,
            fontFamily: fonts.SarabunExtraLight,
          }}
          >
            ข้าพเจ้ายอมรับ เข้าใจ และตระหนักดีว่า การให้ข้อมูลอันเป็นเท็จนั้น
            ผิดกฎหมาย การรับจ้างหรือว่าจ้างบุคคลใดๆ เปิดบัญชีแทนกัน ปกปิดตัวตน
            หรือผู้รับประโยชน์ที่แท้จริง เป็นการกระทำที่ผิดกฎหมาย และต้องถูกดำเนินคดี
            ภายใต้กฎหมายอาญาและกฎหมายอื่นใดที่เกี่ยวข้องทั้งหมด
          </div>
        )
      }
      <div style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
      }}
      >
        <Buttons
          style={{
            backgroundColor: "#ffffff",
            color: "#0067b1",
            borderWidth: 1,
            borderColor: "#0067b1",
          }}
          onClick={() => onChange({ key: "BACK", value: 0 })}
        >
          กลับ
        </Buttons>
        <Buttons
          // disabled={!onValidate().validateButton.every((val) => val)}
          style={{ marginLeft: 16 }}
          onClick={onNext}
        >
          ยืนยัน
        </Buttons>
      </div>
    </Card>
  )
}
