import React from "react"
import { Buttons, Images } from "../.."
import { IDialogProps } from "../../../interfaces"
import { warningIcon } from "../../Images"
import { TMedium } from "../../Texts"

export default ({ onResolve, params }: IDialogProps) => {
  const { ButtonComp } = params
  return (
    <div style={{
      width: 350,
      height: 250,
      background: "#FFFFFF",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      paddingLeft: 40,
      paddingRight: 40,
      paddingTop: 20,
      paddingBottom: 20,
      borderRadius: 15,
    }}
    >
      <div style={{ flex: 0.4, display: "flex", alignItems: "center" }}>
        <Images
          style={{
            width: "80px",
            height: "80px",
            padding: "5px",
            flex: 1,
            justifyContent: "center",
          }}
          src={warningIcon}
        />
      </div>
      <div style={{
        flex: 0.4, textAlign: "center", fontWeight: "normal", display: "flex", alignItems: "center",
      }}
      >
        <TMedium>
          {params?.message}
        </TMedium>
      </div>
      {
        ButtonComp ? <ButtonComp onResolve={onResolve} params={params} /> :
          (
            <div style={{
              display: "flex",
              alignItems: "center",
              flex: 0.2,
            }}
            >
              <Buttons onClick={() => onResolve(1)}>ตกลง</Buttons>
            </div>
          )
      }
    </div>
  )
}