import {
  combineReducers,
} from "redux"

import AuthReducer from "./auth.reducer"
import LoginReducer from "./login.reducer"
import NdidReducer from "./ndid.reducer"
import OpeningAccountReducer from "./openingAccount.reducer"
import StepsReducer from "./steps.reducer"
import SuitReducer from "./suit.reducer"
import TFexReducer from "./tfex.reducer"

const appReducer = combineReducers({
  AuthReducer,
  LoginReducer,
  OpeningAccountReducer,
  StepsReducer,
  NdidReducer,
  SuitReducer,
  TFexReducer,
})

const rootReducer = (state: any, action: any) => {
  if (action.type === "RESET") {
    state = {
      AuthReducer: null,
      BanksReducer: null,
      LoginReducer: state.LoginReducer,
      OpeningAccountReducer: null,
      PersonalReducer: null,
      SuitsReducer: null,
      TermsReducer: null,
      NdidReducer: null,
      SuitReducer: null,
      TFexReducer: null,
    }
  }

  return appReducer(state, action);
}

export default rootReducer
