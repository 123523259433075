import parse from "html-react-parser"
import React, { useEffect, useState } from "react"
import { Texts } from "../.."
import { fonts, mainFontSize } from "../../../config/themes"
import { ICheckBoxs } from "../../../interfaces"
import Checkbox from "../../CheckBox"

interface props {
  value: Array<ICheckBoxs>
  onChange: (obj: {value: Array<ICheckBoxs>}) => void;
  disabled?: boolean
}

export default
({
  onChange,
  value,
  disabled,
}: props) => {
  const tmpValue = [...value]
  tmpValue.filter((el) => el.consentId === 3).map((el) => {
    el.selected = true
    return el
  })
  const [values, setValues] = useState(tmpValue)

  const onHandleChange = (indexItems: number) => {
    const result = values.map((val, index) => (index === indexItems ?
      ({ ...val, selected: !val.selected }) : val))
    setValues(result)
    onChange({ value: result })
  };

  useEffect(() => {
    if (typeof value === "object" && value !== null) {
      setValues(tmpValue)
    }
  }, [value])

  return (
    <div
      style={{
        display: "flex",
        flexWrap: "wrap",
        alignItems: "flex-start",
        flexDirection: "column",
      }}
    >
      {
        values.map((val, index) => (
          <div>
            <Checkbox
              selected={val.selected}
              onChange={() => onHandleChange(index)}
              disabled={disabled || val.consentId === 3}
            >
              {
                val.subTitle
                  ? (
                    <div>
                      <div style={{ padding: "0px 0px 0px 10px" }}>
                        <Texts
                          style={{
                            fontFamily: fonts.SarabunMedium,
                            fontSize: val.title.includes("<h1>") ? mainFontSize.xxxs : mainFontSize.s,
                            lineHeight: 2,
                            color: "#333333",
                          }}
                        >
                          { val?.title && parse(val?.title)}
                        </Texts>
                        <Texts
                          style={{
                            fontFamily: fonts.SarabunLight,
                            fontSize: mainFontSize.s,
                            color: "#666666",
                            lineHeight: 2,
                          }}
                        >
                          { val?.subTitle && parse(val?.subTitle)}
                        </Texts>
                      </div>
                    </div>
                  ) : (
                    <p
                      style={{
                        fontFamily: fonts.SarabunLight,
                        fontSize: mainFontSize.s,
                        color: disabled ? "#66666680" : "#000000",
                        marginLeft: 11,
                      }}
                    >
                      { val?.title && parse(val.title)}
                    </p>
                  )
              }
            </Checkbox>
          </div>
        ))
      }
    </div>
  )
}
