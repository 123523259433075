import { useQueryAuthen } from "./apiCall"

// ====================================== Query Master Data ===================================

export const GetThaiAddressApi = (searchText: string) => {
  const gql = `query GetThaiAddress ($getThaiAddressInput: GetThaiAddressInput){
      GetThaiAddress (input: $getThaiAddressInput) {
        id
        subdistrict_th
        subdistrict_en
        district_th
        district_en
        province_th
        province_en
        display_th
        display_en
        zipcode
      }
  }`
  const variables = {
    getThaiAddressInput: {
      query: searchText,
    },
  }
  return useQueryAuthen(gql, variables)
}

export const QueryReferralPeopleApi = (query: string, type: Array<"MARKETING" | "IBA">) => {
  const gql = `query GetReferralPeople ($input: ReferralPeopleInput) {
    GetReferralPeople(input: $input){
      id
      nameTh
      nameEn
      email
    }
  }`
  const variables = {
    input: {
      query,
      type,
    },
  }
  return useQueryAuthen(gql, variables)
}

export const GetTitlteApi = () => {
  const gql = `query GetTitlte {
    GetTitle {
      id
      displayTh
      displayEn
    }
  }`
  return useQueryAuthen(gql)
}

export const GetcountryApi = () => {
  const gql = `query Getcountry {
    GetCountry {
      id
      name
    }
  }`
  return useQueryAuthen(gql)
}

// ยังไม่ได้ใช้
export const GetNationalityApi = () => {
  const gql = `query GetNationality {
    GetNationality {
      id
      name
    }
  }`
  return useQueryAuthen(gql)
}

export const GetSourceOfIncomeApi = () => {
  const gql = `query GetSourceOfIncome {
    GetSourceOfIncome {
      id
      displayTh
      displayEn
    }
  }`
  return useQueryAuthen(gql)
}

export const GetMonthlyIncomeApi = () => {
  const gql = `query GetMonthlyIncome {
    GetMonthlyIncome {
      id
      displayEn
      displayTh
    }
  }`
  return useQueryAuthen(gql)
}

export const GetInvestmentPurposeApi = () => {
  const gql = `query GetInvestmentPurpose {
    GetInvestmentPurpose {
      id
      displayEn
      displayTh
    }
  }`
  return useQueryAuthen(gql)
}

export const GetCareerApi = () => {
  const gql = `query GetCareer {
    GetCareer {
      id
      displayEn
      displayTh
    }
  }`
  return useQueryAuthen(gql)
}

export const GetBusinessTypeApi = () => {
  const gql = `query GetBusinessType {
    GetBusinessType {
      id
      displayEn
      displayTh
    }
  }`
  return useQueryAuthen(gql)
}

export const GetAllMasterDataApi = () => {
  const gql = `query {
    GetBusinessType {
      id
      displayEn
      displayTh
    }
    GetCareer {
      id
      displayEn
      displayTh
    }
    GetInvestmentPurpose {
      id
      displayEn
      displayTh
    }
    GetMonthlyIncome {
      id
      displayEn
      displayTh
    }
    GetSourceOfIncome {
      id
      displayTh
      displayEn
    }
    GetNationality {
      id
      name
    }
    GetCountry {
      id
      name
    }
    GetTitle {
      id
      displayTh
      displayEn
    }
  }`
  return useQueryAuthen(gql)
}

export function GetAllNoTinReasonsApi() {
  const gql = `query NoTinReasons {
    GetAllNoTinReasons {
        id
        reasonTh
    }
}`

  return useQueryAuthen(gql)
}

export function FindCountries(contain: string) {
  const gql = `query ($input: FindCountriesInput) {
    FindCountries(input: $input) {
      code
      nameTh
    }
  }`

  const variables = {
    input: {
      contain,
    },
  }
  return useQueryAuthen(gql, variables)
}
