import React from "react"
import styled from "styled-components";
import { fonts, mainFontSize } from "../../config/themes";
import Buttons from "../Buttons";
import Image, { Correct } from "../Images"
import { TLight } from "../Texts";
import { LineOption, MessageComponentOption } from "./index.types";
import { activeLine, activeCycle } from "./index.util";

const WrapperLine = styled.div`
  height: 6px;
  background: #cccccc;
  flex-grow: 1;
  margin-left: 4px;
  margin-right: 4px;
  border-radius: 4px;
`

export const StepperContainer = styled.div`
  display: flex;
  justify-Content: space-around;
  flex-Flow: row wrap;
  margin: 10px 20px 100px;
  align-Items: center;
`

const CycleContainer = styled.div`
  display: flex;
  color: #cccccc;
  border: solid 4px #cccccc;
  width: 2.5rem;
  height: 2.5rem;
  border-Radius: 50px;
  align-Items: center;
  justify-Content: center;
`

const StepperMessage = styled.div`
  position: absolute;
  width: 100px;
  left: -20px;
  text-Align: center;
  margin-Top: 14px;
  font-Size: ${mainFontSize.s};
  font-family: ${fonts.SarabunMedium};
  color: #999999;
`

export const LineComponents = (props: LineOption) => (
  <WrapperLine>
    <div 
      style={{
        height: "100%",
        width: "0%",
        borderRadius: "6px",
        ...activeLine(props),
      }}
    />
  </WrapperLine>
)

const onClick = (
  index: number | null | undefined, 
  active: number, 
  callback: (index: number) => void,
) => {
  // Check case can not forward main step
  if ((index || index === 0) && index < active) {
    callback(index)
  }
}

export const MessageComponent = styled((props: MessageComponentOption) => (
  <Buttons cursor={props.indexItems < props.activeStep ? "pointer" : "not-allowed"} onClick={() => onClick(props.indexItems, props.activeStep, props.onClick)} href="#" key={props.step} style={{ position: "relative" }}>
    <CycleContainer style={{ ...activeCycle(props) }}>
      <Image
        style={{
          position: "absolute",
          width: "24px",
          height: "24px",
          transition: "opacity 0.9s ease-in-out", 
          opacity: props.indexItems + 1 <= +props.activeStep.toFixed(2) ? 1 : 0,
        }}
        src={Correct}
      />
      <TLight style={{
        fontSize: mainFontSize.l,
        fontFamily: fonts.SarabunMedium,
        transition: "opacity 0.2s ease-in-out", 
        opacity: !(props.indexItems + 1 <= +props.activeStep.toFixed(2)) ? 1 : 0,
      }}
      >
        {props.indexItems + 1}
      </TLight>
    </CycleContainer>
    <StepperMessage>
      {props.step}
    </StepperMessage>
  </Buttons>
))``
