import React, { useContext } from "react"
import styled from "styled-components";
import { ComponentContext } from "../../contexts"
import { useWindowDimensions } from "../../utilities";
import Buttons from "../Buttons";

interface props {
  data: string[],
  page: string,
  activeIndex: number
  // onChange?: (object: {key:string, value: number}) => void
}

const Triangle = styled.div`
  width: 0;
  height: 0;
  margin-right: 10px;
  margin-left: 10px;
  border-bottom: 3px solid #70cde3;
  border-left: 3px solid transparent;
  border-right: 3px solid transparent;
  transform: rotate(90deg);
`

export default ({
  data, page, activeIndex, 
}: props) => {
  const { width } = useWindowDimensions()
  const indexFirst = +page.split(".")[0] || 0

  const historyPath = data
    .map((val, index) => ({ title: val, index }))
    .reduce(
      (pre, curr) => pre.concat({ 
        index: curr.index,
        title: curr.title,
      }, {
        index: curr.index,
        title: "",
      }), [] as { title: string, index?: number | null }[],
    )
  historyPath.pop()

  const componentContext = useContext(ComponentContext);

  const onClick = (index: number | null | undefined, active: number) => {
    // Check case can not forward sub step
    if (componentContext?.onChange && (index || index === 0) && index < active) {
      componentContext?.onChange({ key: "STEPPER", value: +`${indexFirst}.${index}` })
    }
  }
  
  if (width < 540) return null
  return (
    <div 
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        flexWrap: "wrap", 
        marginBottom: 16,
      }}
    >
      {
        historyPath.map((val) => {
          if (val.index || val.index === 0) {
            return (
              val.title 
                ? <Buttons cursor={val.index < activeIndex ? "pointer" : "not-allowed"} onClick={() => onClick(val.index, activeIndex)} href="#" style={{ fontSize: "10px", marginBottom: 10, color: (val.index <= activeIndex) ? "#0067b1" : "#666666" }}>{val.title}</Buttons> 
                : <Triangle style={{ marginBottom: 10 }} />
            )
          }
          return null
        })
      }
    </div>
  )
}