import { createAction } from "redux-actions"
import { fileProps, IFindCountriesResponse, UploadFileType } from "../interfaces"
import * as KEYWORD from "./keyword.action"

// LOGIN
export const getLogin = createAction(KEYWORD.LOGIN)
export const loginSetState = createAction(KEYWORD.LOGIN_SET_STATE)

// HANDLE STEPPER
export const navigateSetWithKey = createAction(KEYWORD.NAVIGATE_SET_WITH_KEY)
export const navigateNext = createAction(KEYWORD.NEXT_NAVIGATE)
export const navigateBack = createAction(KEYWORD.BACK_NAVIGATE)
export const navigateSetStep = createAction<string>(KEYWORD.NEXT_SET_STEP)
export const navigateSetStepWithParams = createAction(KEYWORD.NEXT_SET_STEP_WITH_PARAMS)

// OPENING ACCOUNT
export const setOpenAccountReducer = createAction(KEYWORD.OPENING_ACCOUNT_SET_REDUCER)
export const setOpenAccountWithPageReducer = createAction(
  KEYWORD.OPENING_ACCOUNT_SET_WITH_PAGE_REDUCER,
)
export const verifyRegister = createAction(KEYWORD.OPENING_ACCOUNT_VERIFY_REGISTER)

export type SaveFatcaPayload = {
  isAmerican: boolean;
  hasTaxResidenceOtherThanUsaOrThai: boolean;
  birthplace: {
    id: string
    place: string
    city: string
    countryCode: string
    countryName?: string
  }
  taxResidences: {
    id: string
    countryCode: string
    countryName: string
    tin: string
    noTinReasonId: string
    noTinExplanation: string
  }[]
}

export const saveFatca = createAction<SaveFatcaPayload>(KEYWORD.OPENING_ACCOUNT_SAVE_FATCA)
export const getPep = createAction(KEYWORD.OPENING_ACCOUNT_GET_PEP)
export const validatePep = createAction(KEYWORD.OPENING_ACCOUNT_VALIDATE_PEP)
export const openingAccoutSetState = createAction(KEYWORD.OPENING_ACCOUNT_SET_STATE)
export const getConsent = createAction(KEYWORD.OPENING_ACCOUNT_GET_CONSENT)
export const saveConsent = createAction(KEYWORD.OPENING_ACCOUNT_SAVE_CONSENT)
export const setAoStep = createAction(KEYWORD.OPENING_ACCOUNT_SET_AO_STEP)
export const getAoStep = createAction(KEYWORD.OPENING_ACCOUNT_GET_AO_STEP)
export const getOpenAccountTypeSaga = createAction(KEYWORD.OPENING_ACCOUNT_GETACCOUNTTYPE_SAGA)
export const resetAoSteps = createAction(KEYWORD.OPENING_ACCOUNT_RESET_AO_STEPS)
export const getAllNoTinReasons = createAction(KEYWORD.OPENING_ACCOUNT_GET_ALL_NO_TIN_REASONS)

// AUTH
export const setAuthReducer = createAction(KEYWORD.AUTH_SET_REDUCER)
export const sendEmailOtp = createAction(KEYWORD.AUTH_SEND_EMAIL_OTP)
export const sendMobileOtp = createAction(KEYWORD.AUTH_SEND_MOBOLE_OTP)
export const setEmail = createAction(KEYWORD.AUTH_SET_EMAIL)
export const setMobileNo = createAction(KEYWORD.AUTH_SET_MOBILE_NO)
export const setEmailOtpAnswer = createAction(KEYWORD.AUTH_SET_EMAIL_OTP_ANSWER)
export const setMobileOtpAnswer = createAction(KEYWORD.AUTH_SET_MOBILE_OTP_ANSWER)
export const setAuth = createAction(KEYWORD.AUTH_SET_AUTH)

export const setEmailOtpRequest = createAction(KEYWORD.AUTH_SET_EMAIL_OTP_REQUEST)
export const setMobileOtpRequest = createAction(KEYWORD.AUTH_SET_MOBILE_OTP_REQUEST)
export const setEmailOtpVerify = createAction(KEYWORD.AUTH_SET_EMAIL_OTP_VERIFY)
export const setMobileOtpVerify = createAction(KEYWORD.AUTH_SET_MOBILE_OTP_VERIFY)
export const verifyMobileOtp = createAction(KEYWORD.AUTH_VERIFY_MOBILE_OTP)
export const verifyOtp = createAction(KEYWORD.AUTH_VERIFY_OTP)
export const register = createAction(KEYWORD.AUTH_REGISTER)
export const checkTargetExists = createAction(KEYWORD.AUTH_CHECK_TARGET_EXISTS)
export const loginWithOtp = createAction(KEYWORD.AUTH_LOGIN_WITH_OTP)
export const validateRegisterField = createAction(KEYWORD.AUTH_VALIDATE_REGISTER_FIELD)

// NDID
export const getIDPNDID = createAction(KEYWORD.NDID_GET_IDP)
export const verifyIDPNDID = createAction(KEYWORD.NDID_VERIFY_IDP)
export const saveNDID = createAction(KEYWORD.NDID_FLOW)
export const getCheckVerifyNDID = createAction(KEYWORD.NDID_CHECK_VERICATION_STATUS)
export const getRetrieveData = createAction(KEYWORD.NDID_RETRIEVE_DATA)
export const consentNDID = createAction(KEYWORD.NDID_CONSENT_NDID)
export const cancelNDID = createAction(KEYWORD.NDID_CANCEL_VERIFICATION)

// SUIT
export const getSuitQuestionSaga = createAction(KEYWORD.OPENING_ACCOUNT_GETSUIT_SAGA)
export const saveSuitAnswerSaga = createAction(KEYWORD.OPENING_ACCOUNT_SAVEANSWERSUIT_SAGA)
export const previewSuitabilityTestResult =
  createAction(KEYWORD.OPENING_PREVIEW_SUIT_ABILITY_TEST_RESULT)
export const getSuitAnswerSaga = createAction(KEYWORD.OPENING_ACCOUNT_GETANSWERSUIT_SAGA)
export const saveSuitQuestionReducer =
createAction(KEYWORD.OPENING_ACCOUNT_SAVE_QUESTION_SUIT_REDUCER)
export const saveSuitAnswerReducer = createAction(KEYWORD.OPENING_ACCOUNT_SET_ANSWER_SUIT_REDUCER)
export const setPreviewScore = createAction(KEYWORD.SUITTEST_SET_PREVIEW_TEST_SCORE)

// UPLOAD SAGA
export const saveUploadFileSaga = createAction<{
      file?: File,
      files?: Array<fileProps>,
      field: UploadFileType,
      disabledNext?: boolean, callback?:() => void,
        }>(
        KEYWORD.OPENING_ACCOUNT_UPLOADFILE_SAGA)

// TFEX
export const getTFexQuestion = createAction(KEYWORD.TFEX_GET_QUESTION)
export const saveTFexQuestionReducer = createAction(KEYWORD.TFEX_SAVE_QUESTION_REDUCER)
export const previewTFexTest = createAction(KEYWORD.OPENING_ACCOUNT_PREVIEW_TFEX_TEST)
export const saveTFexTest = createAction(KEYWORD.OPENING_ACCOUNT_SAVE_TFEX_TEST)
export const setPreviewTFex = createAction(KEYWORD.OPENING_ACCOUNT_SET_PREVIEW_TFEX)

// GET ADDDRESS
export const getAddressSaga = createAction<{ text: string, callback:(response: any) => void}>(
  KEYWORD.OPENING_ACCOUNT_GETADDRESS_SAGA)

// GET REFERRAL PEOPLE
export const getReferralPeopleSaga = createAction<{ text: string, type: Array<"MARKETING" | "IBA">, callback:(response: any) => void}>(
  KEYWORD.OPENING_ACCOUNT_GET_REFERRAL_PEOPLE)

// eslint-disable-next-line max-len
export const getCountry = createAction<{ contain: string, callback:(data: { code: string, nameTh: string}[]) => void}>(KEYWORD.OPENING_ACCOUNT_GET_COUNTRY)

// GET MASTER DATA
export const getCountrySaga = createAction(KEYWORD.OPENING_ACCOUNT_GETCOUNTRY_SAGA)
export const getTitleSaga = createAction(KEYWORD.OPENING_ACCOUNT_GETTITLE_SAGA)
export const getSourceOfIncomeSaga = createAction(KEYWORD.OPENING_ACCOUNT_GETSOURCEOFINCOME_SAGA)
export const getMonthlyIncomeSaga = createAction(KEYWORD.OPENING_ACCOUNT_GETMONTHLYINCOME_SAGA)
export const getInvestmentPurposeSaga = createAction(
  KEYWORD.OPENING_ACCOUNT_GETINVESTMENTPURPOSE_SAGA,
)
export const getCareerSaga = createAction(KEYWORD.OPENING_ACCOUNT_GETCAREER_SAGA)
export const getBusinessTypeSaga = createAction(KEYWORD.OPENING_ACCOUNT_GETBUSINESS_SAGA)

// SAVE
export const savePersonalInfoSaga = createAction(KEYWORD.OPENING_ACCOUNT_SAVEPERSONALINFO_SAGA)
export const saveBackgroundInfoSaga = createAction(KEYWORD.OPENING_ACCOUNT_SAVEBACKGROUNDINFO_SAGA)
export const saveContactInfoSaga = createAction(KEYWORD.OPENING_ACCOUNT_SAVECONTACTINFO_SAGA)
export const saveMutualFundAccountSaga = createAction(
  KEYWORD.OPENING_ACCOUNT_SAVEMUTUALFUNDACCOUNT_SAGA,
)
export const saveSavingAccountToMutualFund = createAction(
  KEYWORD.OPENING_ACCOUNT_SAVESAVINGACCOUNTTOMUTUALFUND_SAGA,
)
export const savePersonalContactsSaga = createAction(
  KEYWORD.OPENING_ACCOUNT_SAVE_PERSONAL_CONTACTS_SAGA,
)
// GET ALL DATA
export const getAllDataSaga = createAction(KEYWORD.OPENING_ACCOUNT_QUERYALL_SAGA)

export const getSaveAoStep = createAction(KEYWORD.OPENING_ACCOUNT_SAVEAOSTEP_SAGA)
export const submitSaga = createAction(KEYWORD.OPENING_ACCOUNT_SUBMIT_SAGA)

export const saveSelectAccountSaga = createAction(KEYWORD.OPENING_ACCOUNT_SAVESELECTEDACCOUNT_SAGA)
export const saveReferralPersonSaga = createAction(KEYWORD.OPENING_ACCOUNT_SAVEREFERRAL_SAGA)
// test
export const countDown = createAction("COUNT_DOWN_SAGA")

// CONSENT
export const getAllConsentSaga = createAction(KEYWORD.OPENING_ACCOUNT_GETCONSENTALL_SAGA)

export const setOaStatus = createAction<{ oaStatus: string }>(KEYWORD.STEPS_SET_OA_STATUS)
