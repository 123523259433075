import React from "react";
import styled from "styled-components";
import { TLight } from "../..";
import { fonts, mainFontSize } from "../../../config/themes"
import Image from "../../Images"
import InputCommon from "../../inputCommon";

interface props {
  title?: string
  subtitle?: string
  subtitles?: Array<string>
  buttonTitle?: string
  fontSize?: mainFontSize
  titleImage?: string
  ImageWidth?: string
  ImageHeight?: string
  errorMessage?: string
  maxlength?: number
  placeholder?: string
  onChange: (obj: { value: string }) => void
  onClick?: () => void
  value?: string
}

export default ({
  title,
  subtitle,
  subtitles,
  buttonTitle,
  fontSize,
  titleImage,
  ImageWidth,
  ImageHeight,
  errorMessage,
  onChange,
  placeholder,
  maxlength,
  onClick,
  value,
}: props) => {
  const onChangeText = (obj: {value: string}) => {
    onChange({ value: obj.value })
  }

  const StyledButton = styled("button")<{disabled?: boolean}>`
    display: inline-block;
    box-sizing: border-box;
    padding: 0.4em 1.5em;
    text-decoration: none;
    border-radius: 4px;
    -webkit-font-smoothing: antialiased;
    -webkit-touch-callout: none;
    user-select: none;
    cursor: pointer;
    outline: 0;
    font-size: ${mainFontSize.l};
    font-weight: bold;
    font-family: ${fonts.SarabunMedium};
    border: 2px solid transparent;
    background-color: ${(props) => (props.disabled ? "#CCCCCC" : "#fff")};
    color: #ffffff;

    &:active {
      background: #41addd;
      color: #fff!important;
    }
  `;

  return (
    <div style={{
      display: "flex", flexDirection: "column", marginTop: "20px", marginBottom: "20px", 
    }}
    >
      <Image
        width={ImageWidth}
        height={ImageHeight}
        src={titleImage} 
      />
      <div style={{ marginTop: "10px" }}>
        <p
          style={{
            fontFamily: fonts.SarabunMedium,
            fontSize: mainFontSize.s,
            color: "#4E4E4E",
            textAlign: "left", 
            margin: "0 0 6px 0",
          }}
        >
          {title}
        </p>
      </div>

      <div style={{ flexDirection: "row", display: "flex" }}>
        <div style={{ flex: 0.6, display: "flex" }}>
          <InputCommon
            value={value}
            fontSize={fontSize}
            maxlength={maxlength}
            placeholder={placeholder}
            onChange={onChangeText}
            errorMessage={errorMessage}
          />
        </div>
        <div style={{
          flex: 0.4, justifyContent: "center", alignItems: "center", display: "flex",
        }}
        >
          <StyledButton
            onClick={onClick && onClick}
            style={{
              color: "#0067b1", 
              borderWidth: 1, 
              borderColor: "#0067b1",
              marginLeft: 12,
            }}
          >
            {buttonTitle}
          </StyledButton>
        </div>
      </div>
      { errorMessage && (
        <div style={{ marginTop: 4, display: "flex", flexDirection: "row" }}>
          <TLight color="red" fontSize={mainFontSize.xs}>{errorMessage}</TLight>
        </div>
      )}
      <div style={{ marginTop: "10px" }}>
        {
          subtitle && (
            <p
              style={{
                fontFamily: fonts.SarabunMedium,
                fontSize: mainFontSize.xs,
                color: "#666666",
                textAlign: "left", 
                margin: "0 0 6px 0",
              }}
            >
              {subtitle}
            </p>
          )
        }
        {
          subtitles?.map((text, index) => (
            <p
              key={index}
              style={{
                fontFamily: fonts.SarabunMedium,
                fontSize: mainFontSize.xs,
                color: "#666666",
                textAlign: "left", 
                margin: "0 0 6px 0",
              }}
            >
              {text}
            </p>
          ))
        }
      </div>
    </div>
  )
}