import React from "react"
import { Buttons, Images } from "../.."
import { IDialogProps } from "../../../interfaces"
import { ApproveFail, ApprovalIcon } from "../../Images"
import { TMedium } from "../../Texts"

export default ({ onResolve, onReject, params }: IDialogProps) => (
  <div style={{
    width: 350,
    height: 200,
    background: "#FFFFFF",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    paddingLeft: 40,
    paddingRight: 40,
    paddingTop: 20,
    borderRadius: 15,
  }}
  >
    {/* <div style={{ flex: 0.4, display: "flex", alignItems: "center" }}>
      <Images
        style={{
          width: "80px", 
          height: "80px", 
          padding: "5px", 
          flex: 1, 
          justifyContent: "center", 
        }}
        src={ApprovalIcon}
      />
    </div> */}
    <div style={{ 
      flex: 0.4, textAlign: "center", fontWeight: "normal", display: "flex", alignItems: "center", marginTop: "20px",
    }}
    >
      <TMedium>
        {params?.message}
      </TMedium>
    </div>
    <div style={{
      flexDirection: "row",
      display: "flex",
      justifyContent: "space-between",
      paddingTop: "45px",
    }}
    >
      <div style={{
        display: "flex",
        alignItems: "center",
        marginRight: "25px",
      }}
      >
        <Buttons 
          style={{ 
            padding: "5px 10px",
            backgroundColor: "#ffffff",
            border: "1px solid #0067b1",
          }} 
          onClick={() => onResolve(1, true)}
        >
          <TMedium style={{ color: "#0C67B1" }}>ทำรายการต่อ</TMedium>
        </Buttons>
      </div>
      <div style={{
        display: "flex",
        alignItems: "center", 
      }}
      >
        <Buttons style={{ padding: "5px 10px" }} onClick={() => onResolve(2, true)}>
          <TMedium>ยกเลิกรายการ</TMedium>
        </Buttons>
      </div>
    </div>
  </div>
)