import { useQueryAuthen } from "./apiCall"

export const GetSuitQuestionApi = () => {
  const gql = `query SuitabilityQuestion {
    SuitabilityQuestion {
      order
      question {
        th
        en
      }
      questionImage
      remark{
        th
        en
      }
      subRemark{
        th
        en
      }
      answerType
      choices {
        value
        order
        label {
          th
          en
        }
      }
    }
  }`

  return useQueryAuthen(gql)
}

export const SaveSuitAnswerApi = (input: { [name: string]: string }) => {
  const gql = `mutation saveSuitTesting($input: InputSuitabilityTestInfo) {
    SaveSuitabilityTestInfo(input: $input) {
      isSuccess
      message
    }
  }`
  const variables = {
    input,
  }
  return useQueryAuthen(gql, variables)
}

export const GetSuitResultAnwserApi = () => {
  const gql = `query getSuitTesting {
    GetSuitabilityTestInfo {
      answer01
      answer02
      answer03
      answer04
      answer05
      answer06
      answer07
      answer08
      answer09
      answer10
      answer11
      answer12
      score
      investorLevel
      isExpired
      expiryDate
    }
  }`

  return useQueryAuthen(gql)
}

export const PreviewSuitabilityTestResultApi = (input: { [name: string]: string }) => {
  const gql = `mutation previewSuitabilityTestResult($input: SuitabilityTestAnswerInput) {
    PreviewSuitabilityTestResult(input: $input) {
      score
      investorLevel
      suggestAssetAllocation {
        order
        labelTh
        labelEn
        percentage
      }
      suggestFundLevel
    }
  }`
  const variables = {
    input,
  }
  return useQueryAuthen(gql, variables)
}
