import React from "react"
import styled from "styled-components"

interface props {
  percent: number
}

const WrapperProgressBar = styled.div`
  width: 100%;
  height: 10px;
  border-radius: 10px;
  background-color: #e5e5e5;
`

export default styled((props) => (
  <WrapperProgressBar>
    <div {...props} />
  </WrapperProgressBar>
))<props>`
  width: ${({ percent }) => percent * 100}%;
  height: 100%;
  border-radius: 10px;
  ${({ percent }) => percent >= 0.1 && "background-color: red;"}
  ${({ percent }) => percent >= 0.6 && "background-color: orange;"}
  ${({ percent }) => percent >= 1 && "background-color: #00b419;"}
  transition: width 0.25s ease-in;
  ${({ percent }) => percent < 0 && "width: 0%;"}
  ${({ percent }) => percent > 1 && "width: 100%;"}
  
`