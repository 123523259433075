import { useQueryAuthen } from "./apiCall"

export const GetListIdpAPI = () => {
  const gql = `query ListIdp {
    ListIdp {
      remainingVerify
      idpList {
        idpID
        displayNameEN
        displayNameTH
        isUsage
        nodeName
        agent
        startServiceTime
        endServiceTime
        logo
      }
      onTheFlyList{
        idpID
        displayNameEN
        displayNameTH
        isUsage
        nodeName
        agent
        startServiceTime
        endServiceTime
        logo
      }
    }
  }`
  return useQueryAuthen(gql)
}

export const CancelVerificationAPI = () => {
  const gql = `mutation CancelVerification{
    CancelVerification {
      isSuccess
      message
    }
  }`
  return useQueryAuthen(gql)
}

export const VerifyIdpAPI = (params: {
  idpID: string,
  displayNameEN: string,
  displayNameTH: string,
}) => {
  const gql = `mutation verifyIdp($input: VerifyIdpRequest) {
    VerifyIdp(input: $input) {
      verification {
        service
        secret
      }
      error
    }
  }`
  const variables = { 
    input: {
      idpID: params.idpID,
      displayNameEN: params.displayNameEN,
      displayNameTH: params.displayNameTH,
    },
  }
  return useQueryAuthen(gql, variables)
}

export const CheckStatusVerificationAPI = () => {
  const gql = `mutation CheckStatusVerification {
    CheckStatusVerification {
      status
      verifyBy
      referenceID
      verifyBy
      error {
        errorMessageTH
        errorMessageEN
      }
    }
  }`
  return useQueryAuthen(gql)
}

export const RetrieveDataVerificationAPI = () => {
  const gql = `query RetrieveDataVerification {
    RetrieveDataVerification {
      referenceId
      identificationCard {
        type
        number
        issueDate
        issueCountry
        expiryDate
        isExpiryDate
      }
      gender
      title
      titleOther
      firstNameTh
      middleNameTh
      lastNameTh
      firstNameEn
      middleNameEn
      lastNameEn
      birthDate
      nationality
      maritalStatus
      identificationCardAddress {
        addressId
        number
        village
        building
        floor
        room
        soi
        road
        moo
        postcode
        fullAddress
      }
      currentAddress {
        addressId
        number
        village
        building
        floor
        room
        soi
        road
        moo
        postcode
        fullAddress
      }
      workplaceAddress {
        addressId
        number
        village
        building
        floor
        room
        soi
        road
        moo
        postcode
        fullAddress
      }
      companyName
      occupation
      occupationOther
      monthlyIncomeLevel
      phoneNumber
      email
      transactionDate
    }
  }`
  return useQueryAuthen(gql)
}

export const ConsentNDIDAPI = (accept: boolean) => {
  const gql = `mutation ConsentNdid($input: ConsentNdidInput){
    ConsentNdid(input: $input){
      isSuccess
    }
  }`
  const variables = {
    input: {
      accept,
    },
  }
  return useQueryAuthen(gql, variables)
}