import React from "react"
import { Buttons } from "../.."
import { IDialogProps } from "../../../interfaces"
import { TMedium } from "../../Texts"

export default ({ onResolve, params }: IDialogProps) => {
  const { ChildComp, buttonStyle } = params
  return (
    <div style={{
      minWidth: 350,
      minHeight: 250,
      background: "#FFFFFF",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      padding: "40px",
      borderRadius: 15,
      maxWidth: "720px",
      maxHeight: "80vh",
      overflow: "scroll",
    }}
    >
      <div
        style={{
          flex: 0.4,
          textAlign: "center",
          fontWeight: "normal",
          display: "flex",
          alignItems: "center",
        }}
      >
        {ChildComp ? <ChildComp /> : <TMedium>{params?.message}</TMedium>}
      </div>
      <div style={{
        display: "flex",
        alignItems: "center",
        flex: 0.2,
      }}
      >
        <Buttons style={{ fontFamily: buttonStyle.fontFamily }} onClick={() => onResolve(1)}>
          ปิด
        </Buttons>
      </div>
    </div>
  )
}