import React from "react"

export const Month = [
  "มกราคม",
  "กุมภาพันธ์",
  "มีนาคม",
  "เมษายน",
  "พฤษภาคม",
  "มิถุนายน",
  "กรกฎาคม",
  "สิงหาคม",
  "กันยายน",
  "ตุลาคม",
  "พฤศจิกายน",
  "ธันวาคม",
];

export const ShortMonth = [
  "ม.ค.",
  "ก.พ.",
  "มี.ค.",
  "เม.ย.",
  "พ.ค.",
  "มิ.ย.",
  "ก.ค.",
  "ส.ค.",
  "ก.ย.",
  "ต.ค.",
  "พ.ย.",
  "ธ.ค.",
];

export const MonthEN = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export const ShortMonthEN = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

export const DateString = (dateString: string, lang: "en" | "th") => {
  if (!dateString) return ""
  const date = new Date(dateString);
  let newDate = "";
  newDate = `${date.getDate()} `;
  newDate += `${lang === "th" ? Month[date.getMonth()] : MonthEN[date.getMonth()]} `
  newDate += `${lang === "th" ? date.getFullYear() + 543 : date.getFullYear()}`;
  return newDate
}

export const DateFormat = (dateString: string, divider = "/") => {
  if (!dateString) return ""
  const date: Array<string> = dateString.split("-");
  return `${date[2]}${divider}${date[1]}${divider}${parseInt(date[0], 10) + 543}`
}

export const DateFormatYMD = (dateString: string, divider = "-") => {
  if (!dateString) return ""
  const date: Array<string> = dateString.split("/");
  return `${parseInt(date[2], 10) - 543}${divider}${date[1]}${divider}${date[0]}`
}

export const DateFormatProtectSensitive = (dateString: string, divider = "/") => {
  if (!dateString) return ""
  const date: Array<string> = dateString.split("-");
  return `${date[2]}${divider}XX${divider}XX${(parseInt(date[0], 10) + 543).toString().substr(2, 2)}`
}

export const isValidDate = (dateString: string) => {
  // First check for the pattern
  const regexDate = /^\d{4}-\d{1,2}-\d{1,2}$/;

  if (!regexDate.test(dateString)) {
    return false;
  }

  // Parse the date parts to integers
  const parts = dateString.split("-");
  const day = parseInt(parts[2], 10);
  const month = parseInt(parts[1], 10);
  const year = parseInt(parts[0], 10);

  // Check the ranges of month and year
  if (year < 1000 || year > 3000 || month === 0 || month > 12) {
    return false;
  }

  const monthLength = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

  // Adjust for leap years
  if (year % 400 === 0 || (year % 100 !== 0 && year % 4 === 0)) {
    monthLength[1] = 29;
  }

  // Check the range of the day
  return day > 0 && day <= monthLength[month - 1];
}