import React, { useState, useEffect, FormEvent } from "react"

import styled from "styled-components"
import { fonts, mainFontSize } from "../../config/themes"
import { IInputsChoiceShema } from "../../interfaces"

interface props {
  fontSize?: mainFontSize
  onChange: (obj: {value: string}) => void
  placeholder?: string
  style?: React.CSSProperties,
  value?: string | IInputsChoiceShema[] | null
  disabled?: boolean
  maxlength?: number
  errorMessage?: string | Array<string>
  upperCaseOnly?: boolean
}

const Input = styled((props) => <input {...props} />)<props>`
  font-size: ${(props) => props.fontSize || mainFontSize.m};
  outline: none;
  width: 100%;
  padding: 10px 20px;
  box-sizing: border-box;
  border: 1px solid #cccccc;
  border: 1px solid  ${(props) => (props.errormessage ? "red" : "#cccccc")};
  border-radius: 4px;
  font-family: ${(props) => props.style?.fontFamily || fonts.SarabunLight};
  color: #333333;

  ::placeholder {
    color: #cccccc;
    padding-left: 2px;
    font-size: ${mainFontSize.m};
    font-family: ${(props) => props.style?.fontFamily || fonts.SarabunLight};
  }
`

export default ({
  fontSize,
  onChange,
  placeholder,
  style,
  value,
  maxlength,
  disabled,
  errorMessage,
  upperCaseOnly,
}: props) => {
  const [text, setText] = useState("")
  const onChangeText = (e: FormEvent<HTMLInputElement>) => {
    e.preventDefault()
    if (upperCaseOnly) {
      setText(e.currentTarget.value.toUpperCase())
      onChange({ value: e.currentTarget.value.toUpperCase() })
    } else {
      setText(e.currentTarget.value)
      onChange({ value: e.currentTarget.value })
    }
  }
  
  useEffect(() => {
    setText(value as string || "")
  }, [value])
  
  return (
    <Input
      style={style}
      fontSize={fontSize}
      placeholder={placeholder}
      maxLength={maxlength}
      value={text}
      disabled={disabled}
      onChange={onChangeText}
      errormessage={errorMessage}
    />
  )
}