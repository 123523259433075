import React from "react"
import { useDispatch, useSelector } from "react-redux"
import {
  getBusinessTypeSaga, getCareerSaga, getCountrySaga, getTitleSaga,
} from "../../../actions"
import {
  IGetBusinessTypeResponse, IGetCareerResponse, IGetCountryResponse, IGetTitleResponse, IStore,
} from "../../../interfaces"
import Dropdown from "../../Dropdown"

interface props {
  value: string
  onChange: (obj: {key: string, value: string }) => void
  errorMessage?: string | Array<string>
  disabled?: boolean
  field?: string
  placeholder?: string
}

type selectedItems = {
  title: string
  titleTh: string
  titleEn: string
  id: number
}

const unselectedOption = {
  id: "",
  title: "ไม่เลือก",
}

export default ({
  value,
  disabled,
  onChange,
  errorMessage,
  field,
  placeholder,
}: props) => {
  const dispatch = useDispatch()
  const [items, setItems] = React.useState<{title: string}[]>([])
  const [text, setText] = React.useState("")
  const accountOpeningReducer = useSelector((state: IStore) => state.OpeningAccountReducer);

  // Check field for render Items
  const renderItems = (fieldInput: string) => {
    if (!fieldInput) {
      return;
    }

    const textField = fieldInput.toLowerCase()
    if (textField.includes("country")) {
      dispatch(
        getCountrySaga({
          callback: (response: IGetCountryResponse) => {
            if (response.data.GetCountry.length > 0) {
              setItems(response.data.GetCountry.map((val) => ({
                title: val.name,
                id: val.id,
              })) as never[])
            } else {
              setItems([])
            }
          },
        }),
      )
    }
    if (textField === "title" || textField === "titleen") {
      dispatch(
        getTitleSaga({
          callback: (response: IGetTitleResponse) => {
            if (response.data.GetTitle.length > 0) {
              setItems(response.data.GetTitle.map((val) => ({
                title: textField === "title" ? val.displayTh : val.displayEn,
                titleTh: val.displayTh,
                titleEn: val.displayEn,
                id: val.id,
              })) as never[])
            } else {
              setItems([])
            }
          },
        }),
      )
    }

    if (textField.includes("career")) {
      dispatch(
        getCareerSaga({
          callback: (response: IGetCareerResponse) => {
            if (response.data.GetCareer.length > 0) {
              setItems(response.data.GetCareer.map((val) => ({
                title: val.displayTh,
                id: val.id,
              })) as never[])
            } else {
              setItems([])
            }
          },
        }),
      )
    }
    if (textField.includes("typebusiness")) {
      dispatch(
        getBusinessTypeSaga({
          callback: (response: IGetBusinessTypeResponse) => {
            if (response.data.GetBusinessType.length > 0) {
              setItems(response.data.GetBusinessType.map((val) => ({
                title: val.displayTh,
                id: val.id,
              })) as never[])
            } else {
              setItems([])
            }
          },
        }),
      )
    }

    if (textField === "notinreasons") {
      const { noTinReasons } = accountOpeningReducer.termAndConditoin.STEP_VERIFY_FATCA
      const tmpItems = noTinReasons.map(
        (reason: { id: string, reasonTh: string, reasonEn: string }) => ({
          id: reason.id,
          title: reason.reasonTh,
        }),
      )
      setItems([unselectedOption, ...tmpItems])
    }
  }

  const onSelected = (val: string) => {
    const obj: selectedItems = JSON.parse(val)
    if (field === "otherCountryOfIncome") {
      onChange({ key: "otherCountryOfIncome", value: obj.title })
      onChange({ key: "codeCountry", value: obj.id.toString() })
    }
    if (field === "title" || field === "titleEn") {
      onChange({ key: "title", value: obj.titleTh })
      onChange({ key: "codeTitle", value: obj.id.toString() })
      onChange({ key: "titleEn", value: obj.titleEn })
    }

    if (field === "career") {
      onChange({ key: "career", value: obj.title })
      onChange({ key: "codeCareer", value: obj.id.toString() })
    }
    if (field === "typeBusiness") {
      onChange({ key: "typeBusiness", value: obj.title })
      onChange({ key: "codeTypeBusiness", value: obj.id.toString() })
    }
    if (field === "noTinReasons") {
      onChange({ key: "", value: val });
    }
  }

  React.useEffect(() => { renderItems(field as string) }, [])
  React.useEffect(() => { if (typeof value === "string") setText(value) }, [value])

  return (
    <Dropdown
      disabled={disabled}
      errorMessage={errorMessage}
      onClick={() => renderItems(field as string)}
      data={items}
      value={text}
      onChange={onSelected}
      placeholder={placeholder}
    />
  )
}
