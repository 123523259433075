import styled from "styled-components";
import { fonts } from "../../config/themes";

interface props {
  paddingVertical?: string
  paddingHorizontal?: string
  cursor?: "pointer" | "not-allowed"
}

export default styled("a")<props>`
  display: inline-block;
  box-sizing: border-box;
  text-decoration: none;
  border-radius: 4px;
  -webkit-font-smoothing: antialiased;
  -webkit-touch-callout: none;
  user-select: none;
  cursor: ${(props) => props.cursor || "pointer"};
  outline: 0;
  font-weight: bold;
  font-size: 16px;
  color: #41addd;
  ${(props) => `padding: ${props.paddingVertical || "0px"} ${props.paddingHorizontal || "0px"};`}
  font-family: ${(props) => props.style?.fontFamily || fonts.SarabunMedium};
  target: ${(props) => props.target || "_self"};
`;