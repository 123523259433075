import EventEmitter from "eventemitter3";

const eventEmitter = new EventEmitter();

type event = string
type fn = (params?: any) => void
type payload = any

const Emitter = {
  on: (event: event, fn: fn) => eventEmitter.on(event, fn),
  once: (event: event, fn: fn) => eventEmitter.once(event, fn),
  off: (event: event, fn: fn) => eventEmitter.off(event, fn),
  emit: (event: event, payload?: payload) => eventEmitter.emit(event, payload),
}

Object.freeze(Emitter);

export default Emitter;