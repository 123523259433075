/* eslint-disable consistent-return */
import React from "react"
import { showDialog } from "../components/Provider/dialog"
import {
  fileProps,
  IDialogTypeEnum, IInputsShema, InputEnum, IOpenAccountShema, IRule, IRuleEnum, 
} from "../interfaces"
import { isValidDate } from "."

/*
  allow password onCheckStrongPassword({ text })
   1 easy = no min
   2 medium = a-z A-Z 0-9 min 6 characters or min 8 characters
   3 medium = a-z A-Z 0-9 must to 8 characters
  return 0 | 0.1 | 0.6 | 1 
*/
export const onCheckStrongPassword = ({ text = "2@sAsd" }: { text: string }): number => {
  const mediumPassword = new RegExp("((?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{6,}))|((?=.*[a-z])(?=.*[A-Z])(?=.*[^A-Za-z0-9])(?=.{8,}))")
  const strongPassword = new RegExp("(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,})")
  const easy = new RegExp("^(?=.*[A-Za-z])(?=.*)[A-Za-z]{8,}$")

  if (strongPassword.test(text)) return 1
  if (mediumPassword.test(text)) return 0.6
  if (easy.test(text)) return 0.1
  return 0
}

/*
  handle routing on url and click items
  return 0 or leng for focus items
*/
const initRouter = ((initRout: string, data: IOpenAccountShema[]) => {
  const totalLengItems: number = +`${data.length - 1}.${data[data.length - 1].form.length}`
  if (Number.isNaN(initRout)) return 0
  if (+initRout < 0) return 0
  if (+initRout > totalLengItems) return totalLengItems
  return initRout
})

const positionEncode = [23, 30, 75, 76, 77, 78, 79, 80, 81, 82]

export const onRandomGenaredString = (length: number) => {
  const result = [];
  const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  const charactersLength = characters.length;
  for (let i = 0; i < length; i += 1) {
    result.push(characters.charAt(Math.floor(Math.random() * charactersLength)));
  }
  return result.join("");
}

export const encode = (memberid = "") => {
  try {
    const arrId = memberid.split("")
    const genSlit = onRandomGenaredString(100).split("")
    return arrId.map((val, index) => {
      genSlit.splice(positionEncode[index], 0, val)
      return genSlit.join("")
    })[arrId.length - 1].toString().concat(arrId.length.toString())
  } catch (e) {
    return e
  }
}

export const decode = (text = "") => {
  const arr = text.toString().split("")
  const decoded = arr.map((val, index) => (positionEncode.indexOf(index) !== -1 ? val : null)).join("").split("")
  const lastWord = arr[arr.length - 1]
  decoded.splice(+lastWord, decoded.length - 1)
  return decoded.join("")
}

interface IInbound {
  data: IOpenAccountShema[], 
  initRout: string, 
  onChange?: (value: string) => void,
  allowEdit?: any[]
}
interface IOutbound {
  page: string
  index: number
  nextPage: () => void
  backPage: () => void
}

export const usePageScreen = ({ 
  data,
  initRout = "1.0", 
  onChange, 
  allowEdit = [],
}:IInbound): IOutbound => {
  const isEncoded = initRout.length > 20 ? decode(initRout) : initRout
  const [index, setIndex] = React.useState<number>(+initRouter(isEncoded, data).toString().split(".")[0])
  const [subView, setSubView] = React.useState<number>(+initRouter(isEncoded, data).toString().split(".")[1] || 0)
  const [page, setPage] = React.useState("0")

  React.useEffect(() => {
    setPage(index.toString().concat(`.${subView}`))
    if (onChange) {
      onChange(index.toString().concat(`.${subView}`))
    }
  }, [subView, index])

  React.useEffect(() => {
    setIndex(+initRouter(isEncoded, data).toString().split(".")[0])
    setSubView(+initRouter(isEncoded, data).toString().split(".")[1] || 0)
  }, [isEncoded, data])

  return {
    page,
    index,
    nextPage: () => {
      if (data[index].form.length <= 1) {
        setIndex((val) => val + 1)
      } else if (subView < data[index].form.length - 1) {
        setSubView((val) => val + 1)
      } else if (index < data.length - 1) {
        setIndex((val) => val + 1)
        setSubView(0)
      }
    },
    backPage: () => {
      // Check befor back
      const pageIndex = subView === 0 ? index - 1 : index
      const subPageIndex = subView === 0 
        ? data[index - 1 < 0 ? 0 : index - 1].form.length - 1 
        : subView - 1
        
      const isTfexAlive = allowEdit[6]?.subStep?.find((val: {stepName: string}) => val.stepName === "STEP_TFEX_TEST")
      const isSuit = allowEdit[isTfexAlive ? pageIndex - 1 : pageIndex]?.subStep?.find((val: {stepName: string}) => val.stepName === "STEP_SUITABILITY_TEST")
      const isTfex = allowEdit[pageIndex]?.subStep?.find((val: {stepName: string}) => val.stepName === "STEP_TFEX_TEST")

      const back = () => {
        setSubView((val) => val - 1)
        if (subView === 0) {
          setIndex((val) => val - 1)
          setSubView(data[index - 1 < 0 ? 0 : index - 1].form.length - 1)
        }
      }
      
      if (isSuit?.allowEdit) {
        back()
        return
      }
      if (isTfexAlive?.allowEdit) {
        back()
        return
      }

      console.log("onback", pageIndex, subPageIndex, allowEdit, data)
      
      if (allowEdit[pageIndex]?.subStep[subPageIndex]?.allowEdit) {
        back()
      } else {
        showDialog(
          IDialogTypeEnum.ERROR_DIALOG, 
          { message: "ทำการยืนยันรายการแล้ว ไม่สามารถกดย้อนกลับได้" },
          false,
        )
      }
    },
  }
}

export const GetCurrentPage = (currentPage: string): { page: string, subPage: string } => {
  const dc = decode(currentPage)

  const c = (`${dc || currentPage}`)?.split(".");
  return {
    page: (c[0] || "0"),
    subPage: (c[1] || "0"),
  }
};

export const SecureString = (str: string): string => {
  if (str === "") return ""
  if (str.indexOf("@") === -1) return `${str.substring(0, 3)}***${str.substring(str.length - 3)}`
  return `${str.substring(0, 3)}***${str.substring(str.indexOf("@"))}`
}

export const IsEmailFormat = (str: string): boolean => {
  const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|((([a-zA-Z\-0-9]{2,})+\.)+[a-zA-Z]{2,}))$/;
  return regex.test(str)
}

export const IsMobileFormat = (str: string): boolean => {
  const regex = /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/;
  return regex.test(str)
}

export const CheckMobileNoOrEmailFormat = (str: string): "MOBILE" | "EMAIL" | "INVALID" => {
  if (IsMobileFormat(str)) return "MOBILE"
  if (IsEmailFormat(str)) return "EMAIL"
  return "INVALID"
}

export const GetDeviceId = () => {
  const nav = window.navigator;
  const { screen } = window;
  let deviceId = `${nav.mimeTypes.length}`;
  deviceId += nav.userAgent.replace(/\D+/g, "");
  deviceId += nav.plugins.length;
  deviceId += screen.height || "";
  deviceId += screen.width || "";
  deviceId += screen.pixelDepth || "";
  
  return deviceId
}

export function useWindowDimensions() {
  const getWindowDimensions = () => {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height,
    };
  }
  const [windowDimensions, setWindowDimensions] = React.useState(getWindowDimensions);

  React.useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions);
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowDimensions;
}

export function useOutsideAlerter(ref: any, callback?: () => void) {
  React.useEffect(() => {
    function handleClickOutside(event: any) {
      if (ref.current && !ref.current.contains(event.target)) {
        // alert("You clicked outside of me!");
        callback?.()
      }
    }
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);
}

export const Gender = { M: "ชาย", F: "หญิง" }

export const GenderEN = { M: "Male", F: "Female" }

export const GenderString = (code: "M" | "F", lang: "en" | "th"): string => (lang === "th" ? Gender[code] : GenderEN[code])

export const textInputValidate = (rules: Array<IRule>, value: string): string => {
  if (!rules) return ""
  for (let i = 0; i < rules.length; i += 1) {
    const validate = rules[i]
    if (validate.type === IRuleEnum.REQUIRED && (!value || value.trim() === "")) {
      return validate.errorMessage || "กรุณาระบุข้อมูล"
    }
    
    if (validate.type === IRuleEnum.FORMAT && 
      value &&
      (validate?.regex instanceof RegExp && validate?.regex?.test(value) === false)) {
      return validate.errorMessage || "รูปแบบไม่ถูกต้อง"
    }

    if (validate.type === IRuleEnum.FIXED_LENGTH && 
      (value && value.length !== validate.maxlength)) {
      return validate.errorMessage || `กรุณาระบุตัวอักษรให้ครบ ${validate.maxlength} หลัก`
    }

    if (validate.type === IRuleEnum.NUMBER_ONLY && /^\d+$/.test(value) === false) {
      return validate.errorMessage || "กรุณาระบุเป็นตัวเลขเท่านั้น"
    }

    if (validate.type === IRuleEnum.DATE_FORMAT && !isValidDate(value)) {
      return validate.errorMessage || "รูปแบบวันที่ไม่ถูกต้อง"
    }
  }

  return ""
}

export const checkboxValidate = 
  (rules: Array<IRule>, values: Array<string>): string => {
    if (!rules) return ""
    for (let i = 0; i < rules.length; i += 1) {
      const validate = rules[i]
      if (validate.type === IRuleEnum.REQUIRED) {
        const isSelected = values.some((val: any) => val?.selected)
        if (!isSelected) {
          return validate.errorMessage || "กรุณาเลือกอย่างน้อย 1 ข้อ"
        }
      }
    }

    return ""
  }

export const radioValidate = 
  (rules: Array<IRule>, values: Array<string>): string => {
    if (!rules) return ""
    for (let i = 0; i < rules.length; i += 1) {
      const validate = rules[i]
      if (validate.type === IRuleEnum.REQUIRED) {
        const isSelected = values.some((val: any) => val?.selected)
        if (!isSelected) {
          return validate.errorMessage || "กรุณาเลือกอย่างน้อย 1 ข้อ"
        }
      }
    }

    return ""
  }

export const dropdownValidate = 
  (rules: Array<IRule>, value: string): string => {
    if (!rules) return ""
    for (let i = 0; i < rules.length; i += 1) {
      const validate = rules[i]
      if (validate.type === IRuleEnum.REQUIRED && !value) {
        return validate.errorMessage || "กรุณาระบุข้อมูล"
      }
    }

    return ""
  }

export const autoCompleteValidate = 
  (rules: Array<IRule>, value: string): string => {
    if (!rules) return ""
    for (let i = 0; i < rules.length; i += 1) {
      const validate = rules[i]
      if (validate.type === IRuleEnum.REQUIRED && !value) {
        return validate.errorMessage || "กรุณาระบุข้อมูล"
      }
    }

    return ""
  }

export const uploadFileValidate = (rules: Array<IRule>, value: any): string => {
  if (!rules) return ""
  for (let i = 0; i < rules.length; i += 1) {
    const validate = rules[i]
    if (validate.type === IRuleEnum.REQUIRED && !value) {
      return validate.errorMessage || "กรุณาระบุข้อมูล"
    }
  }

  return ""
}

export const uploadMultipleFileValidate = (rules: Array<IRule>, value: any)
  : string | Array<string> => {
  if (!rules) return ""
  for (let i = 0; i < rules.length; i += 1) {
    const validate = rules[i]
    if (validate.type === IRuleEnum.REQUIRED && (!value || (value && value?.length === 0))) {
      return validate.errorMessage || "กรุณาระบุข้อมูล"
    }

    if (validate.type === IRuleEnum.MAX_FILE_SIZE && (value && value?.length > 0)) {
      const isOversize = (size: number, limitSizeMB = 5) => size > 1024 * (1024 * limitSizeMB)
      const errorMessages: Array<string> = []
      for (let j = 0; j < value.length; j += 1) {
        const file = value[j]?.data;
        
        if (isOversize(file?.size, validate?.maxFileSize as number)) {
          errorMessages.push(`${j + 1}-ไฟล์มีขนาดใหญ่เกิน ${validate?.maxFileSize} MB`)
        }
      }

      if (errorMessages.length > 0) {
        return errorMessages
      }
    }
  }

  return ""
}

export const datePickerValidate = (rules: Array<IRule>, value: string): string => {
  if (!rules) return ""
  for (let i = 0; i < rules.length; i += 1) {
    const validate = rules[i]
    if (validate.type === IRuleEnum.REQUIRED && (!value || value.trim() === "")) {
      return validate.errorMessage || "กรุณาระบุข้อมูล"
    }

    if (validate.type === IRuleEnum.DATE_FORMAT && !isValidDate(value)) {
      return validate.errorMessage || "รูปแบบวันที่ไม่ถูกต้อง"
    }
  }

  return ""
}

export const handleErrorScreen = (inputs: IInputsShema[], valuesObj: any):
  { errors: Array<{ field: string, message: string | Array<string> }>, validateFlag: boolean } => {
  // const values = Object.keys(valuesObj).map((field, keyindex) => valuesObj[field])
  const values = Object.keys(valuesObj).map(
    (field, keyindex) => ({ field, value: valuesObj[field] }),
  )

  const errors: Array<{ field: string, message: Array<string> | string }> = []
  let validateFlag = true

  // get keyTarget name
  const inputWithKeyTargets = inputs?.filter(
    (input) => input.keyTarget && input.keyTarget?.length > 0,
  ).filter(
    (ip) => valuesObj[ip.field]?.some(
      (d: any) => d.requireOther === true && d.selected === false,
    ),
  )
  
  let keyTargetAll: any = []
  for (let i = 0; i < inputWithKeyTargets.length; i += 1) {
    keyTargetAll = keyTargetAll.concat(inputWithKeyTargets[i].keyTarget) 
  }

  const clones = JSON.parse(JSON.stringify(inputs))
  const inputClones = clones.map(
    (clone: any, index: number) => ({ ...clone, rules: inputs[index].rules }),
  )
  
  for (let i = 0; i < keyTargetAll.length; i += 1) {
    for (let j = 0; j < inputClones.length; j += 1) {
      if (keyTargetAll[i] === inputClones[j].field) {
        delete inputClones[j].rules
      }
    }
  }

  for (let i = 0; i < inputClones.length; i += 1) {
    const input: IInputsShema = inputClones[i]
    const value = values.find((val: any) => val?.field === input.field)

    let error: Array<string> | string = ""
    if (input?.rules) {
      if (input.type === InputEnum.inputCommon) {
        error = textInputValidate(input.rules as Array<IRule>, value?.value as string)
        if (error) validateFlag = false
      } 
      
      if (input.type === InputEnum.inputCheckbox) {
        error = checkboxValidate(
          input.rules as Array<IRule>, value?.value as Array<any>,
        )
        if (error) validateFlag = false
      } 
      
      if (input.type === InputEnum.inputRadio) {
        error = radioValidate(input.rules as Array<IRule>, value?.value as Array<any>)
        if (error) validateFlag = false
      } 

      if (input.type === InputEnum.inputDropdown) {
        if (error) validateFlag = false
        error = dropdownValidate(input.rules as Array<IRule>, value?.value as string)
        if (error) validateFlag = false
      } 
      
      if (input.type === InputEnum.inputAutoComplete) {
        if (error) validateFlag = false
        error = autoCompleteValidate(
          input.rules as Array<IRule>, value?.value as string,
        )
        if (error) validateFlag = false
      } 
      
      if (input.type === InputEnum.inputUploadFile) {
        if (error) validateFlag = false
        error = uploadFileValidate(
          input.rules as Array<IRule>, value?.value,
        )
        if (error) validateFlag = false
      } 

      if (input.type === InputEnum.inputUploadMultipleFiles) {
        error = uploadMultipleFileValidate(
          input.rules as Array<IRule>, value?.value,
        )
        if (error) validateFlag = false
      } 
      
      if (input.type === InputEnum.inputDatePicker) {
        if (error) validateFlag = false
        error = datePickerValidate(
          input.rules as Array<IRule>, value?.value,
        )
        if (error) validateFlag = false
      }
    }

    errors.push({ field: input.field, message: error })
  }

  const field = errors.find((error) => error.message !== "")?.field
  if (field) {
    const scrollTo: any = document.getElementById(field)
    // scrollTo = scrollTo?.previousElementSibling
    scrollTo?.scrollIntoView({ behavior: "smooth", block: "center" })
  }
  return { errors, validateFlag }
}

export const getErrorMessage = 
  (fieldName: string, errorMessages: Array<{ field: string, message: Array<string> | string }>): 
    Array<string> | string => errorMessages.find((error) => error.field === fieldName)?.message || ""

export const IdentityCardProtectSensitive = (identityStr: string, divider = "-") => {
  if (!identityStr) return ""
  return `${identityStr.substr(0, 1)}${divider}${identityStr.substr(1, 3)}X${divider}XXXXX${divider}${identityStr.substr(10, 2)}${divider}${identityStr.substr(12, 1)}`
  // const date: Array<string> = dateString.split("-");
  // return `${date[2]}${divider}XX${divider}XX${(parseInt(date[0], 10) + 54
  // 3).toString().substr(2, 4)}`
}

export const GenFilePropsArray = 
  (files: Array<any>): Array<fileProps> => files?.map((file) => ({
    name: file.filename,
    contentType: file.contentType,
    data: file.imageBase64Encode,
    imageType: file.imageType,
    status: file.status,
  }))

export const ConvertBase64Tofile = async (files?: Array<fileProps>) => {
  if (!files || (files && files.length === 0)) return []
  const r = await Promise.all(files.map(async (file, key): Promise<fileProps> => {
    let base64 = `data:image/jpeg;base64,${file.data}`
    if (file.contentType?.includes("pdf")) {
      base64 = `data:application/pdf;base64,${file.data}`
    }
    
    const blob = await fetch(base64).then((res) => res.blob());
    return {
      ...file,
      data: blob as File,
    }
  }));

  return r
}