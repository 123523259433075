import { Button, Grid } from "@material-ui/core";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components"
import {
  getAllNoTinReasons,
  saveFatca, SaveFatcaPayload,
} from "../../../actions";
import {
  Buttons,
  Card,
  HistoryOfPath,
  Inputs,
  Texts,
  TLight, TMedium,
} from "../../../components";
import Checkbox from "../../../components/CheckBox";
import Images, { cross } from "../../../components/Images";
import { TextView } from "../../../components/Inputs/inputTermsAndCons";
import { color, fonts, mainFontSize } from "../../../config/themes";

import { ComponentContext } from "../../../contexts"
import {
  IFormsShema,
  InputEnum,
  IStore,
  IRuleEnum,
  IInputsShema,
} from "../../../interfaces";

interface props {
  page: string
  dataOfPage: IFormsShema[],
}

type State = {
  isAmerican: boolean
  birthplace: {
    id: string
    place: string
    city: string
    countryCode: string
    countryName: string
  }
  hasTaxResidencesOtherThanUsaOrThailand: boolean
  taxResidences: {
    id: string,
    countryCode: string,
    countryName: string,
    tin: string,
    noTinReasonId: string,
    noTinExplanation: string,
  }[]
  isConfirmationChecked: boolean
}

const ButtonHolder = styled.div`
  display: flex;
  justify-content: space-between;
`

function randomTemporaryId() {
  return `TMP${Date.now().toString()}${Math.round(Math.random() * 100)}`
}

function FatcaTermAndCons({ page, dataOfPage }: props) {
  const dispatch = useDispatch()
  const componentContext = useContext(ComponentContext);
  const { termAndConditoin } = useSelector((state: IStore) => state.OpeningAccountReducer)
  const { t } = useTranslation()
  const data = termAndConditoin.STEP_VERIFY_FATCA
  const [state, setState] = useState<State>({
    isAmerican: data.isAmerican,
    birthplace: {
      id: data.birthplace.id || randomTemporaryId(),
      place: data.birthplace.place,
      city: data.birthplace.city,
      countryCode: data.birthplace.countryCode,
      countryName: data.birthplace.countryName,
    },
    hasTaxResidencesOtherThanUsaOrThailand: data.hasTaxResidencesOtherThanUsaOrThailand,
    taxResidences: data.taxResidences,
    isConfirmationChecked: false,
  });
  useEffect(() => {
    dispatch(getAllNoTinReasons())
  }, [])

  const index = +page.split(".")[1] || 0
  const onChange = (val: {key?: string, value?: string | boolean | number}) => {
    componentContext?.onChange({ key: val.key || "", value: val.value })
  }

  const handleBirthplacePlaceChange = (val: { value: string }) => {
    const place = val.value;
    setState((prevState) => {
      const newState = { ...prevState }
      newState.birthplace.place = place
      return newState
    })
  }

  const handleBirthplaceCityChange = (val: { value: string }) => {
    const city = val.value;
    setState((prevState) => {
      const newState = { ...prevState }
      newState.birthplace.city = city
      return newState
    })
  }

  const handleBirthplaceCountryChange = (
    val: { key: string, value?: { code: string, nameTh: string }},
  ) => {
    if (!val.value) {
      setState((prevState) => {
        const newState = { ...prevState }
        newState.birthplace.countryCode = ""

        return newState
      })

      return
    }

    const { code, nameTh } = val.value
    setState((prevState) => {
      const newState = { ...prevState }
      newState.birthplace.countryCode = code
      newState.birthplace.countryName = nameTh

      return newState
    })
  }

  const generateIsAmericanOptions = (isAmerican: boolean) => [
    {
      title: "ไม่เป็นบุคคลอเมริกันและไม่มีข้อบ่งชี้ใดๆว่าเป็นบุคคลอเมริกัน",
      selected: !isAmerican,
    },
    {
      title: "เป็นบุคคลอเมริกัน / มีข้อบ่งชี้ใดๆ ว่าเป็นบุคคลอเมริกัน",
      selected: isAmerican,
    },
  ]

  const handleIsAmericanChange = (
    val: { value: {title: string, selected: boolean }[]},
  ) => {
    const isAmerican = val.value.find((item) => item.title === "เป็นบุคคลอเมริกัน / มีข้อบ่งชี้ใดๆ ว่าเป็นบุคคลอเมริกัน");
    if (!isAmerican) {
      return;
    }

    setState((prevState) => {
      const newState = { ...prevState }
      newState.isAmerican = isAmerican.selected;
      return newState;
    })
  }

  const generateTaxResidenceOptions = (isTaxResidenceInUsaOrThailand: boolean) => [
    {
      title: "ไม่ใช่/No",
      selected: !isTaxResidenceInUsaOrThailand,
    },
    {
      title: "ใช่/Yes",
      selected: isTaxResidenceInUsaOrThailand,
    },
  ]

  const handleUsaOrThaiTaxResidenceChange = (
    val: { value: {title: string, selected: boolean }[]},
  ) => {
    const isTaxResidenceInUsaOrThailand = val.value.find((item) => item.title === "ใช่/Yes");
    if (!isTaxResidenceInUsaOrThailand) {
      return;
    }

    setState((prevState) => {
      const newState = { ...prevState }
      newState.hasTaxResidencesOtherThanUsaOrThailand = isTaxResidenceInUsaOrThailand.selected;
      return newState;
    })
  }

  const handleCountryChange = (i: number, val: { value?: { code: string, nameTh: string } }) => {
    if (!val.value) {
      setState((prevState) => {
        const newState = { ...prevState }
        newState.taxResidences[i].countryCode = ""

        return newState
      })

      return
    }

    const { code, nameTh } = val.value
    setState((prevState) => {
      const newState = { ...prevState }
      newState.taxResidences[i].countryCode = code
      newState.taxResidences[i].countryName = nameTh

      return newState
    })
  }

  const handleTinChange = (i: number, value: { value: string }) => {
    const tin = value.value;
    setState((prevState) => {
      const newState = { ...prevState };
      newState.taxResidences[i].tin = tin;

      if (tin.length > 0) {
        newState.taxResidences[i].noTinReasonId = "";
        newState.taxResidences[i].noTinExplanation = "";
      }

      return newState
    })
  }

  const generateNoTinReasonValue = (reasonId: string) => {
    if (!reasonId) {
      return "ไม่เลือก"
    }

    const found = data.noTinReasons.find(
      (reason: { id: string, reasonTh: string }) => reason.id === reasonId,
    )

    if (!found) {
      return "ไม่เลือก"
    }

    return found.reasonTh
  }

  const handleNoTinReasonSelection = (i: number, val: { value: string }) => {
    const { id } = JSON.parse(val.value)
    setState((prevState) => {
      const newState = { ...prevState };
      newState.taxResidences[i].noTinReasonId = id;
      return newState
    })
  }

  const handleNoTinExplanationChange = (i: number, val: { value: string }) => {
    const explanation = val.value
    setState((prevState) => {
      const newState = { ...prevState }
      newState.taxResidences[i].noTinExplanation = explanation
      return newState
    })
  }

  const createNewTaxResidence = () => {
    setState((prevState) => {
      const newState = { ...prevState }
      newState.taxResidences.push({
        id: randomTemporaryId(),
        countryCode: "",
        countryName: "",
        tin: "",
        noTinReasonId: "",
        noTinExplanation: "",
      });

      return newState
    })
  }

  const handleTaxResidenceDelete = (i: number) => {
    setState((prevState) => {
      const newState = { ...prevState }
      newState.taxResidences.splice(i, 1)
      return newState
    })
  }

  const handleConfirmationCheck = () => {
    setState((prevState) => {
      const newState = { ...prevState }
      newState.isConfirmationChecked = !newState.isConfirmationChecked
      return newState
    })
  }

  const buttonDisabled = () => {
    let inCompleteTaxResidences = false;
    if (state.hasTaxResidencesOtherThanUsaOrThailand) {
      inCompleteTaxResidences = state.taxResidences.some((taxResidence) => {
        if (taxResidence.countryCode === "") {
          return true
        }

        if (taxResidence.tin === "") {
          if (taxResidence.noTinReasonId === "B") {
            return taxResidence.noTinExplanation === ""
          }

          return taxResidence.noTinReasonId === ""
        }

        if (taxResidence.noTinReasonId === "") {
          return taxResidence.tin === ""
        }

        return false
      })
    }

    return state.birthplace.countryCode === "" ||
      !state.isConfirmationChecked ||
      inCompleteTaxResidences
  }

  const onNext = () => {
    const payload: SaveFatcaPayload = {
      isAmerican: state.isAmerican,
      birthplace: {
        id: state.birthplace.id.startsWith("TMP") ? "" : state.birthplace.id,
        place: state.birthplace.place,
        city: state.birthplace.city,
        countryCode: state.birthplace.countryCode,
        countryName: state.birthplace.countryName,
      },
      hasTaxResidenceOtherThanUsaOrThai: state.hasTaxResidencesOtherThanUsaOrThailand,
      taxResidences: state.taxResidences.map((taxResidence) => ({
        id: taxResidence.id.startsWith("TMP") ? "" : taxResidence.id,
        countryCode: taxResidence.countryCode,
        countryName: taxResidence.countryName,
        tin: taxResidence.tin,
        noTinReasonId: taxResidence.tin === "" ? taxResidence.noTinReasonId : "",
        noTinExplanation: taxResidence.tin === "" ? taxResidence.noTinExplanation : "",
      })),
    }

    dispatch(saveFatca(payload))
  }

  return (
    <Card>
      <HistoryOfPath
        data={dataOfPage.map((val) => val.title)}
        page={page}
        activeIndex={index}
      />
      <div style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",

      }}
      >
        <Texts style={{
          color: "#0C67B1",
          padding: "5px 0px 5px 0px",
          fontSize: mainFontSize.xl,
          fontFamily: fonts.SarabunMedium,
        }}
        >
          FATCA/CRS
        </Texts>
      </div>
      <Inputs
        onChange={handleIsAmericanChange}
        config={{
          type: InputEnum.inputRadio,
          title: "บุคคลอเมริกัน",
          titleColor: "#0C67B1",
          subTitle: "ข้าพเจ้า <span style='color: red'>“ไม่ได้เป็น”</span> พลเมืองอเมริกัน / ไม่ได้ถือบัตรประจำตัวผู้มีถิ่นที่อยู่ถาวร (กรีนการ์ด) / ไม่มีถิ่นที่อยู่หรือที่อยู่อาศัยใน สหรัฐอเมริกา / ไม่มีข้อบ่งชี้อื่นใดว่าอาจเป็นบุคคลอเมริกาซึ่งมีหน้าที่เสียภาษีให้กับสหรัฐอเมริกา",
        }}
        value={generateIsAmericanOptions(state.isAmerican) as IInputsShema[]}
      />
      <TLight
        color={color.primary}
        fontSize={mainFontSize.s}
      >
        รายละเอียดข้อตกลงและเงื่อนไขการรับรองตนเองฯ
      </TLight>
      <TextView
        style={{
          fontFamily: fonts.SarabunLight,
          fontSize: mainFontSize.xs,
          color: "#333333",
          whiteSpace: "break-spaces",
          marginBottom: "16px",
        }}
      >
        หากท่านต้องการทำงานในสหรัฐอเมริกาเป็นการชั่วคราวxxx
        &nbsp;ภายใต้กฎหมายว่าด้วยการเข้าเมืองสหรัฐฯ ท่านจะต้องได้รับวีซ่าให้ถูกประเภท
        ตามประเภทของงานที่จะไปทำ
        &nsbp;สำหรับงานแรงงานชั่วคราวส่วนใหญ่กำหนดไว้ว่านายจ้างหรือตัวแทนจะต้องยื่นคำร้อง และ
        ผ่านการอนุมัติจากสำนักงานตรวจคนเข้าเมืองและสัญชาติของสหรัฐอเมริกา (USCIS)
        &nbsp;ในสหรัฐอเมริกาก่อน ผู้สมัครจึงจะสามารถมา
        ยื่นคำร้องขอวีซ่าทำงานได้

        <br />
        <br />
        ผู้สมัครวีซ่าประเภท H, L, O, P และ Q ทั้งหมดต้องได้รับการอนุมัติ
        &nbsp;คำร้อง Form I-129โดยสำนักงาน USCIS ก่อนที่ท่านจะสามารถ
        ยื่นคำร้องขอวีซ่าทำงานที่สถานทูตหรือสถานกงสุลอเมริกาได้
        &nbsp;เมื่อคำร้องผ่านการอนุมัติแล้ว นายจ้างหรือตัวแทนของท่านจะได้รับ
        Notice of Action, Form I-797 ซึ่งเป็นการแจ้งว่าคำร้องของท่านผ่านการอนุมัติแล้ว
        &nbsp;เจ้าหน้าที่กงสุลจะตรวจสอบคำร้องของท่านผ่าน
        ระบบจัดการข้อมูลคำร้อง (PIMS) ขณะสัมภาษณ์

        <br />
        <br />
        ท่านต้องนำใบเสร็จชำระเงินค่าธรรมเนียม I-129 SEVIS
        &nbsp;มาด้วยในวันสัมภาษณ์ ณ สถานทูตหรือสถานกงสุลอเมริกาเพื่อใช้ในการตรวจ
        สอบการอนุมัติคำร้องของท่าน
        &nbsp;โปรดทราบว่าคำร้องที่ผ่านการอนุมัติแล้วไม่ได้เป็น
        สิ่งรับประกันว่าวีซ่าของท่านจะผ่านการอนุมัติ
        &nbsp;หากพบว่าท่านขาดคุณสมบัติในการได้รับวีซ่าภายใต้กฎหมายว่าด้วยการเข้าเมืองสหรัฐฯ
      </TextView>
      <TLight
        color={color.primary}
        fontSize={mainFontSize.l}
      >
        การแจ้งสถานะความเป็นผู้มีถิ่นที่อยู่ทางภาษีในแต่ละประเทศนอกจากสหรัฐอเมริกาและประเทศไทย
      </TLight>
      <Grid
        container
        spacing={1}
        style={{
          marginBottom: "16px",
        }}
      >
        <Grid
          item
          xs={12}
          sm={12}
          md={6}
        >
          <Inputs
            onChange={handleBirthplacePlaceChange}
            config={{
              type: InputEnum.inputCommon,
              title: "สถานที่เกิด",
              placeholder: "ระบุสถานที่เกิด",
              titleColor: "black",
            }}
            value={state.birthplace.place}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={6}
        >
          <Inputs
            onChange={handleBirthplaceCityChange}
            config={{
              type: InputEnum.inputCommon,
              title: "เมือง",
              placeholder: "ระบุเมือง",
              titleColor: "black",
            }}
            value={state.birthplace.city}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={6}
        >
          <Inputs
            onChange={handleBirthplaceCountryChange}
            config={{
              field: "country",
              type: InputEnum.inputAutoComplete,
              title: "ประเทศ",
              placeholder: "ระบุประเทศ",
              titleColor: "black",
              rules: [{ type: IRuleEnum.REQUIRED }],
            }}
            value={state.birthplace.countryName}
          />
        </Grid>
      </Grid>
      <TLight
        color={color.primary}
        fontSize={mainFontSize.s}
      >
        ท่านเป็นผู้มีถิ่นที่อยู่ทางภาษีในประเทศนอกจากสหรัฐอเมริกาและประเทศไทย
      </TLight>
      <Inputs
        onChange={handleUsaOrThaiTaxResidenceChange}
        config={{
          type: InputEnum.inputRadio,
          rules: [{ type: IRuleEnum.REQUIRED }],
        }}
        value={generateTaxResidenceOptions(
          state.hasTaxResidencesOtherThanUsaOrThailand,
        ) as IInputsShema[]}
      />
      <TLight
        color={color.primary}
        fontSize={mainFontSize.s}
      >
        รายละเอียด
      </TLight>
      <TextView
        style={{
          fontFamily: fonts.SarabunLight,
          fontSize: mainFontSize.xs,
          color: "#333333",
          whiteSpace: "break-spaces",
          marginBottom: "16px",
        }}
      >
        “ถิ่นที่อยู่ทางภาษี” หมายถึง
        &nbsp;ประเทศที่ท่านมีหน้าที่ต้องเสียภาษีเงินได้ในประเทศนั้นสำหรับเงิน
        ได้ที่ได้รับจากประเทศนั้นและ/หรือประเทศอื่น ๆ
        &nbsp;เนื่องจากการมีภูมิลำเนา ถิ่นที่อยู่ จำนวนวันที่ท่านอยู่ในในประเทศนั้นในแต่ละปี
        &nbsp;หรือโดยการพิจารณาหลักเกณฑ์อื่น ๆ
        <br />
        <br />
        “tax residence” means particular jurisdictions in which you are
        &nbsp;liable to pay income tax by reason of domicile, residence,
        &nbsp;number of days you stay in that country in each year or any other criterion.
        <br />
        <br />
        โปรดตอบ “ใช่” หากท่านเป็นผู้มีถิ่นที่อยู่ทางภาษีในประเทศอื่นๆ
        &nbsp;นอกจากสหรัฐอเมริกาและประเทศไทย
        &nbsp;และโปรดระบุข้อมูลประเทศของถิ่นที่อยู่ทางภาษีและเลขประจําตัวผู้เสียภาษีในประเทศนั้น
        &nbsp;ตามตารางด้านล่าง
        <br />
        <br />
        You must answer “Yes” if you have tax residence in countries
        &nbsp;other than Thailand and the U.S.
        &nbsp;and specify your country of tax residence and TIN in the table below.
        <br />
      </TextView>

      {
        state.hasTaxResidencesOtherThanUsaOrThailand && (
          <>
            <div style={{ margin: "8px 0" }}>
              <TLight fontSize={mainFontSize.xs} color="#666666">
                เพื่อวัตถุประสงค์ของแบบฟอร์มนี้&nbsp;
                “กลุ่มธุรกิจการเงินXXXX” สามารถดูรายละเอียดได้ที่&nbsp;
                <a href="https://www.rhbgroup.com/index.html">https://www.rhbgroup.com/index.html</a>
              </TLight>
              <TLight fontSize={mainFontSize.xs} color="#666666">
                For this purpose, please see “companies in XXXX Group” at&nbsp;
                <a href="https://www.rhbgroup.com/index.html">https://www.rhbgroup.com/index.html</a>
              </TLight>
            </div>
            <TLight
              color={color.primary}
              fontSize={mainFontSize.s}
            >
              ข้อมูลประเทศถิ่นที่อยู่ทางภาษี
            </TLight>
            {
              state.taxResidences.map((taxResidence, i) => (
                <div key={taxResidence.id}>
                  {
                    i === 1 &&
                    (
                      <TLight
                        color={color.primary}
                        fontSize={mainFontSize.s}
                      >
                        ข้อมูลประเทศถิ่นที่อยู่ทางภาษี (เพิ่มเติม)
                      </TLight>
                    )
                  }
                  <Grid
                    container
                    style={{
                      borderStyle: "dashed",
                      borderColor: "#CCCCCC",
                      borderRadius: "8px",
                      borderWidth: "2px",
                      padding: "4px",
                      margin: "8px 0",
                      position: "relative",
                    }}
                    spacing={1}
                  >
                    {
                      i !== 0 && (
                        <Images
                          style={{
                            position: "absolute",
                            right: "4px",
                            cursor: "pointer",
                          }}
                          src={cross}
                          height="20px"
                          width="20px"
                          onClick={() => handleTaxResidenceDelete(i)}
                        />
                      )
                    }
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={6}
                    >
                      <Inputs
                        onChange={(val) => handleCountryChange(i, val)}
                        config={{
                          field: "country",
                          type: InputEnum.inputAutoComplete,
                          title: "ประเทศถิ่นที่อยู่ทางภาษี",
                          titleColor: "black",
                          placeholder: "ระบุประเทศ",
                          rules: [{ type: IRuleEnum.REQUIRED }],
                        }}
                        value={taxResidence.countryName}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={6}
                    >
                      <Inputs
                        onChange={(val) => handleTinChange(i, val)}
                        config={{
                          type: InputEnum.inputCommon,
                          title: "หมายเลขประจำตัวผู้เสียภาษี TIN",
                          titleColor: "black",
                          placeholder: "ระบุหมายเลขประจำตัวผู้เสียภาษี",
                        }}
                        value={taxResidence.tin}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={12}
                    >
                      <Inputs
                        onChange={(val) => handleNoTinReasonSelection(i, val)}
                        config={{
                          field: "noTinReasons",
                          type: InputEnum.inputDropdown,
                          title: "หากไม่มีหมายเลขประจำตัวผู้เสียภาษี โปรดระบุเหตุผล ก, ข หรือ ค",
                          titleColor: "black",
                          placeholder: "เลือกเหตุผล",
                          rules: taxResidence.tin === "" ? [{ type: IRuleEnum.REQUIRED }] : [],
                          disabled: taxResidence.tin !== "",
                        }}
                        value={generateNoTinReasonValue(taxResidence.noTinReasonId)}
                      />
                    </Grid>
                    <Grid item md={12}>
                      <Inputs
                        onChange={(val) => handleNoTinExplanationChange(i, val)}
                        config={{
                          type: InputEnum.inputCommon,
                          title: "หากท่านเลือกเหตุผล ข โปรดอธิบายเหตุผลที่ท่านไม่สามารถขอหมายเลขประจำตัวผู้เสียภาษีได้",
                          titleColor: "black",
                          placeholder: "โปรดอธิบายเหตุผลที่ท่านไม่สามารถขอหมายเลขประจำตัวผู้เสียภาษีได้",
                          rules: taxResidence.noTinReasonId === "B" ? [{ type: IRuleEnum.REQUIRED }] : [],
                          disabled: taxResidence.tin !== "",
                        }}
                        value={taxResidence.noTinExplanation}
                      />
                    </Grid>
                  </Grid>
                </div>
              ))
            }
          </>
        )
      }

      {
        state.hasTaxResidencesOtherThanUsaOrThailand &&
        (
          <Button
            fullWidth
            variant="outlined"
            color="primary"
            onClick={createNewTaxResidence}
            style={{
              margin: "8px",
            }}
          >
            <TMedium>
              + เพิ่มประเทศถิ่นที่อยู่ทางภาษี
            </TMedium>
          </Button>
        )
      }
      <Checkbox
        selected={state.isConfirmationChecked}
        onChange={handleConfirmationCheck}
      >
        <p
          style={{
            fontFamily: fonts.SarabunLight,
            fontSize: mainFontSize.s,
            color: "#000000",
            marginLeft: 11,
          }}
        >
          ข้าพเจ้ายืนยันว่า คำรับรองที่ให้ไว้กับธนาคารดังกล่าวเป็นความจริงทุกประการ
        </p>
      </Checkbox>

      <TLight mt="16px" fontSize={mainFontSize.s} mb="40px" color="#666666">
        เพื่อปฎิบัติให้เป็นไปตาม พ.ร.บ การปฎิบัติการตามความตกลงระหว่างรัฐบาลแห่งราชอาณาจักร
        ไทยกับรัฐบาลแห่งประเทศสหรัฐอเมริกาเพื่อความ ร่วมมือในการปรับปรุงการปฎิบัติการตามภาษี
        อากรระหว่างประเทศ พ.ศ.2560
      </TLight>

      <ButtonHolder style={{ marginTop: 20 }}>
        <Buttons
          style={{
            backgroundColor: "#ffffff",
            color: "#0067b1",
            borderWidth: 1,
            borderColor: "#0067b1",
          }}
          onClick={() => onChange({ key: "BACK", value: 0 })}
        >
          ย้อนกลับ
        </Buttons>
        <div
          style={{
            backgroundColor: "#ffffff",
            color: "#0067b1",
            borderWidth: 1,
            borderColor: "#0067b1",
          }}
        />
        <Buttons
          disabled={buttonDisabled()}
          onClick={onNext}
        >
          {t("termsAndCon.buttonNext")}
        </Buttons>
      </ButtonHolder>
    </Card>
  )
}

export default FatcaTermAndCons;
