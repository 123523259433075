/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable max-len */
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components"
import {
  navigateNext,
  navigateSetStep,
  saveConsent,
  validatePep,
} from "../../../actions";
import {
  Buttons,
  Card,
  HistoryOfPath,
  Inputs,
  Texts,
  TLight,
} from "../../../components";
import { color, fonts, mainFontSize } from "../../../config/themes";

import { ComponentContext } from "../../../contexts"
import {
  IFormsShema,
  IOpenAccountShema,
  InputEnum,
  IStore,
  ISubPage,
} from "../../../interfaces";
import { getErrorMessage, handleErrorScreen } from "../../../utilities";

interface props {
  page: string
  data: IOpenAccountShema[],
  dataOfPage: IFormsShema[],
}

const ButtonHolder = styled.div`
  display: flex;
  justify-content: space-between;
`

export default ({ page, data, dataOfPage }: props) => {
  const dispatch = useDispatch()
  const [errorMessages, setErrorMessages] =
    React.useState<Array<{ field: string, message: Array<string> | string }>>([])
  const componentContext = useContext(ComponentContext);
  const { termAndConditoin } = useSelector((state: IStore) => state.OpeningAccountReducer)
  const { t } = useTranslation()
  const index = +page.split(".")[1] || 0
  const dataDisplay = dataOfPage?.[index]
  const mainData = termAndConditoin?.[dataDisplay?.type]
  if (!dataDisplay) {
    dispatch(navigateSetStep("1.2"))
    return null
  }

  const onChange = (val: {key?: string, value?: string | boolean | number}) => {
    componentContext?.onChange({ key: val.key || "", value: val.value })
  }

  const onValidate = (): {
    isRequireUpload: (false | { type: string; field: string; })[],
    validateButton: boolean[]
  } => {
    const dataInputs = dataDisplay?.inputs
    const dataFromReducer = Object.keys(mainData)

    // Get KeyTarget other all subpage.
    const otherField = dataInputs.map((val, otherIndex) => dataInputs
      .map((input) => input.keyTarget)
      .filter((inputFilter) => inputFilter)
      .flat()
      .map((targetField) => ((val.field === targetField) && otherIndex))
      .filter((fil) => fil))
      .flat()

    const isRequireUpload = dataInputs.map((val) => val.type === "inputUploadFile"
      && { type: val.type, field: val.field }).filter((val) => val)

    // Check data no field and check click required and selected yet?
    const addOtherTargetField: any[] = []
    const result = dataFromReducer.map((field, keyindex) => {
      if (Array.isArray(mainData[field])) {
        return mainData[field].map((val: any) => {
          if (val.selected && val.requireOther) {
            dataInputs.map((vv, iii) => iii === keyindex && addOtherTargetField.push(vv.keyTarget))
            return true
          }
          if (val.selected) return true
          return false
        }).some((someval: boolean) => someval)
      }
      return !!mainData[field]
    })

    // Trigger other field when other buttons are pressed.
    dataFromReducer
      .map((field, keyindex) => addOtherTargetField
        .flat()
        .map((itemTest1) => ((field === itemTest1) ? keyindex : false))
        .filter((val) => val)
        .toString())
      .forEach((vall) => vall && otherField.splice(otherField.indexOf(+vall), 1))

    return {
      isRequireUpload,
      validateButton: result.filter((val, inde) => !(otherField.some((vall) => inde === vall))),
    }
  }

  let initialSelectOtherField = false
  const onHandleOtherField = ({ field, isHidden }: { field: string, isHidden?: boolean }) => {
    if (Array.isArray(mainData[field])) {
      initialSelectOtherField = mainData[field].some(({ requireOther, selected }: {
          requireOther: boolean
          selected: boolean
        }) => requireOther && selected)
    }
    const result = dataDisplay?.inputs.some((tar) => (
      tar.keyTarget) && tar.keyTarget.some((key) => (initialSelectOtherField && key === field)))
    return !result && isHidden
  }

  const onNext = () => {
    const { errors, validateFlag } = handleErrorScreen(dataDisplay.inputs, mainData)

    setErrorMessages(errors)
    if (!validateFlag) return
    console.log("xxx", dataDisplay?.type)
    if (dataDisplay?.type === ISubPage.STEP_VERIFY_PEP) {
      dispatch(validatePep())
      return
    }
    if (dataDisplay?.type === ISubPage.STEP_TERM_CONDITION_PDPA) {
      dispatch(saveConsent({ page: ISubPage.STEP_TERM_CONDITION_PDPA }))
    }
    if (dataDisplay?.type === ISubPage.STEP_TERM_CONDITION_WEB) {
      dispatch(saveConsent({ page: ISubPage.STEP_TERM_CONDITION_WEB }))
    }
    if (
      dataDisplay?.type === ISubPage.STEP_TERM_CONDITION_EQUITY_AND_MUTUAL_FUND ||
      dataDisplay?.type === ISubPage.STEP_TERM_CONDITION_CREDIT_BALANCE_ACCOUNT ||
      // dataDisplay?.type === ISubPage.STEP_TERM_CONDITION_CASH_BALANCE_ACCOUNT ||
      // dataDisplay?.type === ISubPage.STEP_TERM_CONDITION_MUTUAL_FUND_ACCOUNT ||
      dataDisplay?.type === ISubPage.STEP_TERM_CONDITION_SAVING_ACCOUNT ||
      dataDisplay?.type === ISubPage.STEP_TERM_CONDITION_TFEX ||
      dataDisplay?.type === ISubPage.STEP_TERM_CONDITION_TFEX_MOU_BLOCK_TRADE ||
      dataDisplay?.type === ISubPage.STEP_TERM_CONDITION_CONSENT_CLAUSE_OF_RETAIL_CUSTOMER
    ) {
      dispatch(saveConsent({ page: dataDisplay?.type }))
    }
    dispatch(navigateNext())
  }

  return (
    <Card>

      <HistoryOfPath
        data={dataOfPage.map((val) => val.title)}
        page={page}
        activeIndex={index}
      />
      <div style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",

      }}
      >
        <Texts style={{
          color: "#0C67B1",
          padding: "5px 0px 5px 0px",
          fontSize: mainFontSize.xl,
          fontFamily: fonts.SarabunMedium,
        }}
        >
          {dataDisplay?.title}
        </Texts>
      </div>

      {dataDisplay.type === ISubPage.STEP_TERM_CONDITION_PDPA && (
        <>
          <TLight fontSize={mainFontSize.s} mb="40px" color="rgb(51, 51, 51)">
            โปรดแจ้งให้เราทราบถึงความพึงพอใจและความตกลงของท่านตามที่ปรากฎด้านล่างนี้ คำที่นิยามไว้ในนโยบายความเป็นส่วนตัวของ บริษัท หลักทรัพย์อาร์เอชบี (ประเทศไทย) จำกัด (มหาชน) จะมีความหมายเช่นเดียวกันเมื่อใช้ในเอกสารฉบับนี้ เว้นแต่นิยามไว้เป็นอย่างอื่นในเอกสารฉบับนี้
          </TLight>
          <TLight fontSize={mainFontSize.s} mb="40px" color="rgb(51, 51, 51)">
            ข้าพเจ้าได้อ่าน และรับทราบ
            <u>นโยบายความเป็นส่วนตัว</u> <a href="https://th.rhbtradesmart.com/th/PrivacyPolicy.html" target="_blank"> https://th.rhbtradesmart.com/th/PrivacyPolicy.html </a>

            ตามที่ได้เผยแพร่บนเว็บไซต์ของบริษัท ซึ่งระบุถึงวิธีการที่ บริษัท หลักทรัพย์อาร์เอชบี (ประเทศไทย) จำกัด (มหาชน)(&ldquo;
            <b>บริษัท</b>
            &rdquo;) จะเก็บรวบรวม ใช้ เปิดเผย และ/หรือโอนข้อมูลส่วนบุคคลของข้าพเจ้าไปยังบริษัทในกลุ่ม/ ในเครือเพื่อวัตถุประสงค์และเงื่อนไขที่อธิบายไว้ในนโยบายความเป็นส่วนตัวของบริษัทและบริษัทในกลุ่ม/ในเครือ
          </TLight>
        </>
      )}

      {
        dataDisplay?.inputs?.map((val, key) => (
          val && onHandleOtherField(val) ? null : (
            Inputs({
              key: `${val.title}-${val.field}-${key.toString()}`,
              config: {
                type: val.type as InputEnum,
                title: val.title,
                titleColor: (dataDisplay.type === ISubPage.STEP_VERIFY_FATCA || dataDisplay.type === ISubPage.STEP_VERIFY_PEP) ? color.primary : "black",
                subTitle: val.subTitle,
                field: val.field,
                errorMessage: getErrorMessage(val.field, errorMessages),
                description: val.description,
                rules: val.rules,
              },
              value: mainData[val.field],
            })
          )))
      }

      {
        dataDisplay.type === ISubPage.STEP_VERIFY_FATCA && (
          <TLight fontSize={mainFontSize.s} mb="40px" color="#ccc">
            เพื่อปฎิบัติให้เป็นไปตาม พ.ร.บ การปฎิบัติการตามความตกลงระหว่างรัฐบาลแห่งราชอาณาจักร
            ไทยกับรัฐบาลแห่งประเทศสหรัฐอเมริกาเพื่อความ ร่วมมือในการปรับปรุงการปฎิบัติการตามภาษี
            อากรระหว่างประเทศ พ.ศ.2560
          </TLight>
        )
      }

      {
        dataDisplay.type === ISubPage.STEP_TERM_CONDITION_PDPA && (
          <TLight fontSize={mainFontSize.s} mb="40px" color="rgb(51, 51, 51)">
            ในกรณีที่ท่านไม่ระบุเลือกให้ความยินยอมตามข้างต้น บริษัทจะพิจารณาว่าท่านไม่ประสงค์จะให้ความยินยอมตามวัตถุประสงค์
            ดังกล่าว โดยจะดำเนินการตามความประสงค์ของท่านต่อไป ทั้งนี้ ท่านสามารถแจ้งเปลี่ยน หรือยกเลิกความยินยอมได้ทุกเมื่อ
            ผ่านช่องทางเว็บไซต์ของบริษัท
          </TLight>
        )
      }

      <ButtonHolder style={{ marginTop: 20 }}>
        {
          dataDisplay?.type !== ISubPage.STEP_TERM_CONDITION_PDPA
          && dataDisplay.type !== ISubPage.STEP_VERIFY_FATCA && (
            <Buttons
              style={{
                backgroundColor: "#ffffff",
                color: "#0067b1",
                borderWidth: 1,
                borderColor: "#0067b1",
              }}
              onClick={() => onChange({ key: "BACK", value: 0 })}
            >
              ย้อนกลับ
            </Buttons>
          )
        }
        {
          (dataDisplay?.type === ISubPage.STEP_TERM_CONDITION_PDPA
          || dataDisplay.type === ISubPage.STEP_VERIFY_FATCA) && (
            <div
              style={{
                backgroundColor: "#ffffff",
                color: "#0067b1",
                borderWidth: 1,
                borderColor: "#0067b1",
              }}
            />
          )
        }

        <Buttons
          disabled={
            dataDisplay?.type !== ISubPage.STEP_TERM_CONDITION_PDPA
            && dataDisplay.type !== ISubPage.STEP_VERIFY_PEP
            && !onValidate().validateButton.every((val) => val)
          }
          onClick={onNext}
        >
          {t("termsAndCon.buttonNext")}
        </Buttons>
      </ButtonHolder>
    </Card>
  )
}
