import React from "react"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"
import { Redirect, useHistory } from "react-router-dom"
import styled from "styled-components"
import {
  setEmail, 
  setMobileNo, 
  checkTargetExists,
  validateRegisterField,
} from "../../actions"
import { Buttons, TMedium } from "../../components";
import Header from "../../components/Header"
import Image from "../../components/Images"
import {
  BG,
  rhbCheckbox,
} from "../../components/Images/svg"
import PageWrapper from "../../components/PageWrapper"
import { showDialog } from "../../components/Provider/dialog";
import { fonts, mainFontSize } from "../../config/themes";
import { ComponentContext } from "../../contexts"
import {
  IComponentContextProps,
  IInputValueResponse,
  InputEnum,
  IRuleEnum,
  IDialogTypeEnum,
} from "../../interfaces"
import { routerName } from "../../interfaces/router.enum"
import { auth, useWindowDimensions } from "../../utilities"
import CardLoginInputs from "../../view/cards/cardLoginInputs"
import CardRegistration from "../../view/cards/cardRegistration";

const Container = styled.div`
  display: flex;
  flex: 1;
  height: 100vh;
  flex-direction: column;
  align-items: centen;
`

const CheckboxItem = ({ text }: {text: string}) => (
  <div
    style={{
      textAlign: "center",
      display: "flex",
      justifyContent: "left",
      alignItems: "center",
      fontSize: "1.6rem",
      margin: "1rem 0",
    }}
  >
    <Image
      style={{ marginRight: "8px" }}
      width="2rem"
      height="2rem"
      src={rhbCheckbox}
    />
    {text}
  </div>
)

const customNoticeButtons = ({ onResolve }: {onResolve: (code: any) => void}) => (
  <div style={{
    flexDirection: "row",
    display: "flex",
    justifyContent: "space-between",
    paddingTop: "45px",
  }}
  >
    <div style={{
      display: "flex",
      alignItems: "center",
      marginRight: "25px",
    }}
    >
      <Buttons
        style={{
          backgroundColor: "#ffffff",
          border: "1px solid #0067b1",
        }}
        onClick={() => onResolve(1)}
      >
        <TMedium style={{ color: "#0C67B1" }}>ยกเลิก</TMedium>
      </Buttons>
    </div>
    <div style={{
      display: "flex",
      alignItems: "center",
    }}
    >
      <Buttons onClick={() => onResolve(2)}>
        <TMedium>เข้าสู่ระบบ</TMedium>
      </Buttons>
    </div>
  </div>
)

interface onHandleValueProps { 
  key?: string | undefined, 
  value?: IInputValueResponse 
}

const Login = () => {
  const { width } = useWindowDimensions()
  const dispatch = useDispatch()
  const [regisPage, setRegisPage] = React.useState<boolean>(true)
  const [loginPage, setLoginPage] = React.useState<boolean>(false)
  const { t } = useTranslation()

  const history = useHistory();

  const schemaRegister = [
    {
      type: InputEnum.inputCommon, 
      title: t("register.titleInputEmail"), 
      placeholder: t("register.placeInputEmail"), 
      field: "email", 
      rules: [
        { type: IRuleEnum.REQUIRED }, 
        { 
          type: IRuleEnum.FORMAT, 
          regex: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|((([a-zA-Z\-0-9]{2,})+\.)+[a-zA-Z]{2,}))$/,
        }, 
      ],
      value: "",
    },
    {
      type: InputEnum.inputCommon, 
      title: t("register.titleInputPhone"), 
      placeholder: t("register.placeInputPhone"), 
      field: "mobileNo", 
      maxlength: 10,
      rules: [
        { type: IRuleEnum.REQUIRED }, 
        { 
          type: IRuleEnum.FORMAT, 
          regex: /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/,
        }, 
      ],
      value: "",
    },
  ]
  
  const schemaLogin = [
    {
      type: InputEnum.inputCommon, 
      title: t("login.titleInputEmail"), 
      placeholder: t("login.placeInputEmail"), 
      field: "email", 
      rules: [
        { type: IRuleEnum.REQUIRED }, 
        { 
          type: IRuleEnum.FORMAT, 
          regex: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|((([a-zA-Z\-0-9]{2,})+\.)+[a-zA-Z]{2,}))$/,
        }, 
      ],
      value: "",
    },
    {
      type: InputEnum.inputCommon, 
      title: t("register.titleInputPhone"), 
      placeholder: t("register.placeInputPhone"), 
      field: "mobileNo", 
      maxlength: 10, 
      rules: [
        { type: IRuleEnum.REQUIRED }, 
        { 
          type: IRuleEnum.FORMAT, 
          regex: /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/,
        }, 
      ],
      value: "",
    },
  ]

  const clearPage = () => {
    setLoginPage(false)
    setRegisPage(false)
  }

  const onHandleValue = ({ key, value }: onHandleValueProps) => {
    switch (key) {
      case "REGISTER":
        clearPage()
        setLoginPage(true)
        break;

      case "LOGIN":
        clearPage()
        setRegisPage(true)
        break;

      case "CHECK_EXIST_TARGET":
        dispatch(checkTargetExists({
          callback: () => {
            history.push("/openAccount/0")
          },
        }))
        break;
      case "REGISTER_VALIDATE":
        dispatch(validateRegisterField({
          callback: () => {
            history.push("/openAccount/0") 
          },
          callDialog: () => {
            showDialog(
              IDialogTypeEnum.NOTICE_DIALOG, 
              {
                message: "ลูกค้าได้ลงทะเบียนเปิดบัญชีแล้ว กรุณาเลือก เข้าสู่ระบบ",
                ButtonComp: customNoticeButtons,
              },
              true,
            )
              .then((res) => {
                if (res === 2) {
                  setLoginPage(true)
                  setRegisPage(false)
                }
              })
          },
        }))
        break;
      case "NEXT":
        history.push("/openAccount/0");
        break;
    
      default:
        if (key === "mobileNo") dispatch(setMobileNo(value))
        if (key === "email") dispatch(setEmail(value))

        if (regisPage) {
          if (key === "email") schemaRegister[0].value = value as string
          if (key === "mobileNo") schemaRegister[1].value = value as string
        }
        if (loginPage) {
          if (key === "email") schemaLogin[0].value = value as string
          if (key === "mobileNo") schemaLogin[1].value = value as string
        }
        break;
    }
  }

  const contextValue: IComponentContextProps = {
    onChange: onHandleValue,
  };
  
  return (
    <ComponentContext.Provider value={contextValue}>
      { auth.isAuthenticated() && <Redirect to={routerName.openAccount} /> }
      <PageWrapper image={BG}>
        <Container>
          <div style={{ marginTop: 20 }} />
          <Header style={{ fontFamily: fonts.PromptMedium, fontSize: mainFontSize.l }} />
          <div style={{
            display: "flex",
            flex: 1,
            alignItems: "center",
            justifyContent: "space-between",
            transform: "all 1s ease-in-out",
            marginTop: -20,
            justifyItems: "center",
          }}
          >
            {(regisPage || loginPage) && (
              <div style={width < 540 ? {} : { display: "contents" }}>
                <div
                  style={{
                    textAlign: "left",
                    fontFamily: fonts.PromptBold,
                  }}
                >
                  <div style={{
                    display: "flex",
                    flex: 1,
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                  >
                    <div style={{ fontSize: "3rem", paddingLeft: "0.5rem" }}>เปิดบัญชีออนไลน์</div>
                    <div
                      style={{
                        paddingLeft: "0.5rem",
                        color: "#0C67B1",
                        fontSize: "1.9rem",
                      }}
                    >
                      ง่ายๆ เพียงปลายนิ้วสัมผัส
                    </div>
                  </div>

                  <div
                    style={{
                      marginLeft: "2.5rem",
                      marginTop: "1rem",
                    }}
                  >
                    <CheckboxItem text="รู้ผลไว" />
                    <CheckboxItem text="เข้าใจง่าย" />
                    <CheckboxItem text="ครบครันทุกบริการ" />
                  </div>
                </div>
                {regisPage && (
                  <CardRegistration
                    page="REGISTER"
                    buttonKey="REGISTER_VALIDATE"
                    data={schemaRegister}
                    titleHeader={t("register.titleHeader")}
                    titleButtonNext={t("register.titleButtonNext")}
                    titleLink={t("register.titleLink")}
                    titleDesLink={t("register.titleDesLink")}
                  />
                )}
                {loginPage && (
                  <CardLoginInputs
                    page="LOGIN"
                    data={schemaLogin}
                    titleHeader={t("login.titleHeader")}
                    titleButtonNext={t("login.titleButtonNext")}
                    titleLink={t("login.titleLink")}
                    titleDesLink={t("login.titleDesLink")}
                    buttonKey="CHECK_EXIST_TARGET"
                  />
                )}
              </div>
            )}
          </div>
        </Container>
      </PageWrapper>
    </ComponentContext.Provider>
  )
}

export default Login
