import MomentUtils from "@date-io/moment";
import { MuiPickersUtilsProvider, DatePicker, MaterialUiPickersDate } from "material-ui-thai-datepickers";
import React, { useState, useEffect } from "react"

import styled from "styled-components"
import { fonts, mainFontSize } from "../../../config/themes"
import { IInputsChoiceShema } from "../../../interfaces"

interface props {
  onChange: (obj: {value: string}) => void
  value?: string | IInputsChoiceShema[] | null
  errorMessage?: string | Array<string>
}

const Container = styled((props) => <div {...props} />)<props>`
  font-size: ${(props) => props.fontSize || mainFontSize.m};
  outline: none;
  width: 100%;
  padding: 5px 20px;
  box-sizing: border-box;
  border: 1px solid #cccccc;
  border: 1px solid  ${(props) => (props.errorMessage ? "red" : "#cccccc")};
  border-radius: 4px;
  font-family: ${fonts.SarabunMedium};
  color: #333333;
  cursor: pointer;

  ::placeholder {
    color: #cccccc;
    padding-left: 2px;
    font-size: ${mainFontSize.m};
    font-family: ${fonts.SarabunLight};
  }
`

export default ({
  onChange,
  value,
  errorMessage,
}: props) => {
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);
  
  useEffect(() => {
    if (value) setSelectedDate(new Date(`${value}`))
    else setSelectedDate(null)
  }, [value])

  const onDateChange = (val: MaterialUiPickersDate) => {
    const jsonDate = val?.toJSON()
    const dateOnly = jsonDate?.split("T")[0]
    
    setSelectedDate(new Date(`${dateOnly}`))
    onChange({ value: dateOnly as string })
  }
  
  return (
    <Container errorMessage={errorMessage}>
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <DatePicker
          format="DD/MM/YYYY"
          // pickerHeaderFormat="dd D MMM"
          yearOffset={543}
          value={selectedDate}
          onChange={onDateChange}
          okLabel="ตกลง"
          cancelLabel="ยกเลิก"
          maxDateMessage=""
          InputProps={{
            disableUnderline: true,
          }}
      
        />
      </MuiPickersUtilsProvider>
    </Container>
  )
}