import React from "react"
import styled, { css } from "styled-components"
import Config from "../../config";
import { device } from "../../config/themes";

const { debug } = Config

const Container = styled("div")<{ image?: string }>`
    background-image: url(${({ image }) => image});
    background-position: center;
    background-repeat: no-repeat;
    width: 100vw;
    height: 100vh;
`;

export const styledMedia = css`
  margin: auto;

  @media ${device.mobileSS} {
    ${debug && "background-color: rgb(174, 0, 255);"}
  }

  @media ${device.mobileS} { 
    ${debug && "background-color: rgb(0, 89, 255);"}
  }

  @media ${device.mobileM} {
    ${debug && "background-color: rgb(0, 217, 255);"}

  }

  @media ${device.mobileL} { 
    ${debug && "background-color: rgb(0, 255, 157);"}

  }

  @media ${device.tablet} {
    max-width: 768px;
    ${debug && "background-color: rgb(157, 255, 0);"}

  }

  @media ${device.laptop} { 
    max-width: 900px;
    ${debug && "background-color: rgb(255, 187, 0);"}

  }

  @media ${device.laptopL} {
    max-width: 900px;
    ${debug && "background-color: rgb(255, 136, 0);"}
  }

  @media ${device.desktop} {
    max-width: 900px;
    ${debug && "background-color: rgb(145, 108, 108);"}
  }
`

export default styled(({ image, className, children }) => (
  <Container image={image}>
    <div className={className}>
      {children}
    </div>
  </Container>
))`
  display: flex;
  flex: 1;
  text-align: center;
  font-family: "sans-serif";
  transition: all 0.5s ease;
  ${styledMedia}
`;
