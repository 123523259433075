import styled from "styled-components"
import { mainFontSize, fonts } from "../../config/themes";

interface props { 
  fontSize?: mainFontSize
  textAlign?: "left" | "right" | "center"
  mt?:string
  mb?:string
  ml?:string
  mr?:string
}

export default styled("h1")<props>`
  font-size: ${(props) => props.fontSize || mainFontSize.xl};
  margin-bottom: 0.25em;
  font-family: ${(props) => props.style?.fontFamily || fonts.SarabunBold};
  line-height: 2;
`;

export const TExtraBold = styled("p")<props>`
  font-size: ${(props) => props.fontSize || mainFontSize.s};
  text-align: ${(props) => props.textAlign};
  color: ${(props) => props.color};
  margin-top: ${(props) => props.mt || "0px"};
  margin-bottom: ${(props) => props.mb || "0px"};
  margin-left: ${(props) => props.ml || "0px"};
  margin-right: ${(props) => props.mr || "0px"};
  font-family: ${(props) => props.style?.fontFamily || fonts.SarabunExtraBold};
  line-height: 2;
`

export const TBold = styled("p")<props>`
  font-size: ${(props) => props.fontSize || mainFontSize.s};
  text-align: ${(props) => props.textAlign};
  color: ${(props) => props.color};
  margin-top: ${(props) => props.mt || "0px"};
  margin-bottom: ${(props) => props.mb || "0px"};
  margin-left: ${(props) => props.ml || "0px"};
  margin-right: ${(props) => props.mr || "0px"};
  font-family: ${(props) => props.style?.fontFamily || fonts.SarabunBold};
  line-height: 2;
`

export const TMedium = styled("p")<props>`
  font-size: ${(props) => props.fontSize || mainFontSize.s};
  text-align: ${(props) => props.textAlign};
  color: ${(props) => props.color};
  margin-top: ${(props) => props.mt || "0px"};
  margin-bottom: ${(props) => props.mb || "0px"};
  margin-left: ${(props) => props.ml || "0px"};
  margin-right: ${(props) => props.mr || "0px"};
  font-family: ${(props) => props.style?.fontFamily || fonts.SarabunMedium};
  line-height: 2;
`

export const TLight = styled("p")<props>`
  font-size: ${(props) => props.fontSize || mainFontSize.m};
  text-align: ${(props) => props.textAlign};
  color: ${(props) => props.color};
  margin-top: ${(props) => props.mt || "0px"};
  margin-bottom: ${(props) => props.mb || "0px"};
  margin-left: ${(props) => props.ml || "0px"};
  margin-right: ${(props) => props.mr || "0px"};
  font-family: ${(props) => props.style?.fontFamily || fonts.SarabunLight};
  line-height: 2;
`

export const TExtraLight = styled("p")<props>`
  font-size: ${(props) => props.fontSize || mainFontSize.s};
  text-align: ${(props) => props.textAlign};
  color: ${(props) => props.color};
  margin-top: ${(props) => props.mt || "0px"};
  margin-bottom: ${(props) => props.mb || "0px"};
  margin-left: ${(props) => props.ml || "0px"};
  margin-right: ${(props) => props.mr || "0px"};
  font-family: ${(props) => props.style?.fontFamily || fonts.SarabunExtraLight};
  line-height: 2;
`