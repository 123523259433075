import { createStore, compose, StoreEnhancer } from "redux"
import { persistStore, persistReducer } from "redux-persist"
import storage from "redux-persist/lib/storage"
import rootReducer from "../reducers"

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose
  }
}

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["LoginReducer"],
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

const composeEnhancers = (!process.env.NODE_ENV || process.env.NODE_ENV === "development") ? (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose) : compose

export default (devTools: StoreEnhancer) => {
  const store = createStore(persistedReducer, composeEnhancers(devTools))
  const persistor = persistStore(store)
  return { store, persistor }
}