import React, { useState, useEffect, ElementType } from "react";
import { IDialogTypeEnum } from "../../../interfaces";
import Emitter from "../../../utilities/emitter.util";
import CancelDialog from "./cancelDialog";
import DialogTest from "./dialogTest";
import ErrorDialog from "./errorDialog";
import InfoDialog from "./infoDialog";
import NoticeDialog from "./noticeDialog";

interface Params {
  name?: string
  message?: string
  buttonStyle?: any
  ChildComp?: ElementType
  ButtonComp?: ElementType
  errCode?: any
  type?: string
}

interface IConfigParams {
  dialogType: IDialogTypeEnum,
  resolve: (value: unknown) => void
  reject: (value: unknown) => void
  params: Params
  uniq: string
  clearAll?: boolean
  
  shouldReloadPage: boolean
}

const getUnique = () => Math.random().toString(36)

const DialogUI = (props: IConfigParams) => {
  const {
    dialogType,
    resolve,
    reject,
    params,
    uniq,
    clearAll,
    shouldReloadPage,
  } = props
  const onResolve = (code: unknown) => {
    resolve(code)
    Emitter.emit("close_dialog", clearAll === true ? "all" : uniq);
    if ((code === 5 || code === 10) && shouldReloadPage) {
      window.location.reload()
    }
  }
  const onReject = () => {
    reject("DIALOG_CANCEL_ERROR")
    Emitter.emit("close_dialog", uniq);
  }
  const onRejectNdid = (code: unknown) => {
    reject("DIALOG_CANCEL_NDID_ERROR")
    Emitter.emit("close_dialog", uniq)
  }

  switch (dialogType) {
    case IDialogTypeEnum.TEST_DIALOG:
      return (
        <DialogTest
          onResolve={onResolve}
          onReject={onReject}
          params={params}
        />
      )
    case IDialogTypeEnum.ERROR_DIALOG:
      return (
        <ErrorDialog
          onResolve={onResolve}
          onReject={onReject}
          params={params}
        />
      )
    case IDialogTypeEnum.INFO_DIALOG:
      return (
        <InfoDialog
          onResolve={onResolve}
          onReject={onReject}
          params={params}
        />
      )
    case IDialogTypeEnum.NOTICE_DIALOG:
      return (
        <NoticeDialog
          onResolve={onResolve}
          onReject={onReject}
          params={params}
        />
      )
    case IDialogTypeEnum.CANCEL_DIALOG:
      return (
        <CancelDialog 
          onResolve={onResolve}
          onReject={onRejectNdid}
          params={params}
        />
      )
    default:
      return (
        <DialogTest
          onResolve={onResolve}
          onReject={onReject}
          params={params}
        />
      )
  }
}

export const showDialog = (
  dialogType: IDialogTypeEnum,
  params: Params,
  clearAll?: boolean,
  shouldReloadPage = true,
) => new Promise((resolve, reject) => {
  const config = {
    dialogType, resolve, reject, params, clearAll, shouldReloadPage,
  } as IConfigParams
  Emitter.emit("show_dialog", config);
});

const Dialog = (props: IConfigParams) => (
  <div style={{
    width: "100%",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  }}
  >
    <DialogUI {...props as IConfigParams} />
  </div>
)

const DialogProvider = () => {
  const [dialogs, setDialogs] = useState<Array<IConfigParams>>([])

  const openDialog = (config: IConfigParams) => {
    if (config) {
      config.uniq = getUnique()
      setDialogs((list) => [...list, config])
    }
  }

  const onClose = (uniq: string) => {
    // console.log("unig", uniq)
    if (uniq === "all") {
      setDialogs([])
    } else {
      setDialogs((list) => list.filter((item) => item.uniq !== uniq))
    }
  }

  useEffect(() => {
    Emitter.on("show_dialog", openDialog)
    Emitter.on("close_dialog", onClose)
    return () => {
      Emitter.off("show_dialog", openDialog)
      Emitter.off("close_dialog", onClose)
    }
  }, [])

  return (dialogs.length > 0) ? (
    <div style={{
      position: "fixed",
      left: 0,
      right: 0,
      top: 0,
      bottom: 0,
      backgroundColor: "#000000c0",
      padding: 40,
      zIndex: 3000,
    }}
    >
      <Dialog {...dialogs.slice(-1).pop() as IConfigParams} />
    </div>
  ) : null
}

export default DialogProvider;
