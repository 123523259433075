import React from "react";
import styled from "styled-components";
import internalStyle from "./style.module.css"

export * from "./png";
export * from "./svg";

interface props {
  width?: string
  height?: string
}
const Image = styled("img")<props>`
  width: ${(props) => props.width || "166px"};
  height: ${(props) => props.height};
`

type Cursor = "default" | "pointer"

export default ({
  src,
  style,
  width,
  height,
  onClick,
  hoverCursor = "default",
}: {
  style?: React.CSSProperties
  src?: string
  onClick?: () => void
  width?: string
  height?: string
  hoverCursor?: Cursor,
}) => {
  if (src) {
    return (
      <Image
        className={internalStyle[hoverCursor]}
        style={style}
        src={src}
        onClick={onClick}
        width={width}
        height={height}
      />
    )
  }
  
  return <div style={style} />
}
