import { loginSetState } from "../actions"
import { LockOutApi } from "../apis"
import state from "../store"

export const auth = {
  isAuthenticated: () => state.store.getState().LoginReducer.accessToken,
  signOut() {
    LockOutApi().catch((err) => console.error(err))
    window.location.href = "/"
    state.store.dispatch(loginSetState({ key: "accessToken", value: "" }))
  },
}