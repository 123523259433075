import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import styled, { css } from "styled-components"
import {
  getIDPNDID, 
  saveNDID, 
  navigateNext, 
} from "../../../actions";

import {
  Buttons,
  Card,
  Texts,
  Images,
  HistoryOfPath,
  TBold,
} from "../../../components"
import { kBankLogo, scbLogo, bblLogo } from "../../../components/Images";
import { getEndPoint } from "../../../config";
import { fonts, mainFontSize } from "../../../config/themes";
import { ComponentContext } from "../../../contexts";
import {
  IdpListType,
  IFormsShema, 
  IItemsIdp, 
  INDIDReducers, 
  IOpenAccountShema, 
  IStatusNDID, 
  IStore, 
} from "../../../interfaces";
import { useWindowDimensions } from "../../../utilities";

interface props {
  page: string
  data: IOpenAccountShema[],
  dataOfPage: IFormsShema[],
}

const ButtonHolder = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
`
const Box = styled.div`
  display: flex;
  border: 1.2px solid #cccccc;
  border-radius: 10px;
  padding: 10px 5px 10px 5px;
  margin-top: 20px;
  cursor: pointer;
  height: 11rem;
  
`
// width : 28%;
// margin-top: 20px;
// :hover {
//   border: 1.2px solid #1669AF;
//   border-radius: 10px;
//   opacity: 1;
// }
const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  justify-content: space-between;
  column-gap: 2rem;
`

const TextHolder = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  padding: 5px 5px 5px 5px;
  align-items: center;
`

const ListImageBank = {
  KBANK: kBankLogo,
  "Siam Commercial Bank (SCB)": scbLogo,
  bbl: bblLogo,
}

export default ({
  page, 
  data, 
  dataOfPage,
}: props) => {
  const [hover, setHover] = React.useState({ isHover: false, index: 0 })
  const [select, setSelect] = React.useState({ isSelect: false, index: 0, type: "" })
  const { width } = useWindowDimensions()
  const componentContext = useContext(ComponentContext);
  const NDID = useSelector((state: IStore) => state.NdidReducer)
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const index = +page.split(".")[1] || 0
  const step = +page.split(".")[0] || 0
  const dataDisplay = dataOfPage[index]
  const [showMoreIdp, setShowMoreIdp] = React.useState(false)
  const onHandleHistoryOfPath = (val: {key?: string, value?: string | boolean | number}) => { 
    componentContext?.onChange({ key: val.key || "", value: val.value })
  }

  React.useEffect(() => {
    dispatch(getIDPNDID())
  }, [])

  React.useEffect(() => {
    if (NDID.idp.length === 0) {
      setShowMoreIdp(true)
    } else {
      setShowMoreIdp(false)
    }
  }, [NDID.idp.length])

  const onChange = () => {
    if (select.isSelect) {
      let value: Partial<IItemsIdp> = {};

      if (select.type === IdpListType.REGISTER) {
        value = NDID.idp[select.index]
      } else {
        value = NDID.onTheFly[select.index]
      }
      
      if (value) {
        dispatch(saveNDID({
          key: "CHOOSE_IDP_NDID",
          value: {
            logo: value.logo,
            idpID: value.idpID,
            displayNameEN: value.displayNameEN,
            displayNameTH: value.displayNameTH,
          },
        }))
        dispatch(navigateNext())
      }
    }
  }

  const onSelect = (key : number, type : IdpListType) => {
    if (select.index === key && select.type === type) {
      setSelect({ isSelect: !select.isSelect, index: key, type })
    } else {
      setSelect({ isSelect: true, index: key, type })
    }
  }

  const selectStytle = (key:number, type : IdpListType) => select.index === key && select.isSelect
  && select.type === type && ({
    border: "1.2px solid #1669AF",
    borderRadius: "10px",
    opacity: "1",
  })

  const mobileStyle = () => width < 540 && ({
    gridTemplateColumns: "repeat(2, 1fr)",
  })

  const mobileBoxStyle = () => width < 540 && ({
    height: "11.25rem",
  })

  const IDPList = (props: {itemIndex : number, type : IdpListType, val : IItemsIdp}) => {
    const { itemIndex, type, val } = props
    return (
      <Box
        key={`ndid-idp-id-${itemIndex.toString()}`} 
        onClick={() => onSelect(itemIndex, type)}
        style={{ ...selectStytle(itemIndex, type), ...mobileBoxStyle() }}
      >
        <div style={{
          display: "flex",
          flex: 1,
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center", 
        }}
        >
          <Images
            style={{
              width: "3.75rem", 
              height: "3.75rem", 
              // padding: width < 540 ? "" : "5px 25px 5px 35px", 
              borderRadius: 60, 
              overflow: "hidden",
            }}
            src={new URL(val.logo, getEndPoint()).toString()}
          />
          <TextHolder>
            <Texts style={{ fontSize: mainFontSize.s, color: "#4e4e4e", fontFamily: fonts.SarabunMedium }}>{val.displayNameTH}</Texts>
            <Texts style={{ fontSize: mainFontSize.xs, color: "#4e4e4e", fontFamily: fonts.SarabunLight }}>{t("bankIDP.bankSubHeader")}</Texts>
          </TextHolder>
        </div>
      </Box>
    ) 
  }

  const IDPBox = (propss: {
    children: React.ReactNode,
    title:string, 
    styleTitle?: React.CSSProperties,
    titleFunction? : any
    }) => {
    const {
      children, title, styleTitle, titleFunction, 
    } = propss  
    
    return (
      <div style={{
        display: "flex",
        flex: 1,
        flexDirection: "column",
        justifyContent: "space-between",
        border: "1.2px dotted #cccccc",
        padding: "10px", 
        marginBottom: "10px", 
      }}
      >
        <div style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "space-between", 
        }}
        >
          <Texts
            style={{ 
              color: "#666666", 
              padding: "5px 0px 5px 0px", 
              fontSize: mainFontSize.m, 
              fontFamily: fonts.SarabunMedium,
              ...styleTitle,
            }}
            onClick={() => (titleFunction ? titleFunction() : false)}
            
          >
            {t(title)}
          </Texts>
        </div>
        <Container
          style={{ ...mobileStyle() }}
        >
          {children}
        </Container>
      </div>

    )
  }
  
  return (
    <Card>
      <HistoryOfPath 
        data={dataOfPage.map((val) => val.title)}
        page={page}
        activeIndex={index}
      />
      <div style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between", 
      }}
      >
        <Texts style={{ 
          color: "#0C67B1", 
          padding: "5px 0px 5px 0px", 
          fontSize: mainFontSize.l, 
          fontFamily: fonts.SarabunMedium,
        }}
        >
          {dataDisplay.title}
        </Texts>
      </div>
      <Texts style={{
        color: "#666666", 
        padding: "5px 0px 5px 0px", 
        fontFamily: fonts.SarabunLight,
        fontSize: mainFontSize.s, 
        lineHeight: "20pt",
      }}
      >
        {t("bankIDP.subTitle")}
      </Texts>
      { NDID.idp.length > 0 && (
        <IDPBox
          title="bankIDP.alreadyIDPTitle"
        >
          { 
            NDID.idp
              .map((val: IItemsIdp, key) => (
                <IDPList 
                  itemIndex={key}
                  val={val}
                  type={IdpListType.REGISTER}
                />
              ))
              
          }
        </IDPBox>
        
      )}

      { !showMoreIdp && (
        <div style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "space-between", 
        }}
        >
          <Texts
            style={{ 
              color: "#0C67B1", 
              padding: "5px 0px 5px 0px", 
              fontSize: mainFontSize.m, 
              fontFamily: fonts.SarabunMedium,
              textDecoration: "underline",
              cursor: "pointer",
            }}
            onClick={() => setShowMoreIdp(!showMoreIdp)}
          >
            {t("bankIDP.IDPButtonMore")}
          </Texts>
        </div>
      )}
      { showMoreIdp && (
        <IDPBox
          title="bankIDP.IDPButtonLess"
          styleTitle={{
            textDecoration: "underline",
            color: "#0C67B1", 
            cursor: "pointer",
          }}
          titleFunction={() => setShowMoreIdp(!showMoreIdp)}

        >
          {
            NDID.onTheFly
              .map((val: IItemsIdp, key) => (
                <IDPList 
                  itemIndex={key}
                  val={val}
                  type={IdpListType.ON_THE_FLY}
                />
              ))

          }
        </IDPBox>
      )}
      <ButtonHolder>
        <Buttons
          style={{
            backgroundColor: "#ffffff",
            color: "#0067b1",
            borderWidth: 1,
            borderColor: "#0067b1",
          }}
          onClick={() => onHandleHistoryOfPath({ key: "BACK", value: 0 })}
        >
          {t("bankIDP.buttonBack")}
        </Buttons>
        <div />
        <Buttons
          disabled={!select.isSelect}
          onClick={() => onChange()}
        >
          {t("bankIDP.buttonNext")}
        </Buttons>
      </ButtonHolder>
    </Card>
  )
}