import React, { useEffect, useState } from "react"
import Emitter from "../../../utilities/emitter.util";
import LoadingIndicator from "../../LoadingIndicator";
import { TLight } from "../../Texts";

export const showSpinner = (message?: string) => Emitter.emit("show", message);

export const hideSpinner = (closeAll?: boolean) => Emitter.emit("hide", closeAll);

export default () => {
  const [messages, setMessages] = useState<Array<string>>([])

  const setOverlay = (visible: boolean, option?: string | boolean) => {
    if (visible) {
      setMessages((list) => [...list, option as string || "loading..."])
    } else {
      setMessages(option ? [] : messages.splice(0, 1))
    }
  }

  const showOverlay = (option: string) => setOverlay(true, option)
  const hideOverlay = () => setOverlay(false)

  useEffect(() => {
    Emitter.on("show", showOverlay)
    Emitter.on("hide", hideOverlay)
    return () => {
      Emitter.off("show", showOverlay)
      Emitter.off("hide", hideOverlay)
    }
  }, [])

  return (messages.length > 0) ? (
    <div style={{
      position: "fixed",
      left: 0,
      right: 0,
      top: 0,
      bottom: 0,
      zIndex: 3000,
      backgroundColor: "#ffffffd6",
      padding: 40,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    }}
    >
      <div
        style={{
          width: 300,
          height: 300,
          // background: "#FFFFFF",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <LoadingIndicator style={{ marginBottom: 10 }} />
        <TLight>
          {messages.slice(-1).pop()}
        </TLight>
      </div>
    </div>
  ) : null
}