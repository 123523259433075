import React, { useContext, useEffect } from "react";
import { 
  IFormsShema, 
  IOpenAccountShema,
} from "../../../interfaces";
import CardNDIDTerms from "./cardNDIDTerms"

interface props {
  page: string
  data: IOpenAccountShema[],
  dataOfPage: IFormsShema[],
}

export default ({
  page, data, dataOfPage,
}: props) => (
  <CardNDIDTerms
    page={page}
    dataOfPage={dataOfPage}
    data={data}
  />
)