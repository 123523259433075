import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components"
import { getCheckVerifyNDID, verifyIDPNDID } from "../../../actions";
import {
  Buttons,
  Card,
  Texts,
  Images,
  HistoryOfPath,
} from "../../../components"
import { kBankLogo } from "../../../components/Images";
import { getEndPoint } from "../../../config";
import { fonts, mainFontSize } from "../../../config/themes";
import { ComponentContext } from "../../../contexts";
import {
  IFormsShema,
  IOpenAccountShema,
  IStore, 
} from "../../../interfaces";
import { getLanguage } from "../../../services/languageProvider";

interface props {
  page: string
  data: IOpenAccountShema[],
  dataOfPage: IFormsShema[],
}

const ButtonHolder = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
`
const TextView = styled.div`
  background-color: rgba(204,204,204,0.15);
  padding: 10;
  border-radius: 10px;
  padding: 10px 15px 10px 15px;
  font-size: 11pt;
  line-height: 20pt;
  color: "#333333";
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`
export default ({
  page, 
  data, 
  dataOfPage,
}: props) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const index = +page.split(".")[1] || 0
  const dataDisplay = dataOfPage[index]
  const componentContext = useContext(ComponentContext);
  const NDID = useSelector((state: IStore) => state.NdidReducer)
  const {
    idpID,
    logo,
    displayNameTH,
    displayNameEN,
  } = NDID.CHOOSE_IDP_NDID

  const onChange = (val: {key?: string, value?: string | boolean | number}) => {
    componentContext?.onChange({ key: val.key || "", value: val.value })
  }

  const onNext = () => {
    dispatch(verifyIDPNDID({
      idpID,
      displayNameEN,
      displayNameTH,
    }))
  }
  
  return (
    <Card>
      <HistoryOfPath 
        data={dataOfPage.map((val) => val.title)}
        page={page}
        activeIndex={index}
      />
      <div style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between", 
      }}
      >
        <Texts style={{ 
          color: "#0C67B1", 
          padding: "5px 0px 5px 0px", 
          fontSize: mainFontSize.l, 
          fontFamily: fonts.SarabunMedium,
        }}
        >
          {dataDisplay.title}
        </Texts>
      </div>

      <TextView>
        <Images style={{ width: "120px", height: "120px", padding: "20px" }} src={new URL(logo, getEndPoint()).toString()} />
        <Texts style={{
          color: "#333333", 
          padding: "5px 0px 5px 0px", 
          fontFamily: fonts.SarabunMedium,
          fontSize: mainFontSize.s, 
          textAlign: "center", 
        }}
        >
          {
            t("idpConfirmation.header")
              .replace(
                "{bankName}", 
                getLanguage() === "en" ? displayNameEN : displayNameTH,
              )
          }
        </Texts>
        <Texts style={{
          color: "#666666", 
          padding: "5px 125px 5px 125px", 
          fontFamily: fonts.SarabunLight,
          fontSize: mainFontSize.s, 
          textAlign: "center", 
        }}
        >
          {
            t("idpConfirmation.message")
              .replace(
                "{bankName}", 
                getLanguage() === "en" ? displayNameEN : displayNameTH,
              )
          }
        </Texts>
      </TextView>

      <ButtonHolder>
        <Buttons
          style={{
            backgroundColor: "#ffffff", 
            color: "#0067b1", 
            borderWidth: 1, 
            borderColor: "#0067b1",
          }}
          onClick={() => onChange({ key: "BACK", value: 0 })}
        >
          {t("idpConfirmation.buttonBack")}
        </Buttons>
        <Buttons 
          onClick={onNext}
        >
          {t("idpConfirmation.buttonNext")}
        </Buttons>
      </ButtonHolder>
    </Card>
  )
}