import React from "react"
import {
  BrowserRouter as Router, Redirect, Route, Switch,
} from "react-router-dom"
import { getAoStep, navigateSetWithKey } from "../actions"
import PageNotFound from "../containers/404/pageNotFound"
import { dataOpenAccount } from "../containers/__mock__"
import About from "../containers/about"
import Contact from "../containers/contact"
import Home from "../containers/home/home"
import Login from "../containers/login/login"
import OpenAccount from "../containers/openAccount"
import AprovalStatus from "../containers/openAccount/approval-status"
import otpSoftreject from "../containers/openAccount/otp-softreject"
import { routerName } from "../interfaces/router.enum"
import store from "../store"
import { auth } from "../utilities"
import ProtectedRoute from "./protected.route"

const handleRootPath = () => {
  if (auth.isAuthenticated()) {
    store.store.dispatch(navigateSetWithKey({
      data: dataOpenAccount.opanAccount, 
    }))
    store.store.dispatch(getAoStep())
    return <Redirect to={`${routerName.openAccount}/1.0`} />
  }
  return <Redirect to={routerName.login} />
}

const AppRouter = () => (
  <Router>
    <Switch>
      <Route exact path="/" render={handleRootPath} />
      <Route path={routerName.login} component={Login} />
      <Route path={routerName.home} component={Home} />
      <Route path={routerName.contact} component={Contact} />
      <Route path={routerName.approvalStatus} component={AprovalStatus} />
      <Route path={routerName.otpSoftReject} component={otpSoftreject} />
      <ProtectedRoute path={routerName.about} component={About} />
      <Redirect exact path={routerName.openAccount} to={`${routerName.openAccount}/1.0`} />
      <Route path={`${routerName.openAccount}/:id`} component={OpenAccount} />
      <Route component={PageNotFound} />
    </Switch>
  </Router>
)

export default AppRouter