import * as React from "react";

import Circle from "./circle";

const LoadingIndicator = ({ 
  style,
}: {style: React.CSSProperties}) => (
  <div style={style}>
    <Circle />
  </div>
);

export default LoadingIndicator;
