/* eslint-disable max-len */
import { Action } from "redux-actions";
import {
  takeLatest,
  call,
  put,
  select,
} from "redux-saga/effects"
import {
  navigateNext,
  OPENING_ACCOUNT_GETANSWERSUIT_SAGA,
  OPENING_ACCOUNT_GETSUIT_SAGA,
  OPENING_ACCOUNT_GET_PEP,
  OPENING_ACCOUNT_SAVEANSWERSUIT_SAGA,
  OPENING_ACCOUNT_UPLOADFILE_SAGA,
  OPENING_ACCOUNT_SAVE_FATCA,
  OPENING_ACCOUNT_VALIDATE_PEP,
  OPENING_ACCOUNT_VERIFY_REGISTER,
  saveSuitAnswerReducer,
  saveSuitQuestionReducer,
  navigateSetStep,
  setPreviewScore,
  OPENING_PREVIEW_SUIT_ABILITY_TEST_RESULT,
  TFEX_GET_QUESTION,
  saveTFexQuestionReducer,
  OPENING_ACCOUNT_PREVIEW_TFEX_TEST,
  OPENING_ACCOUNT_SAVEPERSONALINFO_SAGA,
  OPENING_ACCOUNT_SAVEBACKGROUNDINFO_SAGA,
  OPENING_ACCOUNT_SAVECONTACTINFO_SAGA,
  OPENING_ACCOUNT_SAVEMUTUALFUNDACCOUNT_SAGA,
  OPENING_ACCOUNT_SAVESAVINGACCOUNTTOMUTUALFUND_SAGA,
  OPENING_ACCOUNT_SAVE_TFEX_TEST,
  setPreviewTFex,
  OPENING_ACCOUNT_GET_CONSENT,
  setOpenAccountReducer,
  OPENING_ACCOUNT_QUERYALL_SAGA,
  setOpenAccountWithPageReducer,
  navigateSetWithKey,
  OPENING_ACCOUNT_SAVE_CONSENT,
  OPENING_ACCOUNT_SET_AO_STEP,
  OPENING_ACCOUNT_GET_AO_STEP,
  navigateSetStepWithParams,
  OPENING_ACCOUNT_SUBMIT_SAGA,
  OPENING_ACCOUNT_SAVEAOSTEP_SAGA,
  OPENING_ACCOUNT_GETACCOUNTTYPE_SAGA,
  OPENING_ACCOUNT_SAVESELECTEDACCOUNT_SAGA,
  OPENING_ACCOUNT_SAVE_PERSONAL_CONTACTS_SAGA,
  OPENING_ACCOUNT_SAVEREFERRAL_SAGA,
  OPENING_ACCOUNT_GETCONSENTALL_SAGA,
  setMobileOtpVerify,
  OPENING_ACCOUNT_RESET_AO_STEPS, setOaStatus, OPENING_ACCOUNT_GET_ALL_NO_TIN_REASONS, SaveFatcaPayload,
} from "../actions"
import {
  GetAccountTypeApi,
  GetAllImage,
  GetAllMasterDataApi,
  GetAoStepApi,
  GetConsentApi,
  GetFatcaApi,
  GetPepApi,
  GetQueryAllDataApi,
  GetReferralPersonByIdApi,
  GetSelectConsentApi,
  SaveBackgroundInfo,
  SaveConsentApi,
  SaveContactInfo,
  SaveMutualFundAccount,
  SaveMutualFundAccountDetail,
  SavePersonalContacts,
  SavePersonalInfo,
  SaveReferralPersonApi,
  SaveSavingAccountToMutualFund,
  SaveSelectedAccountApi,
  SetAoStepApi,
  SubmitDataApi,
  UploadFile,
  SaveFatcaApi,
  ValidatePepApi,
  VerifyMobileOtpApi,
  VerifyRegisterApi,
  ResetAoSteps, GetUsers, GetBusinessTypeApi,
  GetAllNoTinReasonsApi, FindCountries,
} from "../apis"
import {
  GetSuitQuestionApi,
  GetSuitResultAnwserApi,
  PreviewSuitabilityTestResultApi,
  SaveSuitAnswerApi,
} from "../apis/suit.api"
import { GetTfexQuestionApi, PreviewTfexTestApi, SaveTfexTestApi } from "../apis/tfex.api"
import { showDialog } from "../components/Provider/dialog"
import { hideSpinner, showSpinner } from "../components/Provider/spinner"
import {
  fileProps,
  IConsentType,
  ICreateOrUpdatePersonalContacts,
  IDialogTypeEnum, IFindCountriesResponse,
  IGetAllNoTinReasonsResponse,
  IGetAoStepResponse,
  IGetConsentResponse,
  IGetFatcaResponse,
  IGetOpenAccount,
  IGetPepResponse,
  IGetQueryAllMasterResponse,
  IGetTitleResponse,
  IGetUserConsentResponse, IGetUsers,
  InputEnum,
  IOpenAccountShema,
  IOpeningAccountState,
  IPageOpenAccountOptionEnum,
  IQueryReferralPersonByIdResponse, IResetAoStepsResponse,
  IRetrieveNDIDData,
  ISaveBackgroundInfoResponse,
  ISaveConsentResponse,
  ISaveContactInfoResponse,
  ISaveMutualFundAccountDetailResponse,
  ISaveMutualFundResponse,
  ISavePersonalInfoResponse,
  ISaveReferralPersonResponse,
  ISaveSavingFualFundResponse,
  ISelectedAccountReponse,
  ISetAoStepResponse,
  IStore,
  ISubmitReponse,
  ISubPage,
  IUploadFileResponse,
  ISaveFatcaResponse,
  IValidatePepResponse,
  IVerification,
  IVerifyMobileOtpResponse,
  IVerifyRegisterResponse,
  UploadFileType,
  UploadFileTypeEnum,
} from "../interfaces"
import { IStepperReducer } from "../interfaces/steps.interface"
import {
  IPreviewSuitabilityTestResultResponse,
  ISuitAnswerReponse,
  ISuitQuestionResponse,
  ISuitSaveAnswerResponse,
} from "../interfaces/suit.interface"
import {
  IPreviewTFexTestResponse,
  ISaveTFexTestResponse,
  ITFexQuestion,
  ITFexQuestionResponse,
  ITFexReducers,
} from "../interfaces/tfex.interface"
import {
  ConvertBase64Tofile,
  DateFormatProtectSensitive,
  GenFilePropsArray,
  GetCurrentPage,
  getLayoutAnswer,
  mapDataAnsWithQuestionChoice,
} from "../utilities"
import {
  WorkerGetTitle,
} from "./masterdata.saga"
import { RetrieveDataNDID } from "./ndid.saga"
import {
  handleStatusError,
} from "."

export function* WorkerSetAoStep({ payload }: { payload?: {
  callback: (value: ISetAoStepResponse) => void,
  itemStep: string,
}, type: string }) {
  try {
    const Tfex: {[key:string]: string} = yield select((state: IStore) => state.OpeningAccountReducer.tfexQuestionaire)
    const Suit: {[key:string]: any} = yield select((state: IStore) => state.OpeningAccountReducer.suitability)
    const { page, subPage } = GetCurrentPage(payload?.itemStep || "1.0")
    const { data }: IStepperReducer = yield select((state: IStore) => state.StepsReducer)

    let type = ""

    if (data[page].type === "tfexQuestionaire" && Object.keys(Tfex).map((val) => val.replace("answer", "")).some((val) => +val === 20)) {
      type = ISubPage.STEP_TFEX_TEST
    } else if (data[page].type === "suitability" && Suit?.STEP_SUITABILITY_SUMMARY_TEST?.acceptSuitabilityResult.some((val: any) => val.selected)) {
      type = ISubPage.STEP_SUITABILITY_TEST
    } else if (data[page].type !== "suitability" && data[page].type !== "tfexQuestionaire") {
      type = data[page]?.form[subPage]?.type
    }

    if (!type) return

    const response: ISetAoStepResponse = yield call(SetAoStepApi, { stepName: type, isPass: true })

    if (response?.errors) {
      yield call(handleStatusError, response?.errors);
      return
    }
    yield put(navigateSetWithKey({ allowEdit: response.data.SetAoStep.step }))
  } catch (error) {
    console.error("setAoStep ===>", error)
  }
}

export function* WorkerGetStep() {
  try {
    const response: IGetAoStepResponse = yield call(GetAoStepApi)
    if (response?.errors) {
      yield call(handleStatusError, response?.errors);
      return
    }
    yield put(navigateSetWithKey({ allowEdit: response.data.GetAoStep.step }))

    // eslint-disable-next-line consistent-return
    return response
  } catch (error) {
    console.error(error)
  }
}

export function* WorkerPushToCurrentStep() {
  try {
    const { data }: IStepperReducer = yield select((state: IStore) => state.StepsReducer)
    if (data.length === 0) return

    const response: IGetAoStepResponse = yield call(WorkerGetStep)

    for (let i = 0; i < data.length; i += 1) {
      for (let j = 0; j < data?.[i]?.form.length; j += 1) {
        if (response.data.GetAoStep.subCurrentStep === data?.[i]?.form[j].type) {
          yield put(navigateSetStepWithParams({ page: `${i}.${j}` }))
          return
        }
      }
    }
    yield put(navigateSetStep("1.0"))
  } catch (error) {
    console.error(error)
  }
}

function* VerifyRegister({ payload }: any) {
  const openingAccount: IOpeningAccountState = yield select((
    state: IStore,
  ) => state.OpeningAccountReducer)
  try {
    yield call(showSpinner)
    const verifyResponse: IVerifyRegisterResponse = yield call(
      VerifyRegisterApi,
      openingAccount.termAndConditoin.STEP_VERIFY_IDENTITY.citizenId as string,
      openingAccount.termAndConditoin.STEP_VERIFY_IDENTITY.laserId as string,
      openingAccount.termAndConditoin.STEP_VERIFY_IDENTITY.firstNameTh as string,
      openingAccount.termAndConditoin.STEP_VERIFY_IDENTITY.lastNameTh as string,
      openingAccount.termAndConditoin.STEP_VERIFY_IDENTITY.birthDate as string,
    )

    if (verifyResponse?.errors) {
      yield call(handleStatusError, verifyResponse?.errors, true);
      yield call(hideSpinner)
      return
    }

    if (verifyResponse.errors) {
      verifyResponse.errors.forEach((err) => {
        alert(err.extensions.status)
      })
    }

    yield call(SetAoStepApi, { stepName: ISubPage.STEP_VERIFY_IDENTITY, isPass: true })

    if (verifyResponse.data.VerifyRegister) {
      const fatcaResponse: IGetFatcaResponse = yield call(GetFatcaApi)

      if (fatcaResponse.data?.GetFatca?.isVerified === true) {
        yield call(SetAoStepApi, { stepName: "STEP_VERIFY_FATCA", isPass: true })
        if (fatcaResponse.data?.GetFatca?.isAmerican === true) {
          yield call(showDialog,
            IDialogTypeEnum.ERROR_DIALOG,
            { message: "กรุณาติดต่อ RHB Contact Center 02 088 9896 หรือ 02 088 9897 หรือ 02 088 9797" },
            true)
        } else {
          const pepResponse: IGetPepResponse = yield call(GetPepApi)
          if (pepResponse?.data?.GetPep?.isVerified === true) {
            yield call(SetAoStepApi, { stepName: "STEP_VERIFY_PEP", isPass: true })
            // if (pepResponse.data?.GetPep?.isPep === true) {
            //   alert("You are Pep!")
            // } else {
            //   yield put(navigateSetStep("2"))
            // }
            yield put(navigateSetStep("2"))
          } else {
            yield put(navigateSetStep("1.5"))
          }
        }
      } else {
        yield put(navigateNext())
      }
      yield call(hideSpinner)
    }
  } catch (error) {
    console.error(error)
    yield call(hideSpinner)
  }
}

function* SaveFatca({ payload }: Action<SaveFatcaPayload>) {
  try {
    yield call(showSpinner)
    const { countryName } = payload.birthplace;
    delete payload.birthplace.countryName;

    const fatcaResponse: ISaveFatcaResponse = yield call(
      SaveFatcaApi,
      payload,
    )

    if (fatcaResponse?.errors) {
      yield call(handleStatusError, fatcaResponse?.errors);
      yield call(hideSpinner)
      return
    }

    if (fatcaResponse.data?.SaveFatca) {
      yield put(setOpenAccountReducer({
        page: "termAndConditoin",
        subPage: ISubPage.STEP_VERIFY_FATCA,
        addValue: {
          isAmerican: payload.isAmerican,
          birthplace: {
            ...fatcaResponse.data.SaveFatca.birthplace,
            countryName,
          },
          taxResidences: fatcaResponse.data.SaveFatca.taxResidences,
          hasTaxResidencesOtherThanUsaOrThailand: payload.hasTaxResidenceOtherThanUsaOrThai,
        },
      }))

      const pepResponse: IGetPepResponse = yield call(GetPepApi)
      if (pepResponse?.errors) {
        yield call(handleStatusError, pepResponse?.errors);
        yield call(hideSpinner)
        return
      }

      if (pepResponse?.data?.GetPep?.isVerified === true) {
        yield put(navigateSetStep("2"))
      } else {
        yield put(navigateNext())
      }
    }
  } catch (error) {
    console.error(error)
  } finally {
    yield call(hideSpinner)
  }
}

function* GetPep({ payload }: any) {
  try {
    const response: IGetPepResponse = yield call(GetPepApi);

    if (response?.errors) {
      yield call(handleStatusError, response?.errors);
      return
    }

    yield call(payload?.callback, response?.data?.GetPep)

    console.log("GetPep IGetPepResponse ===> ", response)
  } catch (error) {
    console.error(error)
  }
}

function* ValidatePep({ payload }: any) {
  try {
    yield call(showSpinner)
    const openingAccount: IOpeningAccountState = yield select((
      state: IStore,
    ) => state.OpeningAccountReducer)
    const pepResponse: IValidatePepResponse = yield call(
      ValidatePepApi,
      openingAccount.termAndConditoin.STEP_VERIFY_PEP.isPep[1]?.selected as boolean,
      openingAccount.termAndConditoin.STEP_VERIFY_PEP.otherPep,
    )

    if (pepResponse?.errors) {
      yield call(handleStatusError, pepResponse?.errors);
      yield call(hideSpinner)
      return
    }

    if (pepResponse.data?.ValidatePep) {
      yield put(navigateSetStep("2"))
    } else {
      alert("PEP Error ")
    }
    yield call(hideSpinner)
  } catch (error) {
    console.error(error)
    yield call(hideSpinner)
  }
}

function* GetSuitQuestion() {
  try {
    const response: ISuitQuestionResponse = yield call(GetSuitQuestionApi)

    if (response?.errors) {
      yield call(handleStatusError, response?.errors);
      return
    }

    yield put(saveSuitQuestionReducer(response.data.SuitabilityQuestion))
  } catch (error) {
    console.error(error)
  }
}

function* SaveSuitAnswer() {
  const { OpeningAccountReducer, SuitReducer } = yield select((state: IStore) => state)
  const questionSuit = SuitReducer.question
  const suitReducer = OpeningAccountReducer.suitability
  const suitResult = getLayoutAnswer(suitReducer)

  delete suitResult.STEP_SUITABILITY_SUMMARY_TEST

  try {
    yield call(showSpinner)
    const {
      result,
      someSuitEmptyArr,
    } = mapDataAnsWithQuestionChoice(
      suitResult, questionSuit.map((val: any) => val.choices), questionSuit,
    )

    if (someSuitEmptyArr.every((val) => val)) {
      const response: ISuitSaveAnswerResponse = yield call(SaveSuitAnswerApi, result)
      if (response?.errors) {
        yield call(handleStatusError, response?.errors);
        yield call(hideSpinner)
        return
      }
      if (response.data.SaveSuitabilityTestInfo.isSuccess) {
        yield put(navigateNext())
      }
    } else {
      yield put(navigateNext())
    }
    yield call(hideSpinner)
  } catch (error) {
    console.error(error)
    yield call(hideSpinner)
  }
}

function* PreviewSuitabilityTestResult() {
  const { OpeningAccountReducer, SuitReducer } = yield select((state: IStore) => state)
  const steps: { step: string, page: number, subPage: number } =
  yield select((state: IStore) => state.StepsReducer)

  const questionSuit = SuitReducer.question
  const suitReducer = OpeningAccountReducer.suitability

  const suitResult = getLayoutAnswer(suitReducer)

  delete suitResult.STEP_SUITABILITY_SUMMARY_TEST

  try {
    const {
      result,
      someSuitEmptyArr,
    } = mapDataAnsWithQuestionChoice(
      suitResult, questionSuit.map((val: any) => val.choices), questionSuit,
    )

    if (someSuitEmptyArr.every((val) => val) && steps.subPage === someSuitEmptyArr.length) {
      const response: IPreviewSuitabilityTestResultResponse =
        yield call(PreviewSuitabilityTestResultApi, result)
      if (response?.errors) {
        yield call(handleStatusError, response?.errors);
        return
      }
      yield put(setPreviewScore(response.data.PreviewSuitabilityTestResult))
      yield put(navigateNext())
    } else {
      yield put(navigateNext())
    }
  } catch (error) {
    console.error(error)
  }
}

function* GetSuitResultAnswer() {
  try {
    const response: ISuitAnswerReponse = yield call(GetSuitResultAnwserApi)
    yield console.log("GetSuitResultAnswer", response)
    if (response?.errors) {
      yield call(handleStatusError, response?.errors);
      return
    }
    yield console.log("GetSuitResultAnswer", response)
    yield put(saveSuitAnswerReducer(response.data.GetSuitabilityTestInfo))
  } catch (error) {
    console.error(error)
  }
}

function* WorkerUploadFile({ payload }: {
  payload: {
    file: File
    files?: Array<fileProps>,
    field: UploadFileType
    disabledNext: boolean,
    callback?: () => void,
  }
  type: string
}) {
  const isOversize = (size: number, limitSizeMB = 5) => size > 1024 * (1024 * limitSizeMB)

  try {
    yield call(showSpinner)
    const { files = [], field } = payload
    if (files.length > 0) {
      for (let i = 0; i < files.length; i += 1) {
        const file = files[i];
        const realFile = file.data as File;

        if (isOversize(realFile.size)) {
          yield call(hideSpinner)
          yield call(showDialog,
            IDialogTypeEnum.ERROR_DIALOG,
            { message: "ไม่สามารถอัปโหลดได้เนื่องจากขนาดไฟล์ต้องไม่เกิน 5 MB" },
            true)
          return
        }

        if (!realFile.type.includes("jpg") && !realFile.type.includes("jpeg") && !realFile.type.includes("png") && !realFile.type.includes("pdf")) {
          yield call(hideSpinner)
          yield call(showDialog,
            IDialogTypeEnum.ERROR_DIALOG,
            { message: "รองรับไฟล์ : JPEG , JPG , PNG เท่านั้น" },
            true)
          return
        }
      }

      let uploadFiles = files
      const isDeleteFile = files?.some((key) => key.isDelete)
      if (isDeleteFile) {
        uploadFiles = []
      }

      const response: IUploadFileResponse = yield UploadFile(uploadFiles, field)
      if (response?.errors) {
        yield call(hideSpinner)
        yield call(handleStatusError, response?.errors);
        return
      }

      yield console.log("WorkerUploadFile", response)
    } else if (files.length === 0 && field === UploadFileTypeEnum.IMAGE_STATEMENT) {
      const response: IUploadFileResponse = yield UploadFile([], field)
      if (response?.errors) {
        yield call(hideSpinner)
        yield call(handleStatusError, response?.errors);
        return
      }
    }

    console.log("cccc", payload.disabledNext)
    if (payload?.callback) {
      yield call(hideSpinner)
      yield call(payload?.callback)
      return
    }

    if (!payload.disabledNext) {
      yield call(hideSpinner)
      yield put(navigateNext())
    }
  } catch (error) {
    console.error(error)
    yield call(hideSpinner)
  }
}

function* GetTfexQuestion() {
  try {
    const response: ITFexQuestionResponse = yield call(GetTfexQuestionApi)

    if (response?.errors) {
      yield call(handleStatusError, response?.errors);
      return
    }
    yield put(saveTFexQuestionReducer(response.data.GetTfexQuestion))
  } catch (error) {
    console.error(error)
  }
}

function* PreviewTFexTest() {
  const OpeningAccountReducer:IOpeningAccountState =
    yield select((state: IStore) => state.OpeningAccountReducer)
  const TFexReducer: ITFexReducers = yield select((state: IStore) => state.TFexReducer)
  const steps: { step: string, page: number, subPage: number } =
  yield select((state: IStore) => state.StepsReducer)

  try {
    const tfexAnswer: Array<any> =
      Object.entries(OpeningAccountReducer.tfexQuestionaire).map(
        ([_key, value]: Array<any>) => ({ answer: value?.answer }),
      )

    if (TFexReducer.question.length > tfexAnswer.length
        || steps.subPage < TFexReducer.question.length - 1) {
      yield put(navigateNext())
    } else if (TFexReducer.question.length === tfexAnswer.length) {
      const result: Array<ITFexQuestion> = TFexReducer?.question?.map((item, i) => ({
        ...item,
        answer: tfexAnswer?.[i]?.answer[0] === "ใช่",
      }))
      const response: IPreviewTFexTestResponse =
        yield call(PreviewTfexTestApi, result)
      if (response?.errors) {
        yield call(handleStatusError, response?.errors);
        return
      }
      yield put(setPreviewTFex(response.data.PreviewTFexTest))
      // yield put(navigateNext())
    }
  } catch (error) {
    console.error("error ===>", error)
  }
}

function* SaveTFexTest() {
  const OpeningAccountReducer:IOpeningAccountState =
    yield select((state: IStore) => state.OpeningAccountReducer)
  const TFexReducer: ITFexReducers = yield select((state: IStore) => state.TFexReducer)
  const steps: { step: string, page: number, subPage: number } =
  yield select((state: IStore) => state.StepsReducer)

  try {
    const tfexAnswer: Array<any> =
      Object.entries(OpeningAccountReducer.tfexQuestionaire).map(
        ([_key, value]: Array<any>) => ({ answer: value?.answer }),
      )

    if (TFexReducer.question.length > tfexAnswer.length
        || steps.subPage < TFexReducer.question.length - 1) {
      yield put(navigateNext())
    } else if (TFexReducer.question.length === tfexAnswer.length) {
      const result: Array<ITFexQuestion> = TFexReducer?.question?.map((item, i) => ({
        ...item,
        answer: tfexAnswer?.[i]?.answer[0] === "ใช่",
      }))
      const response: ISaveTFexTestResponse =
        yield call(SaveTfexTestApi, result)
      if (response?.errors) {
        yield call(handleStatusError, response?.errors);
        return
      }

      if (response?.data?.SaveTfexTest.isSuccess) {
        yield put(navigateNext())
      }
    }
  } catch (error) {
    console.error(error)
  }
}

// ====================================== saga save data ===============================

function* WorkerSavePersonalInfo() {
  try {
    yield call(showSpinner)
    const { NdidReducer, OpeningAccountReducer } = yield select((state: IStore) => state)
    const NDIDDATA: IRetrieveNDIDData = NdidReducer.RETRIEVE_DATA_NDID
    const SPOUSE = OpeningAccountReducer.personalInformation.STEP_MARITAL_STATUS
    const IDCARDINFO = OpeningAccountReducer.idCardInformation.STEP_ID_CARD_INFO

    let getTitleCode: any = {}

    yield WorkerGetTitle({
      payload: {
        callback: (res: IGetTitleResponse) => {
          const items = res.data.GetTitle.find((val) => val.displayTh === IDCARDINFO.title)
          if (items) {
            getTitleCode = items?.id
            return
          }
          getTitleCode = "T000"
        },
      },
    })
    const isSpousePep: boolean = SPOUSE.isRelatedPEP.some((val: {
      requireOther: boolean
      selected: boolean
    }) => val.requireOther && val.selected)
    const isSpouse: boolean = SPOUSE.isSpouse.some((val: {
      title: string
      selected: boolean
    }) => val.title === "โสด" && val.selected)
    const data = {
      birthdate: NDIDDATA.birthDate,
      firstNameEn: IDCARDINFO.firstNameEn,
      firstNameTh: IDCARDINFO.firstNameTh,
      identificationCardExpiryDate: IDCARDINFO.expiryDate,
      identificationCardIssueCountry: NDIDDATA.identificationCard.issueCountry,
      identificationCardNumber: NDIDDATA.identificationCard.number,
      identificationCardType: NDIDDATA.identificationCard.type,
      isIdentificationCardExpiryDate: !!NDIDDATA.identificationCard.expiryDate,
      lastNameEn: IDCARDINFO.lastNameEn,
      lastNameTh: IDCARDINFO.lastNameTh,
      maritalStatus: isSpouse ? "MS001" : "MS002", // โสด = MS001 | สมรส = MS002
      nationality: NDIDDATA.nationality,
      spouse: {
        firstNameEn: SPOUSE.firstNameEn,
        lastNameEn: SPOUSE.lastNameEn,
        firstNameTh: SPOUSE.firstNameTh,
        lastNameTh: SPOUSE.lastNameTh,
        isPep: isSpousePep,
        pepYear: isSpousePep ? SPOUSE.BC : "",
        position: isSpousePep ? SPOUSE.position : "",
      },
      title: getTitleCode, // T000 = อื่นๆ
      titleOther: IDCARDINFO.title,
      gender: IDCARDINFO.gender,
      // titleOther: OpeningAccountReducer.idCardInformation.STEP_ID_CARD_INFO.title,
    }
    // console.log("WorkerSavePersonalInfo ==> ", data)
    // 1309913659936
    // console.log("SavePersonalInfo request || ", data)

    const response: ISavePersonalInfoResponse = yield call(SavePersonalInfo, data)
    console.log("SavePersonalInfo response ==> ", response)
    if (response?.errors) {
      yield call(handleStatusError, response?.errors);
      yield call(hideSpinner)
      return
    }

    if (response.data.SavePersonalInfo.isSuccess) {
      yield put(navigateNext())
    }
    // console.log("SavePersonalInfo response || ", response)
    yield call(hideSpinner)
  } catch (error) {
    console.error(error)
    yield call(hideSpinner)
  }
}

function* WorkerSaveMutualFundAccountDetai() {
  try {
    const { OpeningAccountReducer } = yield select((state: IStore) => state)
    const INCOME_INFO = OpeningAccountReducer.personalInformation.STEP_INCOME

    const codePurposeArr = INCOME_INFO.purposeOfInvest
      .map((val: any) => val.selected && val?.id)
      .filter((val: any) => val)
    const isPurposeOther = INCOME_INFO.purposeOfInvest.some((val: {
        requireOther: boolean
        selected: boolean
      }) => val.requireOther && val.selected)

    const data = {
      channelId: "RHB",
      mutualFundAccountDetail: {
        investmentObjective: codePurposeArr.toString(),
        investmentObjectiveOther: isPurposeOther ? INCOME_INFO.otherPurposeOfInvest : "",
        isAcceptFxRisk: false,
        isAcceptDerivativeInvestment: false,
      },
    }

    console.log("SaveMutualFundAccountDetail request || ", data)
    const response: ISaveMutualFundAccountDetailResponse =
    yield call(SaveMutualFundAccountDetail, data)
    if (response?.errors) {
      yield call(handleStatusError, response?.errors);
      return
    }
    console.log("SaveMutualFundAccountDetail response || ", response)

    // eslint-disable-next-line consistent-return
    return response
  } catch (error) {
    console.error(error)
  }
}

function* WorkerSaveBackgroundInfo({ payload }: {
  payload: { disabledNext: boolean }, type: string
}) {
  try {
    yield call(showSpinner)
    const { OpeningAccountReducer } = yield select((state: IStore) => state)
    const FRAUD = OpeningAccountReducer.personalInformation.STEP_FRAUD
    const INCOME_INFO = OpeningAccountReducer.personalInformation.STEP_INCOME
    const addressWork = OpeningAccountReducer.personalInformation.STEP_WORKPLACE_ADDRESS
    const fatca = OpeningAccountReducer.termAndConditoin.STEP_VERIFY_FATCA;

    const isFrauding: boolean = FRAUD?.isFraud?.some((val: {
      requireOther: boolean, selected: boolean
    }) => val.requireOther && val.selected)

    const codeIncome = INCOME_INFO.sourceOfIncome
      .map((val: any) => val.selected && val?.id)
      .filter((val: any) => val)

    const isSourceOfIncome = INCOME_INFO.sourceOfIncome.some((val: {
        requireOther: boolean
        selected: boolean
      }) => val.requireOther && val.selected)

    const isCountry = INCOME_INFO.countryOfIncome.some((val: {
      requireOther: boolean
      selected: boolean
    }) => val.requireOther && val.selected)

    const salary = INCOME_INFO.salary.find((val: { selected: any }) => val.selected)

    const data = {
      occupation: addressWork?.codeCareer || "",
      occupationOther: "",
      businessType: addressWork?.codeTypeBusiness || "",
      businessTypeOther: addressWork?.businessTypeOther || "",
      incomeSource: codeIncome.toString(),
      incomeSourceOther: isSourceOfIncome ? INCOME_INFO?.otherSourceOfIncome : "",
      incomeSourceCountry: isCountry ? INCOME_INFO?.codeCountry : "TH",
      monthlyIncomeLevel: salary?.id ? salary?.id : "",
      assetValue: INCOME_INFO.propertyValue,
      isRelatedPoliticalPerson: false,
      politicalPosition: "",
      isAcceptFxRisk: true,
      isAcceptDerivativeInvestment: true,
      isVulnerable: false,
      companyName: addressWork.nameWorkplace || "",
      workPosition: addressWork.position || "",
      isFraud: isFrauding,
      fundamentallyWrong: isFrauding ? FRAUD.predicateOftence : "",
      fraudYear: isFrauding ? FRAUD.BC : "",
      hasTaxResidencesOtherThanUsaOrThailand: fatca.hasTaxResidencesOtherThanUsaOrThailand,
    }

    console.log("SaveBackgroundInfo request || ", data, isCountry)

    const response: ISaveBackgroundInfoResponse = yield call(SaveBackgroundInfo, data)
    const mutationMutualAccountDetailRes: ISaveMutualFundAccountDetailResponse =
    yield call(WorkerSaveMutualFundAccountDetai)
    if (response?.errors) {
      yield call(handleStatusError, response?.errors);
      yield call(hideSpinner)
      return
    }

    if (response.data.SaveBackgroundInfo.isSuccess
      && mutationMutualAccountDetailRes.data.UpdateMutualFundAccountDetail.isSuccess
      && !payload.disabledNext) {
      yield put(navigateNext())
    }
    console.log("SaveBackgroundInfo response || ", response)
    yield call(hideSpinner)
  } catch (error) {
    console.error(error)
    yield call(hideSpinner)
  }
}

function* WorkerSaveContactInfo({ payload } : {
  payload: { dataDisplayType: string }, type: string
}) {
  try {
    yield call(showSpinner)
    const { OpeningAccountReducer, AuthReducer } = yield select((state: IStore) => state)
    const addressHome = OpeningAccountReducer.personalInformation.STEP_ID_CARD_ADDRESS
    const addressCurr = OpeningAccountReducer.personalInformation.STEP_CURRENT_ADDRESS
    const addressWork = OpeningAccountReducer.personalInformation.STEP_WORKPLACE_ADDRESS

    let isCurrSameAs = addressCurr.isSameHomeAddress.some((val: {
      requireOther: boolean
      selected: boolean
    }) => val.requireOther && val.selected)

    console.log("isCurrSameAs", isCurrSameAs, "dataDisplayType", payload.dataDisplayType);
    if (payload.dataDisplayType === ISubPage.STEP_ID_CARD_ADDRESS && isCurrSameAs &&
      !(addressCurr.houseNumber && addressCurr.subDistrict && addressCurr.district && addressCurr.province && addressCurr.postalCode)) {
      isCurrSameAs = false
    }

    const data = {
      identificationCardAddress: {
        addressNumber: addressHome.houseNumber,
        village: "",
        building: addressHome.building,
        floor: addressHome.floor,
        room: addressHome.roomNumber,
        soi: addressHome.alley,
        road: addressHome.road,
        moo: addressHome.moo,
        addressCode: addressHome.codeAddress,
        subDistrict: addressHome.subDistrict,
        district: addressHome.district,
        province: addressHome.province,
        zipCode: addressHome.postalCode,
        country: "TH",
      },
      currentAddressSameAs: isCurrSameAs ? "OTHER" : "IDENTIFICATION_CARD",
      currentAddress: {
        addressNumber: isCurrSameAs ? addressCurr.houseNumber : "",
        village: "",
        building: isCurrSameAs ? addressCurr.building : "",
        floor: isCurrSameAs ? addressCurr.floor : "",
        room: isCurrSameAs ? addressCurr.roomNumber : "",
        soi: isCurrSameAs ? addressCurr.alley : "",
        road: isCurrSameAs ? addressCurr.road : "",
        moo: isCurrSameAs ? addressCurr.moo : "",
        addressCode: isCurrSameAs ? addressCurr.codeAddress : "",
        subDistrict: isCurrSameAs ? addressCurr.subDistrict : "",
        district: isCurrSameAs ? addressCurr.district : "",
        province: isCurrSameAs ? addressCurr.province : "",
        zipCode: isCurrSameAs ? addressCurr.postalCode : "",
        country: "TH",
      },
      workplaceAddressSameAs: "OTHER",
      workplaceAddress: {
        addressNumber: addressWork.houseNumber || "",
        village: "",
        building: addressWork.building || "",
        floor: addressWork.floor || "",
        room: addressWork.roomNumber || "",
        soi: addressWork.alley || "",
        road: addressWork.road || "",
        moo: addressWork.moo || "",
        addressCode: addressWork.codeAddress || "",
        subDistrict: addressWork.subDistrict || "",
        district: addressWork.district || "",
        province: addressWork.province || "",
        zipCode: addressWork.postalCode || "",
        country: "TH",
      },
      companyName: addressWork.nameWorkplace || "",
      workPosition: addressWork.position || "",
      phoneNumber: "",
      mobileNumber: AuthReducer.mobileNo || "",
      email: AuthReducer.email || "",
      fax: "",
    }
    console.log("WorkerSaveContactInfo request || ", data)

    const response: ISaveContactInfoResponse = yield call(SaveContactInfo, data)
    if (response?.errors) {
      yield call(handleStatusError, response?.errors);
      yield call(hideSpinner)
      return
    }

    if (response.data.SaveContactInfo.isSuccess) {
      yield put(navigateNext())
    }
    console.log("WorkerSaveContactInfo response || ", response)
    yield call(hideSpinner)
  } catch (error) {
    console.error(error)
    yield call(hideSpinner)
  }
}

function* WorkerSaveMutualFundAccount() {
  try {
    yield call(showSpinner)
    const { OpeningAccountReducer } = yield select((state: IStore) => state)
    const addressDocs = OpeningAccountReducer.personalInformation.STEP_MAILING_ADDRESS
    const docCode = addressDocs.sameSomeAddress.find((val: { selected: any }) => val.selected)
    const isDocsSameAs = addressDocs.sameSomeAddress.some((val: {
      requireOther: boolean
      selected: boolean
    }) => val.requireOther && val.selected)

    // ADDRESS_SAME_IDENTIFICATION_CARD = "IDENTIFICATION_CARD"
    // ADDRESS_SAME_CURRENT             = "CURRENT"
    // ADDRESS_SAME_WORKPLACE           = "WORKPLACE"
    // ADDRESS_SAME_MAILING             = "MAILING"

    const data = {
      channelId: "RHB",
      mutualFundAccount: {
        mailingMethod: "POST",
        mailingAddressSameAs: docCode.id,
        optionalMailingMethod: "",
        mailingAddress: {
          addressNumber: isDocsSameAs ? addressDocs.houseNumber : "",
          village: "",
          building: isDocsSameAs ? addressDocs.building : "",
          floor: isDocsSameAs ? addressDocs.floor : "",
          room: isDocsSameAs ? addressDocs.roomNumber : "",
          soi: isDocsSameAs ? addressDocs.alley : "",
          road: isDocsSameAs ? addressDocs.road : "",
          moo: isDocsSameAs ? addressDocs.moo : "",
          addressCode: isDocsSameAs ? addressDocs.codeAddress : "",
          subDistrict: isDocsSameAs ? addressDocs.subDistrict : "",
          district: isDocsSameAs ? addressDocs.district : "",
          province: isDocsSameAs ? addressDocs.province : "",
          zipCode: isDocsSameAs ? addressDocs.postalCode : "",
          country: "TH",
        },
      },
    }
    console.log("WorkerSaveMutualFundAccount request || ", data, isDocsSameAs)

    const response: ISaveMutualFundResponse = yield call(SaveMutualFundAccount, data)
    if (response?.errors) {
      yield call(handleStatusError, response?.errors);
      yield call(hideSpinner)
      return
    }

    if (response.data.CreateOrUpdateMutualFundAccount.isSuccess) {
      yield put(navigateNext())
    }
    console.log("WorkerSaveMutualFundAccount response || ", response)
    yield call(hideSpinner)
  } catch (error) {
    console.error(error)
    yield call(hideSpinner)
  }
}

function* WorkerSaveSavingAccountToMutualFund({ payload }: any) {
  try {
    yield call(showSpinner)
    const { OpeningAccountReducer } = yield select((state: IStore) => state)
    const BANK_SUBSCRIPTION = OpeningAccountReducer.accountInformation.STEP_SAVING_ACCOUNT_SUBSCRIPTION
    const BANK_REDEEMTION = OpeningAccountReducer.accountInformation.STEP_SAVING_ACCOUNT_REDEMPTION
    const isRedeemtion = BANK_REDEEMTION.political.some((val: {
      requireOther: boolean
      selected: boolean
    }) => val.requireOther && val.selected)

    const data = {
      channelId: "RHB",
      mutualFundType: "D",
      subscriptionBankAccount: [
        {
          accountName: BANK_SUBSCRIPTION.accountName || "",
          accountNumber: BANK_SUBSCRIPTION.accountNo || "",
          bankCode: BANK_SUBSCRIPTION.bankName || "",
          branchCode: BANK_SUBSCRIPTION.branch || "",
          default: false,
        },
      ],
      redemptionBankAccountSameAs: isRedeemtion ? "OTHER" : "SUBSCRIPTION",
      redemptionBankAccount: [
        {
          accountName: isRedeemtion ? BANK_REDEEMTION.accountName : "",
          accountNumber: isRedeemtion ? BANK_REDEEMTION.accountNo : "",
          bankCode: isRedeemtion ? BANK_REDEEMTION.bankName : "",
          branchCode: isRedeemtion ? BANK_REDEEMTION.branch : "",
        },
      ],
    }

    console.log("WorkerSaveSavingAccountToMutualFund request || ", data)

    const response: ISaveSavingFualFundResponse = yield call(SaveSavingAccountToMutualFund, data)
    if (response?.errors) {
      yield call(handleStatusError, response?.errors);
      yield call(hideSpinner)
      return
    }
    console.log("WorkerSaveSavingAccountToMutualFund response || ", response)

    if (response.data.UpdateSavingAccountToMutualFund.isSuccess) {
      call(payload?.callback)
      // yield put(navigateNext())
    }
    yield call(hideSpinner)
  } catch (error) {
    console.error("error ===>", error)
    yield call(hideSpinner)
  }
}

function* WorkerGetConsent({ payload }: any) {
  try {
    const OpeningAccountReducer: IOpeningAccountState =
      yield select((state: IStore) => state.OpeningAccountReducer)

    const response: IGetConsentResponse = yield call(GetConsentApi, payload?.consentType)

    if (payload?.consentType === IConsentType.NDID) {
      const { acceptTermNDID } = OpeningAccountReducer.connectBank.STEP_PROPERTY_LIST_NDID

      const consents = response.data.GetConsent.consent.map((item, key) => (
        { title: "ฉันได้อ่านและทำความเข้าใจในข้อความทั้งหมดโดยที่ฉันได้ยอมรับและเห็นด้วย", description: item.consentTh, selected: false }
      ))

      console.log("OpeningAccountReducer", consents)

      yield put(setOpenAccountReducer({
        page: "connectBank",
        subPage: ISubPage.STEP_PROPERTY_LIST_NDID,
        addValue: {
          acceptTermNDID: consents,
        },
      }))
    }
  } catch (error) {
    console.error("error ===>", error)
  }
}

function* WorkerSaveConsent({ payload }: any) {
  try {
    yield call(showSpinner)

    const OpeningAccountReducer: IOpeningAccountState =
      yield select((state: IStore) => state.OpeningAccountReducer)
    const { connectBank, termAndConditoin, accountInformation } = OpeningAccountReducer

    let consents: Array<{consentID: number, consentType: string, accept: boolean}> = []

    if (payload?.page === ISubPage.STEP_TERM_CONDITION_PDPA) {
      consents = termAndConditoin?.STEP_TERM_CONDITION_PDPA?.acceptOpenInfo?.map((item: any) => ({
        consentID: item.consentId,
        consentType: IConsentType.PDPA,
        accept: item?.selected,
      }))
    } else if (payload?.page === ISubPage.STEP_TERM_CONDITION_WEB) {
      consents = termAndConditoin?.STEP_TERM_CONDITION_WEB?.acceptTermWebSite?.map((item: any) => ({
        consentID: item.consentId,
        consentType: IConsentType.WEB,
        accept: item?.selected,
      }))
    } else if (payload?.page === ISubPage.STEP_PROPERTY_LIST_NDID) {
      consents = connectBank?.STEP_PROPERTY_LIST_NDID?.acceptTermNDID?.map((item: any) => ({
        consentID: item.consentId,
        consentType: IConsentType.NDID,
        accept: item?.selected,
      }))
    } else if (payload?.page === ISubPage.STEP_TERM_CONDITION_NDID) {
      consents = connectBank?.STEP_TERM_CONDITION_NDID?.acceptTermFATCA?.map((item: any) => ({
        consentID: item.consentId,
        consentType: IConsentType.NDID,
        accept: item?.selected,
      }))
    } else if (payload?.page === ISubPage.STEP_TERM_CONDITION_SAVING_ACCOUNT) {
      consents = accountInformation?.STEP_TERM_CONDITION_SAVING_ACCOUNT?.acceptTermConditionSavingAccount?.map((item: any) => ({
        consentID: item.consentId,
        consentType: IConsentType.SAVING_ACCOUNT,
        accept: item?.selected,
      }))
    } else if (payload?.page === ISubPage.STEP_TERM_CONDITION_EQUITY_AND_MUTUAL_FUND) {
      consents = termAndConditoin?.STEP_TERM_CONDITION_EQUITY_AND_MUTUAL_FUND?.EQUITY_AND_MUTUAL_FUND?.map((item: any) => ({
        consentID: item.consentId,
        consentType: IConsentType.EQUITY_AND_MUTUAL_FUND,
        accept: item?.selected,
      }))
    } else if (payload?.page === ISubPage.STEP_TERM_CONDITION_TFEX) {
      consents = termAndConditoin?.STEP_TERM_CONDITION_TFEX?.TFEX?.map((item: any) => ({
        consentID: item.consentId,
        consentType: IConsentType.TFEX,
        accept: item?.selected,
      }))
    } else if (payload?.page === ISubPage.STEP_TERM_CONDITION_CONSENT_CLAUSE_OF_RETAIL_CUSTOMER) {
      consents = termAndConditoin?.STEP_TERM_CONDITION_CONSENT_CLAUSE_OF_RETAIL_CUSTOMER?.CONSENT_CLAUSE_OF_RETAIL_CUSTOMER?.map((item: any) => ({
        consentID: item.consentId,
        consentType: IConsentType.CONSENT_CLAUSE_OF_RETAIL_CUSTOMER,
        accept: item?.selected,
      }))
    } else if (payload?.page === ISubPage.STEP_TERM_CONDITION_CREDIT_BALANCE_ACCOUNT) {
      consents = termAndConditoin?.STEP_TERM_CONDITION_CREDIT_BALANCE_ACCOUNT?.CREDIT_BALANCE_ACCOUNT?.map((item: any) => ({
        consentID: item.consentId,
        consentType: IConsentType.CREDIT_BALANCE_ACCOUNT,
        accept: item?.selected,
      }))
    } else if (payload?.page === ISubPage.STEP_TERM_CONDITION_TFEX_MOU_BLOCK_TRADE) {
      consents = termAndConditoin?.STEP_TERM_CONDITION_TFEX_MOU_BLOCK_TRADE?.TFEX_MOU_BLOCK_TRADE?.map((item: any) => ({
        consentID: item.consentId,
        consentType: IConsentType.TFEX,
        accept: item?.selected,
      }))
    }

    // else if (payload?.page === ISubPage.STEP_TERM_CONDITION_CASH_BALANCE_ACCOUNT) {
    //   consents = termAndConditoin?.STEP_TERM_CONDITION_CASH_BALANCE_ACCOUNT?.CASH_BALANCE_ACCOUNT?.map((item: any) => ({
    //     consentID: item.consentId,
    //     consentType: IConsentType.CASH_BALANCE_ACCOUNT,
    //     accept: item?.selected,
    //   }))
    // }
    // else if (payload?.page === ISubPage.STEP_TERM_CONDITION_MUTUAL_FUND_ACCOUNT) {
    //   consents = termAndConditoin?.STEP_TERM_CONDITION_MUTUAL_FUND_ACCOUNT?.MUTUAL_FUND_ACCOUNT?.map((item: any) => ({
    //     consentID: item.consentId,
    //     consentType: IConsentType.MUTUAL_FUND_ACCOUNT,
    //     accept: item?.selected,
    //   }))
    // }

    const response: ISaveConsentResponse = yield call(SaveConsentApi, consents)

    if (response?.errors) {
      yield call(handleStatusError, response?.errors);
      yield call(hideSpinner)
      return
    }
    yield call(hideSpinner)
  } catch (error) {
    console.error("error ===>", error)
    yield call(hideSpinner)
  }
}

function* HandleSuitAndTfex() {
  try {
    const {
      OpeningAccountReducer,
      StepsReducer,
    } = yield select((state: IStore) => state)
    const response: {[key:string]: any} = yield call(GetQueryAllDataApi)

    if (response?.errors) {
      yield call(handleStatusError, response?.errors);
      return
    }

    let dataTfexStep = []

    const GetSuitability = response.data?.GetOverviewInfo?.suitabilityTestInfo
    const SuitabilityQuestion = response.data?.SuitabilityQuestion
    const GetTfexsQuestionApi = response.data?.GetTfexQuestion
    const GetResultTfexTest = response.data?.GetOverviewInfo?.tfexTestInfo
    const tfexQuestionaire = OpeningAccountReducer?.tfexQuestionaire
    const suitability = OpeningAccountReducer?.suitability

    /// SUIT and TFEX Question query
    const CreateQuestionSuitAndTfexWithQuery = StepsReducer.data.map((val: any) => {
      // SUIT Question
      if (val.type === "suitability") {
        if (SuitabilityQuestion?.length > 0) {
          return {
            ...val,
            form: [
              {
                title: "แบบประเมินความเหมาะสมในการลงทุนสำหรับบุคคลธรรมดา",
                subTitles: [
                  "คำถามข้อ 1-10 ใช้เพื่อความเหมาะสมในการลงทุน",
                  "คำถามข้อ 11-12 ใช้เป็นข้อมูลเพิ่มเติมเพื่อประกอบการให้คำแนะนำ (ไม่นำมาคิดคะแนน)",
                ],
                type: ISubPage.STEP_SUITABILITY_TEST,
              },
              ...SuitabilityQuestion?.map((item: any, i: number) => ({
                title: item.question.th,
                type: `answer${(i + 1).toString().padStart(2, "00")}`,
                inputs: item.choices.map((choi: any) => ({
                  title: choi.label.th,
                  field: "answer",
                  image: item.questionImage,
                  type: item.answerType === "RADIO" ? "inputChoiceSingle" : "inputChoiceMulti",
                  selected: suitability[`answer${(i + 1).toString().padStart(2, "00")}`].answer.some((ans: string) => ans === choi.label.th),
                })),
              })),
              {
                title: "ผลการประเมินความเสี่ยง",
                type: ISubPage.STEP_SUITABILITY_SUMMARY_TEST,
                inputs: [
                  { title: "ข้าพเจ้าได้ตรวจสอบข้อมูลแล้วว่าถูกต้อง", type: InputEnum.inputCheckbox, field: "acceptSuitabilityResult" },
                ],
              },
            ],
          }
        }
      }

      // TFEX Question
      if (val.type === "tfexQuestionaire" && GetResultTfexTest) {
        const TfexAnswer = Object.keys(GetResultTfexTest)
          .filter((key) => key.includes("answer"))
          .map((key) => ({
            [key]: { answer: Array.isArray(GetResultTfexTest[key]) ? GetResultTfexTest[key] : [GetResultTfexTest[key]] },
          }))

        const resultTfex = Object.assign({}, ...TfexAnswer.map((resultTfexResponse, i) => ({
          ...resultTfexResponse,
          [`answer${(i + 1).toString().padStart(2, "00")}`]: {
            answer: TfexAnswer[i][`answer${(i + 1).toString().padStart(2, "00")}`]?.answer.toString(),
          },
        })))

        return {
          ...val,
          form: [
            {
              title: "แบบทดสอบความรู้ความเข้าใจในการลงทุนตราสารอนุพันธุ์",
              subTitles: [
                "คำถามข้อ 1-20 ใช้เพื่อประเมินความเหมาะสมในการลงทุน",
              ],
              type: ISubPage.STEP_TFEX_PRE_TEST,
            },
            ...GetTfexsQuestionApi?.map((item: any, i: number) => ({
              title: item.questionTh,
              type: `answer${(i + 1).toString().padStart(2, "00")}`,
              inputs: ["ใช่", "ไม่ใช่"].map((choice, key) => ({
                title: choice,
                field: "answer",
                type: "inputChoiceSingle",
                selected: +resultTfex[`answer${(i + 1).toString().padStart(2, "00")}`]?.answer?.toString() - 1 === key,
              })),
            })),
          ],
        }
      }
      return val
    })
    yield put(navigateSetWithKey({ data: CreateQuestionSuitAndTfexWithQuery }))

    // SUIT Answer
    if (GetSuitability) {
      yield put(setPreviewScore(GetSuitability))

      const SuitAnswer = Object.keys(GetSuitability)
        .filter((key) => key.includes("answer"))
        .map((key) => ({
          [key]: { answer: Array.isArray(GetSuitability[key]) ? GetSuitability[key] : [GetSuitability[key]] },
        }))

      const suitChoice = SuitabilityQuestion?.map((val: { choices: any }, i: number) => {
        const answer = SuitAnswer[i][`answer${(i + 1).toString().padStart(2, "00")}`]?.answer.toString()
        const isArr = answer.split(",").length > 1
        return val.choices.map((choice: { value: number, label: { th: string} }) => {
          if (isArr) return answer.split(",").map((arrSplit: number) => +arrSplit === choice.value && choice.label.th).filter((fil: any) => fil).toString()
          return +answer === choice.value && choice.label.th
        }).filter((fil: any) => fil)
      })

      // console.log("TEST SAGA", suitChoiceImage)
      const resultSuit = Object.assign({}, ...SuitAnswer.map((val, i) => ({
        ...val,
        [`answer${(i + 1).toString().padStart(2, "00")}`]: {
          answer: suitChoice[i],
        },
      })))

      yield put(setOpenAccountWithPageReducer({
        page: IPageOpenAccountOptionEnum.suitability,
        addValue: {
          ...resultSuit,
          STEP_SUITABILITY_SUMMARY_TEST: {
            acceptSuitabilityResult: [
              {
                title: "ข้าพเจ้าได้ตรวจสอบข้อมูลแล้วว่าถูกต้อง",
                selected: false,
              },
            ],
          },
        },
      }))
    }

    // TFEX Answer
    if (GetResultTfexTest) {
      const TfexAnswer = Object.keys(GetResultTfexTest)
        .filter((key) => key.includes("answer"))
        .map((key) => ({
          [key]: { answer: Array.isArray(GetResultTfexTest[key]) ? GetResultTfexTest[key] : [GetResultTfexTest[key]] },
        }))

      const resultTfex = Object.assign({}, ...TfexAnswer.map((resultTfexResponse, i) => ({
        ...resultTfexResponse,
        [`answer${(i + 1).toString().padStart(2, "00")}`]: {
          answer: TfexAnswer[i][`answer${(i + 1).toString().padStart(2, "00")}`]?.answer.toString(),
        },
      })))

      dataTfexStep = [
        {
          title: "แบบทดสอบความรู้ความเข้าใจในการลงทุนตราสารอนุพันธุ์",
          subTitles: [
            "คำถามข้อ 1-20 ใช้เพื่อความประเมินเหมาะสมในการลงทุน",
          ],
          type: ISubPage.STEP_TFEX_TEST,
        },
        ...GetTfexsQuestionApi?.map((item: any, i: number) => ({
          title: item.questionTh,
          type: `answer${(i + 1).toString().padStart(2, "00")}`,
          inputs: ["ใช่", "ไม่ใช่"].map((choice, key) => ({
            title: choice,
            field: "answer",
            type: "inputChoiceSingle",
            selected: +resultTfex[`answer${(i + 1).toString().padStart(2, "00")}`]?.answer?.toString() - 1 === key,
          })),
        })),
      ]
      yield put(setOpenAccountWithPageReducer({
        page: IPageOpenAccountOptionEnum.tfexQuestionaire,
        addValue: {
          ...resultTfex,
        },
      }))
    }

    /// SUIT and TFEX Question none query
    const CreateQuestionSuitAndTfexNoneQuery = StepsReducer.data.map((val: any) => {
      // add sutit preview page
      if (val.type === "suitability") {
        if (SuitabilityQuestion.length > 0) {
          return {
            ...val,
            form: [
              {
                title: "แบบประเมินความเหมาะสมในการลงทุนสำหรับบุคคลธรรมดา",
                subTitles: [
                  "คำถามข้อ 1-10 ใช้เพื่อความเหมาะสมในการลงทุน",
                  "คำถามข้อ 11-12 ใช้เป็นข้อมูลเพิ่มเติมเพื่อประกอบการให้คำแนะนำ (ไม่นำมาคิดคะแนน)",
                ],
                type: ISubPage.STEP_SUITABILITY_TEST,
              },
              ...SuitabilityQuestion?.map((item: any, i: number) => ({
                title: item.question.th,
                type: `answer${(i + 1).toString().padStart(2, "00")}`,
                inputs: item.choices.map((choi: any) => ({
                  title: choi.label.th,
                  field: "answer",
                  image: item.questionImage,
                  type: item.answerType === "RADIO" ? "inputChoiceSingle" : "inputChoiceMulti",
                  selected: suitability[`answer${(i + 1).toString().padStart(2, "00")}`].answer.some((ans: string) => ans === choi.label.th),
                })),
              })),
              {
                title: "ผลการประเมินความเสี่ยง",
                type: ISubPage.STEP_SUITABILITY_SUMMARY_TEST,
                inputs: [
                  { title: "ข้าพเจ้าได้ตรวจสอบข้อมูลแล้วว่าถูกต้อง", type: InputEnum.inputCheckbox, field: "acceptSuitabilityResult" },
                ],
              },
            ],
          }
        }
      }

      if (val.type === IPageOpenAccountOptionEnum.tfexQuestionaire) {
        return {
          ...val,
          form: [
            {
              title: "แบบทดสอบความรู้ความเข้าใจในการลงทุนตราสารอนุพันธุ์",
              subTitles: [
                "คำถามข้อ 1-20 ใช้เพื่อประเมินความเหมาะสมในการลงทุน",
              ],
              type: ISubPage.STEP_TFEX_PRE_TEST,
            },
            ...GetTfexsQuestionApi?.map((item: any, i: number) => ({
              title: item.questionTh,
              type: `answer${(i + 1).toString().padStart(2, "00")}`,
              inputs: ["ใช่", "ไม่ใช่"].map((choice) => ({
                title: choice,
                field: "answer",
                type: "inputChoiceSingle",
                selected: GetTfexsQuestionApi[`answer${(i + 1).toString().padStart(2, "00")}`]?.answer?.some((ans: string) => ans === choice),
              })),
            })),
          ],
        }
      }
      return val
    })

    dataTfexStep = [
      {
        title: "แบบทดสอบความรู้ความเข้าใจในการลงทุนตราสารอนุพันธุ์",
        subTitles: [
          "คำถามข้อ 1-20 ใช้เพื่อความประเมินเหมาะสมในการลงทุน",
        ],
        type: ISubPage.STEP_TFEX_TEST,
      },
      ...GetTfexsQuestionApi?.map((item: any, i: number) => ({
        title: item.questionTh,
        type: `answer${(i + 1).toString().padStart(2, "00")}`,
        inputs: ["ใช่", "ไม่ใช่"].map((choice) => ({
          title: choice,
          field: "answer",
          type: InputEnum.inputChoiceSingle,
          selected: GetTfexsQuestionApi[`answer${(i + 1).toString().padStart(2, "00")}`]?.answer?.some((ans: string) => ans === choice),
        })),
      })),
    ]

    yield put(navigateSetWithKey({ data: CreateQuestionSuitAndTfexNoneQuery }))
    if (GetResultTfexTest) {
      yield put(setOpenAccountWithPageReducer({
        page: IPageOpenAccountOptionEnum.tfexQuestionaire,
        addValue: Object.keys(GetResultTfexTest)
          .filter((key) => key.includes("answer"))
          .map((key) => ({ answer: [GetResultTfexTest[key] === "1" ? "ใช่" : "ไม่ใช่"], question: key }))
          .reduce((obj: any, item: any) => {
            obj[item.question] = item
            delete obj[item.question].question
            return obj
          }, {}),
      }))
    }
    // eslint-disable-next-line consistent-return
    return dataTfexStep
  } catch (error) {
    console.error(error)
  }
}

function* WorkerSaveReferralPerson({ payload }: any) {
  try {
    const { OpeningAccountReducer } = yield select((state: IStore) => state)
    const data = OpeningAccountReducer?.termAndConditoin?.STEP_SELECT_OPEN_TYPE_ACCOUNT;
    const response: ISaveReferralPersonResponse = yield call(
      SaveReferralPersonApi,
      data?.codeMaketingRecomment || "",
      data?.codeIbaRecomment || "",
      data?.generalReferrer || "",
    )
    if (response?.errors) {
      yield call(handleStatusError, response?.errors);
      return
    }
    payload?.callback()
  } catch (error) {
    console.error(error)
  }
}

function* WorkerHandleSelectedAccount(dataIsSeleted: any) {
  try {
    const { data, page } = yield select((state: IStore) => state.StepsReducer)
    const dataTfexStep: {[key: string]: string} = yield call(HandleSuitAndTfex)

    // handle add tfex to main page
    const mainFlow: IOpenAccountShema[] = data.reduce((
      pre: IOpenAccountShema[],
      _curr: IOpenAccountShema,
      _index: number,
      array: IOpenAccountShema[],
    ) => {
      const startForms = array?.slice(0, array?.findIndex(
        (forms: { type: string; }) => forms.type === "accountInformation",
      ) + 1)
      const endForms = array?.slice(array?.findIndex(
        (forms: { type: string; }) => forms.type === "suitability",
      ), array.length)

      const tfex = [
        {
          title: "ตราสารอนุพันธุ์",
          type: "tfexQuestionaire",
          form: dataTfexStep,
        },
      ]

      if (dataIsSeleted.find((dataSelete: { type: string }) => dataSelete.type.includes("TFEX"))) {
        pre = [
          ...startForms,
          ...tfex,
          ...endForms,
        ] as IOpenAccountShema[]
      } else {
        pre = [
          ...startForms,
          ...endForms,
        ] as IOpenAccountShema[]
      }
      return pre
    }, data)

    const termConsentClause = [
      {
        title: "คำยินยอมลูกค้ารายย่อย (Consent Clause of Retail Customer)",
        type: ISubPage.STEP_TERM_CONDITION_CONSENT_CLAUSE_OF_RETAIL_CUSTOMER,
        inputs: [
          {
            type: "inputTermsAndCons",
            field: "CONSENT_CLAUSE_OF_RETAIL_CUSTOMER",
            title: "",
            value: [],
          },
        ],
      },
    ]

    const termTfexMOU = [
      {
        title: "สัญญาแต่งตั้งตัวแทนซื้อขายสัญญาซื้อขายล่วงหน้า",
        type: ISubPage.STEP_TERM_CONDITION_TFEX_MOU_BLOCK_TRADE,
        inputs: [
          {
            type: "inputTermsAndCons",
            field: "TFEX_MOU_BLOCK_TRADE",
            title: "",
            value: [],
          },
        ],
      },
    ]

    // handle add term to subpage
    const resultForms = mainFlow.map((val, ii) => {
      if (ii === 1) {
        const startSubForms = val.form?.slice(0, val.form?.findIndex(
          (forms) => forms.type === ISubPage.STEP_SELECT_OPEN_TYPE_ACCOUNT,
        ) + 1)
        const endSubForms = val.form?.slice(val.form?.findIndex(
          (forms) => forms.type === ISubPage.STEP_VERIFY_IDENTITY,
        ), val.form.length)

        let arr = []

        if (dataIsSeleted.length === 0) {
          arr = [
            ...startSubForms,
            ...dataIsSeleted,
            ...endSubForms,
          ]
        } else if (dataIsSeleted.find((dataSelete: { type: string }) => dataSelete.type.includes("TFEX"))) {
          arr = [
            ...startSubForms,
            ...dataIsSeleted,
            ...termTfexMOU,
            ...termConsentClause,
            ...endSubForms,
          ]
        } else {
          arr = [
            ...startSubForms,
            ...dataIsSeleted,
            ...termConsentClause,
            ...endSubForms,
          ]
        }

        return {
          ...val,
          form: arr,
        }
      }
      return val
    })

    yield put(navigateSetWithKey({ data: resultForms }))
  } catch (error) {
    console.error(error)
  }
}

function* WorkerGetConsents() {
  try {
    const { OpeningAccountReducer } = yield select((state: IStore) => state)

    const pdpa: IGetConsentResponse = yield call(GetConsentApi, IConsentType.PDPA)
    const PDPA_SELECTED: IGetUserConsentResponse = yield call(GetSelectConsentApi, IConsentType.PDPA)
    const web: IGetConsentResponse = yield call(GetConsentApi, IConsentType.WEB)
    const WEB_SELECTED: IGetUserConsentResponse = yield call(GetSelectConsentApi, IConsentType.WEB)
    const ndid: IGetConsentResponse = yield call(GetConsentApi, IConsentType.NDID)
    const NDID_SELECTED: IGetUserConsentResponse = yield call(GetSelectConsentApi, IConsentType.NDID)
    const EQUITY_AND_MUTUAL_FUND: IGetConsentResponse = yield call(GetConsentApi, IConsentType.EQUITY_AND_MUTUAL_FUND)
    const CASH_ACCOUNT_SELECTED: IGetUserConsentResponse = yield call(GetSelectConsentApi, IConsentType.EQUITY_AND_MUTUAL_FUND)
    // const CASH_BALANCE_ACCOUNT: IGetConsentResponse = yield call(GetConsentApi, IConsentType.CASH_BALANCE_ACCOUNT)
    // const CASH_BALANCE_ACCOUNT_SELECTED: IGetUserConsentResponse = yield call(GetSelectConsentApi, IConsentType.CASH_BALANCE_ACCOUNT)
    // const MUTUAL_FUND_ACCOUNT: IGetConsentResponse = yield call(GetConsentApi, IConsentType.MUTUAL_FUND_ACCOUNT)
    // const MUTUAL_FUND_ACCOUNT_SELECTED: IGetUserConsentResponse = yield call(GetSelectConsentApi, IConsentType.MUTUAL_FUND_ACCOUNT)
    const CREDIT_BALANCE_ACCOUNT: IGetConsentResponse = yield call(GetConsentApi, IConsentType.CREDIT_BALANCE_ACCOUNT)
    const CREDIT_BALANCE_ACCOUNT_SELECTED: IGetUserConsentResponse = yield call(GetSelectConsentApi, IConsentType.CREDIT_BALANCE_ACCOUNT)
    const TFEX: IGetConsentResponse = yield call(GetConsentApi, IConsentType.TFEX)
    const TFEX_SELECTED: IGetUserConsentResponse = yield call(GetSelectConsentApi, IConsentType.TFEX)
    const savingAccount: IGetConsentResponse = yield call(GetConsentApi, IConsentType.SAVING_ACCOUNT)
    const SAVING_ACCOUNT_SELECTED: IGetUserConsentResponse = yield call(GetSelectConsentApi, IConsentType.SAVING_ACCOUNT)
    const CONSENT_CLAUSE_OF_RETAIL_CUSTOMER: IGetConsentResponse = yield call(GetConsentApi, IConsentType.CONSENT_CLAUSE_OF_RETAIL_CUSTOMER)
    const CONSENT_CLAUSE_OF_RETAIL_CUSTOMER_SELECTED: IGetUserConsentResponse = yield call(GetSelectConsentApi, IConsentType.CONSENT_CLAUSE_OF_RETAIL_CUSTOMER)
    const ndids = ndid.data.GetConsent.consent.map((item, key) => (
      {
        title: "ฉันได้อ่านและทำความเข้าใจในข้อความทั้งหมดโดยที่ฉันได้ยอมรับและเห็นด้วย", description: item.consentTh, selected: false, consentId: item.consentId,
      }
    ))

    const tfex = TFEX.data?.GetConsent?.consent?.map((item, key) => (
      {
        title: "ฉันได้อ่านและทำความเข้าใจในข้อความทั้งหมดโดยที่ฉันได้ยอมรับและเห็นด้วย", description: item.consentTh, selected: false, consentId: item.consentId,
      }
    ))

    yield put(setOpenAccountWithPageReducer({
      page: "connectBank",
      addValue: {
        ...OpeningAccountReducer.connectBank,
        STEP_PROPERTY_LIST_NDID: {
          acceptTermNDID: [ndids?.[0]],
        },
        STEP_TERM_CONDITION_NDID: {
          acceptTermFATCA: [ndids?.[1]],
        },
      },
    }))

    yield put(setOpenAccountWithPageReducer({
      page: "accountInformation",
      addValue: {
        ...OpeningAccountReducer.accountInformation,
        STEP_TERM_CONDITION_SAVING_ACCOUNT: {
          acceptTermConditionSavingAccount: savingAccount.data?.GetConsent?.consent?.map((item, key) => (
            {
              title: "ฉันได้อ่านและทำความเข้าใจในข้อความทั้งหมดโดยที่ฉันได้ยอมรับและเห็นด้วย", description: item.consentTh, selected: false, consentId: item.consentId,
            }
          )),
        },
      },
    }))

    yield put(setOpenAccountWithPageReducer({
      page: "termAndConditoin",
      addValue: {
        ...OpeningAccountReducer.termAndConditoin,
        STEP_TERM_CONDITION_PDPA: {
          acceptOpenInfo: pdpa.data?.GetConsent?.consent?.map((item, key) => (
            {
              title: item.labelTitleTh, subTitle: item.labelSubTh, selected: PDPA_SELECTED.data?.GetUserConsent?.consent?.[key]?.accept || false, consentId: item.consentId,
            }
          )),
        },
        STEP_TERM_CONDITION_WEB: {
          acceptTermWebSite: web.data?.GetConsent?.consent?.map((item, key) => (
            {
              title: "ฉันได้อ่านและทำความเข้าใจในข้อความทั้งหมดโดยที่ฉันได้ยอมรับและเห็นด้วย", description: item.consentTh, selected: false, consentId: item.consentId,
            }
          )),
        },
        STEP_TERM_CONDITION_EQUITY_AND_MUTUAL_FUND: {
          EQUITY_AND_MUTUAL_FUND: EQUITY_AND_MUTUAL_FUND.data?.GetConsent?.consent?.map((item, key) => (
            {
              title: "ฉันได้อ่านและทำความเข้าใจในข้อความทั้งหมดโดยที่ฉันได้ยอมรับและเห็นด้วย", description: item.consentTh, selected: false, consentId: item.consentId,
            }
          )),
        },
        // STEP_TERM_CONDITION_CASH_BALANCE_ACCOUNT: {
        //   CASH_BALANCE_ACCOUNT: CASH_BALANCE_ACCOUNT.data?.GetConsent?.consent?.map((item, key) => (
        //     {
        //       title: "ฉันได้อ่านและทำความเข้าใจในข้อความทั้งหมดโดยที่ฉันได้ยอมรับและเห็นด้วย", description: item.consentTh, selected: false, consentId: item.consentId,
        //     }
        //   )),
        // },
        // STEP_TERM_CONDITION_MUTUAL_FUND_ACCOUNT: {
        //   MUTUAL_FUND_ACCOUNT: MUTUAL_FUND_ACCOUNT.data?.GetConsent?.consent?.map((item, key) => (
        //     {
        //       title: "ฉันได้อ่านและทำความเข้าใจในข้อความทั้งหมดโดยที่ฉันได้ยอมรับและเห็นด้วย", description: item.consentTh, selected: false, consentId: item.consentId,
        //     }
        //   )),
        // },
        STEP_TERM_CONDITION_CREDIT_BALANCE_ACCOUNT: {
          CREDIT_BALANCE_ACCOUNT: CREDIT_BALANCE_ACCOUNT.data?.GetConsent?.consent?.map((item, key) => (
            {
              title: "ฉันได้อ่านและทำความเข้าใจในข้อความทั้งหมดโดยที่ฉันได้ยอมรับและเห็นด้วย", description: item.consentTh, selected: false, consentId: item.consentId,
            }
          )),
        },
        STEP_TERM_CONDITION_TFEX: {
          TFEX: [tfex?.[0]],
        },
        STEP_TERM_CONDITION_TFEX_MOU_BLOCK_TRADE: {
          TFEX_MOU_BLOCK_TRADE: [tfex?.[1]],
        },
        STEP_TERM_CONDITION_CONSENT_CLAUSE_OF_RETAIL_CUSTOMER: {
          CONSENT_CLAUSE_OF_RETAIL_CUSTOMER: CONSENT_CLAUSE_OF_RETAIL_CUSTOMER.data?.GetConsent?.consent?.map((item, key) => (
            {
              title: "ฉันได้อ่านและทำความเข้าใจในข้อความทั้งหมดโดยที่ฉันได้ยอมรับและเห็นด้วย", description: item.consentTh, selected: false, consentId: item.consentId,
            }
          )),
        },
      },
    }))
  } catch (error) {
    console.error("TEST", error)
  }
}

function* WorkerGetAllQuery() {
  const {
    OpeningAccountReducer,
    SuitReducer,
    TFexReducer,
    NdidReducer,
  } = yield select((state: IStore) => state)
  try {
    yield call(showSpinner)
    const response: {[key:string]: any} = yield call(GetQueryAllDataApi)
    const responseMaster: IGetQueryAllMasterResponse = yield call(GetAllMasterDataApi)
    const responseImage: {[key:string]: any} = yield call(GetAllImage)
    const { page, data } = yield select((state: IStore) => state.StepsReducer)

    if (response?.errors) {
      yield call(hideSpinner)
      yield call(handleStatusError, response?.errors);
      return
    }
    if (responseMaster?.errors) {
      yield call(hideSpinner)
      yield call(handleStatusError, responseMaster?.errors);
      return
    }
    if (responseImage?.errors) {
      yield call(hideSpinner)
      yield call(handleStatusError, responseImage?.errors);
      return
    }

    const birthplace = response.data?.GetOverviewInfo?.birthplace
    let taxResidences = response.data?.GetOverviewInfo?.taxResidences
    const GetPersonalInfo = response.data?.GetOverviewInfo?.personalInfo
    const GetMutualFundAccount = response.data?.GetOverviewInfo?.mutualFundAccount
    const GetContactInfo = response.data?.GetOverviewInfo?.contactInfo
    const GetBackgroundInfo = response.data?.GetOverviewInfo?.backgroundInfo
    const GetPersonalContacts = response.data?.GetPersonalContacts
    const GetCustomerInfo = response.data?.GetOverviewInfo?.customerInfo
    const GetPepInfo = response.data?.GetPep
    const GetImageApi = responseImage.data?.GetImageDocument

    const mGetBusinessType = responseMaster.data?.GetBusinessType
    const mGetCareer = responseMaster.data?.GetCareer
    const mGetCountry = responseMaster.data?.GetCountry
    const mGetInvestmentPurpose = responseMaster.data?.GetInvestmentPurpose
    const mGetMonthlyIncome = responseMaster.data?.GetMonthlyIncome
    const mGetSourceOfIncome = responseMaster.data?.GetSourceOfIncome
    const mGetTitle = responseMaster.data?.GetTitle

    const getCareer = mGetCareer?.find((career) => GetBackgroundInfo?.occupation === career?.id)
    const getBussiness = mGetBusinessType?.find((bu) => GetBackgroundInfo?.businessType === bu?.id)
    const personalInfo = OpeningAccountReducer?.personalInformation
    const accountInformation = OpeningAccountReducer?.accountInformation
    const Suit = SuitReducer
    const TFex = TFexReducer

    if (taxResidences.length === 0) {
      taxResidences = [
        {
          id: "",
          countryCode: "",
          countryName: "",
          tin: "",
          noTinReasonId: "",
          noTinExplanation: "",
        },
      ]
    }
    yield put(setOpenAccountReducer({
      page: "termAndConditoin",
      subPage: ISubPage.STEP_VERIFY_FATCA,
      addValue: {
        isAmerican: GetBackgroundInfo?.isFatca || false,
        birthplace,
        taxResidences,
        hasTaxResidencesOtherThanUsaOrThailand:
          GetBackgroundInfo?.hasTaxResidencesOtherThanUsaOrThailand || false,
      },
    }))

    const sourceOfIncome = mGetSourceOfIncome?.map((income) => {
      const backGroundInfo = GetBackgroundInfo?.incomeSource.split(",")?.find((IncomeCode: string) => income.id === IncomeCode)
      const isOther = income.id === "SI000"
      if (backGroundInfo) {
        return {
          ...income,
          title: income.displayTh,
          selected: true,
          requireOther: isOther,
        }
      }
      return {
        ...income,
        title: income.displayTh,
        selected: false,
        requireOther: isOther,
      }
    })

    const salary = mGetMonthlyIncome?.map((salaryItem, index) => {
      const salaryInfo = GetBackgroundInfo?.monthlyIncomeLevel === salaryItem.id
      if (!GetBackgroundInfo) {
        return { ...salaryItem, title: salaryItem.displayTh, selected: index === 0 }
      }
      if (salaryInfo) {
        return { ...salaryItem, title: salaryItem.displayTh, selected: true }
      }
      return { ...salaryItem, title: salaryItem.displayTh, selected: false }
    })

    const purposeOfInvest = mGetInvestmentPurpose?.map((purposeItem) => {
      const purposeInfo = GetMutualFundAccount?.investmentObjective.split(",").find((IncomeCode: string) => purposeItem.id === IncomeCode)
      const isOther = purposeItem.id === "P000"
      if (purposeInfo) {
        return {
          ...purposeItem,
          title: purposeItem.displayTh,
          selected: true,
          requireOther: isOther,
        }
      }
      return {
        ...purposeItem,
        title: purposeItem.displayTh,
        selected: false,
        requireOther: isOther,
      }
    })

    const resultIncome = mGetCountry.find((country) => GetBackgroundInfo
      ?.incomeSourceCountry === country.id)
    const countryOfIncome = personalInfo?.STEP_INCOME?.countryOfIncome?.map((val: any, index: number) => {
      if (
        GetBackgroundInfo &&
        GetBackgroundInfo?.incomeSourceCountry &&
        typeof resultIncome !== "undefined"
      ) {
        return ({
          ...val,
          requireOther: val?.title !== "ประเทศไทย",
          selected: (resultIncome.id === "TH" && val?.title === "ประเทศไทย") ||
            (resultIncome.id !== "TH" && val?.title.includes("ประเทศอื่นๆ")),
        })
      }

      return ({
        ...val,
        selected: index === 0,
      })
    })

    const isFraud = personalInfo?.STEP_FRAUD?.isFraud?.map((val: any) => ({
      ...val,
      requireOther: val?.title !== "ไม่มี",
      selected: !!GetBackgroundInfo?.isFraud || val?.title === "ไม่มี",
    }))
    const spouse = personalInfo?.STEP_MARITAL_STATUS?.isSpouse
    const isSpouse = spouse?.map((val: any) => {
      if (val?.title === "โสด") {
        return ({
          ...val,
          requireOther: false,
          selected: !GetPersonalInfo?.spouse,
        })
      }
      return ({
        ...val,
        requireOther: true,
        selected: !!GetPersonalInfo?.spouse,
      })
    })

    const relatePEP = personalInfo?.STEP_MARITAL_STATUS?.isRelatedPEP
    const isPep = relatePEP?.map((val: any) => ({
      ...val,
      requireOther: val?.title !== "ไม่มี",
      selected: !!GetPersonalInfo?.spouse?.isPep || val?.title === "ไม่มี",
    }))

    const currSameSomeAddress = personalInfo?.STEP_CURRENT_ADDRESS?.isSameHomeAddress
    const isSameHomeAddress = currSameSomeAddress?.map((val: any) => {
      if (val?.title === "ที่อยู่ตามทะเบียนบ้าน") {
        return ({
          ...val,
          requireOther: false,
          selected: !GetContactInfo?.currentAddress,
        })
      }
      return ({
        ...val,
        requireOther: true,
        selected: !!GetContactInfo?.currentAddress,
      })
    })

    const docSameSomeAddress = personalInfo.STEP_MAILING_ADDRESS.sameSomeAddress
    const isSameDocAddress = docSameSomeAddress?.map((val: any, index: number) => {
      if (!GetMutualFundAccount?.mailingAddressSameAs) {
        if (index === 0) {
          return ({
            ...val,
            requireOther: false,
            selected: true,
          })
        }
      }
      if (val?.id === GetMutualFundAccount?.mailingAddressSameAs) {
        return ({
          ...val,
          requireOther: val?.id === "OTHER",
          selected: true,
        })
      }
      return ({
        ...val,
        requireOther: val?.id === "OTHER",
        selected: false,
      })
    })

    const political = accountInformation?.STEP_SAVING_ACCOUNT_REDEMPTION
    if (GetMutualFundAccount?.redemptionBankAccountSameAs === "OTHER") {
      political.political = [
        { title: "ใช้บัญชีธนาคารสำหรับหักเงินเพื่อซื้อหลักทรัพย์", selected: false },
        {
          title: "อื่นๆ (โปรดระบุข้อมูลด้านล่างนี้)",
          requireOther: true,
          selected: true,
        },
      ]
    } else {
      political.political = [
        { title: "ใช้บัญชีธนาคารสำหรับหักเงินเพื่อซื้อหลักทรัพย์", selected: true },
        {
          title: "อื่นๆ (โปรดระบุข้อมูลด้านล่างนี้)",
          requireOther: true,
          selected: false,
        },
      ]
    }

    yield call(HandleSuitAndTfex)

    const STEP_EMERGENCY_CONTACT: any = personalInfo?.STEP_EMERGENCY_CONTACT

    for (let i = 0; i < GetPersonalContacts?.length; i += 1) {
      if (GetPersonalContacts?.[i]?.type === "EMERGENCY") {
        STEP_EMERGENCY_CONTACT.emergencyFirstNameTh = GetPersonalContacts?.[i]?.firstName
        STEP_EMERGENCY_CONTACT.emergencyLastNameTh = GetPersonalContacts?.[i]?.lastName
        STEP_EMERGENCY_CONTACT.emergencyRelate = GetPersonalContacts?.[i]?.relate
        STEP_EMERGENCY_CONTACT.emergencyMobileNumber = GetPersonalContacts?.[i]?.mobileNumber
      } else if (GetPersonalContacts?.[i]?.type === "SPOUSE") {
        STEP_EMERGENCY_CONTACT.spouseFirstNameTh = GetPersonalContacts?.[i]?.firstName
        STEP_EMERGENCY_CONTACT.spouseLastNameTh = GetPersonalContacts?.[i]?.lastName
        STEP_EMERGENCY_CONTACT.spouseMobileNumber = GetPersonalContacts?.[i]?.mobileNumber
      } else if (GetPersonalContacts?.[i]?.type === "RELATE_WITH_EMPLOYEE") {
        STEP_EMERGENCY_CONTACT.rhbFirstNameTh = GetPersonalContacts?.[i]?.firstName
        STEP_EMERGENCY_CONTACT.rhbLastNameTh = GetPersonalContacts?.[i]?.lastName
        STEP_EMERGENCY_CONTACT.rhbRelate = GetPersonalContacts?.[i]?.relate
        STEP_EMERGENCY_CONTACT.rhbMobileNumber = GetPersonalContacts?.[i]?.mobileNumber
      } else if (GetPersonalContacts?.[i]?.type === "FINAL_BENEFICIARY") {
        if (GetPersonalContacts?.[i]?.itHas) {
          STEP_EMERGENCY_CONTACT.beneficialOwner = [
            { title: "ตนเอง", selected: false },
            { title: "มี (โปรดระบุ)", requireOther: true, selected: true },
          ]
        } else {
          STEP_EMERGENCY_CONTACT.beneficialOwner = [
            { title: "ตนเอง", selected: true },
            { title: "มี (โปรดระบุ)", requireOther: true, selected: false },
          ]
        }

        STEP_EMERGENCY_CONTACT.boFirstNameTh = GetPersonalContacts?.[i]?.firstName
        STEP_EMERGENCY_CONTACT.boLastNameTh = GetPersonalContacts?.[i]?.lastName
        STEP_EMERGENCY_CONTACT.boRelate = GetPersonalContacts?.[i]?.relate
        STEP_EMERGENCY_CONTACT.boMobileNumber = GetPersonalContacts?.[i]?.mobileNumber
      } else if (GetPersonalContacts?.[i]?.type === "FINAL_TRANSACTION_AUTHORITY") {
        if (GetPersonalContacts?.[i]?.itHas) {
          STEP_EMERGENCY_CONTACT.controllingPerson = [
            { title: "ตนเอง", selected: false },
            { title: "มี (โปรดระบุ)", requireOther: true, selected: true },
          ]
        } else {
          STEP_EMERGENCY_CONTACT.controllingPerson = [
            { title: "ตนเอง", selected: true },
            { title: "มี (โปรดระบุ)", requireOther: true, selected: false },
          ]
        }

        STEP_EMERGENCY_CONTACT.cpFirstNameTh = GetPersonalContacts?.[i]?.firstName
        STEP_EMERGENCY_CONTACT.cpLastNameTh = GetPersonalContacts?.[i]?.lastName
        STEP_EMERGENCY_CONTACT.cpRelate = GetPersonalContacts?.[i]?.relate
        STEP_EMERGENCY_CONTACT.cpMobileNumber = GetPersonalContacts?.[i]?.mobileNumber
      }
    }

    const marketing: IQueryReferralPersonByIdResponse = yield call(GetReferralPersonByIdApi, GetCustomerInfo?.referralPerson1)
    const ibc: IQueryReferralPersonByIdResponse = yield call(GetReferralPersonByIdApi, GetCustomerInfo?.referralPerson2)

    let marketingName = ""
    let ibcName = ""

    if (marketing?.data?.GetReferralPersonById?.id && marketing?.data?.GetReferralPersonById?.nameTh) {
      marketingName = `${marketing?.data?.GetReferralPersonById?.id} ${marketing?.data?.GetReferralPersonById?.nameTh}`
    }

    if (ibc?.data?.GetReferralPersonById?.id && ibc?.data?.GetReferralPersonById?.nameTh) {
      ibcName = `${ibc?.data?.GetReferralPersonById?.id} ${ibc?.data?.GetReferralPersonById?.nameTh}`
    }

    const selectedAccounts = response.data?.GetOverviewInfo?.selectedAccounts
    const selectedAccountsMap: { [key: string]: boolean } = {}

    for (let i = 0; i < selectedAccounts.length; i += 1) {
      const selectedAccount = selectedAccounts[i]
      selectedAccountsMap[selectedAccount.accountType] = true
    }

    yield put(setOpenAccountReducer({
      page: "termAndConditoin",
      subPage: ISubPage.STEP_SELECT_OPEN_TYPE_ACCOUNT,
      addValue: {
        codeMaketingRecomment: marketing?.data?.GetReferralPersonById?.id || "",
        nameMaketingRecomment: marketingName,
        noCodeMarketingName: marketing?.data?.GetReferralPersonById?.nameTh,
        codeIbaRecomment: ibc?.data?.GetReferralPersonById?.id || "",
        nameIbaRecomment: ibcName,
        generalReferrer: GetCustomerInfo?.generalReferrer,
        selectedAccounts: selectedAccountsMap,
      },
    }))

    yield put(setOpenAccountReducer({
      page: "termAndConditoin",
      subPage: ISubPage.STEP_VERIFY_PEP,
      addValue: {
        isPep: [
          { title: "ไม่ใช่", selected: GetPepInfo?.isPep === false },
          { title: "ใช่ (โปรดระบุ)", requireOther: true, selected: GetPepInfo?.isPep === true },
        ],
        otherPep: GetPepInfo?.isPep === true ? GetPepInfo?.position : "",
      },
    }))

    const imageHouseRegistrations: Array<fileProps> = yield ConvertBase64Tofile(GenFilePropsArray(GetImageApi?.filter((val: { imageType: string }) => val?.imageType === "IMAGE_HOUSE_REGISTRATION")))

    yield put(setOpenAccountWithPageReducer({
      page: "personalInformation",
      addValue: {
        ...OpeningAccountReducer.personalInformation,
        STEP_INCOME: {
          ...OpeningAccountReducer.personalInformation.STEP_INCOME,
          sourceOfIncome,
          otherSourceOfIncome: GetBackgroundInfo?.incomeSourceOther,
          salary,
          purposeOfInvest,
          otherPurposeOfInvest: GetMutualFundAccount?.investmentObjectiveOther,
          propertyValue: GetBackgroundInfo?.assetValue,
          countryOfIncome,
          otherCountryOfIncome: countryOfIncome.find((val: { requireOther: any; selected: any }) => val.requireOther && val.selected) ? resultIncome?.name : "",
          codeCountry: resultIncome?.id || "TH",
        },
        STEP_FRAUD: {
          ...OpeningAccountReducer.personalInformation.STEP_FRAUD,
          isFraud,
          predicateOftence: GetBackgroundInfo?.fundamentallyWrong || "",
          BC: GetBackgroundInfo?.fraudYear || "",
        },
        STEP_MARITAL_STATUS: {
          ...OpeningAccountReducer.personalInformation.STEP_MARITAL_STATUS,
          firstNameTh: GetPersonalInfo?.spouse?.firstNameTh || "",
          lastNameTh: GetPersonalInfo?.spouse?.lastNameTh || "",
          firstNameEn: GetPersonalInfo?.spouse?.firstNameEn || "",
          lastNameEn: GetPersonalInfo?.spouse?.lastNameEn || "",
          isSpouse,
          isRelatedPEP: isPep || "",
          position: GetPersonalInfo?.spouse?.position || "",
          BC: GetPersonalInfo?.spouse?.pepYear || "",
        },
        STEP_ID_CARD_ADDRESS: {
          ...OpeningAccountReducer.personalInformation.STEP_ID_CARD_ADDRESS,
          houseNumber: GetContactInfo?.identificationCardAddress?.addressNumber || "",
          moo: GetContactInfo?.identificationCardAddress?.moo || "",
          building: GetContactInfo?.identificationCardAddress?.building || "",
          roomNumber: GetContactInfo?.identificationCardAddress?.room || "",
          floor: GetContactInfo?.identificationCardAddress?.floor || "",
          alley: GetContactInfo?.identificationCardAddress?.soi || "",
          road: GetContactInfo?.identificationCardAddress?.road || "",
          postalCode: GetContactInfo?.identificationCardAddress?.zipCode || "",
          province: GetContactInfo?.identificationCardAddress?.province || "",
          district: GetContactInfo?.identificationCardAddress?.district || "",
          subDistrict: GetContactInfo?.identificationCardAddress?.subDistrict || "",
          codeAddress: GetContactInfo?.identificationCardAddress?.addressCode || "",
        },
        STEP_CURRENT_ADDRESS: {
          ...OpeningAccountReducer.personalInformation.STEP_CURRENT_ADDRESS,
          isSameHomeAddress,
          houseNumber: GetContactInfo?.currentAddress?.addressNumber || "",
          moo: GetContactInfo?.currentAddress?.moo || "",
          building: GetContactInfo?.currentAddress?.building || "",
          roomNumber: GetContactInfo?.currentAddress?.room || "",
          floor: GetContactInfo?.currentAddress?.floor || "",
          alley: GetContactInfo?.currentAddress?.soi || "",
          road: GetContactInfo?.currentAddress?.road || "",
          postalCode: GetContactInfo?.currentAddress?.zipCode || "",
          province: GetContactInfo?.currentAddress?.province || "",
          district: GetContactInfo?.currentAddress?.district || "",
          subDistrict: GetContactInfo?.currentAddress?.subDistrict || "",
          codeAddress: GetContactInfo?.currentAddress?.addressCode || "",
        },
        STEP_WORKPLACE_ADDRESS: {
          ...OpeningAccountReducer.personalInformation.STEP_WORKPLACE_ADDRESS,
          codeCareer: getCareer?.id || "",
          codeTypeBusiness: getBussiness?.id || "",
          career: getCareer?.displayTh || "",
          position: GetBackgroundInfo?.workPosition || "",
          typeBusiness: getBussiness?.displayTh || "",
          typeBusinessOther: GetBackgroundInfo?.businessTypeOther || "",
          nameWorkplace: GetBackgroundInfo?.companyName || "",
          houseNumber: GetContactInfo?.workplaceAddress?.addressNumber || "",
          moo: GetContactInfo?.workplaceAddress?.moo || "",
          building: GetContactInfo?.workplaceAddress?.building || "",
          roomNumber: GetContactInfo?.workplaceAddress?.room || "",
          floor: GetContactInfo?.workplaceAddress?.floor || "",
          alley: GetContactInfo?.workplaceAddress?.soi || "",
          road: GetContactInfo?.workplaceAddress?.road || "",
          postalCode: GetContactInfo?.workplaceAddress?.zipCode || "",
          province: GetContactInfo?.workplaceAddress?.province || "",
          district: GetContactInfo?.workplaceAddress?.district || "",
          subDistrict: GetContactInfo?.workplaceAddress?.subDistrict || "",
          codeAddress: GetContactInfo?.workplaceAddress?.addressCode || "",
        },
        STEP_MAILING_ADDRESS: {
          ...OpeningAccountReducer.personalInformation.STEP_MAILING_ADDRESS,
          sameSomeAddress: isSameDocAddress,
          houseNumber: GetMutualFundAccount?.mailingAddress?.addressNumber || "",
          moo: GetMutualFundAccount?.mailingAddress?.moo || "",
          building: GetMutualFundAccount?.mailingAddress?.building || "",
          roomNumber: GetMutualFundAccount?.mailingAddress?.room || "",
          floor: GetMutualFundAccount?.mailingAddress?.floor || "",
          alley: GetMutualFundAccount?.mailingAddress?.soi || "",
          road: GetMutualFundAccount?.mailingAddress?.road || "",
          postalCode: GetMutualFundAccount?.mailingAddress?.zipCode || "",
          province: GetMutualFundAccount?.mailingAddress?.province || "",
          district: GetMutualFundAccount?.mailingAddress?.district || "",
          subDistrict: GetMutualFundAccount?.mailingAddress?.subDistrict || "",
          codeAddress: GetMutualFundAccount?.mailingAddress?.addressCode || "",
        },
        STEP_IMAGE_HOUSE_REGISTRATION: {
          IMAGE_HOUSE_REGISTRATION: imageHouseRegistrations,
        },
        STEP_EMERGENCY_CONTACT,
      },
    }))

    const imageBookBankSubscribe: Array<fileProps> = yield ConvertBase64Tofile(GenFilePropsArray(GetImageApi?.filter((val: { imageType: string }) => val?.imageType === "IMAGE_BOOKBANK_SUBSCRIPTION")))
    const imageBookBanRedemption: Array<fileProps> = yield ConvertBase64Tofile(GenFilePropsArray(GetImageApi?.filter((val: { imageType: string }) => val?.imageType === "IMAGE_BOOKBANK_REDEMPTION")))
    const imageStatement: Array<fileProps> = yield ConvertBase64Tofile(GenFilePropsArray(GetImageApi?.filter((val: { imageType: string }) => val?.imageType === "IMAGE_STATEMENT")))

    yield put(setOpenAccountWithPageReducer({
      page: "accountInformation",
      addValue: {
        ...OpeningAccountReducer.accountInformation,
        STEP_SAVING_ACCOUNT_SUBSCRIPTION: {
          accountName: GetMutualFundAccount?.subscriptionBankAccount?.[0]?.accountName || "",
          accountNo: GetMutualFundAccount?.subscriptionBankAccount?.[0]?.accountNumber || "",
          bankName: GetMutualFundAccount?.subscriptionBankAccount?.[0]?.bankCode || "",
          branch: GetMutualFundAccount?.subscriptionBankAccount?.[0]?.branchCode || "",
          IMAGE_BOOKBANK_SUBSCRIPTION: imageBookBankSubscribe,
        },
        STEP_SAVING_ACCOUNT_REDEMPTION: {
          political: political.political,
          accountName: GetMutualFundAccount?.redemptionBankAccountSameAs === "OTHER" ? GetMutualFundAccount?.redemptionBankAccount?.[0]?.accountName : "",
          accountNo: GetMutualFundAccount?.redemptionBankAccountSameAs === "OTHER" ? GetMutualFundAccount?.redemptionBankAccount?.[0]?.accountNumber : "",
          bankName: GetMutualFundAccount?.redemptionBankAccountSameAs === "OTHER" ? GetMutualFundAccount?.redemptionBankAccount?.[0]?.bankCode : "",
          branch: GetMutualFundAccount?.redemptionBankAccountSameAs === "OTHER" ? GetMutualFundAccount?.redemptionBankAccount?.[0]?.branchCode : "",
          IMAGE_BOOKBANK_REDEMPTION: imageBookBanRedemption,
        },
        STEP_IMAGE_STATEMENT: {
          IMAGE_STATEMENT: imageStatement,
        },
      },
    }))

    const imageIdcards: Array<fileProps> = yield ConvertBase64Tofile(GenFilePropsArray(GetImageApi?.filter((val: { imageType: string }) => val?.imageType === "IMAGE_ID_CARD")))
    const imageSelfieWithIdcards: Array<fileProps> = yield ConvertBase64Tofile(GenFilePropsArray(GetImageApi?.filter((val: { imageType: string }) => val?.imageType === "IMAGE_SELFIE_WITH_ID_CARD")))
    yield put(setOpenAccountWithPageReducer({
      page: "idCardInformation",
      addValue: {
        ...OpeningAccountReducer.idCardInformation,
        STEP_IMAGE_ID_CARD: {
          IMAGE_ID_CARD: imageIdcards,
        },
        STEP_IMAGE_SELFIE_WITH_ID_CARD: {
          IMAGE_SELFIE_WITH_ID_CARD: imageSelfieWithIdcards,
        },
      },
    }))

    const imageSignature: Array<fileProps> = yield ConvertBase64Tofile(GenFilePropsArray(GetImageApi?.filter((val: { imageType: string }) => val?.imageType === "IMAGE_SIGNATURE")))

    yield put(setOpenAccountWithPageReducer({
      page: "successFully",
      addValue: {
        ...OpeningAccountReducer.successFully,
        STEP_IMAGE_SIGNATURE: {
          IMAGE_SIGNATURE: imageSignature,
        },
      },
    }))

    // if (page >= 3) {
    if (GetPersonalInfo !== null) {
      yield call(RetrieveDataNDID)
      let getTitleCode: any = {}

      yield WorkerGetTitle({
        payload: {
          callback: (res: IGetTitleResponse) => {
            getTitleCode = res.data.GetTitle.find((val) => val.id === GetPersonalInfo.title)
          },
        },
      })

      yield put(setOpenAccountReducer({
        page: "idCardInformation",
        subPage: "STEP_ID_CARD_INFO",
        addValue: {
          expiryDate: GetPersonalInfo.identificationCardExpiryDate,
          title: getTitleCode.displayTh,
          titleEn: getTitleCode.displayEn,
          firstNameEn: GetPersonalInfo.firstNameEn,
          lastNameEn: GetPersonalInfo.lastNameEn,
          firstNameTh: GetPersonalInfo.firstNameTh,
          lastNameTh: GetPersonalInfo.lastNameTh,
          birthDate: page === data.findIndex((val: any) => val.type === "successFully")
            ? DateFormatProtectSensitive(GetPersonalInfo.birthdate, " ")
            : DateFormatProtectSensitive(NdidReducer.RETRIEVE_DATA_NDID.birthDate, " "),
          gender: page === data.findIndex((val: any) => val.type === "successFully")
            ? GetPersonalInfo.gender
            : NdidReducer.RETRIEVE_DATA_NDID.gender,
        },
      }))
    } else {
      yield call(RetrieveDataNDID)
    }
    // }

    yield WorkerGetConsents()

    const getCurrentStep: IGetAoStepResponse = yield call(WorkerGetStep)
    const fieldName = {
      STEP_TERM_CONDITION_EQUITY_AND_MUTUAL_FUND: {
        stepName: "EQUITY_AND_MUTUAL_FUND",
        stepLabel: "สัญญาประเภทบัญชีของ Equity & Mutual Fund",
      },
      // STEP_TERM_CONDITION_CASH_BALANCE_ACCOUNT: {
      //   stepName: "CASH_BALANCE_ACCOUNT",
      //   stepLabel: "สัญญาประเภทบัญชีของ Equity & Mutual Fund",
      // },
      // STEP_TERM_CONDITION_MUTUAL_FUND_ACCOUNT: {
      //   stepName: "MUTUAL_FUND_ACCOUNT",
      //   stepLabel: "สัญญาประเภทบัญชีของ Equity & Mutual Fund",
      // },
      STEP_TERM_CONDITION_CREDIT_BALANCE_ACCOUNT: {
        stepName: "CREDIT_BALANCE_ACCOUNT",
        stepLabel: "สัญญาประเภทบัญชีของ Credit balance",
      },
      STEP_TERM_CONDITION_SAVING_ACCOUNT: {
        stepName: "SAVING_ACCOUNT",
        stepLabel: "",
      },
      STEP_TERM_CONDITION_TFEX: {
        stepName: "TFEX",
        stepLabel: "สัญญาประเภทบัญชีของ TFEX",
      },
    }
    const result: { title: string; type: string; inputs: { type: string; field: string; title: string; value: never[] }[] }[] = []
    getCurrentStep.data.GetAoStep.step.forEach((val, index) => {
      if (index === 1) {
        val.subStep.forEach((vall, indexx) => {
          if (Object.keys(fieldName).includes(vall.stepName)) {
            const map = {
              title: fieldName[vall.stepName as "STEP_TERM_CONDITION_EQUITY_AND_MUTUAL_FUND"].stepLabel,
              type: vall.stepName,
              inputs: [
                {
                  type: "inputTermsAndCons",
                  field: fieldName[vall.stepName as "STEP_TERM_CONDITION_EQUITY_AND_MUTUAL_FUND"].stepName,
                  title: "",
                  value: [],
                },
              ],
            }
            result.push(map)
          }
        })
      }
    })

    yield WorkerHandleSelectedAccount(result)
    yield WorkerPushToCurrentStep()

    yield call(hideSpinner)
  } catch (error) {
    yield call(hideSpinner)
    console.error("WorkerGetAllQuery ===>", error)
  }
}

function* WorkerSubmitData({ payload }: {payload?:any, type: string}) {
  try {
    const response: ISubmitReponse = yield call(
      SubmitDataApi,
      {} as IVerification,
    )

    if (response?.errors) {
      yield call(hideSpinner)
      yield call(handleStatusError, response?.errors);
      return
    }

    yield call(hideSpinner)
    payload?.()
    yield put(navigateNext())
  } catch (error) {
    yield call(hideSpinner)
    console.error("ERROR", error)
  }
}

function* WorkerGetAccountType() {
  try {
    const { OpeningAccountReducer } = yield select((state: IStore) => state)

    const response: IGetOpenAccount = yield call(GetAccountTypeApi)
    if (response?.errors) {
      yield call(handleStatusError, response?.errors);
      return
    }

    const oldData = OpeningAccountReducer
      .termAndConditoin
      .STEP_SELECT_OPEN_TYPE_ACCOUNT
    yield put(setOpenAccountWithPageReducer({
      page: "termAndConditoin",
      addValue: {
        ...OpeningAccountReducer.termAndConditoin,
        STEP_SELECT_OPEN_TYPE_ACCOUNT: {
          ...oldData,
          selectOpenTypeList: response.data?.GetAccountType?.map((val) => ({
            title: val.typeNameTH,
            subTitle: val.descriptionTH,
            id: val.typeID,
            consentType: val.consentType,
            selected: oldData.selectedAccounts?.[val.typeID],
          })),
        },
      },
    }))
  } catch (error) {
    console.error(error)
  }
}

function* WorkerSaveSelectedAccountApi() {
  try {
    yield call(showSpinner)
    const { OpeningAccountReducer } = yield select((state: IStore) => state)
    const term = OpeningAccountReducer?.termAndConditoin

    const response: ISelectedAccountReponse = yield call(SaveSelectedAccountApi, {
      accountTypes: term.STEP_SELECT_OPEN_TYPE_ACCOUNT?.selectOpenTypeList
        ?.filter((val: { selected: boolean }) => val.selected)
        ?.map((val: { id: string }) => val.id),
    })

    const selectedAccounts = term.STEP_SELECT_OPEN_TYPE_ACCOUNT?.selectOpenTypeList
    const selectedAccountsMap: { [key: string]: boolean } = {}

    for (let i = 0; i < selectedAccounts.length; i += 1) {
      const selectedAccount = selectedAccounts[i]
      selectedAccountsMap[selectedAccount.id] = selectedAccount.selected
    }

    yield put(setOpenAccountReducer({
      page: "termAndConditoin",
      subPage: ISubPage.STEP_SELECT_OPEN_TYPE_ACCOUNT,
      addValue: {
        selectedAccounts: selectedAccountsMap,
      },
    }))

    yield call(WorkerGetConsents)
    if (response?.errors) {
      yield call(hideSpinner)
      yield call(handleStatusError, response?.errors);
      return
    }

    const stackName = {
      CASH_ACCOUNT: {
        stepName: "STEP_TERM_CONDITION_EQUITY_AND_MUTUAL_FUND",
        stepField: "EQUITY_AND_MUTUAL_FUND",
        stepLabel: "สัญญาประเภทบัญชีของ Equity & Mutual Fund",
      },
      CASH_BALANCE_ACCOUNT: {
        stepName: "STEP_TERM_CONDITION_EQUITY_AND_MUTUAL_FUND",
        stepField: "EQUITY_AND_MUTUAL_FUND",
        stepLabel: "สัญญาประเภทบัญชีของ Equity & Mutual Fund",
      },
      MUTUAL_FUND_ACCOUNT: {
        stepName: "STEP_TERM_CONDITION_EQUITY_AND_MUTUAL_FUND",
        stepField: "EQUITY_AND_MUTUAL_FUND",
        stepLabel: "สัญญาประเภทบัญชีของ Equity & Mutual Fund",
      },
      CREDIT_BALANCE_ACCOUNT: {
        stepName: "STEP_TERM_CONDITION_CREDIT_BALANCE_ACCOUNT",
        stepField: "CREDIT_BALANCE_ACCOUNT",
        stepLabel: "สัญญาประเภทบัญชีของ Credit balance",
      },
      SAVING_ACCOUNT: {
        stepName: "STEP_TERM_CONDITION_SAVING_ACCOUNT",
        stepField: "SAVING_ACCOUNT",
        stepLabel: "",
      },
      TFEX: {
        stepName: "STEP_TERM_CONDITION_TFEX",
        stepField: "TFEX",
        stepLabel: "สัญญาประเภทบัญชีของ TFEX",
      },
    }

    const dataIsSeleted = term.STEP_SELECT_OPEN_TYPE_ACCOUNT?.selectOpenTypeList

    const mapDataSame = dataIsSeleted.reduce((pre: any, curr: any, index: any, arr: any) => {
      if (curr.consentType === "EQUITY_AND_MUTUAL_FUND") {
        pre.arrSame.push(curr)
        if (curr.selected) {
          pre.selectedIndex = pre.arrSame.map((val: any) => val.selected).lastIndexOf(true)
        }
        return pre
      }
      pre.arr.push(curr)
      return pre
    }, {
      selectedIndex: 0,
      arrSame: [] as any[],
      arr: [] as any[],
    })

    const analyData = [mapDataSame.arrSame[mapDataSame.selectedIndex]].concat(mapDataSame.arr)

    // find item is selected from require to show
    const dataIsSeletedMap = analyData
      .filter((val: { selected: boolean }) => val.selected)
      .map((val: { id: string }) => ({
        title: stackName[val.id as "TFEX" | "CASH_BALANCE_ACCOUNT"].stepLabel,
        type: stackName[val.id as "TFEX" | "CASH_BALANCE_ACCOUNT"].stepName,
        inputs: [
          {
            type: "inputTermsAndCons",
            field: stackName[val.id as "TFEX" | "CASH_BALANCE_ACCOUNT"].stepField,
            title: "",
            value: [],
          },
        ],
      }))

    yield WorkerHandleSelectedAccount(dataIsSeletedMap)
    yield WorkerGetStep()
    yield put(navigateNext())
    yield call(hideSpinner)
  } catch (error) {
    yield call(hideSpinner)
    console.error(error)
  }
}

function* WorkerSavePersonalContacts() {
  try {
    yield call(showSpinner)
    const { OpeningAccountReducer } = yield select((state: IStore) => state)
    const contacts = []
    contacts.push({
      itHas: true,
      firstName: OpeningAccountReducer?.personalInformation?.STEP_EMERGENCY_CONTACT?.emergencyFirstNameTh,
      lastName: OpeningAccountReducer?.personalInformation?.STEP_EMERGENCY_CONTACT?.emergencyLastNameTh,
      relate: OpeningAccountReducer?.personalInformation?.STEP_EMERGENCY_CONTACT?.emergencyRelate,
      mobileNumber: OpeningAccountReducer?.personalInformation?.STEP_EMERGENCY_CONTACT?.emergencyMobileNumber,
      type: "EMERGENCY",
    })

    const spouseItHas = !!(OpeningAccountReducer?.personalInformation?.STEP_EMERGENCY_CONTACT?.spouseFirstNameTh ||
    OpeningAccountReducer?.personalInformation?.STEP_EMERGENCY_CONTACT?.spouseLastNameTh ||
    OpeningAccountReducer?.personalInformation?.STEP_EMERGENCY_CONTACT?.spouseMobileNumber)

    contacts.push({
      itHas: spouseItHas,
      firstName: OpeningAccountReducer?.personalInformation?.STEP_EMERGENCY_CONTACT?.spouseFirstNameTh,
      lastName: OpeningAccountReducer?.personalInformation?.STEP_EMERGENCY_CONTACT?.spouseLastNameTh,
      relate: "",
      mobileNumber: OpeningAccountReducer?.personalInformation?.STEP_EMERGENCY_CONTACT?.spouseMobileNumber,
      type: "SPOUSE",
    })

    const rhbItHas = !!(OpeningAccountReducer?.personalInformation?.STEP_EMERGENCY_CONTACT?.rhbFirstNameTh ||
    OpeningAccountReducer?.personalInformation?.STEP_EMERGENCY_CONTACT?.rhbLastNameTh ||
    OpeningAccountReducer?.personalInformation?.STEP_EMERGENCY_CONTACT?.rhbRelate ||
    OpeningAccountReducer?.personalInformation?.STEP_EMERGENCY_CONTACT?.rhbMobileNumber)

    contacts.push({
      itHas: rhbItHas,
      firstName: OpeningAccountReducer?.personalInformation?.STEP_EMERGENCY_CONTACT?.rhbFirstNameTh,
      lastName: OpeningAccountReducer?.personalInformation?.STEP_EMERGENCY_CONTACT?.rhbLastNameTh,
      relate: OpeningAccountReducer?.personalInformation?.STEP_EMERGENCY_CONTACT?.rhbRelate,
      mobileNumber: OpeningAccountReducer?.personalInformation?.STEP_EMERGENCY_CONTACT?.rhbMobileNumber,
      type: "RELATE_WITH_EMPLOYEE",
    })

    if (OpeningAccountReducer?.personalInformation?.STEP_EMERGENCY_CONTACT?.beneficialOwner[0]?.selected) {
      contacts.push({
        itHas: false,
        firstName: "",
        lastName: "",
        relate: "",
        mobileNumber: "",
        type: "FINAL_BENEFICIARY",
      })
    } else {
      contacts.push({
        itHas: true,
        firstName: OpeningAccountReducer?.personalInformation?.STEP_EMERGENCY_CONTACT?.boFirstNameTh,
        lastName: OpeningAccountReducer?.personalInformation?.STEP_EMERGENCY_CONTACT?.boLastNameTh,
        relate: OpeningAccountReducer?.personalInformation?.STEP_EMERGENCY_CONTACT?.boRelate,
        mobileNumber: OpeningAccountReducer?.personalInformation?.STEP_EMERGENCY_CONTACT?.boMobileNumber,
        type: "FINAL_BENEFICIARY",
      })
    }

    if (OpeningAccountReducer?.personalInformation?.STEP_EMERGENCY_CONTACT?.controllingPerson[0]?.selected) {
      contacts.push({
        itHas: false,
        firstName: "",
        lastName: "",
        relate: "",
        mobileNumber: "",
        type: "FINAL_TRANSACTION_AUTHORITY",
      })
    } else {
      contacts.push({
        itHas: true,
        firstName: OpeningAccountReducer?.personalInformation?.STEP_EMERGENCY_CONTACT?.cpFirstNameTh,
        lastName: OpeningAccountReducer?.personalInformation?.STEP_EMERGENCY_CONTACT?.cpLastNameTh,
        relate: OpeningAccountReducer?.personalInformation?.STEP_EMERGENCY_CONTACT?.cpRelate,
        mobileNumber: OpeningAccountReducer?.personalInformation?.STEP_EMERGENCY_CONTACT?.cpMobileNumber,
        type: "FINAL_TRANSACTION_AUTHORITY",
      })
    }

    const response: ICreateOrUpdatePersonalContacts = yield call(SavePersonalContacts, contacts)

    if (response?.errors) {
      yield call(handleStatusError, response?.errors);
      yield call(hideSpinner)
      return
    }

    yield put(navigateNext())
    yield call(hideSpinner)
  } catch (error) {
    console.error("ERROR", error)
    yield call(hideSpinner)
  }
}

function* WorkerResetAoSteps() {
  try {
    yield call(showSpinner)

    const resp: IResetAoStepsResponse = yield call(ResetAoSteps)

    if (resp.data.ResetAoSteps.isSuccess) {
      yield call(WorkerGetAllQuery)

      const getUserResp: IGetUsers = yield call(GetUsers)
      yield put(setOaStatus({ oaStatus: getUserResp?.data.GetUserInfo.oaStatus }))
    } else {
      yield call(handleStatusError, resp?.errors);
    }
  } catch (err) {
    console.error(err)
  }

  yield call(hideSpinner)
}

function* getAllNoTinReasons() {
  try {
    const resp: IGetAllNoTinReasonsResponse = yield call(GetAllNoTinReasonsApi)
    yield put(setOpenAccountReducer({
      page: "termAndConditoin",
      subPage: ISubPage.STEP_VERIFY_FATCA,
      addValue: {
        noTinReasons: resp.data.GetAllNoTinReasons,
      },
    }))
  } catch (err) {
    console.error(err)
  }
}

export default function* AccountSaga() {
  yield takeLatest(OPENING_ACCOUNT_VERIFY_REGISTER, VerifyRegister)
  yield takeLatest(OPENING_ACCOUNT_SAVE_FATCA, SaveFatca)
  yield takeLatest(OPENING_ACCOUNT_GET_PEP, GetPep)
  yield takeLatest(OPENING_ACCOUNT_VALIDATE_PEP, ValidatePep)
  yield takeLatest(OPENING_ACCOUNT_GETSUIT_SAGA, GetSuitQuestion)
  yield takeLatest(OPENING_ACCOUNT_SAVEANSWERSUIT_SAGA, SaveSuitAnswer)
  yield takeLatest(OPENING_PREVIEW_SUIT_ABILITY_TEST_RESULT, PreviewSuitabilityTestResult)
  yield takeLatest(OPENING_ACCOUNT_GETANSWERSUIT_SAGA, GetSuitResultAnswer)
  yield takeLatest(OPENING_ACCOUNT_UPLOADFILE_SAGA, WorkerUploadFile)
  yield takeLatest(TFEX_GET_QUESTION, GetTfexQuestion)
  yield takeLatest(OPENING_ACCOUNT_PREVIEW_TFEX_TEST, PreviewTFexTest)
  yield takeLatest(OPENING_ACCOUNT_SAVE_TFEX_TEST, SaveTFexTest)
  yield takeLatest(OPENING_ACCOUNT_SAVEPERSONALINFO_SAGA, WorkerSavePersonalInfo)
  yield takeLatest(OPENING_ACCOUNT_SAVEBACKGROUNDINFO_SAGA, WorkerSaveBackgroundInfo)
  yield takeLatest(OPENING_ACCOUNT_SAVECONTACTINFO_SAGA, WorkerSaveContactInfo)
  yield takeLatest(OPENING_ACCOUNT_SAVEMUTUALFUNDACCOUNT_SAGA, WorkerSaveMutualFundAccount)
  yield takeLatest(
    OPENING_ACCOUNT_SAVESAVINGACCOUNTTOMUTUALFUND_SAGA, WorkerSaveSavingAccountToMutualFund,
  )
  yield takeLatest(OPENING_ACCOUNT_GET_CONSENT, WorkerGetConsent)
  yield takeLatest(OPENING_ACCOUNT_SAVE_CONSENT, WorkerSaveConsent)
  yield takeLatest(OPENING_ACCOUNT_QUERYALL_SAGA, WorkerGetAllQuery)
  yield takeLatest(OPENING_ACCOUNT_SET_AO_STEP, WorkerSetAoStep)
  yield takeLatest(OPENING_ACCOUNT_GET_AO_STEP, WorkerPushToCurrentStep)
  yield takeLatest(OPENING_ACCOUNT_SUBMIT_SAGA, WorkerSubmitData)
  yield takeLatest(OPENING_ACCOUNT_SAVEAOSTEP_SAGA, WorkerGetStep)
  yield takeLatest(OPENING_ACCOUNT_GETACCOUNTTYPE_SAGA, WorkerGetAccountType)
  yield takeLatest(OPENING_ACCOUNT_SAVESELECTEDACCOUNT_SAGA, WorkerSaveSelectedAccountApi)
  yield takeLatest(OPENING_ACCOUNT_SAVE_PERSONAL_CONTACTS_SAGA, WorkerSavePersonalContacts)
  yield takeLatest(OPENING_ACCOUNT_SAVEREFERRAL_SAGA, WorkerSaveReferralPerson)
  yield takeLatest(OPENING_ACCOUNT_GETCONSENTALL_SAGA, WorkerGetConsents)
  yield takeLatest(OPENING_ACCOUNT_RESET_AO_STEPS, WorkerResetAoSteps)
  yield takeLatest(OPENING_ACCOUNT_GET_ALL_NO_TIN_REASONS, getAllNoTinReasons)
}
