import React from "react"
import { Texts } from "..";
import { fonts, mainFontSize } from "../../config/themes";
import { useWindowDimensions } from "../../utilities";
import Image, { riskscaleBar, riskscaleIndecator } from "../Images"

interface props {
  title: string
  level?: string
}

export default ({ title, level = "0" }: props) => {
  const { width } = useWindowDimensions()
  const rotateScaleIndecatorPointer = (lv = "0") => {
    if (lv === "1") return { transform: `rotate(${295}deg)` }
    if (lv === "2") return { transform: `rotate(${325}deg)` }
    if (lv === "3") return { transform: `rotate(${360}deg)` }
    if (lv === "4") return { transform: `rotate(${395}deg)` }
    if (lv === "5") return { transform: `rotate(${425}deg)` }

    return {
      transform: `rotate(${275}deg)`,
    }
  }

  const riskColor = (lv = "0"): string => {
    if (lv === "1") return "#1EA513" 
    if (lv === "2") return "#9FCC00"
    if (lv === "3") return "#FFEF02"
    if (lv === "4") return "#FEAF00"
    if (lv === "5") return "#FB2A01"
    return "#FFFFFF"
  }

  const riskLevels = [
    [{ riskColor: "#1EA513", text: "ระดับที่ 1 : เสี่ยงต่ำ" },
      { riskColor: "#9FCC00", text: "ระดับที่ 2 : เสี่ยงปานกลางค่อนข้างต่ำ" },
      { riskColor: "#FFEF02", text: "ระดับที่ 3 : เสี่ยงปานกลางค่อนข้างสูง" },
    ],
    [
      { riskColor: "#FEAF00", text: "ระดับที่ 4 : เสี่ยงสูง" },
      { riskColor: "#FB2A01", text: "ระดับที่ 5 : เสี่ยงสูงมาก " },
    ],
  ]
  
  const RiskLevelDetails = () => (
    <div style={{
      display: "flex", flexDirection: "column", backgroundColor: "#FFFFFF", padding: 15, borderRadius: 10, marginTop: 15,
    }}
    >
      {
        riskLevels.map((row) => (
          <div style={{ display: "flex", flexDirection: width < 540 ? "column" : "row" }}>
            {row.map((item) => (
              <div style={{
                display: "flex", flexDirection: "row", alignItems: "center", marginRight: 10,
              }}
              >
                <div style={{
                  width: 10, height: 10, borderRadius: 10, backgroundColor: item.riskColor, 
                }}
                />
                <Texts
                  key={JSON.stringify(item)}
                  style={{ 
                    padding: "5px 8px", 
                    borderRadius: 20,
                    fontSize: mainFontSize.xxs, 
                    fontFamily: fonts.SarabunMedium,
                  }}
                >
                  {item.text}
                </Texts>
              </div>
            ))}
          </div>
        ))
      }
    </div>
  )

  return (
    <div>
      <div style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between", 
      }}
      >
        <Texts style={{ 
          color: "#0C67B1", 
          padding: "5px 0px 5px 0px", 
          fontSize: mainFontSize.xxl, 
          fontFamily: fonts.SarabunMedium,
        }}
        >
          {title}
        </Texts>
      </div>
      <div style={{
        borderRadius: 15, 
        marginTop: 25, 
        // height: 350, 
        backgroundColor: "#F4F7FC",
        padding: 20,
      }} 
      >
        <div style={{
          justifyContent: "center", display: "flex", position: "relative", 
        }}
        >
          <Image style={{ width: "200px", height: "100px" }} src={riskscaleBar} />
          <Image
            style={{
              width: "120px", height: "120px", position: "absolute", top: "35%", ...rotateScaleIndecatorPointer(level),
            }}
            src={riskscaleIndecator}
          />
        </div>
        <div style={{
          justifyContent: "center",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
        >
          <Texts style={{ 
            color: "#999999", 
            padding: "5px 0px 5px 0px", 
            fontSize: mainFontSize.s, 
            fontFamily: fonts.SarabunMedium,
            marginTop: 20,
          }}
          >
            ระดับความเสี่ยงของคุณ
          </Texts>
          <Texts style={{ 
            padding: "5px 15px 5px 15px", 
            borderRadius: 20,
            backgroundColor: riskColor(level),
            fontSize: mainFontSize.xs, 
            fontFamily: fonts.SarabunMedium,
            marginTop: 10,
          }}
          >
            ระดับ 
            {" "}
            {level}
          </Texts>
        </div>
        <RiskLevelDetails />
      </div>
    </div>
  )
}