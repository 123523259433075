import React from "react"
import { TBold, TLight } from ".."
import { mainFontSize } from "../../config/themes"
import { GetCurrentPage } from "../../utilities"
import CycleStepper from "../CycleStepper"

export interface MobileStepperProps {
  steps: stepItems[]
  activeStep: string
  maxPageOfStep: number
  maxSubPageOfStep: number
  // styleContainer?: React.CSSProperties
  // onChange?: (object: { key: string, value: number }) => void
}

export interface stepItems {
  title: string
  index?: number | null
}

export default ({
  steps,
  activeStep,
  maxPageOfStep,
  maxSubPageOfStep,
}: MobileStepperProps) => {
  const { page, subPage } = GetCurrentPage(activeStep)
  return (
    <div style={{ marginBottom: 12 }}>
      <div style={{ width: "100%", height: 1, backgroundColor: "#70CDE3" }} />
      <div 
        style={{
          paddingLeft: 20,
          paddingRight: 20,
          paddingBottom: 2,
          paddingTop: 2,
          marginBottom: 20,
          marginTop: 20,
        }}
      >
        <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
          <CycleStepper
            an={+page}
            mn={maxPageOfStep} 
            percent={(+page / maxPageOfStep) * 100}
          />
          <div style={{ flex: 1, marginLeft: 20 }}>
            <TBold fontSize={mainFontSize.xl} color="#0067B1">
              {steps[+page].title}
            </TBold>
            <TLight fontSize={mainFontSize.s} color="#666666">
              ต่อไป :
              {" "}
              {steps[+page + 1 >= maxPageOfStep ? +page : +page + 1].title}
            </TLight>
          </div>
        </div>
      </div>
      <div style={{ width: "100%", height: 4, backgroundColor: "#ccc" }}>
        <div 
          style={{
            width: `${(+subPage / maxSubPageOfStep) * 100}%`,
            height: 4,
            transition: "width 0.25s ease-in",
            backgroundColor: "#0067B1", 
          }}
        />
      </div>
    </div>
  )
}