import React from "react";
import {
  IFormsShema,
  IOpenAccountShema,
  ISubPage,
} from "../../../interfaces";
import CardIdentityVerification from "./CardIdentityVerification";
import CardSelectOpenTypeAccount from "./CardSelectOpenTypeAccount"
import CardTermAndConPDPA from "./CardTermAndConPDPA";
import CardTermAndConWeb from "./CardTermAndConWeb";
import CardTermAndCons from "./CardTermAndCons";
import FatcaTermAndCons from "./FatcaTermAndCons";
import TermCashAccount from "./TermCashAccount";
import TermCreditBalance from "./TermCreditBalance";
import TermFinalConsent from "./TermFinalConsent";
import TermSavingAccount from "./TermSavingAccount";
import TermTfex from "./TermTfex";

interface props {
  page: string
  data: IOpenAccountShema[],
  dataOfPage: IFormsShema[],
}

export default ({
  page, data, dataOfPage,
}: props) => {
  const index = +page.split(".")[1] || 0
  const dataDisplay = dataOfPage[index]

  if (dataDisplay?.type === ISubPage.STEP_VERIFY_IDENTITY) {
    return (
      <CardIdentityVerification
        page={page}
        dataOfPage={dataOfPage}
        data={data}
      />
    )
  }

  if (dataDisplay?.type === ISubPage.STEP_TERM_CONDITION_PDPA) {
    return (
      <CardTermAndConPDPA
        page={page}
        dataOfPage={dataOfPage}
        data={data}
      />
    )
  }

  if (dataDisplay?.type === ISubPage.STEP_TERM_CONDITION_WEB) {
    return (
      <CardTermAndConWeb
        page={page}
        dataOfPage={dataOfPage}
        data={data}
      />
    )
  }

  if (dataDisplay?.type === ISubPage.STEP_SELECT_OPEN_TYPE_ACCOUNT) {
    return (
      <CardSelectOpenTypeAccount
        page={page}
        dataOfPage={dataOfPage}
        data={data}
      />
    )
  }

  if (dataDisplay?.type === ISubPage.STEP_TERM_CONDITION_EQUITY_AND_MUTUAL_FUND) {
    return (
      <TermCashAccount
        page={page}
        dataOfPage={dataOfPage}
        data={data}
      />
    )
  }

  // if (dataDisplay?.type === ISubPage.STEP_TERM_CONDITION_CASH_BALANCE_ACCOUNT) {
  //   return (
  //     <TermCashBalance
  //       page={page}
  //       dataOfPage={dataOfPage}
  //       data={data}
  //     />
  //   )
  // }

  // if (dataDisplay?.type === ISubPage.STEP_TERM_CONDITION_MUTUAL_FUND_ACCOUNT) {
  //   return (
  //     <TermFundConnext
  //       page={page}
  //       dataOfPage={dataOfPage}
  //       data={data}
  //     />
  //   )
  // }

  if (dataDisplay?.type === ISubPage.STEP_TERM_CONDITION_CREDIT_BALANCE_ACCOUNT) {
    return (
      <TermCreditBalance
        page={page}
        dataOfPage={dataOfPage}
        data={data}
      />
    )
  }

  if (dataDisplay?.type === ISubPage.STEP_TERM_CONDITION_SAVING_ACCOUNT) {
    return (
      <TermSavingAccount
        page={page}
        dataOfPage={dataOfPage}
        data={data}
      />
    )
  }

  if (dataDisplay?.type === ISubPage.STEP_TERM_CONDITION_TFEX) {
    return (
      <TermTfex
        page={page}
        dataOfPage={dataOfPage}
        data={data}
      />
    )
  }

  if (dataDisplay?.type === ISubPage.STEP_TERM_CONDITION_CONSENT_CLAUSE_OF_RETAIL_CUSTOMER) {
    return (
      <TermFinalConsent
        page={page}
        dataOfPage={dataOfPage}
        data={data}
      />
    )
  }

  if (dataDisplay?.type === ISubPage.STEP_VERIFY_FATCA) {
    return <FatcaTermAndCons page={page} dataOfPage={dataOfPage} />
  }

  return (
    <CardTermAndCons
      page={page}
      dataOfPage={dataOfPage}
      data={data}
    />
  )
}
