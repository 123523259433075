import React from "react"
import { Redirect, Route } from "react-router-dom"
import { IRouteProps } from "../interfaces"
import { routerName } from "../interfaces/router.enum"
import { auth } from "../utilities"

const ProtectedRoute = ({ component, ...rest }: IRouteProps) => (
  <Route
    {...rest}
    render={(props) => (
      auth.isAuthenticated() ? (
        React.createElement(component, props)
      ) : (
        <Redirect to={{ pathname: routerName.login, state: { from: props.location } }} />
      )
    )}
  />
)

export default ProtectedRoute