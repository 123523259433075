import React, { Children, ReactNode } from "react";
import styled from "styled-components";
import { fonts } from "../../config/themes";

import ButtonLink from "./buttonLink";
import StyledButton from "./styledButton";

export interface Props {
  href?: string;
  onClick?(): void;
  children?: ReactNode;
  style?: React.CSSProperties
  disabled?: boolean
  cursor?: "pointer" | "not-allowed"

  target?: string
}

export const ButtonChoice = styled.button`
  background: white;
  border: 1px solid #cccccc;
  text-align: left;
  padding: 12px 20px!important;
  border-radius: 8px;
  margin: 0px 0px 15px 0px;
  color: #666666;
  font-family: ${fonts.SarabunMedium};
  
  :hover {
    border: 1px solid #0067b1;
  }

  :active {
    box-shadow: 1px 1px 6px #ccc;
  }
`

export default ({
  href,
  onClick,
  children,
  style,
  disabled,
  cursor,
  target,
}: Props) => (href ? (
  <ButtonLink
    cursor={cursor}
    style={style}
    href={href}
    onClick={onClick}
    target={target}
  >
    {Children.toArray(children)}
  </ButtonLink>
) : (
  <StyledButton
    disabled={disabled}
    style={style}
    onClick={onClick}
  >
    {Children.toArray(children)}
  </StyledButton>
))
