export const verifyRegisterSchema = `mutation VerifyRegister($input: VerifyRegisterInput) {
  VerifyRegister(input: $input) {
    verification{
      service
      secret
    }
  }
}`

// export const verifyRegisterSchema = `mutation VerifyRegister($input: VerifyRegisterInput) {
//   VerifyRegister(input: $input) {
//     verification{
//       service
//       secret
//     }
//     passport{
//       service
//       secret
//     }
//   }
// }`

export const getFatcaSchema = `query getFatca {
  GetFatca {
    isVerified
    isAmerican
  }
}`