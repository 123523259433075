import React from "react"
import { TLight, TMedium, Buttons } from "../../../components";
import Images, {
  bankBookIcon,
  bankStatmentIcon,
  emailPhoneIcon,
  houseRegisgrationIcon,
  idCardIcon,
  ndidIcon,
  signatureIcon,
  blueCircle,
} from "../../../components/Images";
import { mainFontSize, fonts } from "../../../config/themes";

type ItemProp = {
  src: string
  text: React.ReactNode
}

const ListItem = (key: string, src: string, textComp: React.ReactNode) => (
  <div
    key={key}
    style={{
      display: "flex",
      width: "100%",
      alignItems: "center",
      margin: "16px 0",
    }}
  >
    <Images
      src={src}
      height="32px"
      width="32px"
      style={{ marginRight: "8px" }}
    />
    <TLight style={{ fontFamily: fonts.PromptLight }}>
      {textComp}
    </TLight>
  </div>
)

const items: ItemProp[] = [
  {
    src: ndidIcon,
    text: <>
      ยืนยันตัวตนดิจิทัลด้วย NDID กับธนาคาร รายละเอียดเพิ่มเติม
      <Buttons
        href="https://th.rhbtradesmart.com/th/national-digital-id"
        target="_blank"
      >
        &nbsp;คลิก
      </Buttons>
    </>,
  },
  {
    src: idCardIcon,
    text: "บัตรประชาชน",
  },
  {
    src: houseRegisgrationIcon,
    text: "ทะเบียนบ้าน",
  },
  {
    src: bankBookIcon,
    text: "หน้าสมุดบัญชีธนาคาร",
  },
  {
    src: bankStatmentIcon,
    text: "Bank Statement เพื่อประเมินวงเงิน",
  },
  {
    src: signatureIcon,
    text: "ภาพลายเซ็นต์",
  },
  {
    src: emailPhoneIcon,
    text: "E-mail และโทรศัพท์มือถือเพื่อรับรหัสผ่าน OTP",
  },
]

export default () => (
  <div>
    <TMedium
      style={{ marginBottom: "8px", fontFamily: fonts.PromptMedium }}
      textAlign="left"
      fontSize={mainFontSize.xxxl}
      color="#0C67B1"
    >
      ข้อมูลหลักฐานที่จำเป็นสำหรับเปิดบัญชี
    </TMedium>
    <TMedium
      fontSize={mainFontSize.m}
      textAlign="left"
      style={{ marginBottom: "8px", fontFamily: fonts.PromptMedium }}
    >
      เตรียมหลักฐานประกอบการเปิดบัญชี
    </TMedium>
    {items.map((item, index) => ListItem(index.toString(), item.src, item.text))}
    <div style={{ textAlign: "left" }}>
      <span style={{ fontFamily: fonts.PromptMedium, fontSize: mainFontSize.m }}>
        หมายเหตุ : &nbsp;
      </span>
      <span style={{ fontFamily: fonts.PromptLight, fontSize: mainFontSize.m }}>
        เงื่อนไขสำหรับการเปิดบัญชีเงินสด (Cash Account), บัญชีอนุพันธ์ (Derivatives)
        และบัญชีเครดิตบาลานซ์ (Credit Balance)
      </span>
    </div>
    <ul
      style={{
        textAlign: "left",
        paddingLeft: 0,
        listStylePosition: "inside",
        listStyleImage: `url(${blueCircle})`,
        fontFamily: fonts.PromptLight,
        fontSize: mainFontSize.m,
        backgroundSize: 8,
      }}
    >
      <li>
        สมัครบริการตัดบัญชีธนาคาร (ATS) และได้รับการอนุมัติ ภายใน 30 วัน
      </li>
      <li>แนบสำเนา Bank Statement ย้อนหลัง 3 เดือนล่าสุด หรือ สลิปเงินเดือนอัพเดทล่าสุด</li>
      <li>การพิจารณาวงเงิน จะขึ้นอยู่กับเอกสารประกอบทางการเงิน</li>
    </ul>
  </div>
)
