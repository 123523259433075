import parse from "html-react-parser"
import React, { useState } from "react"
import styled from "styled-components"
import { fonts, mainFontSize } from "../../../config/themes"
import { ICheckBoxs, IInputsChoiceShema } from "../../../interfaces"
import Checkbox from "../../CheckBox"

interface props {
  value: IInputsChoiceShema[]
  description?: string
  field?: string
  onChange: (obj: {value: Array<ICheckBoxs>}) => void;
}

export const TextView = styled.div`
  background-color: rgba(204,204,204,0.15);
  padding: 10;
  max-height: 350px;
  overflow-y: scroll;
  border-radius: 10px;
  padding: 10px 15px 10px 15px;
  font-size: 11pt;
  line-height: 20pt;
  color: "#333333";
`

export default ({
  onChange, value, field,
}: props) => {
  const [isRead, setIsRead] = useState<boolean>(false)
  const [values, setValues] = React.useState(value)

  const onScroll = (e: React.UIEvent<HTMLElement>): void => {
    e.stopPropagation()

    const bottom = e.currentTarget.scrollHeight - e.currentTarget.scrollTop
    <= e.currentTarget.clientHeight * 1.1;

    if (bottom) {
      setIsRead(true)
    }
  }

  const paneDidMount = (e: HTMLDivElement) => {
    const hasVerticalScrollbar = e?.scrollHeight > e?.clientHeight;
    setIsRead(!hasVerticalScrollbar)
  }

  const textViewRef = React.useRef<HTMLDivElement>(null)

  React.useEffect(() => {
    if (value.some((val) => val.selected)) {
      setIsRead(true)
    }
    paneDidMount(textViewRef?.current as HTMLDivElement)
  }, [field]);

  const onHandleChange = (indexItems: number) => {
    const result = values.map((val, index) => (index === indexItems ?
      ({
        ...val,
        selected: !val.selected,
        description: value[index].description,
        consentId: value[index]?.consentId,
      }) : val))
    setValues(result)
    onChange({ value: result })
  };

  return (
    <div>
      {
        value.map((val, index) => (
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
            }}
          >
            <div
              style={{
                width: "100%",
                justifyContent: "flex-start",
                alignItems: "center",
                marginBottom: 12,
              }}
            >
              <TextView
                style={{
                  fontFamily: fonts.SarabunLight,
                  fontSize: mainFontSize.xs,
                  color: "#333333",
                  whiteSpace: "break-spaces",
                }}
                ref={textViewRef}
                onScroll={onScroll}
              >
                {val?.description && parse(val?.description)}
                <br />
              </TextView>
            </div>
            <div
              style={{
                display: "flex",
                flexWrap: "wrap",
                alignItems: "flex-start",
                flexDirection: "column",
              }}
            >
              <div>
                <Checkbox
                  selected={val.selected}
                  onChange={() => onHandleChange(index)}
                  disabled={!isRead}
                >
                  <p
                    style={{
                      fontFamily: fonts.SarabunLight,
                      fontSize: mainFontSize.s,
                      color: !isRead ? "#66666680" : "#000000",
                      marginLeft: 11,
                    }}
                  >
                    {val.title}
                  </p>
                </Checkbox>
              </div>
            </div>
          </div>
        ))
      }
    </div>
  )
}
