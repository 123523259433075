import React, { useEffect, useCallback } from "react"
import { useDropzone } from "react-dropzone"
import styled from "styled-components";
import { mainFontSize } from "../../../config/themes";
import { fileProps } from "../../../interfaces";
import Buttons from "../../Buttons";
import Image, { FileUpload, PdfSVG } from "../../Images";
import { TBold, TLight } from "../../Texts";
// import { ButtonChoice } from "../../Buttons";

interface props {
  label?: string
  values: Array<fileProps>
  disabled?: boolean
  maxFiles?: number
  errorMessage?: string | Array<string>

  onChange: (obj: {value: Array<fileProps>}) => void

  imagesDisplayPosition?: "before-input" | "after-input"

  // Hook function to decide whether to allow a file to be uploaded to the browser.
  // If the function returns `false` don't upload file else upload the file.
  beforeUpload?: (file: File) => boolean
}

const Box = styled("div")<{isDragActive: boolean}>`
  display: flex;
  border: ${(props) => (props.isDragActive ? "1.5px dashed #0067b1" : "1.5px dashed #cccccc")} ;
  border-radius: 10px;
  padding: 5px 5px 5px 5px;
  margin-top: 5px;
  flex:1;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  margin-bottom: 36px;
  margin-top: 34px;
  margin-right: 30px;
  margin-left: 30px;
`
const IconHolder = styled.div`
  display: flex;
  margin-top: 20px;
  margin-bottom: 5px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex:1;
  height: 100%;
  width: 100%;
`

const ImageHolder = styled.img`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex:1;
  height: 100%;
  width: 100%;
`

const ButtonHolder = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 10px;
  margin-bottom: 20px;
`

export default ({
  label,
  values,
  onChange,
  disabled,
  maxFiles,
  errorMessage,
  imagesDisplayPosition = "after-input",
  beforeUpload,
}: props) => {
  const [files, setFiles] = React.useState<Array<fileProps>>([]);
  const [errMessages, setErrMessages] = React.useState<Array<string>>([]);

  const genFileProps = (file: any): fileProps => ({
    name: file.name,
    contentType: file.type,
    data: file,
    imageType: "",
  })
  
  const onDrop = useCallback((acceptedFiles) => {
    const fileArr = Object.keys(acceptedFiles).map((key) => acceptedFiles[key])

    if (beforeUpload && !beforeUpload(fileArr[fileArr.length - 1])) return

    if (maxFiles === 1) {
      setFiles([genFileProps(fileArr[0])])
      onChange({ value: [genFileProps(fileArr[0])] })
    } else {
      const fls = fileArr.map((f) => (genFileProps(f)))
      setFiles((fs) => [...fs, ...fls])
      onChange({ value: [...files, ...fls] })
    }
  }, [files])

  const {
    getRootProps, 
    getInputProps, 
    isDragActive, 
  } = useDropzone({ 
    accept: "application/pdf, image/jpeg, image/jpg, image/png", 
    maxFiles, 
    onDrop, 
    disabled,
  })
  
  useEffect(() => {
    setFiles(values)
  }, [values])
  
  useEffect(() => {
    setErrMessages(errorMessage as Array<string> || [])
  }, [errorMessage])

  const onRemove = (index: number) => {
    setFiles(files.filter((file, k) => index !== k))
    onChange({ value: files.filter((_, k) => index !== k) })

    const errs: Array<string> = []
    for (let i = 0; i < files.length; i += 1) {
      for (let j = 0; j < errMessages.length; j += 1) { 
        const er = errMessages[j];
        const errNo = er.split("-")[0]
        const errMessage = er.split("-")[1]

        if (i < index && parseInt(errNo, 10) - 1 === i) {
          errs.push(er)
        } else if (i > index && parseInt(errNo, 10) - 1 === i) {
          errs.push(`${(parseInt(errNo, 10) - 1).toString()}-${errMessage}`)
        }
      }
    }
    setErrMessages(errs)
  }

  const onPdfClick = (file: File) => {
    // if (!file?.data) return
    const fileURL = URL.createObjectURL(file);
    window.open(fileURL);
  }

  const displayImages = () => (
    <>
      {
        files?.map((file, key) => {
          let error = "";

          if (errMessages) {
            for (let i = 0; i < errMessages.length; i += 1) {
              const er = errMessages[i];
              const errNo = er.split("-")[0]
              if (errNo === (key + 1).toString()) {
                error = er
              }
            }
          }

          if (file.contentType?.includes("pdf")) {
            return (
              <div
                style={{
                  position: "relative",
                  border: error ? "1px solid red" : "1.5px dashed #cccccc",
                  borderRadius: 10,
                  padding: 10,
                  paddingTop: 15,
                  marginTop: 20,
                  marginBottom: 36,
                  marginLeft: 30,
                  marginRight: 30,
                }}
                key={`${key.toString() + file.name}`}
              >
                <Buttons
                  style={{
                    background: "transparent", position: "absolute", top: 10, right: 5,
                  }}
                  onClick={() => onRemove(key)}
                >
                  <TLight color="red" fontSize={mainFontSize.m}> (X) ลบ</TLight>
                </Buttons>
                <button
                  onClick={() => onPdfClick(file.data as File)}
                  style={{
                    width: "100%", height: "100%", background: "transparent", border: "0px solid", cursor: "pointer",
                  }}
                >
                  <IconHolder>
                    <Image style={{ width: "150px", height: "150px" }} src={PdfSVG} />
                    <TBold color="#666666" fontSize={mainFontSize.l} mt="2px">
                      {file.name}
                    </TBold>
                  </IconHolder>
                  <div style={{ marginTop: 4, display: "flex", flexDirection: "row" }}>
                    <TLight color="red" fontSize={mainFontSize.xs}>{error}</TLight>
                  </div>
                </button>
              </div>
            )
          }

          return (
            <div
              style={{
                position: "relative",
                border: error ? "1px solid red" : "1.5px dashed #cccccc",
                borderRadius: 10,
                padding: 10,
                paddingTop: 15,
                marginTop: 20,
                marginBottom: 36,
                marginLeft: 30,
                marginRight: 30,
              }}
              key={`${key.toString() + file.name}`}
            >
              <Buttons
                style={{
                  background: "transparent", position: "absolute", top: 10, right: 5,
                }}
                onClick={() => onRemove(key)}
              >
                <TLight color="red" fontSize={mainFontSize.m}> (X) ลบ</TLight>
              </Buttons>
              <button
                onClick={() => onPdfClick(file.data as File)}
                style={{
                  width: "100%", height: "100%", background: "transparent", border: "0px solid", cursor: "pointer",
                }}
              >
                <ImageHolder src={URL.createObjectURL(file.data as File)} />
                <div style={{ marginTop: 4, display: "flex", flexDirection: "row" }}>
                  <TLight color="red" fontSize={mainFontSize.xs}>{error}</TLight>
                </div>
              </button>
            </div>
          )
        })
      }
    </>
  )

  return (
    <>
      { imagesDisplayPosition === "before-input" && displayImages() }
      <Box
        {...getRootProps()}
        isDragActive={isDragActive}
      >
        <IconHolder>
          <Image style={{ width: "50px", height: "80px" }} src={FileUpload} />
          <TBold color="#666666" fontSize={mainFontSize.xs} mt="2px">
            {label || "ลากไฟล์มาที่นี่ หรือ"}
          </TBold>
        </IconHolder>
        <input
          {...getInputProps()}
          multiple={maxFiles !== 1}
          onChange={async (e) => {
            const file = e.target.files
            if (file) {
              onDrop(file)
            }
          }}
        />
      
        {!disabled && (
          <ButtonHolder>
            <Buttons
              disabled={disabled}
              style={{
                backgroundColor: "rgba(0,	103,	177, 0.05)", 
                color: "#0067b1",
                fontSize: 16,
              }}
            >
              {!isDragActive ? "เลือกไฟล์" : "วางไฟล์"}
            </Buttons>
          </ButtonHolder>
        )}
      </Box>
      { imagesDisplayPosition === "after-input" && displayImages() }
    </>
  )
}
