import { LineOption, MessageComponentOption } from "./index.types"

export const onHandlePercent = (activeStep: string, indexItems: number, maxPageOfStep: number): { 
  number: number
  decimal: number
} => {
  const number: number = +activeStep.split(".")[0]
  const decimal: number = +activeStep.split(".")[1] || 0

  if (number > indexItems) {
    return {
      number,
      decimal: 100,
    }
  }
  return {
    number,
    decimal: (decimal / maxPageOfStep) * 100,
  }
}

export const activeLine = (prop: LineOption) => prop.indexItems <= +prop.activeStep && ({
  background: "#0067b1",
  width: `${onHandlePercent(prop.activeStep, prop.indexItems, prop.maxPageOfStep).decimal}%`,
  transition: "width 0.25s ease-in",
})

export const activeCycle = (
  props: MessageComponentOption,
) => props.indexItems <= +props.activeStep.toFixed(2) && ({
  background: "#70cde3",
  border: "solid 4px #70cde3",
  color: "#ffffff",
  transition: "background 0.25s ease-in",
})