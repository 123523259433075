import { 
  OPENING_ACCOUNT_SET_PREVIEW_TFEX,
  TFEX_SAVE_QUESTION_REDUCER, 
} from "../actions"

const INIT = {
  question: [],
  answer: [],
  preview: {
  },
}

export default (state = INIT, { type, payload }: any) => {
  switch (type) {
    case TFEX_SAVE_QUESTION_REDUCER:
      return { ...state, question: payload }
    case OPENING_ACCOUNT_SET_PREVIEW_TFEX:
      return { ...state, preview: payload }
    default:
      return state
  }
}