import styled from "styled-components";

const mapColors = (type: "LIGHT" | "THICK" | "WHITE") => {
  switch (type) {
    case "LIGHT": return "#cccccc"
    case "WHITE": return "#ffffff"
    default: return "#0067b1"
  }
}

export default styled("div")<{type: "LIGHT" | "THICK" | "WHITE"}>`
  width: 100%;
  height: ${({ type }) => (type === "LIGHT" ? "1px" : "1.15px")};
  margin: 20px 0px 20px 0px;
  background-color: ${({ type }) => mapColors(type)};
`