import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components"
import { getOpenAccountTypeSaga, saveReferralPersonSaga, saveSelectAccountSaga } from "../../../actions";
import {
  Buttons, Card, HistoryOfPath, Inputs, TBold, Texts, 
} from "../../../components";
import { showDialog } from "../../../components/Provider/dialog";
import { fonts, mainFontSize } from "../../../config/themes";
import { ComponentContext } from "../../../contexts"
import {
  IDialogTypeEnum, IFormsShema, InputEnum, IOpenAccountShema, IStore, 
} from "../../../interfaces";
import { getErrorMessage, handleErrorScreen } from "../../../utilities";

interface props {
  page: string
  data: IOpenAccountShema[],
  dataOfPage: IFormsShema[],
}

const ButtonHolder = styled.div`
  display: flex;
  justify-content: space-between;
`

type AccountType = {
  consentType: string
  id: string
  subTitle: string
  title: string
  selected: undefined | boolean
}

export default ({ page, data, dataOfPage }: props) => {
  const [errorMessages, setErrorMessages] =
      useState<Array<{ field: string, message: Array<string> | string }>>([])
  const dispatch = useDispatch()
  const componentContext = useContext(ComponentContext);
  const { termAndConditoin } = useSelector((state: IStore) => state.OpeningAccountReducer)
  const { t } = useTranslation()
  const index = +page.split(".")[1] || 0
  const dataDisplay = dataOfPage[index]
  const mainData = termAndConditoin[dataDisplay.type]
  const [accountTypeSelectionError, setAccountTypeSelectionError] = useState<string>("")

  const onChange = (val: {key?: string, value?: string | boolean | number}) => {
    componentContext?.onChange({ key: val.key || "", value: val.value })
  }

  useEffect(() => {
    dispatch(getOpenAccountTypeSaga())
  }, [])
  useEffect(() => {
    mainData.selectOpenTypeList = mainData.selectOpenTypeList.map((val: AccountType) => ({
      title: val.title,
      subTitle: val.subTitle,
      id: val.id,
      consentType: val.consentType,
      selected: mainData.selectedAccounts?.[val.id],
    }))
  }, [mainData.selectedAccounts])

  const onNext = () => {
    const { errors, validateFlag } = handleErrorScreen(dataDisplay.inputs, mainData)
    setErrorMessages(errors)
    const accountTypes = mainData.selectOpenTypeList;
    const isAtLeastOneSelected = accountTypes
      ?.some((el: {selected?: boolean}) => el.selected === true)
    if (!isAtLeastOneSelected) {
      setAccountTypeSelectionError("กรุณาเลือกอย่างน้อย 1 บัญขี")
      return
    }
    if (!mainData.nameMaketingRecomment) {
      showDialog(IDialogTypeEnum.NOTICE_DIALOG, { message: "กรุณาใส่ รหัสผู้แนะนำ หรือชื่อเจ้าหน้าที่ผู้แนะนำการลงทุน หากท่านไม่ทราบ กรุณาเลือก EBIS" }, true)
      return
    }

    const cashBalanceAccountType = accountTypes
      .find((accountType: { id: string; }) => accountType.id === "CASH_BALANCE_ACCOUNT")

    if (!cashBalanceAccountType.selected) {
      const accountTypesRequiringCashBalance: string[] = [
        "CREDIT_BALANCE_ACCOUNT",
        "MUTUAL_FUND_ACCOUNT",
        "TFEX",
      ]

      for (let i = 0; i < accountTypes.length; i += 1) {
        const accountType = accountTypes[i]

        if (accountTypesRequiringCashBalance.includes(accountType.id)
          && accountType.selected) {
          setAccountTypeSelectionError("การเลือกบัญชี บัญชีเครดิตบาลานซ์ (Credit Balance), " +
            "บัญชีซื้อขายหน่วยลงทุน (Mutual Fund), หรือ บัญชีซื้อขายอนุพันธ์ (Derivatives) ต้องเลือก " +
            "บัญชีแคชบาลานซ์ (Cash Balance) ด้วย")

          return
        }
      }
    }

    const { nameMaketingRecomment } = mainData

    // Check empty referral.
    if (nameMaketingRecomment === "") {
      showDialog(IDialogTypeEnum.NOTICE_DIALOG,
        { message: "กรุณาใส่ รหัสผู้แนะนำ หรือเจ้าหน้าที่ผู้แนะนำการลงทุน" })

      return
    }

    if (!validateFlag) return
    dispatch(saveReferralPersonSaga({
      callback: () => {
        dispatch(saveSelectAccountSaga())
      },
    }))
  }

  return (
    <Card>
      <HistoryOfPath
        data={dataOfPage.map((val) => val.title)}
        page={page}
        activeIndex={index}
      />
      <div style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between", 
        
      }}
      >
        <Texts style={{ 
          color: "#0C67B1", 
          padding: "5px 0px 5px 0px", 
          fontSize: mainFontSize.xl, 
          fontFamily: fonts.SarabunMedium,
        }}
        >
          {dataDisplay.title}
        </Texts>
      </div>
      {
        accountTypeSelectionError && (
          <TBold color="red">
            *&nbsp;
            { accountTypeSelectionError }
          </TBold>
        )
      }
      {
        dataDisplay.inputs.map((val, key) => (
          <Inputs
            key={key.toString()}
            config={{
              type: val.type as InputEnum,
              title: val.title,
              titleColor: val.titleColor,
              subTitle: val.subTitle,
              subTitleColor: val.subTitleColor,
              field: val.field,
              description: val.description,
              placeholder: val.placeholder,
              errorMessage: getErrorMessage(val.field, errorMessages),
              maxlength: val.maxlength,
              rules: val.rules,
              BelowComp: val.BelowComp,
            }}
            value={mainData[val.field]}
          />
        ))
      }
            
      <ButtonHolder style={{ marginTop: 20 }}>
        <Buttons
          style={{
            backgroundColor: "#ffffff",
            color: "#0067b1",
            borderWidth: 1,
            borderColor: "#0067b1",
          }}
          onClick={() => onChange({ key: "BACK", value: 0 })}
        >
          ย้อนกลับ
        </Buttons>
        <Buttons
          onClick={onNext}
        >
          {t("termsAndCon.buttonNext")}
        </Buttons>
      </ButtonHolder>
    </Card>
  )
}