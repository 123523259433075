import React from "react"
import { useTranslation } from "react-i18next"
import styled from "styled-components"
import { Buttons } from ".."
import { GetUsers } from "../../apis"
import { color } from "../../config/themes"
import { IGetUsers, StatusOpenAccount } from "../../interfaces"
import { routerName } from "../../interfaces/router.enum"
import { handleStatusError } from "../../sagas"

const StyledMenu = styled("nav")<{open: boolean}>`
  z-index: 2500;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: #FFF;
  transform: ${({ open }) => (open ? "translateY(0)" : "translateY(-100%)")};
  height: 100vh;
  text-align: left;
  position: fixed;
  top: 0;
  left: 0;
  transition: transform 0.3s ease-in-out;

  @media (max-width: 576px) {
      width: 100%;
    }

  a {
    font-size: 2rem;
    text-transform: uppercase;
    padding: 2rem 0;
    font-weight: bold;
    letter-spacing: 0.3rem;
    color: #0D0C1D;
    text-decoration: none;
    transition: color 0.3s linear;

    @media (max-width: 576px) {
      font-size: 1.5rem;
      text-align: center;
    }

    &:hover {
      color: #000;
      background-color: #70CDE3;
      border-radius: 0px;
    }
  }
`

const StyledBurger = styled("button")<{open: boolean}>`
  position: fixed;
  right: 2rem;
  z-index: 2501;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 2rem;
  height: 2rem;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;

  &:focus {
    outline: none;
  }

  div {
    width: 2rem;
    height: 0.35rem;
    background: ${({ open }) => (open ? "#0D0C1D" : "#2867ac")};
    border-radius: 2px;
    transition: all 0.3s linear;
    position: relative;
    transform-origin: 1px;

    :first-child {
      transform: ${({ open }) => (open ? "rotate(45deg)" : "rotate(0)")};
    }

    :nth-child(2) {
      opacity: ${({ open }) => (open ? "0" : "1")};
      transform: ${({ open }) => (open ? "translateX(20px)" : "translateX(0)")};
    }

    :nth-child(3) {
      transform: ${({ open }) => (open ? "rotate(-45deg)" : "rotate(0)")};
    }
  }
`

const Burger = ({ open, setOpen }: { 
  open: boolean, 
  setOpen: React.Dispatch<React.SetStateAction<boolean>> 
}) => (
  <StyledBurger open={open} onClick={() => setOpen(!open)}>
    <div />
    <div />
    <div />
  </StyledBurger>
)

const Menu = ({
  open, auth, t, status,
}: { 
  open: boolean, 
  // setOpen: React.Dispatch<React.SetStateAction<boolean>>,
  auth: any,
  t: any
  status: StatusOpenAccount | null
}) => (
  <StyledMenu open={open}>
    {
      auth.isAuthenticated() && 
      window.location.pathname === routerName.contact && 
      (status === StatusOpenAccount.REGISTERED || status === StatusOpenAccount.VERIFIED)
      && (
        <Buttons style={{ color: color.primary }} href="/">เปิดบัญชีกองทุนต่อ</Buttons>
      )
    }
    {
      auth.isAuthenticated() && 
      window.location.pathname === routerName.contact && 
      status !== StatusOpenAccount.REGISTERED && 
      status !== StatusOpenAccount.VERIFIED && (
        <Buttons
          style={{ color: color.primary }} 
          href={routerName.approvalStatus}
        >
          สถานะการเปิดบัญชี
        </Buttons>
      ) 
    }
    <Buttons style={{ color: color.primary }} href={routerName.contact}>{t("headerAccountOpening.contact")}</Buttons>
    {
      auth.isAuthenticated() && (
        <Buttons
          style={{ color: color.primary }}
          onClick={() => auth.signout()}
          href={routerName.root}
        >
          ออกจากระบบ
        </Buttons>
      )
    }
  </StyledMenu>
)

export default ({ auth }: any) => {
  const [open, setOpen] = React.useState(false);
  const [status, SetStatus] = React.useState<StatusOpenAccount | null>(null)
  const { t } = useTranslation()
  
  React.useEffect(() => {
    if (auth.isAuthenticated()) {
      GetUsers()
        .then((res: IGetUsers) => {
          if (res?.errors) {
            handleStatusError(res?.errors)
            return
          }
          SetStatus(res.data.GetUserInfo.oaStatus as StatusOpenAccount)
        })
        .catch((error) => {
          console.log(error)
        })
    }
  }, [])
  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <Burger open={open} setOpen={setOpen} />
      <Menu open={open} auth={auth} t={t} status={status} />
    </div>
  )  
}