import styled from "styled-components";
import { fonts, mainFontSize } from "../../config/themes";

const StyledButton = styled("button")<{disabled?: boolean}>`
  display: inline-block;
  box-sizing: border-box;
  padding: 0.4em 1.5em;
  text-decoration: none;
  border-radius: 4px;
  -webkit-font-smoothing: antialiased;
  -webkit-touch-callout: none;
  user-select: none;
  cursor: pointer;
  outline: 0;
  font-size: ${mainFontSize.l};
  font-weight: bold;
  font-family: ${(props) => props.style?.fontFamily || fonts.SarabunMedium};
  border: 2px solid transparent;
  background-color: ${(props) => (props.disabled ? "#CCCCCC" : "#0C67B1")};
  color: #ffffff;

  &:active {
    background: #41addd;
    color: #fff;
  }
`;

export default StyledButton;
