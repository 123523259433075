import React from "react"
import Card from "../../components/Card";
import { IOpenAccountShema, IPageOpenAccountOptionEnum } from "../../interfaces";
import CardAccountInformation from "../cards/cardAccountInfo"
import CardConnectBank from "../cards/cardConnectBank";
import CardCreatePassword from "../cards/cardCreatePassword";
import CardIdCardInformation from "../cards/cardIdCardInformation";
import CardPersonalInformation from "../cards/cardPersonalInformation";
import CardSuccess from "../cards/cardSuccessManager"
import CardSuitability from "../cards/cardSuitability";
import CardTFexQuestionaire from "../cards/cardTFexQuestionaire";
import CardTermsAndConditions from "../cards/cardTermsAndConditions"

interface formsOptions {
  page: string
  onChange: (object: { key: string, value: number }) => void // deprecate soon
  data: IOpenAccountShema[]
}

export default ({ page, onChange, data }: formsOptions) => {
  const dataOfPage = data[Math.trunc(+page)].form
  switch (data[Math.trunc(+page)].type) {
    case IPageOpenAccountOptionEnum.createPassword:
      return (
        <CardCreatePassword 
          page={page}
          dataOfPage={dataOfPage}
          data={data}
        />
      )

    case IPageOpenAccountOptionEnum.termAndConditoin:
      return (
        <CardTermsAndConditions
          page={page}
          dataOfPage={dataOfPage}
          data={data}
        />
      )

    case IPageOpenAccountOptionEnum.connectBank:
      return (
        <CardConnectBank 
          page={page}
          dataOfPage={dataOfPage}
          data={data}
        />
      )

    case IPageOpenAccountOptionEnum.idCardInformation:
      return (
        <CardIdCardInformation
          page={page}
          dataOfPage={dataOfPage}
          data={data}
        />
      )

    case IPageOpenAccountOptionEnum.personalInformation:
      return (
        <CardPersonalInformation
          page={page}
          dataOfPage={dataOfPage}
          data={data}
        />
      )

    case IPageOpenAccountOptionEnum.accountInformation:
      return (
        <CardAccountInformation
          page={page}
          dataOfPage={dataOfPage}
          data={data}
        />
      )
    
    case IPageOpenAccountOptionEnum.tfexQuestionaire:
      return (
        <CardTFexQuestionaire
          page={page}
          dataOfPage={dataOfPage}
          data={data}
        />
      )

    case IPageOpenAccountOptionEnum.suitability:
      return (
        <CardSuitability
          page={page}
          dataOfPage={dataOfPage}
          data={data}
        />
      )

    case IPageOpenAccountOptionEnum.successFully:
      return (
        <CardSuccess
          page={page}
          dataOfPage={dataOfPage}
          data={data}
        />
      )
  
    default:
      // onChange({ key: "NEXT", value: 1000 })
      return <Card>{`${page}`}</Card>
  }
}