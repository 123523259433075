import React from "react"
import { fonts, mainFontSize } from "../../../config/themes"
import { ICheckBoxs } from "../../../interfaces"
import Radio from "../../Radio"

interface props {
  value: Array<ICheckBoxs>
  onChange: (obj: {value: Array<ICheckBoxs>}) => void;
  disabled?: boolean
  field: string
}

export default ({
  onChange,
  value,
  disabled,
  field,
}: props) => {
  const [values, setValues] = React.useState(value)

  const onHandleChange = (indexItems: number) => {
    const result = values.map((val, index) => (index === indexItems ?
      ({ ...val, selected: true }) : ({ ...val, selected: false })))
    setValues(result)
    onChange({ value: result })
  };

  React.useEffect(() => {
    if (typeof value === "object" && value !== null) {
      setValues(value)
    }
  }, [value])

  return (
    <div
      style={{
        // flex: 1,
        display: "flex",
        flexWrap: "wrap",
        flexDirection: "column",
        marginBottom: 20,
      }}
    >
      {
        // (value.length <= 4) ?
        values.map((val, index) => (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              height: 35,
            }}
          >
            <Radio
              selected={val.selected}
              onChange={() => onHandleChange(index)}
              disabled={disabled}
              field={field}
            />
            <p
              style={{
                fontFamily: fonts.SarabunLight,
                fontSize: mainFontSize.s,
                color: "#000000",
                marginLeft: 5,
              }}
            >
              {val.title}
            </p>
            {/* <Texts style={{
            color: "#333333",
            padding: "5px 0px 5px 0px",
            marginLeft: 5,
            fontSize: mainFontSize.s,
            fontFamily: fonts.SarabunMedium,
          }}
          >
              {val.title}
          </Texts> */}
          </div>
        ))
        // ) : (
        //   (value.map((val, index) => (
        //     <div
        //       style={{
        //         width: "50%",
        //         display: "flex",
        //         alignItems: "center",
        //         justifyContent: "flex-start",
        //       }}
        //     >
        //       <Radio
        //         title={val.title}
        //         field={field}
        //         onChange={onChange}
        //         keyTarget={keyTarget}
        //         requireOther={val.requireOther}
        //         requireOtherActive={requireOtherActive}
        //         currentValue={currentValue}
        //         setCurrentValue={setCurrentValue}
        //       />
        //       <p
        //         style={{
        //           fontFamily: fonts.SarabunLight,
        //           fontSize: mainFontSize.s,
        //           color: "#66666680",
        //           marginLeft: 5,
        //         }}
        //       >
        //         {val.title}
        //       </p>
        //     </div>
        //   )))
        // )
      }
    </div>
  )
}
