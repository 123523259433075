import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import styled, { css } from "styled-components"
import { getRetrieveData, navigateNext } from "../../../actions";
import {
  Buttons,
  Card,
  Images,
  Texts,
  HistoryOfPath,
  Divider,
  TExtraLight,
  TMedium,
} from "../../../components"
import { NdidPreviewIcon } from "../../../components/Images";
import { fonts, mainFontSize } from "../../../config/themes";
import { ComponentContext } from "../../../contexts";
import { IFormsShema, IOpenAccountShema, IStore } from "../../../interfaces";
import {
  DateFormat, DateFormatProtectSensitive, DateString, GenderString, IdentityCardProtectSensitive, 
} from "../../../utilities";

interface props {
  page: string
  data: IOpenAccountShema[],
  dataOfPage: IFormsShema[],
  // onChange: (object: { key: string, value: number }) => void
}

const ButtonHolder = styled.div`
  display: flex;
  justify-content: flex-end;
`

const ImageHolder = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`

export default ({
  page, data, dataOfPage,
}: props) => {
  const dispatch = useDispatch()
  const NDID = useSelector((state: IStore) => state.NdidReducer)
  const componentContext = useContext(ComponentContext);
  const { t } = useTranslation()
  const index = +page.split(".")[1] || 0
  const dataDisplay = dataOfPage[index]
  const onChange = ({ key, value }: {key: string, value: any}) => {
    componentContext?.onChange({ key: key || "", value })
  }
  const { 
    title = "",
    firstNameTh = "",
    lastNameTh = "", 
    firstNameEn = "",
    lastNameEn = "",
    birthDate = "",
    gender = "",
    identificationCard,
    currentAddress,
    workplaceAddress,
    identificationCardAddress,
    phoneNumber = "",
    occupation = "",
  } = NDID.RETRIEVE_DATA_NDID

  /// / FOR DEBUG
  // const title = ""
  // const firstNameTh = ""
  // const lastNameTh = "" 
  // const firstNameEn = ""
  // const lastNameEn = ""
  // const birthDate = ""
  // const gender = ""
  // const identificationCard = {
  //   number: "",
  //   expiryDate: "",
  // }
  // const currentAddress = {
  //   fullAddress: "",
  // }
  // const workplaceAddress = {
  //   fullAddress: "",
  // }
  // const identificationCardAddress = {
  //   fullAddress: "",
  // }
  // const phoneNumber = ""
  // const occupation = ""

  const onChangeHistoryOfPath = ({ key, value }: {key:string, value: number}) => {
    onChange({ key, value })
  }

  React.useEffect(() => {
    dispatch(getRetrieveData())
  }, [])

  const dataLayout = [
    {
      header: "",
      items: [
        {
          header: "",
          subHeader: "ข้อมูลส่วนตัว",
          rows: true,
          items: [
            {
              label: `${t("informationPreview.subheader1.6")}`,
              value: `${title} ${firstNameTh} ${lastNameTh}`,
              image: "",
            },
            {
              label: `${t("informationPreview.subheader1.8")}`,
              value: `${firstNameEn} ${lastNameEn}`,
              image: "",
            },
            {
              label: t("informationPreview.subheader1.10"),
              value: GenderString(gender as "M" | "F", "th"),
              image: "",
            },
            {
              label: t("informationPreview.subheader1.9"),
              value: DateFormatProtectSensitive(birthDate, " "),
              image: "",
            },
            
            {
              label: t("informationPreview.subheader1.1"),
              value: IdentityCardProtectSensitive(identificationCard?.number, " "),
              image: "",
            },
            {
              label: t("informationPreview.subheader1.3"),
              value: DateFormat(identificationCard?.expiryDate, " "),
              image: "",
            },
          ],
        },
        {
          header: "",
          subHeader: "ช่องทางการติดต่อ",
          rows: false,
          items: [
            {
              label: "ที่อยู่ปัจจุบัน",
              value: currentAddress?.fullAddress,
              image: "",
            },
            {
              label: "ที่อยู่ตามทะเบียนบ้าน",
              value: identificationCardAddress?.fullAddress,
              image: "",
            },
            {
              label: "หมายเลขโทรศัพท์",
              value: phoneNumber,
              image: "",
            },
          ],
        },
        {
          header: "",
          subHeader: "อาชีพและการทำงาน",
          rows: false,
          items: [
            {
              label: "อาชีพ",
              value: occupation,
              image: "",
            },
            {
              label: "ที่อยู่ที่ทำงาน",
              value: workplaceAddress?.fullAddress,
              image: "",
            },
          ],
        },
      ],
    },
  ]

  const onNext = () => {
    dispatch(getRetrieveData())
    dispatch(navigateNext())
  }
  
  return (
    <Card>
      <HistoryOfPath
        data={dataOfPage.map((val) => val.title)}
        page={page}
        activeIndex={index}
      />
      <div style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between", 
      }}
      >
        <Texts style={{ 
          color: "#0C67B1", 
          padding: "5px 0px 5px 0px", 
          fontSize: mainFontSize.l, 
          fontFamily: fonts.SarabunMedium,
        }}
        >
          {dataDisplay.title}
        </Texts>
      </div>

      <div style={{ display: "flex", justifyContent: "center" }}>
        <Images
          src={NdidPreviewIcon}
          style={{
            width: 80, height: 80, borderRadius: 40, overflow: "hidden",
          }}
        />
      </div>

      {
        dataLayout.map((groupItems) => (
          <>
            <Texts style={{
              color: "#0c67b1", 
              padding: "5px 0px 5px 0px", 
              fontSize: mainFontSize.s, 
              fontFamily: fonts.SarabunMedium, 
            }}
            />
            {
              groupItems.items.map((groupSubItems, groupSubIndex) => (
                <>
                  <Texts style={{ color: "#0c67b1", padding: "5px 0px 5px 0px", fontSize: mainFontSize.m }}>{groupSubItems.header}</Texts>
                  <Texts style={{ color: "#0c67b1", padding: "5px 0px 5px 0px", fontSize: mainFontSize.s }}>{groupSubItems.subHeader}</Texts>

                  <div style={{ display: "flex", flexDirection: groupSubItems.rows ? "row" : "column", flexWrap: "wrap" }}>
                    {
                      groupSubItems.items.map((items) => (
                        <div key={`items-${items.label}`} style={{ flex: "50%", margin: "10px 0px" }}>
                          <TMedium color="#333333" mt="5px" mb="5px" fontSize={mainFontSize.s}>{items.label || "-"}</TMedium>
                          <TExtraLight color="#666666" mt="5px" mb="5px" fontSize={mainFontSize.s}>{items.value || "-"}</TExtraLight>
                          { items.image && (
                            <ImageHolder>  
                              <Images style={{ width: "380px", height: "250px" }} src={items.image} />
                            </ImageHolder>
                          ) }
                        </div>
                      ))
                    }
                  </div>
                  { (groupItems.items.length - 1) !== groupSubIndex && <Divider type="LIGHT" />}
                </>
              ))
            }
            <Divider type="LIGHT" />
          </>
        ))
      }

      <ButtonHolder>
        <Buttons onClick={onNext}>{t("ndidRreviewData.buttonNext")}</Buttons>
      </ButtonHolder>
    </Card>
  )
}