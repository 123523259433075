import {
  NEXT_NAVIGATE,
  BACK_NAVIGATE,
  NEXT_SET_STEP,
  NAVIGATE_SET_WITH_KEY,
  NEXT_SET_STEP_WITH_PARAMS,
  STEPS_SET_OA_STATUS,
} from "../actions"
import { GetCurrentPage } from "../utilities"

const initState = {
  step: "0",
  page: 0,
  subPage: 0,
  data: [{ form: [] }],
  allowEdit: [],
  oaStatus: "",
}

export default (state = initState, { type, payload }: { type: string, payload: any}) => {
  switch (type) {
    case STEPS_SET_OA_STATUS:
      return {
        ...state,
        oaStatus: payload?.oaStatus,
      }
    case NEXT_NAVIGATE:
      window.scrollTo({ top: 0 })
      if (state.data[state.page]?.form.length <= 1) {
        return {
          ...state,
          page: state.page + 1,
          step: (state.page + 1).toString().concat(`.${state.subPage}`),
        }
      }
      if (state.subPage < state.data[state.page]?.form.length - 1) {
        return {
          ...state,
          subPage: state.subPage + 1,
          step: state.page.toString().concat(`.${state.subPage + 1}`),
        }
      }
      if (state.page < state.data.length - 1) {
        return {
          ...state,
          subPage: 0,
          page: state.page + 1,
          step: (state.page + 1).toString().concat(".0"),
        }
      }

      return state
    case BACK_NAVIGATE:
      window.scrollTo({ top: 0 })
      if (state.subPage === 0) {
        return {
          ...state,
          page: state.page - 1,
          subPage: state.data[state.page - 1 < 0 ? 0 : state.page - 1].form.length - 1,
          step: (state.page - 1).toString().concat(`.${state.data[state.page - 1 < 0 ? 0 : state.page - 1]?.form.length - 1}`),
        }
      }
      return {
        ...state,
        subPage: state.subPage - 1,
        step: state.page.toString().concat(`.${state.subPage - 1}`),
      }

    case NEXT_SET_STEP:
      window.scrollTo({ top: 0 })
      return {
        ...state,
        step: payload,
        page: +GetCurrentPage(payload).page,
        subPage: +GetCurrentPage(payload).subPage,
      }

    case NEXT_SET_STEP_WITH_PARAMS:
      window.scrollTo({ top: 0 })
      return {
        ...state,
        step: payload?.page,
        page: +GetCurrentPage(payload?.page).page,
        subPage: +GetCurrentPage(payload?.page).subPage,
      }
    case NAVIGATE_SET_WITH_KEY:
      window.scrollTo({ top: 0 })
      return {
        ...state,
        ...payload,
      }

    default:
      return state
  }
}
