import React from "react";
import { IFormsShema, IOpenAccountShema, ISubPage } from "../../../interfaces";
import CardServiceAppFeatureInfo from "./CardServiceAppFeatureInfo";
import CardIDPBank from "./cardIDPBank"
import CardIDPStatus from "./cardIDPStatus"
import CardIDPVerify from "./cardIDPVerify"
import CardInformationPreview from "./cardInformationPreview"
import CardNDIDTerms from "./cardNDIDTerms"

interface props {
  page: string
  data: IOpenAccountShema[],
  dataOfPage: IFormsShema[],
}

export default ({
  page, data, dataOfPage,
}: props) => {
  const index = +page.split(".")[1] || 0
  const dataDisplay = dataOfPage[index]
 
  if (dataDisplay.type === ISubPage.STEP_IDP_LIST) {
    return (
      <CardIDPBank
        page={page}
        dataOfPage={dataOfPage}
        data={data}
      />
    )
  }
  if (dataDisplay.type === ISubPage.NDID_CONFIRM_IDP_INFO) {
    return (
      <CardIDPVerify
        page={page}
        dataOfPage={dataOfPage}
        data={data}
      />
    )
  }
  if (dataDisplay.type === ISubPage.STEP_WAITING_NDID_VERIFY) {
    return (
      <CardIDPStatus
        page={page}
        dataOfPage={dataOfPage}
        data={data}
      />
    )
  }
  if (dataDisplay.type === ISubPage.STEP_PREVIEW_DATA_FROM_NDID) {
    return (
      <CardInformationPreview
        page={page}
        dataOfPage={dataOfPage}
        data={data}
      />
    )
  }
  if (dataDisplay.type === ISubPage.STEP_PROPERTY_LIST_NDID) {
    return (
      <CardServiceAppFeatureInfo
        page={page}
        dataOfPage={dataOfPage}
        data={data}
      />
    )
  }
  return (
    <CardNDIDTerms
      page={page}
      dataOfPage={dataOfPage}
      data={data}
    />
  )
}