import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components"
import { resetAoSteps, savePersonalInfoSaga } from "../../../actions";
import {
  Buttons,
  Card,
  Texts,
  Inputs,
  HistoryOfPath,
} from "../../../components"
import { fonts, mainFontSize } from "../../../config/themes";
import { ComponentContext } from "../../../contexts"
import {
  IFormsShema, 
  InputEnum, 
  IOpenAccountShema,
  IStore, 
} from "../../../interfaces";
import {
  chunk, 
  GetCurrentPage, 
  getErrorMessage, 
  handleErrorScreen, 
  resizeArrayWithNull, 
  useWindowDimensions, 
} from "../../../utilities";

interface props {
  page: string
  data?: IOpenAccountShema[],
  dataOfPage: IFormsShema[],
}

const ButtonHolder = styled.div`
  display: flex;
  justify-content: space-between;
`

export default ({
  page,
  data,
  dataOfPage,
}: props) => {
  const [errorMessages, setErrorMessages] = 
    React.useState<Array<{ field: string, message: Array<string> | string }>>([])
  const { width, height } = useWindowDimensions()
  const dispatch = useDispatch()
  const componentContext = useContext(ComponentContext);
  const openAccount = useSelector((state: IStore) => state.OpeningAccountReducer)
  const { t } = useTranslation()
  const { subPage } = GetCurrentPage(page)
  const dataDisplay = dataOfPage[+subPage]
  const mainData = openAccount.idCardInformation[dataDisplay.type]

  const onChange = (val: {key?: string, value?: string | number}) => {
    componentContext?.onChange({ key: val.key || "", value: val.value })
  }

  const onNext = () => {
    const { errors, validateFlag } = handleErrorScreen(dataDisplay.inputs, mainData)

    setErrorMessages(errors)
    if (!validateFlag) return
    dispatch(savePersonalInfoSaga())
  }

  const onBack = () => {
    dispatch(resetAoSteps())
  }

  const dataInput = width < 540
    ? chunk(dataDisplay.inputs, 1) 
    : resizeArrayWithNull(dataDisplay.inputs, [3, 3, 3, 3, 3, 3])
  
  return (
    <Card>
      <HistoryOfPath 
        data={dataOfPage.map((val) => val.title)}
        page={page}
        activeIndex={+subPage}
      />
      <div style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between", 
      }}
      >
        <Texts style={{ 
          color: "#0C67B1", 
          padding: "5px 0px 5px 0px", 
          fontSize: mainFontSize.l, 
          fontFamily: fonts.SarabunMedium,
        }}
        >
          {t("cardInformation.title")}
        </Texts>
      </div>

      <div style={{ marginBottom: 28 }}>
        {
          dataInput.map((vals, key) => (
            <div key={key.toString()} style={{ display: "flex", justifyContent: "space-between" }}>
              {
                vals && vals.map((val, i) => (
                  <div 
                    key={`items-idcard-${val.title}-${i.toString()}`}
                    style={{
                      display: "flex",
                      flex: 1,
                      flexDirection: "column",
                      marginLeft: i === 0 ? 0 : 15,
                    }}
                  >
                    {val && (
                      <Inputs 
                        config={{
                          type: val.type as InputEnum,
                          title: val.title,
                          field: val.field,
                          disabled: val.disabled,
                          errorMessage: getErrorMessage(val.field, errorMessages),
                          rules: val.rules,
                        }}
                        value={openAccount.idCardInformation.STEP_ID_CARD_INFO[val.field]}
                      />
                    )}
                  </div>
                ))
              }
            </div>
          ))
        }
      </div>
      
      <ButtonHolder>
        <Buttons
          style={{
            backgroundColor: "#ffffff",
            color: "#0067b1",
            borderWidth: 1,
            borderColor: "#0067b1",
          }}
          onClick={onBack}
        >
          {t("idCardUpload.buttonBack")}
        </Buttons>
        <Buttons 
          onClick={onNext}
        >
          {t("idCardUpload.buttonNext")}
        </Buttons>
      </ButtonHolder>
    </Card>
  )
}