import {
  call, debounce, put, takeLatest,
} from "redux-saga/effects";
import {
  OPENING_ACCOUNT_GETADDRESS_SAGA,
  OPENING_ACCOUNT_GETBUSINESS_SAGA,
  OPENING_ACCOUNT_GETCAREER_SAGA,
  OPENING_ACCOUNT_GETCOUNTRY_SAGA,
  OPENING_ACCOUNT_GETINVESTMENTPURPOSE_SAGA,
  OPENING_ACCOUNT_GETMONTHLYINCOME_SAGA,
  OPENING_ACCOUNT_GETSOURCEOFINCOME_SAGA,
  OPENING_ACCOUNT_GETTITLE_SAGA,
  OPENING_ACCOUNT_GET_REFERRAL_PEOPLE,
  setEmail,
  setMobileNo,
  setOpenAccountReducer, OPENING_ACCOUNT_GET_COUNTRY,
} from "../actions";
import {
  FindCountries,
  GetBusinessTypeApi,
  GetCareerApi,
  GetcountryApi,
  GetInvestmentPurposeApi,
  GetMonthlyIncomeApi,
  GetSourceOfIncomeApi,
  GetThaiAddressApi,
  GetTitlteApi,
  GetUsers,
  QueryReferralPeopleApi,
} from "../apis";
import {
  IFindCountriesResponse,
  IGetBusinessTypeResponse,
  IGetCareerResponse,
  IGetCountryResponse,
  IGetInvestmentPurposeResponse,
  IGetMonthlyIncomeResponse,
  IGetSourceOfIncomeResponse,
  IGetTitleResponse,
  IGetUsers,
  IQueryAddressResponse,
  IQueryReferralPeopleResponse, ISubPage,
} from "../interfaces";
import { handleStatusError } from ".";

function* WorkerGetAddress({ payload }: {
  payload: {
    text: string,
    callback:(res: any) => any
  }
  type: string
}) {
  try {
    const response: IQueryAddressResponse = yield GetThaiAddressApi(payload.text)
    if (response?.errors) {
      yield call(handleStatusError, response?.errors);
      return
    }
    yield call(payload.callback?.(response))
  } catch (error) {
    console.log(error)
  }
}

function* WorkerReferralPeople({ payload }: {
  payload: {
    text: string,
    type: Array<"MARKETING" | "IBA">,
    callback:(res: any) => any
  }
  type: string
}) {
  try {
    const response: IQueryReferralPeopleResponse =
    yield QueryReferralPeopleApi(payload.text, payload.type)
    if (response?.errors) {
      yield call(handleStatusError, response?.errors);
      return
    }
    yield call(payload.callback?.(response))
  } catch (error) {
    console.log(error)
  }
}

function* WorkerGetcountry({ payload }: {
  payload: {
    callback: (res: any) => any
  }
  type: string
}) {
  try {
    const response: IGetCountryResponse = yield call(GetcountryApi)
    if (response?.errors) {
      yield call(handleStatusError, response?.errors);
      return
    }
    yield call(payload.callback?.(response))
  } catch (error) {
    console.log(error)
  }
}

export function* WorkerGetTitle({ payload }: {
  payload?: {
    callback: (res: any) => any
  }
  type?: string
}) {
  try {
    const response: IGetTitleResponse = yield call(GetTitlteApi)
    if (response?.errors) {
      yield call(handleStatusError, response?.errors);
      return
    }

    if (payload?.callback) {
      yield call(payload?.callback, response)
    }
  } catch (error) {
    console.error(error)
  }
}

function* WorkerGetSourceOfIncome({ payload }: any) {
  try {
    const response: IGetSourceOfIncomeResponse = yield call(GetSourceOfIncomeApi)
    if (response?.errors) {
      yield call(handleStatusError, response?.errors);
      return
    }
    yield put(setOpenAccountReducer({
      page: payload.page,
      subPage: payload.subPage,
      addValue: {
        [payload.key]: response.data.GetSourceOfIncome.map((val) => ({
          title: val.displayTh,
          id: val.id,
          selected: false,
          requireOther: val.displayTh.includes("อื่น"),
        })),
      },
    }))
  } catch (error) {
    console.log(error)
  }
}

function* WorkerGetMonthlyIncome({ payload }: any) {
  try {
    const response: IGetMonthlyIncomeResponse = yield call(GetMonthlyIncomeApi)
    if (response?.errors) {
      yield call(handleStatusError, response?.errors);
      return
    }
    yield put(setOpenAccountReducer({
      page: payload.page,
      subPage: payload.subPage,
      addValue: {
        [payload.key]: response.data.GetMonthlyIncome.map((val, i) => ({
          title: val.displayTh,
          id: val.id,
          selected: i === 0,
          requireOther: val.displayTh.includes("อื่น"),
        })),
      },
    }))
    console.log("TEST GetMonthlyIncomeApi", response)
  } catch (error) {
    console.log(error)
  }
}

function* WorkerGetInvestmentPurpose({ payload }: any) {
  try {
    const response: IGetInvestmentPurposeResponse = yield call(GetInvestmentPurposeApi)
    if (response?.errors) {
      yield call(handleStatusError, response?.errors);
      return
    }
    yield put(setOpenAccountReducer({
      page: payload.page,
      subPage: payload.subPage,
      addValue: {
        [payload.key]: response.data.GetInvestmentPurpose.map((val) => ({
          title: val.displayTh,
          id: val.id,
          selected: false,
          requireOther: val.displayTh.includes("อื่น"),
        })),
      },
    }))
    console.log("TEST GetInvestmentPurposeApi", response)
  } catch (error) {
    console.log(error)
  }
}

function* WorkerGetCareer({ payload }: {
  payload: {
    callback: (res: any) => any
  }
  type: string
}) {
  try {
    const response: IGetCareerResponse = yield call(GetCareerApi)
    if (response?.errors) {
      yield call(handleStatusError, response?.errors);
      return
    }
    yield call(payload.callback?.(response))
    console.log("TEST GetCareerApi", response)
  } catch (error) {
    console.log(error)
  }
}

function* WorkerGetBusinessType({ payload }: any) {
  try {
    const response: IGetBusinessTypeResponse = yield call(GetBusinessTypeApi)
    if (response?.errors) {
      yield call(handleStatusError, response?.errors);
      return
    }
    yield call(payload.callback?.(response))
    console.log("TEST GetBusinessTypeApi", response)
  } catch (error) {
    console.log(error)
  }
}

export function* WorkerGetUsers() {
  try {
    const response: IGetUsers = yield call(GetUsers)
    if (response?.errors) {
      yield call(handleStatusError, response?.errors);
      return
    }
    yield put(setEmail(response.data.GetUserInfo.email))
    yield put(setMobileNo(response.data.GetUserInfo.mobileNumber))
    // eslint-disable-next-line consistent-return
    return response
  } catch (error) {
    console.error(error)
  }
}

function* WorkerGetCountry({ payload }: {
  payload: {
    contain: string,
    callback:(res: any) => void
  }
  type: string
}) {
  try {
    const resp: IFindCountriesResponse = yield call(FindCountries, payload.contain)
    if (resp?.errors) {
      yield call(handleStatusError, resp?.errors);
      return
    }
    payload.callback?.(resp.data.FindCountries)
  } catch (error) {
    console.error(error)
  }
}

export default function* WatcherMasterData() {
  yield debounce(1000, OPENING_ACCOUNT_GETADDRESS_SAGA, WorkerGetAddress)
  yield debounce(1000, OPENING_ACCOUNT_GET_REFERRAL_PEOPLE, WorkerReferralPeople)
  yield takeLatest(OPENING_ACCOUNT_GETCOUNTRY_SAGA, WorkerGetcountry)
  yield takeLatest(OPENING_ACCOUNT_GETTITLE_SAGA, WorkerGetTitle)
  yield takeLatest(OPENING_ACCOUNT_GETSOURCEOFINCOME_SAGA, WorkerGetSourceOfIncome)
  yield takeLatest(OPENING_ACCOUNT_GETMONTHLYINCOME_SAGA, WorkerGetMonthlyIncome)
  yield takeLatest(OPENING_ACCOUNT_GETINVESTMENTPURPOSE_SAGA, WorkerGetInvestmentPurpose)
  yield takeLatest(OPENING_ACCOUNT_GETCAREER_SAGA, WorkerGetCareer)
  yield takeLatest(OPENING_ACCOUNT_GETBUSINESS_SAGA, WorkerGetBusinessType)
  yield debounce(1000, OPENING_ACCOUNT_GET_COUNTRY, WorkerGetCountry)
}
