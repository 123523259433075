import React from "react"
import styled from "styled-components"

const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
  margin: 5px;
  cursor: pointer;
`

const Icon = styled.svg`
  fill: none;
  stroke: white;
  stroke-width: 2px;
`

const HiddenCheckbox = styled.input.attrs({ type: "checkbox" })`
  border: 0;
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`

const StyledCheckbox = styled("div")<{checked?: boolean}>`
  display: inline-block;
  width: 24px;
  height: 24px;
  font-weight: 300;
  background: ${(props) => (props.checked ? "#0C67B1" : "white")};
  border: 1px solid ${(props) => (props.checked ? "#0C67B1" : "#cccccc")};
  border-radius: 8px;
  transition: all 150ms;

  ${HiddenCheckbox}:focus + & {
    box-shadow: 0 0 0 3px pink;
  }

  ${Icon} {
    visibility: ${(props) => (props.checked ? "visible" : "hidden")};
  }
`

interface CheckBoxProps {
  children: React.ReactNode,
  onChange: () => void,
  selected?: boolean
  disabled?: boolean
}

export default ({
  children,
  onChange,
  selected,
  disabled,
}: CheckBoxProps) => (
  <CheckboxContainer onClick={disabled ? () => {} : onChange}>
    <HiddenCheckbox />
    <div style={{ display: "flex", alignItems: "center" }}>
      <StyledCheckbox checked={selected}>
        <Icon viewBox="0 0 24 24">
          <polyline points="20 6 9 17 4 12" />
        </Icon>
      </StyledCheckbox>
      <div style={{ flex: 1 }}>
        {children}
      </div>
    </div>
  </CheckboxContainer>
)
