import React, { useCallback } from "react"
import { useDropzone } from "react-dropzone"
import styled from "styled-components";
import { mainFontSize } from "../../../config/themes";
import Buttons from "../../Buttons";
import Image, { FileUpload } from "../../Images";
import { TBold } from "../../Texts";
// import { ButtonChoice } from "../../Buttons";
interface props {
  label?: string
  value: File | string
  disabled?: boolean
  // onChange: (value: IInputsShema[]) => void
  onChange: (obj: {value: File | null}) => void
}

const Box = styled("div")<{isDragActive: boolean}>`
  display: flex;
  border: ${(props) => (props.isDragActive ? "1.5px dashed #0067b1" : "1.5px dashed #cccccc")} ;
  border-radius: 10px;
  padding: 5px 5px 5px 5px;
  margin-top: 5px;
  flex:1;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  margin-bottom: 36px;
  margin-top: 34px;
  margin-right: 30px;
  margin-left: 30px;
`
const IconHolder = styled.div`
  display: flex;
  margin-top: 20px;
  margin-bottom: 5px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex:1;
  height: 100%;
  width: 100%;
`

const ImageHoler = styled.img`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex:1;
  height: 100%;
  width: 100%;
`

const ButtonHolder = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 10px;
  margin-bottom: 20px;
`

export default ({
  label, value, onChange, disabled,
}: props) => {
  const [files, setFiles] = React.useState<File | null>();
  const [base64, setBase64] = React.useState<string | null>();
  const onDrop = useCallback((acceptedFiles) => {
    const singleFile = acceptedFiles[0]
    onChange({ value: singleFile })
    setFiles(singleFile)
  }, [])

  const {
    getRootProps, 
    getInputProps, 
    isDragActive, 
  } = useDropzone({ 
    accept: "image/jpeg, image/jpg, image/png", 
    multiple: false, 
    onDrop, 
    disabled,
  })
  
  React.useEffect(() => {
    if (typeof value === "object" && value !== null) {
      setFiles(value)
    }
    if (typeof value === "string" && value !== null) {
      setBase64(value)
    }
  }, [value])

  const RenderImage = useCallback(() => {
    if (files) {
      return <ImageHoler src={URL.createObjectURL(files)} />
    }
    if (base64) {
      return <ImageHoler src={`data:image/jpeg;base64,${base64}`} />
    }
    return (
      <IconHolder>
        <Image style={{ width: "50px", height: "80px" }} src={FileUpload} />
        <TBold color="#666666" fontSize={mainFontSize.xs} mt="2px">
          {label || "ลากไฟล์มาที่นี่ หรือ"}
        </TBold>
      </IconHolder>
    )
  }, [files, base64])

  return (
    <Box
      {...getRootProps()}
      isDragActive={isDragActive}
    >
      <RenderImage />
      <input
        {...getInputProps()}
        multiple={false} 
        onChange={async (e) => {
          const file = e.target.files
          if (file) {
            onDrop(file)
          }
        }}
      />
      
      {!disabled && (
        <ButtonHolder>
          <Buttons
            disabled={disabled}
            style={{
              backgroundColor: "rgba(0,	103,	177, 0.05)", 
              color: "#0067b1",
              fontSize: 16,
            }}
          >
            {!isDragActive ? "เลือกไฟล์" : "วางไฟล์"}
          </Buttons>
        </ButtonHolder>
      )}
    </Box>

  )
}