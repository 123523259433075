import { ReactNode } from "react";

export enum ISubPage {
  STEP_TERM_CONDITION_PDPA = "STEP_TERM_CONDITION_PDPA",
  STEP_TERM_CONDITION_WEB = "STEP_TERM_CONDITION_WEB",
  STEP_SELECT_OPEN_TYPE_ACCOUNT = "STEP_SELECT_OPEN_TYPE_ACCOUNT",

  // todo
  STEP_TERM_CONDITION_EQUITY_AND_MUTUAL_FUND="STEP_TERM_CONDITION_EQUITY_AND_MUTUAL_FUND",
  // STEP_TERM_CONDITION_CASH_BALANCE_ACCOUNT="STEP_TERM_CONDITION_CASH_BALANCE_ACCOUNT",
  // STEP_TERM_CONDITION_MUTUAL_FUND_ACCOUNT="STEP_TERM_CONDITION_MUTUAL_FUND_ACCOUNT",
  STEP_TERM_CONDITION_CREDIT_BALANCE_ACCOUNT="STEP_TERM_CONDITION_CREDIT_BALANCE_ACCOUNT",
  STEP_TERM_CONDITION_SAVING_ACCOUNT="STEP_TERM_CONDITION_SAVING_ACCOUNT",
  STEP_TERM_CONDITION_TFEX="STEP_TERM_CONDITION_TFEX",
  STEP_TERM_CONDITION_TFEX_MOU_BLOCK_TRADE="STEP_TERM_CONDITION_TFEX_MOU_BLOCK_TRADE",
  STEP_TERM_CONDITION_CONSENT_CLAUSE_OF_RETAIL_CUSTOMER="STEP_TERM_CONDITION_CONSENT_CLAUSE_OF_RETAIL_CUSTOMER",

  STEP_VERIFY_IDENTITY = "STEP_VERIFY_IDENTITY",
  STEP_VERIFY_FATCA = "STEP_VERIFY_FATCA",
  STEP_VERIFY_PEP = "STEP_VERIFY_PEP",

  STEP_PROPERTY_LIST_NDID="STEP_PROPERTY_LIST_NDID",
  STEP_TERM_CONDITION_NDID="STEP_TERM_CONDITION_NDID",
  STEP_IDP_LIST="STEP_IDP_LIST",
  NDID_CONFIRM_IDP_INFO="NDID_CONFIRM_IDP_INFO",
  STEP_WAITING_NDID_VERIFY="STEP_WAITING_NDID_VERIFY",
  STEP_PREVIEW_DATA_FROM_NDID="STEP_PREVIEW_DATA_FROM_NDID",

  STEP_ID_CARD_INFO="STEP_ID_CARD_INFO",
  STEP_IMAGE_ID_CARD="STEP_IMAGE_ID_CARD",
  STEP_IMAGE_SELFIE_WITH_ID_CARD="STEP_IMAGE_SELFIE_WITH_ID_CARD",

  STEP_MARITAL_STATUS="STEP_MARITAL_STATUS",
  STEP_FRAUD="STEP_FRAUD",
  STEP_INCOME="STEP_INCOME",
  STEP_ID_CARD_ADDRESS="STEP_ID_CARD_ADDRESS",
  STEP_CURRENT_ADDRESS="STEP_CURRENT_ADDRESS",
  STEP_WORKPLACE_ADDRESS="STEP_WORKPLACE_ADDRESS",
  STEP_MAILING_ADDRESS="STEP_MAILING_ADDRESS",
  STEP_IMAGE_HOUSE_REGISTRATION = "STEP_IMAGE_HOUSE_REGISTRATION",
  STEP_EMERGENCY_CONTACT = "STEP_EMERGENCY_CONTACT",

  USER_SEND_ADDRESS_INFO="USER_SEND_ADDRESS_INFO",

  STEP_SAVING_ACCOUNT_SUBSCRIPTION="STEP_SAVING_ACCOUNT_SUBSCRIPTION",
  STEP_SAVING_ACCOUNT_REDEMPTION="STEP_SAVING_ACCOUNT_REDEMPTION",
  STEP_IMAGE_STATEMENT="STEP_IMAGE_STATEMENT",

  STEP_TFEX_PRE_TEST = "STEP_TFEX_PRE_TEST",
  STEP_TFEX_TEST = "STEP_TFEX_TEST",
  STEP_SUITABILITY_TEST = "STEP_SUITABILITY_TEST",
  STEP_SUITABILITY_SUMMARY_TEST = "STEP_SUITABILITY_SUMMARY_TEST",

  STEP_IMAGE_SIGNATURE="STEP_IMAGE_SIGNATURE",
// STEP_VERIFY_IDENTITY_DATA_WITH_OTP = "STEP_VERIFY_IDENTITY_DATA_WITH_OTP"
  STEP_OVERVIEW_ALL_DATA="STEP_OVERVIEW_ALL_DATA",
  STEP_STATUS_AO="STEP_STATUS_AO",
  STEP_SUBMIT_DATA="STEP_SUBMIT_DATA",
}

export interface IOpenAccountShema {
  title: string
  type: keyof typeof IPageOpenAccountOptionEnum
  form: IFormsShema[]
}

export interface IFormsShema {
  title: string
  subTitles?: Array<string>
  titleImage?: string
  inputs: IInputsShema[]
  type: keyof typeof ISubPage
}

export interface IInputsShema {
  type: string
  title: string
  subTitle?: string
  titleColor?: string,
  subTitleColor?: string,
  field: string
  placeholder?: string
  selected?: boolean
  value?: string | IInputsChoiceShema[] | IInputsRatioShema[] | null
  keyTarget?: string[]
  isHidden?: boolean
  description?: string
  image?: string
  rules?: Array<IRule>
  maxlength?: number
  maxFiles?: number

  BelowComp?: ReactNode
}

export interface fileProps {
  name: string
  contentType?: string
  data?: File | string
  imageType?: string
  status?: string
  errorMessage?: string
  isDelete? : boolean
}

export type IInputValueResponse =
  | string
  | number
  | boolean
  | File
  | Array<ICheckBoxs>
  | Array<string | boolean | fileProps>
  | null

export type UploadFileType =
  | "IMAGE_ID_CARD"
  | "IMAGE_SIGNATURE"
  | "IMAGE_BOOKBANK_REDEMPTION"
  | "IMAGE_HOUSE_REGISTRATION"
  | "IMAGE_SELFIE_WITH_ID_CARD"
  | "IMAGE_BOOKBANK_SUBSCRIPTION"
  | "IMAGE_STATEMENT"

export enum UploadFileTypeEnum {
  IMAGE_ID_CARD="IMAGE_ID_CARD",
  IMAGE_SIGNATURE="IMAGE_SIGNATURE",
  IMAGE_BOOKBANK_REDEMPTION="IMAGE_BOOKBANK_REDEMPTION",
  IMAGE_HOUSE_REGISTRATION="IMAGE_HOUSE_REGISTRATION",
  IMAGE_SELFIE_WITH_ID_CARD="IMAGE_SELFIE_WITH_ID_CARD",
  IMAGE_BOOKBANK_SUBSCRIPTION="IMAGE_BOOKBANK_SUBSCRIPTION",
  IMAGE_STATEMENT="IMAGE_STATEMENT",
}

export interface ICheckBoxs {
  title: string
  description?: string
  consentId?: string | number
  subTitle?: string
  type?: string
  selected?: boolean
  requireOther?: boolean
  id?: string
}

export interface IInputsChoiceShema {
  description?: string
  consentId?: string
  selected?: boolean
  title: string
  subTitle: string
  value: string
  requireOther?: boolean
}

export interface IInputsRatioShema {
  title: string
  value: boolean
  requireOther?: boolean
}

export interface IRule {
  type: keyof typeof IRuleEnum,
  minlength?: number,
  maxlength?: number,
  maxFileSize?: number,
  regex?: RegExp,
  errorMessage?: string
}

export enum IRuleEnum {
  REQUIRED="REQUIRED",
  FIXED_LENGTH="FIXED_LENGTH",
  MIN_LENGTH="MIN_LENGTH",
  MAX_LENGTH="MAX_LENGTH",
  BTW_LENGTH="BTW_LENGTH",
  FORMAT="FORMAT",
  NUMBER_ONLY="NUMBER_ONLY",
  DATE_FORMAT="DATE_FORMAT",
  MAX_FILE_SIZE="MAX_FILE_SIZE",
}

export type IRuleType =
  | IRuleEnum.REQUIRED
  | IRuleEnum.FIXED_LENGTH
  | IRuleEnum.MIN_LENGTH
  | IRuleEnum.MAX_LENGTH
  | IRuleEnum.BTW_LENGTH
  | IRuleEnum.FORMAT
  | IRuleEnum.NUMBER_ONLY
  | IRuleEnum.DATE_FORMAT
  | IRuleEnum.MAX_FILE_SIZE

export enum IPageOpenAccountOptionEnum {
  createPassword="createPassword",
  termAndConditoin="termAndConditoin",
  connectBank="connectBank",
  idCardInformation="idCardInformation",
  personalInformation="personalInformation",
  accountInformation="accountInformation",
  tfexQuestionaire="tfexQuestionaire",
  suitability="suitability",
  successFully="successFully",
}

export enum InputEnum {
  inputCommon = "inputCommon",
  inputOTP = "inputOTP",
  inputCheckbox = "inputCheckbox",
  inputRadio = "inputRadio",
  inputChoiceMulti = "inputChoiceMulti",
  inputChoiceSingle = "inputChoiceSingle",
  inputUploadFile = "inputUploadFile",
  inputUploadMultipleFiles = "inputUploadMultipleFiles",
  inputWithButton = "inputWithButton",
  inputTermsAndCons = "inputTermsAndCons",
  inputAutoComplete = "inputAutoComplete",
  inputDropdown = "inputDropdown",
  inputDatePicker = "inputDatePicker"
}

export enum IInputChoiceEnum {
  INPUT_CHOICE_CHECKBOX="INPUT_CHOICE_CHECKBOX",
  INPUT_CHOICE_RADIO="INPUT_CHOICE_RADIO"
}

export enum onChangeState {
  NEXT = "NEXT",
  BACK = "BACK",
  STEPPER = "STEPPER",
  SUBMIT = "SUBMIT",
}

export enum IDialogTypeEnum {
  TEST_DIALOG = "TEST_DIALOG",
  TEST_DIALOG2 = "TEST_DIALOG2",
  ERROR_DIALOG = "ERROR_DIALOG",

  INFO_DIALOG = "INFO_DIALOG",
  NOTICE_DIALOG = "NOTICE_DIALOG",
  CANCEL_DIALOG = "CANCEL_DIALOG"
}

export enum StatusOpenAccount {
  REGISTERED = "REGISTERED",
  VERIFIED = "VERIFIED",
  SUBMITTED = "SUBMITTED",
  APPROVED = "APPROVED",
  REJECTED = "REJECTED",
  EDITING = "EDITING",
  UPLOADED = "UPLOADED"
}

export enum IConsentType {
  EQUITY_AND_MUTUAL_FUND="EQUITY_AND_MUTUAL_FUND",
  CASH_BALANCE_ACCOUNT="CASH_BALANCE_ACCOUNT",
  CREDIT_BALANCE_ACCOUNT="CREDIT_BALANCE_ACCOUNT",
  MUTUAL_FUND_ACCOUNT="MUTUAL_FUND_ACCOUNT",
  SAVING_ACCOUNT="SAVING_ACCOUNT",
  TFEX="TFEX",

  CONSENT_CLAUSE_OF_RETAIL_CUSTOMER="CONSENT_CLAUSE_OF_RETAIL_CUSTOMER",
  SEVEN_ELEVEN="SEVEN_ELEVEN",
  FUNDCONNEXT="FUNDCONNEXT",
  TABLET="TABLET",
  PDPA="PDPA",
  NDID="NDID",
  WEB="WEB",
  APP="APP",
}
