import { IError } from "./error.interface";
import { IGraphqlResponse } from "./httpRequest.interface";

export interface IIdpResponse extends IGraphqlResponse {
  data: {
    ListIdp: {
      idpList: IItemsIdp[]
      onTheFlyList: IItemsIdp[]
      remainingVerify: number
    }
  }
}

export interface ICancelVerifyResponse extends IGraphqlResponse {
  data: {
    CancelVerification: {
      isSuccess: boolean
      message: string
    }
  }
}

export interface IIdpVerifyResponse extends IGraphqlResponse {
  data: {
    VerifyIdp: {
      verification: {
        service: string
        secret: string
      } | null
      error: IError | null
    }
  }
}

export interface IIdpCheckVerifyResponse extends IGraphqlResponse {
  data: {
    CheckStatusVerification: IStatusVerifyNDID | null
  }
}

export interface IIdpRetrieveDataResponse extends IGraphqlResponse {
  data: {
    RetrieveDataVerification: IRetrieveNDIDData | null
  }
}

export interface IConsentNDIDResponse extends IGraphqlResponse {
  data: {
    ConsentNdid: {
      isSuccess: boolean
    } | null
  }
}

export interface INDIDPayload {
  type: string
  payload: {
    key: string,
    value: any
  }
}

export interface IItemsIdp {
  image?: string
  idpID: string
  displayNameEN: string
  displayNameTH: string
  isUsage: boolean
  nodeName: string
  agent: boolean
  startServiceTime: string
  endServiceTime: string
  logo: string
}

export interface INDIDReducers {
  idp: IItemsIdp[]
  CHOOSE_IDP_NDID: IChooseIDPNDID
  STATUS_VERIFY_NDID: IStatusVerifyNDID
  RETRIEVE_DATA_NDID: IRetrieveNDIDData
  onTheFly: IItemsIdp[]
}

export interface IChooseIDPNDID {
  idpID: string
  displayNameEN: string
  displayNameTH: string
  logo: string
}

export enum IStatusNDID {
  "VALID"="VALID",
  "INVALID"="INVALID",
  "PENDING"="PENDING",
  "ERROR"="ERROR",
  "TIMEOUT"="TIMEOUT",
  "REJECTED"="REJECTED"
}

interface IStatusVerifyNDID {
  referenceID: string
  status: IStatusNDID
  verifyBy: string
  error: {
    errorMessageTH: string
    errorMessageEN: string
  }
}

export interface IRetrieveNDIDData {
  referenceId: string
  identificationCard: {
    type: string
    number: string
    issueDate: string
    issueCountry: string
    expiryDate: string
    isExpiryDate: string
  }
  gender: string
  title: string
  titleOther: string
  firstNameTh: string
  middleNameTh: string
  lastNameTh: string
  firstNameEn: string
  middleNameEn: string
  lastNameEn: string
  birthDate: string
  nationality: string
  maritalStatus: string
  identificationCardAddress: {
    addressId: string
    number: string
    village: string
    building: string
    floor: string
    room: string
    soi: string
    road: string
    moo: string
    postcode: string
    fullAddress: string
  }
  currentAddress: {
    addressId: string
    number: string
    village: string
    building: string
    floor: string
    room: string
    soi: string
    road: string
    moo: string
    postcode: string
    fullAddress: string
  }
  workplaceAddress: {
    addressId: string
    number: string
    village: string
    building: string
    floor: string
    room: string
    soi: string
    road: string
    moo: string
    postcode: string
    fullAddress: string
  }
  companyName: string
  occupation: string
  occupationOther: string
  monthlyIncomeLevel: string
  phoneNumber: string
  email: string
  transactionDate: string
}

export enum IdpListType {
  REGISTER="REGISTER",
  ON_THE_FLY="ON_THE_FLY",
}