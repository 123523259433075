// LOGIN
export const LOGIN = "LOGIN"
export const LOGIN_SET_STATE = "LOGIN_SET_STATE"

// KEY STEEPER
export const NAVIGATE_SET_WITH_KEY = "NAVIGATE_SET_WITH_KEY"
export const NEXT_NAVIGATE = "NEXT_NAVIGATE"
export const BACK_NAVIGATE = "BANK_NAVIGATE"
export const NEXT_SET_STEP = "NEXT_SET_STEP"
export const NEXT_SET_STEP_WITH_PARAMS = "NEXT_SET_STEP_WITH_PARAMS"

// OPENING_ACCOUNT
export const OPENING_ACCOUNT_SET_REDUCER = "OPENING_ACCOUNT_SET_REDUCER"
export const OPENING_ACCOUNT_SET_WITH_PAGE_REDUCER = "OPENING_ACCOUNT_SET_WITH_PAGE_REDUCER"
export const OPENING_ACCOUNT_VERIFY_REGISTER = "OPENING_ACCOUNT_VERIFY_REGISTER"
export const OPENING_ACCOUNT_SAVE_FATCA = "OPENING_ACCOUNT_SAVE_FATCA"
export const OPENING_ACCOUNT_GET_PEP = "OPENING_ACCOUNT_GET_PEP"
export const OPENING_ACCOUNT_VALIDATE_PEP = "OPENING_ACCOUNT_VALIDATE_PEP"
export const OPENING_ACCOUNT_SET_STATE = "OPENING_ACCOUNT_SET_STATE"
export const OPENING_ACCOUNT_GET_CONSENT = "OPENING_ACCOUNT_GET_CONSENT"
export const OPENING_ACCOUNT_SAVE_CONSENT = "OPENING_ACCOUNT_SAVE_CONSENT"
export const OPENING_ACCOUNT_SET_AO_STEP = "OPENING_ACCOUNT_SET_AO_STEP"
export const OPENING_ACCOUNT_GET_AO_STEP = "OPENING_ACCOUNT_GET_AO_STEP"

// SUITTEST
export const OPENING_ACCOUNT_GETSUIT_SAGA = "OPENING_ACCOUNT_GETSUIT_SAGA"
export const OPENING_ACCOUNT_SAVEANSWERSUIT_SAGA = "OPENING_ACCOUNT_SAVEANSWERSUIT_SAGA"
export const OPENING_PREVIEW_SUIT_ABILITY_TEST_RESULT = "OPENING_PREVIEW_SUIT_ABILITY_TEST_RESULT"
export const OPENING_ACCOUNT_SAVE_QUESTION_SUIT_REDUCER = "OPENING_ACCOUNT_SAVE_QUESTION_SUIT_REDUCER"
export const OPENING_ACCOUNT_SET_ANSWER_SUIT_REDUCER = "OPENING_ACCOUNT_SET_ANSWER_SUIT_REDUCER"
export const OPENING_ACCOUNT_GETANSWERSUIT_SAGA = "OPENING_ACCOUNT_GETANSWERSUIT_SAGA"
export const SUITTEST_SET_PREVIEW_TEST_SCORE = "SUITTEST_SET_PREVIEW_TEST_SCORE"
export const OPENING_ACCOUNT_PREVIEW_TFEX_TEST = "OPENING_ACCOUNT_PREVIEW_TFEX_TEST"
export const OPENING_ACCOUNT_SAVE_TFEX_TEST = "OPENING_ACCOUNT_SAVE_TFEX_TEST"
export const OPENING_ACCOUNT_SET_PREVIEW_TFEX = "OPENING_ACCOUNT_SET_PREVIEW_TFEX"

// UPLOAD FILE
export const OPENING_ACCOUNT_UPLOADFILE_SAGA = "OPENING_ACCOUNT_UPLOADFILE_SAGA"

// GET ADDRESS
export const OPENING_ACCOUNT_GETADDRESS_SAGA = "OPENING_ACCOUNT_GETADDRESS_SAGA"

// GET REFERRAL_PEOPLE
export const OPENING_ACCOUNT_GET_REFERRAL_PEOPLE = "OPENING_ACCOUNT_GET_REFERRAL_PEOPLE"
export const OPENING_ACCOUNT_GET_COUNTRY = "OPENING_ACCOUNT_GET_COUNTRY"

// AUTH
export const AUTH = "AUTH"
export const AUTH_SET_REDUCER = "AUTH_SET_REDUCER"
export const AUTH_SET_MOBILE_NO = "AUTH_SET_MOBILE_NO"
export const AUTH_SET_MOBILE_OTP_ANSWER = "AUTH_SET_MOBILE_OTP_ANSWER"
export const AUTH_SET_MOBILE_OTP_REQUEST = "AUTH_SET_MOBILE_OTP_REQUEST"
export const AUTH_SET_EMAIL = "AUTH_SET_EMAIL"
export const AUTH_SET_EMAIL_OTP_ANSWER = "AUTH_SET_EMAIL_OTP_ANSWER"
export const AUTH_SET_EMAIL_OTP_REQUEST = "AUTH_SET_EMAIL_OTP_REQUEST"
export const AUTH_SET_EMAIL_OTP_VERIFY = "AUTH_SET_EMAIL_OTP_VERIFY"
export const AUTH_SET_MOBILE_OTP_VERIFY = "AUTH_SET_MOBILE_OTP_VERIFY"
export const AUTH_SET_AUTH = "AUTH_SET_AUTH"
export const AUTH_SEND_EMAIL_OTP = "AUTH_SEND_EMAIL_OTP"
export const AUTH_SEND_MOBOLE_OTP = "AUTH_SEND_MOBOLE_OTP"
export const AUTH_VERIFY_MOBILE_OTP = "AUTH_VERIFY_MOBILE_OTP"
export const AUTH_VERIFY_EMAIL_OTP = "AUTH_VERIFY_EMAIL_OTP"
export const AUTH_VERIFY_OTP = "AUTH_VERIFY_OTP"
export const AUTH_REGISTER = "AUTH_REGISTER"
export const AUTH_CHECK_TARGET_EXISTS = "AUTH_CHECK_TARGET_EXISTS"
export const AUTH_LOGIN_WITH_OTP = "AUTH_LOGIN_WITH_OTP"
export const AUTH_VALIDATE_REGISTER_FIELD = "AUTH_VALIDATE_REGISTER_FIELD"

// NDID
export const NDID_GET_IDP = "NDID_GET_IDP"
export const NDID_VERIFY_IDP = "NDID_VERIFY_IDP"
export const NDID_CHECK_VERICATION_STATUS = "NDID_CHECK_VERICATION_STATUS"
export const NDID_RETRIEVE_DATA = "NDID_RETRIEVE_DATA"
export const NDID_FLOW = "NDID_FLOW"
export const NDID_CONSENT_NDID = "NDID_CONSENT_NDID"
export const NDID_CANCEL_VERIFICATION = "NDID_CANCEL_VERIFICATION"

// TFEX
export const TFEX_GET_QUESTION = "TFEX_GET_QUESTION"
export const TFEX_SAVE_QUESTION_REDUCER = "TFEX_SAVE_QUESTION_REDUCER"

// GET MASTER DATA
export const OPENING_ACCOUNT_GETCOUNTRY_SAGA = "OPENING_ACCOUNT_GETCOUNTRY_SAGA"
export const OPENING_ACCOUNT_GETTITLE_SAGA = "OPENING_ACCOUNT_GETTITLE_SAGA"
export const OPENING_ACCOUNT_GETSOURCEOFINCOME_SAGA = "OPENING_ACCOUNT_GETSOURCEOFINCOME_SAGA"
export const OPENING_ACCOUNT_GETMONTHLYINCOME_SAGA = "OPENING_ACCOUNT_GETMONTHLYINCOME_SAGA"
export const OPENING_ACCOUNT_GETINVESTMENTPURPOSE_SAGA = "OPENING_ACCOUNT_GETINVESTMENTPURPOSE_SAGA"
export const OPENING_ACCOUNT_GETCAREER_SAGA = "OPENING_ACCOUNT_GETCAREER_SAGA"
export const OPENING_ACCOUNT_GETBUSINESS_SAGA = "OPENING_ACCOUNT_GETBUSINESS_SAGA"

// SAVE
export const OPENING_ACCOUNT_SAVEPERSONALINFO_SAGA = "OPENING_ACCOUNT_SAVEPERSONALINFO_SAGA"
export const OPENING_ACCOUNT_SAVEBACKGROUNDINFO_SAGA = "OPENING_ACCOUNT_SAVEBACKGROUNDINFO_SAGA"
export const OPENING_ACCOUNT_SAVECONTACTINFO_SAGA = "OPENING_ACCOUNT_SAVECONTACTINFO_SAGA"
export const OPENING_ACCOUNT_SAVEMUTUALFUNDACCOUNT_SAGA = "OPENING_ACCOUNT_SAVEMUTUALFUNDACCOUNT_SAGA"
export const OPENING_ACCOUNT_SAVESAVINGACCOUNTTOMUTUALFUND_SAGA = "OPENING_ACCOUNT_SAVESAVINGACCOUNTTOMUTUALFUND_SAGA"
export const OPENING_ACCOUNT_QUERYALL_SAGA = "OPENING_ACCOUNT_QUERYALL_SAGA"
export const OPENING_ACCOUNT_SAVE_PERSONAL_CONTACTS_SAGA = "OPENING_ACCOUNT_SAVE_PERSONAL_CONTACTS_SAGA"
export const OPENING_ACCOUNT_SUBMIT_SAGA = "OPENING_ACCOUNT_SUBMIT_SAGA"
export const OPENING_ACCOUNT_SAVEAOSTEP_SAGA = "OPENING_ACCOUNT_SAVEAOSTEP_SAGA"
export const OPENING_ACCOUNT_GETACCOUNTTYPE_SAGA = "OPENING_ACCOUNT_GETACCOUNTTYPE_SAGA"

export const OPENING_ACCOUNT_GET_CONSENTS = "OPENING_ACCOUNT_GET_CONSENTS"
export const OPENING_ACCOUNT_SAVESELECTEDACCOUNT_SAGA = "OPENING_ACCOUNT_SAVESELECTEDACCOUNT_SAGA"
export const OPENING_ACCOUNT_SAVEREFERRAL_SAGA = "OPENING_ACCOUNT_SAVEREFERRAL_SAGA"
export const OPENING_ACCOUNT_GETCONSENTALL_SAGA = "OPENING_ACCOUNT_GETCONSENTALL_SAGA"
export const OPENING_ACCOUNT_RESET_AO_STEPS = "OPENING_ACCOUNT_RESET_AO_STEPS"
export const OPENING_ACCOUNT_GET_ALL_NO_TIN_REASONS = "OPENING_ACCOUNT_GET_ALL_NO_TIN_REASONS"

export const STEPS_SET_OA_STATUS = "STEPS_SET_OA_STATUS"
