/* eslint-disable react/no-this-in-sfc */
import React from "react"
import styled from "styled-components"
import Header from "../../components/Header"
import { styledMedia } from "../../components/PageWrapper"

const Container = styled.div`${styledMedia}`

export default () => {
  const obj = {
    name: "test",
    last: "last",
    fullname() {
      return `${this.name} ${this.last}`
    },
  }
  return (
    <Container>
      <Header />
      {obj.fullname()}
    </Container>
  )
}