import React, { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components"
import { navigateBack, navigateNext, saveConsent } from "../../../actions";
import {
  Buttons,
  Card,
  Images,
  Texts,
  HistoryOfPath,
  Inputs,
} from "../../../components"
import { NDIDLogo } from "../../../components/Images";
import { fonts, mainFontSize } from "../../../config/themes";
import { ComponentContext } from "../../../contexts";
import { 
  IFormsShema, 
  IOpenAccountShema, 
  InputEnum, 
  IStore,
  ISubPage,
} from "../../../interfaces";

interface props {
  page: string
  data: IOpenAccountShema[],
  dataOfPage: IFormsShema[],
}

const TextView = styled.div`
  background-color: rgba(204,204,204,0.15);
  padding: 10;
  max-height: 350px;
  overflow-y: scroll;
  border-radius: 10px;
  padding: 10px 15px 10px 15px;
  font-size: 11pt;
  line-height: 20pt;
  color: "#333333";
`
const ButtonHolder = styled.div`
  display: flex;
  justify-content: space-between;
`

export default ({
  page, data, dataOfPage,
}: props) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const index = +page.split(".")[1] || 0
  const dataDisplay = dataOfPage[index]
  const componentContext = useContext(ComponentContext);
  const { connectBank } = useSelector((state: IStore) => state.OpeningAccountReducer)
  const mainData = connectBank[dataDisplay.type]
  const onChange = (val: {key?: string, value?: string | boolean | number}) => {
    componentContext?.onChange({ key: val.key || "", value: val.value })
  }

  const onValidate = (): { 
    isRequireUpload: (false | { type: string; field: string; })[],
    validateButton: boolean[]
  } => {
    const dataInputs = dataDisplay.inputs
    const dataFromReducer = Object.keys(mainData)

    // Get KeyTarget other all subpage.
    const otherField = dataInputs.map((val, otherIndex) => dataInputs
      .map((input) => input.keyTarget)
      .filter((inputFilter) => inputFilter)
      .flat()
      .map((targetField) => ((val.field === targetField) && otherIndex))
      .filter((fil) => fil))
      .flat()

    const isRequireUpload = dataInputs.map((val) => val.type === "inputUploadFile" 
      && { type: val.type, field: val.field }).filter((val) => val)
  
    // Check data no field and check click required and selected yet?
    const addOtherTargetField: any[] = []
    const result = dataFromReducer.map((field, keyindex) => {
      if (Array.isArray(mainData[field])) {
        return mainData[field].map((val: any) => {
          if (val.selected && val.requireOther) {
            dataInputs.map((vv, iii) => iii === keyindex && addOtherTargetField.push(vv.keyTarget))
            return true
          }
          if (val.selected) return true
          return false
        }).some((someval: boolean) => someval)
      }
      return !!mainData[field]
    })

    // Trigger other field when other buttons are pressed.
    dataFromReducer
      .map((field, keyindex) => addOtherTargetField
        .flat()
        .map((itemTest1) => ((field === itemTest1) ? keyindex : false))
        .filter((val) => val)
        .toString())
      .forEach((vall) => vall && otherField.splice(otherField.indexOf(+vall), 1))

    return {
      isRequireUpload,
      validateButton: result.filter((val, inde) => !(otherField.some((vall) => inde === vall))),
    }
  }

  const onSaveRedux = (accept: boolean) => {
    if (dataDisplay.type === ISubPage.STEP_TERM_CONDITION_NDID) {
      dispatch(saveConsent({ page: ISubPage.STEP_TERM_CONDITION_NDID }))
    } else if (dataDisplay.type === ISubPage.STEP_PROPERTY_LIST_NDID) {
      dispatch(saveConsent({ page: ISubPage.STEP_PROPERTY_LIST_NDID }))
    }
  }
  
  const onBack = () => {
    onSaveRedux(false)
    onChange({ key: "BACK", value: 0 })
  }

  const onNext = () => {
    onSaveRedux(true)
    dispatch(navigateNext())
  }

  return (
    <Card>
      <HistoryOfPath 
        data={dataOfPage.map((val) => val.title)}
        page={page}
        activeIndex={index}
      />
        
      <div style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between", 
      }}
      >
        <Texts style={{ 
          color: "#0C67B1", 
          padding: "5px 0px 5px 0px", 
          fontSize: mainFontSize.l, 
          fontFamily: fonts.SarabunMedium,
        }}
        >
          {dataDisplay.title}
        </Texts>
      </div>

      <TextView>
        <Images style={{ width: "100%", height: "55px" }} src={NDIDLogo} />
        
      </TextView>

      {
        dataDisplay.inputs.map((val) => (
          Inputs({
            config: {
              type: val.type as InputEnum,
              title: val.title,
              field: val.field,
              description: val.description,
            },
            value: mainData[val.field],
          })
        ))
      }

      <ButtonHolder>
        { dataDisplay.type === ISubPage.STEP_PROPERTY_LIST_NDID 
          ? <div /> 
          : (
            <Buttons
              style={{
                backgroundColor: "#ffffff",
                color: "#0067b1",
                borderWidth: 1,
                borderColor: "#0067b1",
              }}
              onClick={onBack}
            >
              ย้อนกลับ
            </Buttons>
          )}
        
        <Buttons 
          disabled={!onValidate().validateButton.every((val) => val)}
          onClick={onNext}
        >
          {t("termsAndCon.buttonNext")}
        </Buttons>
      </ButtonHolder>
    </Card>
  )
}