import React, { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components"
import { 
  navigateNext,
  saveConsent,
} from "../../../actions";
import {
  Buttons,
  Card,
  HistoryOfPath,
  Inputs,
  Texts,
} from "../../../components";
import { fonts, mainFontSize } from "../../../config/themes";

import { ComponentContext } from "../../../contexts"
import {
  IFormsShema, 
  IOpenAccountShema, 
  InputEnum,
  IStore,
  ISubPage, 
} from "../../../interfaces";

interface props {
  page: string
  data: IOpenAccountShema[],
  dataOfPage: IFormsShema[],
}

const ButtonHolder = styled.div`
  display: flex;
  justify-content: space-between;
`

export default ({ page, data, dataOfPage }: props) => {
  const dispatch = useDispatch()
  const componentContext = useContext(ComponentContext);
  const { accountInformation } = useSelector((state: IStore) => state.OpeningAccountReducer)
  const { t } = useTranslation()
  const index = +page.split(".")[1] || 0
  const dataDisplay = dataOfPage[index]
  const mainData = accountInformation[dataDisplay.type]

  const onChange = (val: {key?: string, value?: string | boolean | number}) => {
    componentContext?.onChange({ key: val.key || "", value: val.value })
  }

  const onValidate = (): { 
    isRequireUpload: (false | { type: string; field: string; })[],
    validateButton: boolean[]
  } => {
    const dataInputs = dataDisplay.inputs
    const dataFromReducer = Object.keys(mainData)

    // Get KeyTarget other all subpage.
    const otherField = dataInputs.map((val, otherIndex) => dataInputs
      .map((input) => input.keyTarget)
      .filter((inputFilter) => inputFilter)
      .flat()
      .map((targetField) => ((val.field === targetField) && otherIndex))
      .filter((fil) => fil))
      .flat()

    const isRequireUpload = dataInputs.map((val) => val.type === "inputUploadFile" 
      && { type: val.type, field: val.field }).filter((val) => val)
  
    // Check data no field and check click required and selected yet?
    const addOtherTargetField: any[] = []
    const result = dataFromReducer.map((field, keyindex) => {
      if (Array.isArray(mainData[field])) {
        return mainData[field].map((val: any) => {
          if (val.selected && val.requireOther) {
            dataInputs.map((vv, iii) => iii === keyindex && addOtherTargetField.push(vv.keyTarget))
            return true
          }
          if (val.selected) return true
          return false
        }).some((someval: boolean) => someval)
      }
      return !!mainData[field]
    })

    // Trigger other field when other buttons are pressed.
    dataFromReducer
      .map((field, keyindex) => addOtherTargetField
        .flat()
        .map((itemTest1) => ((field === itemTest1) ? keyindex : false))
        .filter((val) => val)
        .toString())
      .forEach((vall) => vall && otherField.splice(otherField.indexOf(+vall), 1))

    return {
      isRequireUpload,
      validateButton: result.filter((val, inde) => !(otherField.some((vall) => inde === vall))),
    }
  }

  let initialSelectOtherField = false
  const onHandleOtherField = ({ field, isHidden }: { field: string, isHidden?: boolean }) => {
    if (Array.isArray(mainData[field])) {
      initialSelectOtherField = mainData[field]?.some(({ requireOther, selected }: { 
          requireOther: boolean
          selected: boolean
        }) => requireOther && selected)
    }
    const result = dataDisplay.inputs.some((tar) => (
      tar.keyTarget) && tar.keyTarget.some((key) => (initialSelectOtherField && key === field)))
    return !result && isHidden
  }

  const onNext = () => {
    if (dataDisplay.type === ISubPage.STEP_TERM_CONDITION_SAVING_ACCOUNT) { 
      dispatch(saveConsent({ page: ISubPage.STEP_TERM_CONDITION_SAVING_ACCOUNT })) 
    } 
    dispatch(navigateNext())
  }

  return (
    <Card>
      <HistoryOfPath 
        data={dataOfPage.map((val) => val.title)}
        page={page}
        activeIndex={index}
      />
      <div style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between", 
        
      }}
      >
        <Texts style={{ 
          color: "#0C67B1", 
          padding: "5px 0px 5px 0px", 
          fontSize: mainFontSize.xl, 
          fontFamily: fonts.SarabunMedium,
        }}
        >
          {dataDisplay.title}
        </Texts>
      </div>

      {
        dataDisplay.inputs.map((val) => (
          val && onHandleOtherField(val) ? null : (
            Inputs({
              config: {
                type: val.type as InputEnum,
                title: val.title,
                field: val.field,
                description: val.description,
              },
              value: mainData[val.field],
            })
          )))
      }
            
      <ButtonHolder style={{ marginTop: 20 }}>
        <Buttons
          style={{
            backgroundColor: "#ffffff", 
            color: "#0067b1", 
            borderWidth: 1, 
            borderColor: "#0067b1",
          }}
          onClick={() => onChange({ key: "BACK", value: 0 })}
        >
          ย้อนกลับ
        </Buttons>
        <Buttons 
          disabled={
            dataDisplay.type !== ISubPage.STEP_TERM_CONDITION_PDPA 
            && !onValidate().validateButton.every((val) => val)
          }
          onClick={onNext}
        >
          {t("termsAndCon.buttonNext")}
        </Buttons>
      </ButtonHolder>
    </Card>
  )
}