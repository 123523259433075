/* eslint-disable react/destructuring-assignment */
import React from "react"
import styled from "styled-components"
import { useWindowDimensions } from "../../utilities"

interface props {
  style?: React.CSSProperties
  children: React.ReactNode
  isMobile?: boolean
}

export const CardLogin = styled("div")<props>`
  background-color: #ffffff;
  border-radius: 10px;
  padding: 30px ${(props) => (props.isMobile ? "20px" : "50px")};
  margin-left: 12px;
  margin-right: 12px;
  box-shadow: 0px 0px 10px #00000020;
`

const Cards = styled("div")<props>`
  background-color: #ffffff;
  border-radius: 10px;
  padding: 30px ${(props) => (props.isMobile ? "20px" : "50px")};
  margin-left: ${(props) => (props.isMobile ? "12px" : "10%")};
  margin-right: ${(props) => (props.isMobile ? "12px" : "10%")};
  box-shadow: 0px 0px 10px #00000020;
  margin-bottom: 20px;
  width: 100vw;
`

export default (propss: props) => {
  const { width } = useWindowDimensions()
  return (
    <Cards {...propss} isMobile={width < 540}>
      {propss.children}
    </Cards>
  )
}