import { ICheckBoxs } from "./acManager.interface";
import { IGraphqlResponse, IVerification } from "./httpRequest.interface";

export interface IOpeningAccountState {
  createPassword: any
  termAndConditoin: any,
  connectBank: any,
  idCardInformation: any
  personalInformation: any
  accountInformation: any
  tfexQuestionaire: any
  suitability: any
  successFully: any
}

export interface IOpeningAccountReducerPayload {
  type: string
  payload: {
    page: string
    subPage: string
    addValue: {
      [key: string]: string
    }
  }
}

export interface IOpeningAccountApiResponse {
  statusCode: number,
  response: unknown
}

export interface IVerifyRegisterResponse extends IGraphqlResponse {
  data: {
    VerifyRegister: IVerifyRegisterState
  },
}

export interface ISaveFatcaResponse extends IGraphqlResponse {
  data: {
    SaveFatca: {
      verification?: IVerification,
      birthplace: {
        id: string
        place: string
        city: string
        countryCode: string
      },
      taxResidences: {
        id: string
        countryCode: string
        tin: string
        noTinReasonId: string
        noTinExplanation: string
      }[]
      error?: string
    }
  },
}

export interface IGetFatcaResponse extends IGraphqlResponse {
  data: {
    GetFatca: {
      isVerified: boolean,
      isAmerican: boolean,
    }
  },
}

export interface IGetPepResponse extends IGraphqlResponse {
  data: {
    GetPep: {
      isVerified: boolean,
      isPep: boolean,
      position: string,
    }
  },
}

export interface IValidatePepResponse extends IGraphqlResponse {
  data: {
    ValidatePep: {
      verification?: IVerification,
      error?: string
    }
  },
}

export interface IUploadFileResponse extends IGraphqlResponse {
  data: {
    SavePicture: {
      name: string
      id: number
      data: string
      content: string
    }
  },
}

// ====================================== master data ======================================

export interface IQueryAddressResponse extends IGraphqlResponse {
  data: {
    GetThaiAddress: {
      id: number
      "subdistrict_th": string
      "subdistrict_en": string
      "district_th": string
      "district_en": string
      "province_th": string
      "province_en": string
      "display_th": string
      "display_en": string
      zipcode: number
    }
  }
}

export interface IQueryReferralPeopleResponse extends IGraphqlResponse {
  data: {
    GetReferralPeople: {
      id: number
      nameTh: string
      nameEn: string
      email: string
    }
  }
}

export interface IQueryReferralPersonByIdResponse extends IGraphqlResponse {
  data: {
    GetReferralPersonById: {
      id: number
      nameTh: string
      nameEn: string
      email: string
    }
  }
}

interface IITEM_COUNTRY {
  id: string
  name: string
}
interface IITEM_MASTER_RESPONSE {
  id: string
  displayTh: string
  displayEn: string
}

export interface IGetCountryResponse extends IGraphqlResponse {
  data: {
    GetCountry: IITEM_COUNTRY[]
  }
}

export interface IGetTitleResponse extends IGraphqlResponse {
  data: {
    GetTitle: IITEM_MASTER_RESPONSE[]
  }
}

export interface IGetSourceOfIncomeResponse extends IGraphqlResponse {
  data: {
    GetSourceOfIncome: IITEM_MASTER_RESPONSE[]
  }
}

export interface IGetMonthlyIncomeResponse extends IGraphqlResponse {
  data: {
    GetMonthlyIncome: IITEM_MASTER_RESPONSE[]
  }
}

export interface IGetInvestmentPurposeResponse extends IGraphqlResponse {
  data: {
    GetInvestmentPurpose: IITEM_MASTER_RESPONSE[]
  }
}

export interface IGetCareerResponse extends IGraphqlResponse {
  data: {
    GetCareer: IITEM_MASTER_RESPONSE[]
  }
}

export interface IGetBusinessTypeResponse extends IGraphqlResponse {
  data: {
    GetBusinessType: IITEM_MASTER_RESPONSE[]
  }
}

export interface IGetQueryAllMasterResponse extends IGraphqlResponse {
  data: {
    GetCountry: IITEM_COUNTRY[]
    GetTitle: IITEM_MASTER_RESPONSE[]
    GetSourceOfIncome: IITEM_MASTER_RESPONSE[]
    GetMonthlyIncome: IITEM_MASTER_RESPONSE[]
    GetInvestmentPurpose: IITEM_MASTER_RESPONSE[]
    GetCareer: IITEM_MASTER_RESPONSE[]
    GetBusinessType: IITEM_MASTER_RESPONSE[]
  }
}

// ====================================== saga save data ===============================
export interface ISavePersonalInfoResponse extends IGraphqlResponse {
  data: {
    SavePersonalInfo: {
      isSuccess: string
      message: string
    }
  }
}

export interface ISaveBackgroundInfoResponse extends IGraphqlResponse {
  data: {
    SaveBackgroundInfo: {
      isSuccess: string
      message: string
    }
  }
}

export interface ISaveMutualFundAccountDetailResponse extends IGraphqlResponse {
  data: {
    UpdateMutualFundAccountDetail: {
      isSuccess: string
      message: string
    }
  }
}

export interface ISaveContactInfoResponse extends IGraphqlResponse {
  data: {
    SaveContactInfo: {
      isSuccess: string
      message: string
    }
  }
}

export interface ISaveMutualFundResponse extends IGraphqlResponse {
  data: {
    CreateOrUpdateMutualFundAccount: {
      isSuccess: string
      message: string
    }
  }
}

export interface ISaveSavingFualFundResponse extends IGraphqlResponse {
  data: {
    UpdateSavingAccountToMutualFund: {
      isSuccess: string
      message: string
    }
  }
}

export interface IGetConsentResponse extends IGraphqlResponse {
  data: {
    GetConsent: {
      consent: Array<{
        version: number
        consentId: number
        consentEn: string
        consentTh: string
        labelTitleEn: string
        labelTitleTh: string
        labelSubEn: string
        labelSubTh: string
      }>
    }
  }
}

export interface IGetUserConsentResponse extends IGraphqlResponse {
  data: {
    GetUserConsent: {
      consent: Array<{
        version: number
        consentId: number
        consentEn: string
        consentTh: string
        labelTitleEn: string
        labelTitleTh: string
        labelSubEn: string
        labelSubTh: string
        accept: boolean
      }>
    }
  }
}

export interface ISaveConsentResponse extends IGraphqlResponse {
  data: {
    saveConsent: {
      isSuccess: boolean
    }
  }
}

export interface ISetAoStepResponse extends IGraphqlResponse {
  data: {
    SetAoStep: {
      step: Array<IMainStep>
      mainCurrentStep: string
      subCurrentStep: string
    }
  }
}

export interface IGetAoStepResponse extends IGraphqlResponse {
  data: {
    GetAoStep: {
      step: Array<IMainStep>
      mainCurrentStep: string
      subCurrentStep: string
    }
  }
}

export interface IMainStep {
  stepName: string
  isPass: boolean
  titleEn: string
  titleTh: string
  subStep: Array<SubMainStep>
  allowEdit: boolean
}

export interface SubMainStep {
  stepName: string
  titleEn: string
  titleTh: string
  isPass: boolean
  status: string
  allowEdit: boolean
}

export interface IGetUsers extends IGraphqlResponse {
  data: {
    GetUserInfo: {
      email: string
      mobileNumber: string
      citizenCardNumber: string
      oaStatus: string
    }
  }
}

export interface IGetOpenAccount extends IGraphqlResponse {
  data: {
    GetAccountType: {
      typeID: string
      typeNameTH: string
      typeNameEN: string
      descriptionTH: string
      descriptionEN: string
      consentType: string
    }[]
  }
}

export interface IGetImageDocumentResponse extends IGraphqlResponse {
  data: {
    GetImageDocument: IGetImageDocumentItems[]
  }
}

export interface IGetImageDocumentItems {
  imageBase64Encode: string
  imageType: string
  status: string
}

export interface ISelectedAccountReponse extends IGraphqlResponse {
  data: {
    SaveSelectedAccount: {
      isSuccess: boolean
      message: string
    }
  }
}

export interface ISubmitReponse extends IGraphqlResponse {
  data: {
    SubmitData: {
      isSuccess: boolean
    }
  }
}

export interface ICreateOrUpdatePersonalContacts extends IGraphqlResponse {
  data: {
    CreateOrUpdatePersonalContacts: {
      isSuccess: boolean
      message: string
    }
  }
}

export interface ISaveReferralPersonResponse extends IGraphqlResponse {
  data: {
    SaveReferralPerson: {
      isSuccess: boolean
      message: string
    }
  }
}

export interface IResetAoStepsResponse extends IGraphqlResponse {
  data: {
    ResetAoSteps: {
      isSuccess: boolean
      message: string
    }
  }
}

export interface IGetAllNoTinReasonsResponse extends IGraphqlResponse {
  data: {
    GetAllNoTinReasons: {
      id: string
      reasonTh: string
      reasonEn: string
    }[]
  }
}

export interface IFindCountriesResponse extends IGraphqlResponse {
  data: {
    FindCountries: {
      code: string
      nameTh: string
    }[]
  }
}

// ====================================== schema reducer openaccount ===============================

type ITypeOfItem =
  | string
  | boolean
  | ICheckBoxs[]
  | Array<string>
  | Array<Record<string, unknown>>
  | Record<string, unknown>

export interface IVerifyRegisterState {
  verification?: IVerification,
  error?: string
}

export interface IGetObjPage {
  [key:string]: {
    [subPage: string]: {
      [item: string]: ITypeOfItem
    }
  }
}
export const getObjPage = <T extends IGetObjPage,
                            U extends keyof T>(obj: T) => (key: U) => obj[key];

export interface IGetObjSubPage {
  [subPage: string]: {
    [item: string]: ITypeOfItem
  }
}

export const getObjSubPage = <T extends IGetObjSubPage,
                            U extends keyof T>(obj: T) => (key: U) => obj[key];
