import React from "react";
import {
  IFormsShema, 
  IOpenAccountShema, 
} from "../../../interfaces";
import CardTermAndCons from "./CardTermAndCons";

interface props {
  page: string
  data: IOpenAccountShema[],
  dataOfPage: IFormsShema[],
}

export default ({ page, data, dataOfPage }: props) => (
  <CardTermAndCons
    page={page}
    dataOfPage={dataOfPage}
    data={data}
  />
)
