import { applyMiddleware } from "redux"
import createSagaMiddleware from "redux-saga"
import { NEXT_NAVIGATE, NEXT_SET_STEP, OPENING_ACCOUNT_SET_AO_STEP } from "../actions"
import { IStore } from "../interfaces"
import rootSaga from "../sagas"
import ConfigureStore from "./configureStore"

const sagaMiddleware = createSagaMiddleware()

const smartMiddleware = (store: any) => (next: any) => (action: any) => {
  // console.log("WorkerSetAoStep", action?.type === NEXT_NAVIGATE && "------------------------")
  // console.log("WorkerSetAoStep", store.getState()?.StepsReducer.step, action)
  // alert(store.getState()?.StepsReducer.step)
  if (action?.type === NEXT_SET_STEP || action?.type === NEXT_NAVIGATE) {
    store.dispatch({
      type: OPENING_ACCOUNT_SET_AO_STEP,
      payload: { itemStep: store.getState()?.StepsReducer.step }, 
    })
  }
  return next(action);
}

const middlewares = [sagaMiddleware, smartMiddleware]

const middleWare = applyMiddleware(...middlewares)

const store = ConfigureStore(middleWare)

export default store

sagaMiddleware.run(rootSaga)
