export const sendEmailOtpSchema = `mutation SendEmailOtp($input: SendEmailOtpInput) {
  SendEmailOtp(input: $input) {
      expireAt
      reference
  }
}`

export const sendMobileOtpSchema = `mutation sendMobileOtp($input: SendMobileOtpInput) {
  SendMobileOtp(input: $input) {
      expireAt
      reference
  }
}`

export const verifyMobileOtpSchema = `mutation verifyMobileOtp($input: VerifyMobileOtpInput) {
  VerifyMobileOtp(input: $input) {
      verification{
          service
          secret
      }
      error
  }
}`

export const verifyEmailOtpSchema = `mutation verifyEmailOtp($input: VerifyEmailOtpInput) {
  VerifyEmailOtp(input: $input) {
      verification{
          service
          secret
      }
      error
  }
}`

export const registerSchema = `mutation ($input: RegisterInput){
  Register(input: $input) {
      accessToken
  }
}`