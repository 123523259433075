import React, { useContext, useEffect } from "react"

import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux"
import styled from "styled-components"
import { navigateSetStep, saveSuitAnswerSaga } from "../../../actions"
import {
  Buttons,
  Card,
  Inputs,
  SuittestRiskLevel,
  Texts,
} from "../../../components"
import { fonts, mainFontSize } from "../../../config/themes"
import { ComponentContext } from "../../../contexts"
import {
  ICheckBoxs,
  IDialogTypeEnum,
  IFormsShema,
  InputEnum,
  IStore,
} from "../../../interfaces"
import { useWindowDimensions } from "../../../utilities";

interface props {
  title: string
  data: IFormsShema
}

const ButtonHolder = styled.div`
  display: flex;
  justify-content: space-between;
`

export default ({
  title,
  data,
}: props) => {
  const { width, height } = useWindowDimensions()
  const componentContext = useContext(ComponentContext);
  const { suitability } = useSelector((state: IStore) => state.OpeningAccountReducer)
  const { data: dataFlow } = useSelector((state: IStore) => state.StepsReducer)
  const { previewTestScore } = useSelector((state: IStore) => state.SuitReducer)

  const mainData = suitability[data.type]

  const dispatch = useDispatch()
  const { t } = useTranslation()

  const onChange = (val: {key?: string, value?: string | number | Array<ICheckBoxs>}) => {
    componentContext?.onChange({ key: val.key || "", value: val.value })
  };

  const generateDot = (): string => {
    let dots = ""
    for (let i = 0; i < (width < 540 ? 100 : 180); i += 1) {
      dots += "."
    }
    return dots
  }

  const RecomendInvestor = () => (
    <div style={{ margin: "25px 0px 15px 0px", overflow: "hidden" }}>
      <Texts style={{
        color: "#0C67B1",
        padding: "5px 0px 15px 0px",
        fontSize: mainFontSize.s,
        fontFamily: fonts.SarabunMedium,
      }}
      >
        แนะนำสัดส่วนการลงทุน
      </Texts>
      {
        previewTestScore?.suggestAssetAllocation.map((item) => {
          let percentage = ""
          if (item.percentage[0] === "<") {
            percentage = `น้อยกว่า ${item.percentage.replace("<", "")}`
          } else if (item.percentage[0] === ">") {
            percentage = `มากกว่า ${item.percentage.replace(">", "")}`
          } else {
            percentage = item.percentage
          }

          return (

            <div style={{
              position: "relative", display: "flex", flexDirection: "row", alignItems: "flex-end", marginTop: 15,
            }}
            >
              <Texts style={{
                color: "#333333",
                padding: "5px 5px 5px 0px",
                fontSize: mainFontSize.s,
                fontFamily: fonts.SarabunMedium,
                position: "absolute",
                backgroundColor: "#FFFFFF",
              }}
              >
                {item.labelTh}
                {item.order}
              </Texts>
              <Texts style={{
                color: "#0C67B1",
                padding: "5px 0px 5px 0px",
                fontSize: mainFontSize.xs,
                fontFamily: fonts.SarabunMedium,
                flex: 1,
              }}
              >
                {generateDot()}
              </Texts>
              <Texts style={{
                color: "#333333",
                padding: "5px 0px 5px 5px",
                fontSize: mainFontSize.s,
                fontFamily: fonts.SarabunMedium,
                position: "absolute",
                backgroundColor: "#FFFFFF",
                right: 0,
              }}
              >
                {percentage}
              </Texts>
            </div>
          )
        })
      }

    </div>
  )

  const disabledButton = (): boolean => {
    console.log("disabedButton", suitability.STEP_SUITABILITY_SUMMARY_TEST?.acceptSuitabilityResult[0]?.selected)
    return !suitability.STEP_SUITABILITY_SUMMARY_TEST?.acceptSuitabilityResult[0]?.selected;
  }

  const onNext = () => {
    dispatch(saveSuitAnswerSaga({ isSave: true }))
  }

  const onBack = () => {
    const pageNumber = dataFlow.findIndex((val: { type: string; }) => val.type === "suitability").toString()
    dispatch(navigateSetStep(pageNumber))
  }

  return (
    <Card>
      <SuittestRiskLevel title={title} level={previewTestScore.investorLevel} />
      <RecomendInvestor />
      <Inputs
        config={{
          type: data?.inputs[0]?.type as InputEnum,
          field: data?.inputs[0]?.field,
        }}
        value={mainData[data?.inputs[0]?.field]}
      />
      <ButtonHolder>
        <Buttons
          style={{
            backgroundColor: "#ffffff",
            color: "#0067b1",
            borderWidth: 1,
            borderColor: "#0067b1",
          }}
          onClick={onBack}
        >
          ย้อนกลับ
        </Buttons>
        <Buttons
          onClick={() => onNext()}
          disabled={disabledButton()}
        >
          {t("idCardUpload.buttonNext")}
        </Buttons>
      </ButtonHolder>
    </Card>
  )
}
