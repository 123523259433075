// ===================================== exsample resizeArray =====================================
// data   = [{ a: "1" }, { a: "2" },{ a: "1" }, { a: "2" },{ a: "1" }, { a: "2" },{ a: "2" }];
// call   = resizeArray(data, [2])
// result = [
//   [ { a: '1' }, { a: '1' } ],
//   [ { a: '1' } ]
// ]
export const resizeArray = (arr: any[], eachRow: number[]) => {
  const chunks = [];
  let i = 0;
  let first = 0;
  let end = eachRow[0];
  while (i < eachRow.length) {
    chunks.push(arr.slice(first, end));
    first = end;
    end = first + eachRow[i + 1];
    i += 1;
  }
  return chunks;
}

// ======================================== exsample chunk ========================================
// data   = [{ a: "1" }, { a: "2" },{ a: "1" }, { a: "2" },{ a: "1" }, { a: "2" },{ a: "2" }];
// call   = chunk(data, 2)
// result = [
//   [ { a: '1' }, { a: '2' } ],
//   [ { a: '1' }, { a: '2' } ],
//   [ { a: '1' }, { a: '2' } ],
//   [ { a: '2' }, null ]
// ]
export const chunk = (arr: any[], size: number) => {
  let index = 0;
  const chunked = [];

  while (index < arr.length) {
    const slice = arr.slice(index, index + size);
    if (slice.length < size) {
      for (let i = slice.length; i < size; i += 1) {
        slice.push(null);
      }
    }
    chunked.push(slice);
    index += size;
  }
  return chunked;
};

// ================================ exsample resizeArrayWithNull ================================
// data   = [{ a: "1" }, { a: "2" },{ a: "1" }, { a: "2" },{ a: "1" }, { a: "2" },{ a: "2" }];
// call   = resizeArrayWithNull(data, [3,2,1,1,2])
// result = [
//   [ { a: '1' }, { a: '2' }, { a: '1' } ],
//   [ { a: '2' }, { a: '1' }, null ],
//   [ { a: '2' }, null, null ],
//   [ { a: '2' }, null, null ]
// ]
export const resizeArrayWithNull = (arr: any[], eachRow: number[] | number) => {
  if (typeof eachRow === "number") return chunk(arr, eachRow)
  const chunks = [];
  const maxValue = Math.max(...eachRow)
  let i = 0;
  let first = 0;
  let end = eachRow[0];
  while (i < eachRow.length) {
    const slice = arr.slice(first, end);
    if (slice.length < maxValue) {
      for (let ii = slice.length; ii < maxValue; ii += 1) {
        slice.push(null);
      }
    }
    chunks.push(slice)
    first = end;
    end = first + eachRow[i + 1];
    i += 1;
  }
  return chunks
    .map((item) => item.some((val) => val) && item)
    .filter((val) => val);
};

// ==============================================================================================
// ======================================== For Suit Flow =======================================
// Change layout array
export const getLayoutAnswer = (data: any): { [name: string]: string } => Object.keys(data)
  .reduce((pre: { [name: string]: string }, key:string) => ({
    ...pre, 
    [key]: data[key].answer,
  }), {})

export const mapDataAnsWithQuestionChoice = (
  suitability:any, 
  questionChoices:any, 
  questionSuit: any,
) => {
  console.log("suitability", suitability)
  const resultCompareData = Object.keys(suitability)
    .map((val: string, index: number) => questionChoices[index]
      .map((vv: {value: number, label: {th: string, en: string}}) => {
        // Check is suitability in questionChoices?
        if (vv.label.th === suitability[val].toString()) return vv.value.toString()
        // Check is suitability[] in questionChoices?
        if (suitability[val].length > 1) {
          return suitability[val]
            .map((vvv: string) => (vvv === vv.label.th && vv.value.toString()))
            .filter((fil: string) => fil).flat()
        }
        // Check it is all not found retrun false
        return false
      }).filter((fil: string) => fil))
    // Merge array with flat()
    .map((val) => ((val.length > 1) ? val.flat() : val))

  // Change layout array
  return Object.keys(suitability)
    .reduce((pre: {
      result: { [name: string]: string }, 
      someSuitEmptyArr: boolean[]
    }, key:string, index) => {
      pre.someSuitEmptyArr.push(questionSuit[index].answerType === "CHECKBOX" 
        ? resultCompareData[index].every((item: string) => item) : 
        !(resultCompareData[index].toString() === ""))
      pre.result = ({
        ...pre.result, 
        [key]: questionSuit[index].answerType === "CHECKBOX"
          ? resultCompareData[index] 
          : resultCompareData[index].toString(),
      })
      return pre
    }, { 
      result: {}, 
      someSuitEmptyArr: [],
    })
}
