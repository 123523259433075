import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components"
import { navigateBack, navigateNext, saveUploadFileSaga } from "../../../actions";
import {
  Buttons,
  Card,
  Texts,
  Inputs,
  Images,
  HistoryOfPath,
} from "../../../components"
import { houseAddress } from "../../../components/Images"
import { showDialog } from "../../../components/Provider/dialog";
import { fonts, mainFontSize } from "../../../config/themes";
import { ComponentContext } from "../../../contexts"
import {
  IFormsShema, 
  InputEnum, 
  IInputsShema, 
  IOpenAccountShema,
  IStore,
  UploadFileType, 
  IDialogTypeEnum,
} from "../../../interfaces";
import { getErrorMessage, handleErrorScreen } from "../../../utilities";

interface props {
  page: string
  data?: IOpenAccountShema[],
  dataOfPage: IFormsShema[],
}

const TextView = styled.div`
  background-color: rgba(204,204,204,0.15);
  padding: 10;
  border-radius: 10px;
  padding: 20px 10px 20px 10px;
  font-size: 11pt;
  line-height: 20pt;
  color: "#333333";
  font-weight: 0.6;
  display: flex;
  flex-direction: row;
  margin-bottom: 36px;
  margin-top: 34px;
  margin-right: 30px;
  margin-left: 30px;
`

const ButtonHolder = styled.div`
  display: flex;
  justify-content: space-between;
`

const TextHolder = styled.div`
  display: flex;
  padding: 0px 15px 0px 15px;
  flex-direction: column;
  flex:1;
`

export default ({
  page, data, dataOfPage,
}: props) => {
  const [errorMessages, setErrorMessages] = 
    React.useState<Array<{ field: string, message: Array<string> | string }>>([])
  const { t } = useTranslation()
  const index = +page.split(".")[1] || 0
  const dataDisplay = dataOfPage[index]
  const componentContext = useContext(ComponentContext);
  const dispatch = useDispatch()
  const openAccount = useSelector((state: IStore) => state.OpeningAccountReducer)
  const mainData = openAccount.accountInformation[dataDisplay.type]

  const onChange = (val: {key?: string, value?: string | number}) => {
    componentContext?.onChange({ key: val.key || "", value: val.value })
  }

  const onValidate = (): { 
    isRequireUpload: (false | { type: string; field: string; })[],
    validateButton: boolean[]
  } => {
    const dataInputs = dataDisplay.inputs
    const dataFromReducer = Object.keys(mainData)

    // Get KeyTarget other all subpage.
    const otherField = dataInputs.map((val, otherIndex) => dataInputs
      .map((input) => input.keyTarget)
      .filter((inputFilter) => inputFilter)
      .flat()
      .map((targetField) => ((val.field === targetField) && otherIndex))
      .filter((fil) => fil))
      .flat()

    const isRequireUpload = dataInputs.map((val) => (val.type === "inputUploadFile" || val.type === "inputUploadMultipleFiles")
      && { type: val.type, field: val.field }).filter((val) => val)
  
    // Check data no field and check click required and selected yet?
    const addOtherTargetField: any[] = []
    const result = dataFromReducer.map((field, keyindex) => {
      if (Array.isArray(mainData[field])) {
        return mainData[field].map((val: any) => {
          if (val.selected && val.requireOther) {
            dataInputs.map((vv, iii) => iii === keyindex && addOtherTargetField.push(vv.keyTarget))
            return true
          }
          if (val.selected) return true
          return false
        }).some((someval: boolean) => someval)
      }
      return !!mainData[field]
    })

    // Trigger other field when other buttons are pressed.
    dataFromReducer
      .map((field, keyindex) => addOtherTargetField
        .flat()
        .map((itemTest1) => ((field === itemTest1) ? keyindex : false))
        .filter((val) => val)
        .toString())
      .forEach((vall) => vall && otherField.splice(otherField.indexOf(+vall), 1))

    return {
      isRequireUpload,
      validateButton: result.filter((val, inde) => !(otherField.some((vall) => inde === vall))),
    }
  }
  
  const onNext = () => {
    const term = openAccount?.termAndConditoin?.STEP_SELECT_OPEN_TYPE_ACCOUNT?.selectedAccounts
    if (term.CASH_ACCOUNT || term.CREDIT_BALANCE_ACCOUNT || term.TFEX) {
      if (mainData.IMAGE_STATEMENT.length === 0) {
        showDialog(
          IDialogTypeEnum.NOTICE_DIALOG, 
          { message: "ท่านจำเป็นต้องแนบเอกสารบัญชีธนาคาร เนื่องจากได้เลือกบัญชีประเภท Cash Account/Credit Balance/Derivatives" },
          true,
        )
        return
      }
    }

    const { errors, validateFlag } = handleErrorScreen(dataDisplay.inputs, mainData)
    setErrorMessages(errors)
    if (!validateFlag) return

    if (onValidate().isRequireUpload.length) {
      onValidate().isRequireUpload.forEach(({ field }: any) => {
        dispatch(saveUploadFileSaga({
          files: mainData[field],
          field, 
        }))
      })
    }
  }
  const onBack = () => {
    onChange({ key: "BACK", value: 0 })
  }

  return (
    <Card>
      <HistoryOfPath
        data={dataOfPage.map((val) => val.title)}
        page={page}
        activeIndex={index}
      />
      <div style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between", 
      }}
      >
        <Texts style={{ 
          color: "#0C67B1", 
          padding: "5px 0px 5px 0px", 
          fontSize: mainFontSize.l, 
          fontFamily: fonts.SarabunMedium,
        }}
        >
          {dataDisplay.title}
        </Texts>
      </div>

      <div style={{ marginBottom: 28 }} />

      <TextView style={{
        width: "90%", height: "80%", margin: "auto", flex: 1,
      }}
      >
        <Images
          style={{
            width: "40%", 
            height: "40%", 
            padding: "", 
            flex: 1,
            
          }}
          src={houseAddress}
        />
        <TextHolder>
          <Texts style={{ 
            color: "#333333", 
            padding: "5px 0px 5px 0px", 
            fontSize: mainFontSize.s, 
            fontFamily: fonts.SarabunMedium,
          }}
          >
            {t("statementCardUpload.header")}
          </Texts>
          <Texts style={{ 
            color: "#666666", 
            padding: "5px 0px 5px 0px", 
            fontSize: mainFontSize.s, 
            fontFamily: fonts.SarabunLight,
          }}
          >
            {t("statementCardUpload.message")}
          </Texts>
        </TextHolder>
      </TextView>

      {
        dataDisplay.inputs.map((val) => (
          <Inputs 
            config={{
              type: val.type as InputEnum,
              title: val.title,
              field: val.field,
              maxFiles: val.maxFiles,
              errorMessage: getErrorMessage(val.field, errorMessages),
              rules: val.rules,
            }}
            value={mainData[val.field]}
          />
        ))
      }
      
      <ButtonHolder>
        <Buttons
          style={{
            backgroundColor: "#ffffff", 
            color: "#0067b1", 
            borderWidth: 1, 
            borderColor: "#0067b1",
          }}
          onClick={onBack}
        >
          {t("idCardUpload.buttonBack")}
        </Buttons>
        <Buttons 
          // disabled={!onValidate().validateButton.every((val) => val)}
          onClick={onNext}
        >
          {t("idCardUpload.buttonNext")}
        </Buttons>
      </ButtonHolder>
    </Card>
  )
}