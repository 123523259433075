import { IVerification } from "../interfaces"
import { 
  sendEmailOtpSchema, 
  sendMobileOtpSchema, 
  verifyMobileOtpSchema, 
  verifyEmailOtpSchema,
  registerSchema,
} from "../schemas"
import { useQuery } from "./apiCall"

// const GetResponseData = async (schema: string, variables: unknown) => {
//   const response = await useQuery(schema, variables)
//   return response?.data
// }

export const SendEmailOtpApi = (email: string) => {
  const variables = {
    input: {
      email,
      language: "TH",
    },
  }
  return useQuery(sendEmailOtpSchema, variables)
}

export const SendMobileOtpApi = (mobileNo: string) => {
  const variables = {
    input: {
      mobileNo,
      language: "TH",
    },
  }
  return useQuery(sendMobileOtpSchema, variables)
}

export const VerifyMobileOtpApi = (mobileNo: string, otp: string, reference: string) => {
  const variables = {
    input: {
      mobileNo,
      otp,
      reference,
    },
  }
  return useQuery(verifyMobileOtpSchema, variables)
}

export const VerifyEmailOtpApi = (email: string, otp: string, reference: string) => {
  const variables = {
    input: {
      email,
      otp,
      reference,
    },
  }
  return useQuery(verifyEmailOtpSchema, variables)
}

export const RegisterApi = (verification: Array<IVerification>) => {
  const variables = {
    input: {
      username: "",
      password: "",
      pin: "",
      verification,
      passport: [],
      consent: [],
    },
  }
  return useQuery(registerSchema, variables)
}

export const CheckTargetExists = (type: string, deviceId: string, target: string) => {
  const gql = `query checkTargetExists($input: CheckTargetExistsInput!) {
    CheckTargetExists(input: $input) {
      isExists
    }
  }`
  const variables = {
    input: {
      type,
      deviceId,
      target,
    },
  }
  return useQuery(gql, variables)
}

export const LoginWithOTPApi = (
  type: string, 
  deviceId: string, 
  target: string, 
  reference: string, 
  otp: string,
) => {
  const gql = `mutation loginWithOTP($input: LoginWithOTPInput!) {
    LoginWithOTP(input: $input) {
      accessToken
    }
  }`
  const variables = {
    input: {
      type,
      deviceId,
      target,
      reference,
      otp,
    },
  }
  return useQuery(gql, variables)
}

export const CheckMobileAndEmailExistsApi = (mobileNumber: string, email: string) => {
  const gql = `query checkMobileAndEmailExists ($input: CheckMobileAndEmailExistsInput) {
    CheckMobileAndEmailExists(input: $input) {
      isPass
    }
  }`

  const variables = {
    input: {
      mobileNumber,
      email,
    },
  }
  return useQuery(gql, variables)
}

export const CheckMobileAndEmailRegisterApi = (mobileNumber: string, email: string) => {
  const gql = `query CheckMobileAndEmailRegister ($checkMobileAndEmail: CheckMobileAndEmailInput) {
    CheckMobileAndEmailRegister(input: $checkMobileAndEmail) {
        isPass
    }
  }`
  const variables = {
    checkMobileAndEmail: {
      mobileNumber,
      email,
    },
  }
  return useQuery(gql, variables)
}

export const CheckMobileAndEmailLoginApi = (mobileNumber: string, email: string) => {
  const gql = `query CheckMobileAndEmailLogin ($checkMobileAndEmail: CheckMobileAndEmailInput) {
    CheckMobileAndEmailLogin(input: $checkMobileAndEmail) {
        isPass
    }
  }`
  const variables = {
    checkMobileAndEmail: {
      mobileNumber,
      email,
    },
  }
  return useQuery(gql, variables)
}

export const LoginWebApi = (verification: any[]) => {
  const gql = `mutation LoginWeb($loginInput: RegisterInput) {
    LoginWeb(input:$loginInput){
      accessToken
    }
  }`
  const variables = {
    loginInput: {
      verification,
    },
  }
  return useQuery(gql, variables)
}