import React, { useContext } from "react"
import { useSelector } from "react-redux"
import {
  Buttons,
  Texts,
  Inputs,
  TLight,
} from "../../../components"
import { CardLogin } from "../../../components/Card"
import { configLabels } from "../../../components/Inputs/type"
import { fonts, mainFontSize } from "../../../config/themes"
import { ComponentContext } from "../../../contexts"
import {
  InputEnum, IRule, IStore, 
} from "../../../interfaces"
import { textInputValidate, useWindowDimensions } from "../../../utilities"

interface props {
  buttonKey?: string
  page: string
  data: configLabels[]
  titleButtonNext?: string
  titleLink?: string
  titleDesLink?: string
  titleHeader?: string
  subTitle?: string
}

export default ({
  buttonKey,
  data,
  page,
  titleLink,
  titleHeader,
  subTitle,
  titleDesLink,
  titleButtonNext,
}: props) => {
  const AuthReducer = useSelector((state: IStore) => state.AuthReducer)
  const newData = data.map(
    (val, index) => ({ 
      ...val, 
      ...[
        { value: AuthReducer.email },
        { value: AuthReducer.mobileNo },
      ][index], 
    }),
  )
  const [errorMessages, setErrorMessages] = React.useState<Array<string>>([])
  const { width } = useWindowDimensions()
  const componentContext = useContext(ComponentContext);

  const onChange = (val: {key?: string, value?: string | number}) => {
    componentContext?.onChange({ key: val.key || "", value: val.value })
  };

  const validate = (): { errors: Array<string>, validateFlag: boolean } => {
    const errors: Array<string> = []
    let validateFlag = true
    const inputs = newData || []

    for (let i = 0; i < inputs.length; i += 1) {
      const input = inputs[i]
      let error = ""
      if (input.rules) {
        if (input.type === InputEnum.inputCommon) {
          error = textInputValidate(input.rules as Array<IRule>, input?.value as string)
          if (error) validateFlag = false
        }
      }
      
      errors.push(error)
    }

    return { errors, validateFlag }
  }

  const onClick = () => {
    const validateRes = validate()
    setErrorMessages(validateRes.errors)
    if (validateRes.validateFlag) onChange({ key: buttonKey || "NEXT" })
  }

  return (
    <CardLogin isMobile={width < 540}>
      <Texts style={{ 
        textAlign: "left",
        color: "#0C67B1", 
        fontSize: "2rem",
        fontFamily: fonts.PromptMedium,
      }}
      >
        {titleHeader}
      </Texts>

      <Texts
        style={{
          textAlign: "left",
          opacity: 0.8,
          marginBottom: 28,
          fontSize: 15,
        }}
      >
        {subTitle}
      </Texts>

      <div style={{ marginTop: -20 }}>
        {newData?.map((config, i) => (
          <Inputs
            style={{ fontFamily: fonts.PromptLight }}
            key={`card-login-inputs-${i.toString()}`}
            config={{ ...config, errorMessage: errorMessages[i] }}
            value={AuthReducer[config.field as "mobileNo" | "email"]}
          />
        ))}
      </div>

      <Buttons
        style={{
          fontSize: mainFontSize.m,
          margin: "16px 0",
          fontFamily: fonts.PromptMedium,
        }}
        onClick={onClick}
      >
        {titleButtonNext}
      </Buttons>

      <div style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
      }}
      >
        <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
          <TLight style={{ fontSize: "1rem", fontFamily: fonts.PromptLight }}>
            {titleDesLink}
          </TLight>
          <Buttons
            style={{
              marginLeft: 10,
              marginRight: 10,
              fontSize: "1rem",
              fontFamily: fonts.PromptMedium,
            }}
            onClick={() => onChange({ key: page })}
            href="#"
          >
            {titleLink}
          </Buttons>
        </div>
      </div>
    </CardLogin>
  )
}