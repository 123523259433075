import { LOGIN_SET_STATE } from "../actions";
import { ILoginReducerState, ILoginReducerPayload } from "../interfaces"

const initialState: ILoginReducerState = {
  isLogin: false,
}

function LoginReducer(state = initialState, { type, payload }: ILoginReducerPayload) {
  switch (type) {
    case LOGIN_SET_STATE:
      return { ...state, [payload.key]: payload.value };
    default:
      return state
  }
}

export default LoginReducer