import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"
import styled from "styled-components"
import { saveUploadFileSaga } from "../../actions"
import { GetImageDocument, GetUsers } from "../../apis"
import {
  Buttons, Card, Header, Images, TBold, TLight, TMedium,
} from "../../components"
import {
  ApprovalIcon,
  ApproveFail,
  ApproveSuccess,
  Magnify,
  PdfSVG,
  rejectIcon,
  Success,
} from "../../components/Images"
import InputUploadMultipleFiles from "../../components/Inputs/InputUploadMultipleFiles";
import LoadingIndicator from "../../components/LoadingIndicator"
import { styledMedia } from "../../components/PageWrapper"
import { showDialog } from "../../components/Provider/dialog";
import { hideSpinner, showSpinner } from "../../components/Provider/spinner"
import { fonts, mainFontSize } from "../../config/themes"
import {
  fileProps,
  IDialogTypeEnum,
  IGetImageDocumentItems,
  IGetImageDocumentResponse,
  IGetUsers,
  StatusOpenAccount,
  UploadFileType,
} from "../../interfaces"
import { routerName } from "../../interfaces/router.enum"
import { handleStatusError } from "../../sagas"
import { ConvertBase64Tofile, GenFilePropsArray, useWindowDimensions } from "../../utilities"

const Container = styled.div`${styledMedia}`

interface IFileObj {
  key: string

  // Files type in human-readable format.
  value: string
  children?: Array<fileProps>
}

type AllowAdditionalUploadMap = { [key in UploadFileType]: boolean }

const ImageHoler = styled.img`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex:1;
  height: auto;
  width: 100%;
`

export default () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [status, SetStatus] = useState<StatusOpenAccount | null>(null)
  const [files, setFiles] = useState<Array<IFileObj>>([]);
  const [loading, setLoading] = useState(false)
  const [additionalFiles, setAdditionalFiles] =
    useState<{[key in UploadFileType]: fileProps[]}>({
      IMAGE_BOOKBANK_REDEMPTION: [],
      IMAGE_BOOKBANK_SUBSCRIPTION: [],
      IMAGE_HOUSE_REGISTRATION: [],
      IMAGE_ID_CARD: [],
      IMAGE_SELFIE_WITH_ID_CARD: [],
      IMAGE_SIGNATURE: [],
      IMAGE_STATEMENT: [],
    })
  const [allowAdditionalUploads, setAllowAdditionalUploads] =
    useState<AllowAdditionalUploadMap>({
      IMAGE_BOOKBANK_REDEMPTION: false,
      IMAGE_BOOKBANK_SUBSCRIPTION: false,
      IMAGE_HOUSE_REGISTRATION: false,
      IMAGE_ID_CARD: false,
      IMAGE_SELFIE_WITH_ID_CARD: false,
      IMAGE_SIGNATURE: false,
      IMAGE_STATEMENT: false,
    })

  // Maximum number of files according to `maxFiles` property of
  // input type inputUploadMultipleFiles in src/containers/__mock__/index.tsx.
  //
  // `maxFiles` == 1 means no additional uploads
  const additionalFileMax: { [key in UploadFileType ]: number } = {
    IMAGE_ID_CARD: 1,
    IMAGE_SIGNATURE: 1,
    IMAGE_BOOKBANK_REDEMPTION: 1,
    IMAGE_HOUSE_REGISTRATION: 9,
    IMAGE_SELFIE_WITH_ID_CARD: 1,
    IMAGE_BOOKBANK_SUBSCRIPTION: 1,
    IMAGE_STATEMENT: 9,
  }
  const isFileSizeOverLimit =
    (size: number, limitSizeMB = 5) => size > 1024 * (1024 * limitSizeMB)
  const fileTooBigErrMsg = "ไม่สามารถอัปโหลดได้เนื่องจากขนาดไฟล์ต้องไม่เกิน 5 MB";

  const FileBox = ({
    file,
    index,
    isMobile, 
  }: {
    file: fileProps,
    isMobile: boolean,
    index: number}) => {
    const color = file.status === StatusOpenAccount.EDITING || file.errorMessage ? "#ef3e42" : "#54b03c"
    const icon = file.status === 
      StatusOpenAccount.EDITING || file.errorMessage ? ApproveFail : Success
    const error = file.status === StatusOpenAccount.EDITING ? "ไม่ผ่านการอนุมัติ " : file.errorMessage || ""

    const onFileClick = (f: File) => {
      const fileURL = URL.createObjectURL(f);
      window.open(fileURL);
    }

    return (
      <div
        style={{
          width: isMobile ? "auto" : "40%",
          border: `2px solid ${color}`, 
          borderRadius: 8, 
          padding: "20px", 
          marginBottom: 20, 
          position: "relative",
        }}
      >
        <div style={{
          display: "flex", flexDirection: "row", justifyContent: "space-between", marginBottom: 20, 
        }}
        >
          <TMedium color="#0C67B1" style={{ width: "80%" }}>
            {index + 1}
            .
            {" "}
            {file.name}
          </TMedium>
          <div style={{
            width: "20%", flexDirection: "row", display: "flex", justifyContent: "space-between", 
          }}
          >
            <Images
              style={{
                width: "20px", 
                height: "20px", 
                cursor: "pointer",
              }}
              onClick={() => onFileClick(file.data as File)}
              src={Magnify}
            />
            <Images
              style={{
                width: "20px", 
                height: "20px", 
              }}
              src={icon}
            />
          </div>
        </div>
        
        <div>
          {
            file.contentType?.includes("pdf") ?
              <ImageHoler src={PdfSVG} />
              :
              <ImageHoler src={URL.createObjectURL(file.data as File)} />
          }
        </div>
        <div style={{ marginTop: 15, display: "flex", flexDirection: "row" }}>
          <TLight color="red" fontSize={mainFontSize.xs}>{error}</TLight>
        </div>
      </div>
    )
  }

  const uploadFiles = (fileObjs: Array<IFileObj>, fileObjNo: number) => {
    const fileObj = fileObjs[fileObjNo]

    if (fileObjNo > fileObjs.length - 1) {
      hideSpinner()
      window.location.href = routerName.approvalStatus
      return
    }
    
    dispatch(saveUploadFileSaga({
      field: fileObj.key as UploadFileType,
      files: fileObj.children,
      callback: () => {
        showSpinner()
        uploadFiles(fileObjs, fileObjNo + 1) 
      },
    }))
  }
  
  const submitReject = () => {
    const requiredAddiUploadSection: string[] = []

    for (let i = 0; i < files.length; i += 1) {
      const child = files[i]
      if (child.children && child?.children?.length > 0) {
        for (let j = 0; j < child?.children?.length; j += 1) {
          const c = child?.children[j]
          if (c.errorMessage || c.status === StatusOpenAccount.EDITING) {
            const additionalFilesByType = additionalFiles[child.key as UploadFileType]

            if (additionalFilesByType.length === 0) {
              requiredAddiUploadSection.push(child.value)
            }
          }
        }
      }
    }

    if (requiredAddiUploadSection.length > 0) {
      const listFmt = new Intl.ListFormat("th", { type: "conjunction" })
      showDialog(IDialogTypeEnum.ERROR_DIALOG,
        { message: `กรุณาอัพโหลด ${listFmt.format(requiredAddiUploadSection)} เพิ่มเติม` },
        true,
        false)
        .finally()
      return
    }

    const noRejectsFiles = files.map((f: IFileObj) => {
      const noRejectsChildren = f.children?.filter((c) => c.status !== StatusOpenAccount.EDITING)
      return { ...f, children: noRejectsChildren }
    })

    const joinedFiles = noRejectsFiles.map((f: IFileObj) => {
      const newChildren = f.children
      if (!newChildren) return f

      const additionalFilesByType = additionalFiles[f.key as UploadFileType]

      for (let i = 0; i < additionalFilesByType.length; i += 1) {
        const fileProp = additionalFilesByType[i]
        newChildren.push(fileProp)
      }

      return { ...f, children: newChildren }
    })

    uploadFiles(joinedFiles, 0)
  }

  // Allow additional files uploads according to `maxFiles` property of
  // input type inputUploadMultipleFiles in src/containers/__mock__/index.tsx.
  //
  // `maxFiles` == 1 means no additional uploads
  const shouldAllowAdditionalUploads =
    (key: UploadFileType, allowAdditionalUploadMap: AllowAdditionalUploadMap) => {
      const allow = allowAdditionalUploadMap[key];
      if (typeof allow !== "undefined") {
        return allow
      }

      return false
    }

  const additionalFilesUploadHandler = 
    (key: UploadFileType): (({ value }: { value: fileProps[] }) => void) => (
      ({ value }: { value: fileProps[] }) => {
        if (!key) return

        setAdditionalFiles((oldAddiFiles) => ({ ...oldAddiFiles, [key]: value }))
      }
    )

  const beforeAdditionalFilesUpload = (file: File): boolean => {
    const fileTooBig = isFileSizeOverLimit(file.size);

    if (!fileTooBig) return true

    showDialog(IDialogTypeEnum.ERROR_DIALOG,
      { message: fileTooBigErrMsg },
      true,
      false)
      // Don't handle promise.
      .then(() => {})

    return false
  }

  const editingComponent = (isMobile: boolean) => (
    <Card>
      <div style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between", 
      }}
      >
        <div>
          <TBold style={{ 
            color: "#0C67B1", 
            padding: "5px 0px 5px 0px", 
            fontSize: mainFontSize.xl, 
            fontFamily: fonts.SarabunMedium,
          }}
          >
            สถานะการอนุมัติ 
          </TBold>

          <div style={{ marginBottom: 10 }} />

          <TMedium style={{ 
            color: "#333333", 
            padding: "0px 10px 5px 0px", 
            lineHeight: 2,
            fontSize: mainFontSize.s, 
            fontFamily: fonts.SarabunLight,
          }}
          >
            การลงทะเบียนเปิดบัญชีกองทุนของท่านไม่ผ่านการอนุมัติ 
            กรุณาทำรายการใหม่ในหัวข้อที่แจ้งแจ้งเตือน
          </TMedium>
        </div>
      </div>
      <div 
        style={{
          display: "flex",
          flex: 1,
          justifyContent: "center",
          alignItems: "center", 
          marginTop: 20,
        }}
      >
        {loading && LoadingIndicator({ style: {} })}
      </div>

      {
        files.map((f, i) => {
          if (!f.children || f.children.length === 0) {
            return null
          }
          
          return (
            <div
              key={`${i.toString}. ${f.key}`}
              style={{ borderBottom: "1px solid #cccccc", marginBottom: 15 }}
            >
              <TBold style={{
                color: "#333333",
                padding: "0px 10px 5px 0px",
                lineHeight: 2,
                fontSize: mainFontSize.l,
                marginBottom: 10,
              }}
              >
                {`${f.value}`}
              </TBold>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  flexWrap: "wrap",
                  width: isMobile ? "auto" : 721,
                  justifyContent: "space-between",
                }}
              >
                {
                  f.children?.map((child, j) => (
                    <FileBox
                      file={child}
                      key={`${i.toString()}-${child.name}`}
                      isMobile={isMobile}
                      index={j}
                    />
                  ))
                }
                {
                  shouldAllowAdditionalUploads(f.key as UploadFileType, allowAdditionalUploads) && (
                    <div style={{ width: "100%" }}>
                      <TBold style={{
                        color: "#333333",
                        padding: "0px 10px 5px 0px",
                        lineHeight: 2,
                        fontSize: mainFontSize.m,
                        marginBottom: 10,
                      }}
                      >
                        {`${f.value} เพิ่มเติม`}
                      </TBold>
                      <InputUploadMultipleFiles
                        label="รุปภาพ/เอกสาร เพิ่มเติม"
                        values={additionalFiles[f.key as UploadFileType] || []}
                        imagesDisplayPosition="before-input"
                        maxFiles={additionalFileMax[f.key as UploadFileType]}
                        onChange={additionalFilesUploadHandler(f.key as UploadFileType)}
                        beforeUpload={beforeAdditionalFilesUpload}
                      />
                    </div>
                  )
                }
              </div>
            </div>
          )
        })
      }
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <Buttons onClick={submitReject}>ยืนยันการทำรายการใหม่</Buttons>
      </div>
    </Card>
  )

  const waitingComponent = () => (
    <Card>
      <TBold style={{ 
        color: "#0C67B1", 
        padding: "5px 0px 5px 0px", 
        fontSize: mainFontSize.xl, 
        fontFamily: fonts.SarabunMedium,
      }}
      >
        สถานะการอนุมัติ 
      </TBold>

      <div style={{ display: "flex", alignItems: "center", flexDirection: "column" }}>
        <Images
          style={{
            width: "190px", height: "190px", padding: "", flex: 1,
          }}
          src={ApprovalIcon}
        />
        
        <TMedium style={{
          color: "#333333",
          fontSize: mainFontSize.s,
          fontWeight: 50,
          textAlign: "center",
        }}
        >
          เราได้รับข้อมูลการเปิดบัญชีของท่านแล้ว
          <br />
          กรุณารอผลการอนุมัติภายใน 3 วันทำการ
        </TMedium>

        <TMedium style={{
          color: "#666666",
          padding: "5px 0px 5px 0px",
          fontSize: mainFontSize.xs,
          fontWeight: 10, 
        }}
        >
          หากมีข้อสงสัยกรุณาติดต่อ RHB Contact Center
          <br />
          02 088 9896 หรือ 02 088 9897 หรือ 02 088 9797
        </TMedium>
      </div>
    </Card>
  )

  const approvedComponent = () => (
    <Card>
      <TBold style={{ 
        color: "#0C67B1", 
        padding: "5px 0px 5px 0px", 
        fontSize: mainFontSize.xl, 
        fontFamily: fonts.SarabunMedium,
      }}
      >
        สถานะการอนุมัติ 
      </TBold>

      <div style={{ display: "flex", alignItems: "center", flexDirection: "column" }}>
        <Images
          style={{
            width: "190px", height: "190px", padding: "", flex: 1,
          }}
          src={ApproveSuccess}
        />
        <TMedium style={{
          color: "#333333",
          fontSize: mainFontSize.s,
          fontWeight: 50,
          textAlign: "center",
        }}
        >
          ทางบริษัทฯ ขอขอบคุณสำหรับข้อมูลการเปิดบัญชีของท่าน
        </TMedium>

        <TMedium style={{
          color: "#666666",
          padding: "5px 0px 5px 0px",
          fontSize: mainFontSize.xs,
          fontWeight: 10, 
        }}
        >
          หากการเปิดบัญชีได้รับการอนุมัติเรียบร้อยแล้ว จะแจ้งให้ท่านทราบทางอีเมลต่อไป
        </TMedium>
      </div>
    </Card>
  )

  const rejectComponent = () => (
    <Card>
      <TBold style={{ 
        color: "#0C67B1", 
        padding: "5px 0px 5px 0px", 
        fontSize: mainFontSize.xl, 
        fontFamily: fonts.SarabunMedium,
      }}
      >
        สถานะการอนุมัติ 
      </TBold>

      <div style={{ display: "flex", alignItems: "center", flexDirection: "column" }}>
        <Images
          style={{
            width: "190px", height: "190px", padding: "", flex: 1,
          }}
          src={rejectIcon}
        />
        
        <TMedium style={{
          color: "#333333",
          fontSize: mainFontSize.s,
          fontWeight: 50,
          textAlign: "center",
        }}
        >
          ขออภัยท่านไม่สามารถเปิดบัญชีกองทุนได้
        </TMedium>

        <TMedium style={{
          color: "#666666",
          padding: "5px 0px 5px 0px",
          fontSize: mainFontSize.xs,
          fontWeight: 10, 
        }}
        >
          {t("cardApproval.caption")}
        </TMedium>
      </div>
    </Card>
  )

  const docTypeMappings: { [key: string]: string} = {
    IMAGE_ID_CARD: "รูปถ่ายบัตรประชาชน",
    IMAGE_SELFIE_WITH_ID_CARD: "รูปถ่าย Selfie",
    IMAGE_BOOKBANK_SUBSCRIPTION: "รูปถ่ายสมุดบัญชีซื้อ",
    IMAGE_BOOKBANK_REDEMPTION: "รูปถ่ายสมุดบัญชีขาย",
    IMAGE_HOUSE_REGISTRATION: "รูปถ่ายทะเบียนบ้าน",
    IMAGE_SIGNATURE: "รูปถ่ายลายเซ็นต์",
    IMAGE_STATEMENT: "รูปถ่าย Statement",
  }
  
  const genFilejObj = async (imageDocs: IGetImageDocumentItems[]) => {
    const blobs = await ConvertBase64Tofile(GenFilePropsArray(imageDocs))
    const fs = Object.keys(docTypeMappings)
      .map((field) => ({ 
        key: field, 
        value: docTypeMappings[field], 
      }))

    const fileObjs = fs.map((f) => ({
      ...f,
      children: blobs.filter((item) => item.imageType === f.key),
    }));
    setFiles(fileObjs)

    const tmpAllowAdditionalUploads = { ...allowAdditionalUploads }
    for (let i = 0; i < fileObjs.length; i += 1) {
      const fileObj = fileObjs[i]
      tmpAllowAdditionalUploads[fileObj.key as UploadFileType] =
        fileObj.children.some((c) => c.status === StatusOpenAccount.EDITING)
    }

    setAllowAdditionalUploads(tmpAllowAdditionalUploads)
  }

  useEffect(() => {
    setLoading(true)
    GetUsers()
      .then((res: IGetUsers) => {
        if (res?.errors) {
          handleStatusError(res?.errors)
          return
        }
        // alert(res.data.GetUserInfo.oaStatus)
        SetStatus(res.data.GetUserInfo.oaStatus as StatusOpenAccount)
        if (res.data.GetUserInfo.oaStatus === StatusOpenAccount.EDITING) {
          GetImageDocument().then((resImage: IGetImageDocumentResponse) => {
            if (resImage?.errors) {
              handleStatusError(resImage?.errors)
              return
            }
            if (resImage.data.GetImageDocument) {
              const imgUpload = resImage.data.GetImageDocument.map((val) => val.status)
              const isEditing = imgUpload.some((val) => val === StatusOpenAccount.EDITING)

              if (!isEditing) {
                window.location.href = routerName.otpSoftReject
                return
              }

              genFilejObj(resImage.data.GetImageDocument).finally()
              setLoading(false)
            }
          }).catch((error) => {
            setLoading(false)

            console.error(error)
          })
        }
      })
      .catch((error) => { 
        setLoading(false)
        console.error(error)
      })
  }, [])

  const { width } = useWindowDimensions()

  return (
    <Container>
      <Header />
      <div 
        style={{ 
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {status === StatusOpenAccount.SUBMITTED && waitingComponent()}
        {status === StatusOpenAccount.EDITING && editingComponent(width < 850)}
        {status === StatusOpenAccount.APPROVED && approvedComponent()}
        {status === StatusOpenAccount.REJECTED && rejectComponent()}
      </div>
    </Container>
  )
}