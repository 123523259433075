import { takeLatest, call } from "redux-saga/effects"
import { LOGIN } from "../actions"
import { ILoginApiResponse } from "../interfaces"

function* GetLogin() {
  try {
    // const response: ILoginApiResponse = yield call(loginApi)
    console.log("response")
  } catch (error) {
    yield console.log(error)
  }
}

export default function* LoginSaga() {
  yield takeLatest(LOGIN, GetLogin)
}
