import React from "react"
import ReactDOM from "react-dom"
import { Provider } from "react-redux"
import { BrowserRouter } from "react-router-dom"
import { PersistGate } from "redux-persist/integration/react"
import { DialogProvider, SpinnerProvider } from "./components"
import reportWebVitals from "./reportWebVitals"
import Route from "./routes"
import ConfigureStore from "./store"
import "./services/languageProvider"

const Application = () => (
  <Provider store={ConfigureStore.store}>
    <PersistGate loading={null} persistor={ConfigureStore.persistor}>
      <BrowserRouter>
        <Route />
        <DialogProvider />
        <SpinnerProvider />
      </BrowserRouter>
    </PersistGate>
  </Provider>
)

if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
  // dev code
} else {
  console.log = () => {}
}

ReactDOM.render(Application(), document.getElementById("root"));
reportWebVitals();