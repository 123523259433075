import React from "react"
import styled from "styled-components"
import { TBold, TLight, TMedium } from "../../components"
import Header from "../../components/Header"
import Image, { Phone } from "../../components/Images"
import { styledMedia } from "../../components/PageWrapper"
import { color, fonts, mainFontSize } from "../../config/themes"

const Container = styled.div`${styledMedia}`

export default () => (
  <Container>
    <Header />
    <div style={{
      display: "flex", flexDirection: "column", alignItems: "center", 
    }}
    >
      <div style={{ marginTop: 25, alignItems: "center" }}>
        <TBold style={{ textAlign: "left", color: color.primary, fontSize: mainFontSize.xxxl }}>
          ติดต่อเรา
        </TBold>
      </div>

      <TLight textAlign="center" mt="15px" fontSize={mainFontSize.s} color="#333">
        กรุณาส่งคำถาม ข้อสงสัย หรือคำแนะนำของท่านให้เราได้ที่ 
        {" "}
        <span style={{ color: color.primary }}>th.support@rhbgroup.com </span>
      </TLight>

      <TLight textAlign="center" mt="10px" fontSize={mainFontSize.s} color="#333">
        หรือโทรหาเราที่ 
        {" "}
        <span style={{ color: "#333333", fontFamily: fonts.SarabunMedium }}>ฝ่ายลูกค้าสัมพันธ์ออนไลน์</span> 
        {" "}
        <span style={{ color: color.primary }}>02 088 9797</span>
      </TLight>

      <div 
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          marginTop: 25,
          padding: 20,
          border: "1px solid #cccccc",
          borderRadius: 7,
        }}
      >
        <p 
          style={{
            textAlign: "left",
            fontSize: mainFontSize.xxxl,
            color: color.primary, 
            fontFamily: fonts.SarabunBold,
            display: "flex", 
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Image
            width="25px"
            height="25px"
            src={Phone}
          />
          {" "}
          <span style={{ marginLeft: 5 }}>02 088 9797</span>
        </p>
        <TMedium textAlign="center" mt="10px" fontSize={mainFontSize.s} color="#333">
          Customer Care สื่อกลางให้บริการนักลงทุน
        </TMedium>
      </div>
    </div>
    
  </Container>
)