import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components"
import { verifyRegister } from "../../../actions";
import {
  Buttons,
  Card,
  HistoryOfPath,
  Inputs,
  Texts,
} from "../../../components";
import { fonts, mainFontSize } from "../../../config/themes";

import { ComponentContext } from "../../../contexts"
import {
  IFormsShema, 
  IOpenAccountShema, 
  InputEnum,
  IStore, 
} from "../../../interfaces";
import {
  chunk, getErrorMessage, handleErrorScreen, resizeArrayWithNull, useWindowDimensions, 
} from "../../../utilities";

interface props {
  page: string
  data: IOpenAccountShema[],
  dataOfPage: IFormsShema[],
}

const ButtonHolder = styled.div`
  display: flex;
  justify-content: space-between;
`

export default ({ page, data, dataOfPage }: props) => {
  const { width, height } = useWindowDimensions()
  const [errorMessages, setErrorMessages] = 
    React.useState<Array<{ field: string, message: Array<string> | string }>>([])
  const dispatch = useDispatch()
  const componentContext = useContext(ComponentContext);
  const { t } = useTranslation()
  const index = +page.split(".")[1] || 0
  const { termAndConditoin } = useSelector((state: IStore) => state.OpeningAccountReducer)
  const dataDisplay = dataOfPage[index]
  const mainData = termAndConditoin[dataDisplay.type]

  const onChange = (val: {key?: string, value?: string | boolean | number}) => {
    componentContext?.onChange({ key: val.key || "", value: val.value })
  }

  const onValidate = (): { 
    isRequireUpload: (false | { type: string; field: string; })[],
    validateButton: boolean[]
  } => {
    const dataInputs = dataDisplay.inputs
    const dataFromReducer = Object.keys(mainData)

    // Get KeyTarget other all subpage.
    const otherField = dataInputs.map((val, otherIndex) => dataInputs
      .map((input) => input.keyTarget)
      .filter((inputFilter) => inputFilter)
      .flat()
      .map((targetField) => ((val.field === targetField) && otherIndex))
      .filter((fil) => fil))
      .flat()

    const isRequireUpload = dataInputs.map((val) => val.type === "inputUploadFile" 
      && { type: val.type, field: val.field }).filter((val) => val)
  
    // Check data no field and check click required and selected yet?
    const addOtherTargetField: any[] = []
    const result = dataFromReducer.map((field, keyindex) => {
      if (Array.isArray(mainData[field])) {
        return mainData[field].map((val: any) => {
          if (val.selected && val.requireOther) {
            dataInputs.map((vv, iii) => iii === keyindex && addOtherTargetField.push(vv.keyTarget))
            return true
          }
          if (val.selected) return true
          return false
        }).some((someval: boolean) => someval)
      }
      return !!mainData[field]
    })

    // Trigger other field when other buttons are pressed.
    dataFromReducer
      .map((field, keyindex) => addOtherTargetField
        .flat()
        .map((itemTest1) => ((field === itemTest1) ? keyindex : false))
        .filter((val) => val)
        .toString())
      .forEach((vall) => vall && otherField.splice(otherField.indexOf(+vall), 1))

    return {
      isRequireUpload,
      validateButton: result.filter((val, inde) => !(otherField.some((vall) => inde === vall))),
    }
  }

  const onNext = () => {
    const { errors, validateFlag } = handleErrorScreen(dataDisplay.inputs, mainData)
    setErrorMessages(errors)
    if (validateFlag) dispatch(verifyRegister())
  }

  const dataInput = width < 540 
    ? chunk(dataDisplay.inputs, 1) 
    : resizeArrayWithNull(dataDisplay.inputs, 2)
  
  return (
    <Card>
      <HistoryOfPath 
        data={dataOfPage.map((val) => val.title)}
        page={page}
        activeIndex={index}
      />
      <div style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between", 
      }}
      >
        <Texts style={{ 
          color: "#0C67B1", 
          padding: "5px 0px 5px 0px", 
          fontSize: mainFontSize.l, 
          fontFamily: fonts.SarabunMedium,
        }}
        >
          {dataDisplay.title}
        </Texts>
      </div>

      <div style={{ marginBottom: 28 }}>
        {
          dataInput.map((vals, key) => (
            <div style={{
              display: "flex",
              flex: 1,
              justifyContent: "space-between",
            }}
            >
              {
                vals && vals.map((item, i) => (
                  <div style={{
                    display: "flex",
                    flex: 1,
                    flexDirection: "column",
                    marginRight: i % 2 ? 0 : 15,
                    marginLeft: i % 2 ? 15 : 0,
                  }}
                  >
                    {
                      item && (
                        <Inputs 
                          style={{ display: "flex", flex: 1 }}
                          config={{
                            type: item.type as InputEnum,
                            title: item.title,
                            field: item.field,
                            placeholder: item.placeholder,
                            upperCaseOnly: item.upperCaseOnly,
                            errorMessage: getErrorMessage(item.field, errorMessages),
                            maxlength: item?.maxlength,
                            rules: item.rules,
                          }}
                          value={mainData[item.field]}
                        />
                      )
                    }
                  </div>
                ))
              }
            </div>
          ))
        }
      </div>

      <ButtonHolder>
        <Buttons
          style={{
            backgroundColor: "#ffffff",
            color: "#0067b1",
            borderWidth: 1,
            borderColor: "#0067b1",
          }}
          onClick={() => onChange({ key: "BACK", value: 0 })}
        >
          ย้อนกลับ
        </Buttons>
        <Buttons 
          // disabled={!onValidate().validateButton.every((val) => val)}
          onClick={onNext}
        >
          {t("termsAndCon.buttonNext")}
        </Buttons>
      </ButtonHolder>
    </Card>
  )
}