import {
  takeLatest, call, put, select, 
} from "redux-saga/effects"
import {
  saveNDID,
  NDID_CHECK_VERICATION_STATUS, 
  NDID_GET_IDP, 
  NDID_RETRIEVE_DATA, 
  NDID_VERIFY_IDP,
  setOpenAccountReducer,
  navigateSetStep,
  NDID_CONSENT_NDID,
  navigateNext,
  NDID_CANCEL_VERIFICATION,
  cancelNDID,
  getCheckVerifyNDID, 
} from "../actions"
import {
  CheckStatusVerificationAPI,
  CancelVerificationAPI,
  ConsentNDIDAPI,
  GetAllMasterDataApi,
  GetListIdpAPI,
  RetrieveDataVerificationAPI,
  VerifyIdpAPI, 
} from "../apis"
import { showDialog } from "../components/Provider/dialog"
import { hideSpinner, showSpinner } from "../components/Provider/spinner"
import {
  IChooseIDPNDID,
  IGetObjPage,
  IIdpCheckVerifyResponse,
  IIdpResponse, 
  IIdpRetrieveDataResponse, 
  IIdpVerifyResponse, 
  IStore, 
  IStatusNDID,
  IDialogTypeEnum,
  IConsentNDIDResponse,
  ISubPage,
  IGetUsers,
  IGetQueryAllMasterResponse,
  ICancelVerifyResponse,
} from "../interfaces"
import {
  DateString, GenderString, DateFormat, IdentityCardProtectSensitive, DateFormatProtectSensitive, 
} from "../utilities"
import { WorkerGetUsers } from "./masterdata.saga"
import { WorkerGetStep } from "./openingAccount.saga"
import { handleStatusError } from "."

function* GetIdp() {
  try {
    const res: IIdpResponse = yield call(GetListIdpAPI)
    yield put(saveNDID({ key: "idp", value: res.data.ListIdp.idpList })) 
    yield put(saveNDID({ key: "onTheFly", value: res.data.ListIdp.onTheFlyList }))
  } catch (error) {
    console.error(error)
  }
}

function* CancelVerification() {
  try {
    console.log("spare file")
    yield call(showSpinner)
    const response: ICancelVerifyResponse = yield call(CancelVerificationAPI)
    if (response?.errors) {
      yield call(hideSpinner)
      yield call(handleStatusError, response?.errors)
      return
    }
    if (response) {
      if (response.data?.CancelVerification?.isSuccess) {
        // const checkVerifyResponse: IIdpCheckVerifyResponse = yield call(getCheckVerifyNDID)
        // if (checkVerifyResponse) yield call(hideSpinner)
        yield call(hideSpinner)
        yield put(navigateSetStep("2.2"))
        return
      }
    }
  } catch (error) {
    console.error(error)
  }
}

function* VerifyIdp({ payload }: { payload: string, type: string }) {
  const req: IChooseIDPNDID = JSON.parse(JSON.stringify(payload))
  try {
    yield call(showSpinner)
    const response: IIdpVerifyResponse = yield call(VerifyIdpAPI, {
      idpID: req.idpID,
      displayNameTH: req.displayNameTH,
      displayNameEN: req.displayNameEN,
    })
    if (response?.errors) {
      yield call(hideSpinner)
      yield call(handleStatusError, response?.errors);
      return
    }

    if (response.data?.VerifyIdp?.verification) {
      yield call(hideSpinner)
      yield put(navigateNext())
    }
  } catch (error) {
    console.error(error)
  }
}

function* CheckStatusVerication({ payload }: { payload: string, type: string }) {
  try {
    const response: IIdpCheckVerifyResponse = yield call(CheckStatusVerificationAPI)
    if (response?.errors) {
      yield call(handleStatusError, response?.errors);
      return
    }
    const { CheckStatusVerification } = response.data
    console.log(response)

    if (CheckStatusVerification?.status === IStatusNDID.ERROR ||
      CheckStatusVerification?.status === IStatusNDID.TIMEOUT ||
      CheckStatusVerification?.status === IStatusNDID.REJECTED) {
      yield put(saveNDID({ key: "STATUS_VERIFY_NDID", value: response.data.CheckStatusVerification }))
      const resolve: number = yield call(
        showDialog, 
        IDialogTypeEnum.ERROR_DIALOG, 
        { message: CheckStatusVerification.error.errorMessageTH, type: "cancelNdid" },
        true,
      );     
      console.log("resolve", resolve) 
      yield put(saveNDID({ key: "STATUS_VERIFY_NDID", value: "" }))
      if (resolve === 1) {
        yield put(navigateSetStep("2.2"))
        return
      }
    }
    yield call(WorkerGetStep)
    yield put(saveNDID({ key: "STATUS_VERIFY_NDID", value: response.data.CheckStatusVerification }))
  } catch (error) {
    console.error(error)
  }
}

export function* RetrieveDataNDID() {
  const STEP_VERIFY_IDENTITY: IGetObjPage = yield select(
    (state: IStore) => state.OpeningAccountReducer.termAndConditoin.STEP_VERIFY_IDENTITY,
  )
  const idCardInformation: IGetObjPage = yield select(
    (state: IStore) => state.OpeningAccountReducer.idCardInformation,
  )
  try {
    const response: IIdpRetrieveDataResponse = yield call(RetrieveDataVerificationAPI)
    const responseyield: IGetUsers = yield call(WorkerGetUsers)
    const responseMaster: IGetQueryAllMasterResponse = yield call(GetAllMasterDataApi)

    if (response?.errors && response?.errors.some((error) => error.extensions.code !== "404")) {
      yield call(handleStatusError, response?.errors);
      return
    }
    if (responseMaster?.errors) {
      yield call(handleStatusError, response?.errors);
      return
    }

    const ndidUserData = response.data.RetrieveDataVerification;
    const mGetTitle = responseMaster.data?.GetTitle
    const mGetCountry = responseMaster.data?.GetCountry

    yield put(setOpenAccountReducer({
      page: "termAndConditoin",
      subPage: ISubPage.STEP_VERIFY_IDENTITY,
      addValue: {
        // citizenId: "",
        // laserId: "",
        ...STEP_VERIFY_IDENTITY,
        expiryDate: ndidUserData?.identificationCard.expiryDate,
        title: mGetTitle.find((val) => val.id
          .includes(ndidUserData?.title || ""))?.displayTh,
        firstNameTh: ndidUserData?.firstNameTh,
        lastNameTh: ndidUserData?.lastNameTh,
        firstNameEn: ndidUserData?.firstNameEn,
        lastNameEn: ndidUserData?.lastNameEn,
        birthDate: ndidUserData?.birthDate,
        gender: ndidUserData?.gender,
        nationality: ndidUserData?.nationality,
      },
    }))

    const oldData = idCardInformation.STEP_ID_CARD_INFO

    yield put(setOpenAccountReducer({
      page: "idCardInformation",
      subPage: ISubPage.STEP_ID_CARD_INFO,
      addValue: {
        ...idCardInformation.STEP_ID_CARD_INFO,
        citizenId: 
          IdentityCardProtectSensitive(responseyield?.data?.GetUserInfo?.citizenCardNumber),
        expiryDate: ndidUserData?.identificationCard.expiryDate || oldData.expiryDate,
        isExpiryDate: [
          { title: "ตลอดชีพ", selected: ndidUserData?.identificationCard?.isExpiryDate === "N" },
        ],
        title: mGetTitle.find((val) => val.id
          .includes(ndidUserData?.title || ""))?.displayTh,
        titleEn: mGetTitle.find((val) => val.id
          .includes(ndidUserData?.title || ""))?.displayEn,
        firstNameTh: ndidUserData?.firstNameTh,
        lastNameTh: ndidUserData?.lastNameTh,
        firstNameEn: ndidUserData?.firstNameEn || oldData.firstNameEn,
        lastNameEn: ndidUserData?.lastNameEn || oldData.lastNameEn,
        birthDate: DateFormatProtectSensitive(ndidUserData?.birthDate || "", " "),
        gender: GenderString(ndidUserData?.gender as "M" | "F", "th"),
        nationality: mGetCountry.find(
          (val) => val.id === ndidUserData?.nationality,
        )?.name,
      },
    }))
    
    const retrieveDataNDID = {
      ...ndidUserData,
      title: mGetTitle.find((val) => val.id
        .includes(ndidUserData?.title || ""))?.displayTh,
    }
    yield put(saveNDID({ key: "RETRIEVE_DATA_NDID", value: retrieveDataNDID }))
  } catch (error) {
    console.error(error)
  }
}

function* ConsentNDID({ payload }: { payload: boolean, type: string }) {
  try {
    const response: IConsentNDIDResponse = yield call(ConsentNDIDAPI, payload)
    if (response?.errors) {
      yield call(handleStatusError, response?.errors);
      return
    }

    if (response.data.ConsentNdid?.isSuccess === true) {
      if (payload === true) {
        yield put(navigateSetStep("2.2"))
      } else {
        yield put(navigateSetStep("2.0"))
      }
    }
  } catch (error) {
    console.error(error)
  }
}

export default function* NdidSaga() {
  yield takeLatest(NDID_GET_IDP, GetIdp)
  yield takeLatest(NDID_CANCEL_VERIFICATION, CancelVerification)
  yield takeLatest(NDID_VERIFY_IDP, VerifyIdp)
  yield takeLatest(NDID_CHECK_VERICATION_STATUS, CheckStatusVerication)
  yield takeLatest(NDID_RETRIEVE_DATA, RetrieveDataNDID)
  yield takeLatest(NDID_CONSENT_NDID, ConsentNDID)
}
