import { getEndPoint, authorizeApi } from "../config"
import { fileProps, ILoginReducerState } from "../interfaces";
import state from "../store"
import { auth, GetDeviceId } from "../utilities";

const handleStatus = (json: any): any => {
  // console.log("JSON", json)
  if (json.success || json.data) {
    return json
  } 
  throw new Error(json.message || "GraphQL Error");
}

interface apiCallProps {
  method: "POST" | "GET",
  uri: string,
  body: any,
  header?: any,
  req?: any,
  isAuthen?: boolean,
}

export const apiCall = ({
  uri, header, method, body,
  isAuthen, req,
}: apiCallProps) => {
  const loginState: ILoginReducerState = state.store.getState().LoginReducer;
  const params = req ? Object.keys(req).map((key) => `${key}=${encodeURIComponent(req[key])}`) : [];
  const url = `${getEndPoint()}${uri}`
  const useURL = params.length > 0 ? `${url}?${params.join("&")}` : url

  const headers = {
    ...header,
    Authorization: isAuthen ? `Bearer ${loginState?.accessToken}` : "",
    DeviceID: GetDeviceId(),
    DeviceModel: "WEB",
    DeviceOS: "WEB",
  }

  return fetch(useURL, { method, headers, body })
    .then((data) => {
      if (data.status === 200) return data.json()
      if (data.status === 401) return auth.signOut()
      // console.log("||||||| API ", body, ">", useURL, ">", data)
      throw new Error(`Please contact administrator (status ${data.status})`)
    }).then((json) => handleStatus(json))
}

export const useQuery = (query: string, variables?: unknown) => {
  const body = {
    query,
    variables: variables || {},
  }
  return apiCall({
    method: "POST",
    uri: `${authorizeApi}query`,
    body: JSON.stringify(body),
    isAuthen: false,
    header: { "Content-Type": "application/json" },
  })
}

export const useQueryAuthen = (query: string, variables?: unknown) => {
  const body = {
    query,
    variables: variables || {},
  }
  return apiCall({
    method: "POST",
    uri: `${authorizeApi}query`,
    body: JSON.stringify(body),
    isAuthen: true,
    header: { "Content-Type": "application/json" },
  })
}

const getContentType = (contentType: string): string => {
  if (contentType.includes("jpg") || contentType.includes("jpeg")) return "jpg"
  if (contentType.includes("png")) return "png"
  if (contentType.includes("png")) return "pdf"
  return "";
} 

export const useUploadFile = (files: Array<fileProps>, gql: string) => {
  const formdata = new FormData();
  formdata.append("operations", gql);

  const map: any = {};
  for (let i = 0; i < files.length; i += 1) {
    map[i.toString()] = [`variables.files.${i}`]
  }

  formdata.append("map", JSON.stringify(map));
  console.log("ddd", JSON.stringify(map))
  for (let i = 0; i < files.length; i += 1) {
    const file = files[i]
    const realFile = file.data as File
    console.log("file.name", file.name)
    // formdata.append("map", `{"${i}": ["variables.files.${i}"]}`);
    formdata.append(i.toString(), realFile, file.name);
    // formdata.append("0", realFile, realFile.name);
  }
  
  return apiCall({
    method: "POST",
    uri: `${authorizeApi}query`,
    body: formdata,
    isAuthen: true, 
  })
}