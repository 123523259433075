import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux"
import { saveTFexTest } from "../../../actions";
import {
  Buttons, Card, Inputs, Texts, 
} from "../../../components";
import { fonts, mainFontSize } from "../../../config/themes";
import { ComponentContext } from "../../../contexts"
import {
  ICheckBoxs,
  IFormsShema, InputEnum, IOpenAccountShema, IStore, ISubPage, 
} from "../../../interfaces";

interface props {
  title: string
  subTitles?: Array<string>
}

export default ({ title, subTitles }: props) => {
  const dispatch = useDispatch()
  const componentContext = useContext(ComponentContext);
  
  const onChange = (val: {key?: string, value?: string | number | Array<ICheckBoxs>}) => {
    componentContext?.onChange({ key: val.key || "", value: val.value })
  };
  
  const onNext = () => {
    // dispatch(saveSuitAnswerSaga({ isSave: true }))
  }

  return (
    <Card>
      <div style={{
        display: "flex",
        flexDirection: "row",
        // alignItems: "center",
        justifyContent: "space-between", 
      }}
      >
        <Texts style={{ 
          color: "#0C67B1", 
          padding: "5px 0px 5px 0px", 
          fontSize: mainFontSize.l, 
          fontFamily: fonts.SarabunMedium,
        }}
        >
          {title}
        </Texts>
      </div>

      <div>
        {
          subTitles?.map((subTitle, key) => (
            <Texts style={{ 
              color: "#333333", 
              padding: "0px 0px 0px 0px", 
              fontSize: mainFontSize.s, 
              fontFamily: fonts.SarabunMedium,
            }}
            >
              {subTitle}
            </Texts>
          ))
        }
      </div>
      <div style={{
        display: "flex", 
        flexDirection: "row", 
        justifyContent: "space-between", 
        marginTop: 30,
      }}
      >
        <Buttons
          style={{
            backgroundColor: "#ffffff",
            color: "#0067b1",
            borderWidth: 1,
            borderColor: "#0067b1",
          }}
          onClick={() => onChange({ key: "BACK", value: 0 })}
        >
          ย้อนกลับ
        </Buttons>
        <Buttons 
          // disabled={!(mainData?.answer?.length > 0)} 
          style={{ marginLeft: 16 }}
          onClick={() => onChange({ key: "NEXT" })}
        >
          เริ่มทำแบบประเมิน
        </Buttons>
      </div>
    </Card>
  )
}