import React, { useContext } from "react"
import { useDispatch, useSelector } from "react-redux"
import { navigateNext, previewSuitabilityTestResult, saveSuitAnswerSaga } from "../../../actions"
import {
  Buttons,
  Card,
  Inputs,
  Images,
  Texts,
} from "../../../components"
import { fonts, mainFontSize } from "../../../config/themes"
import { ComponentContext } from "../../../contexts"
import {
  IFormsShema,
  InputEnum,
  IOpenAccountShema,
  IStore,
  ISubPage,
} from "../../../interfaces"
import PreTest from "./preTest"
import CardResult from "./result"

interface props {
  page: string
  data?: IOpenAccountShema[]
  dataOfPage: IFormsShema[]
}

export default ({
  page,
  data,
  dataOfPage,
}: props) => {
  const dispatch = useDispatch()
  const index = +page.split(".")[1] || 0
  const dataDisplay = dataOfPage[index]
  const { suitability } = useSelector((state: IStore) => state.OpeningAccountReducer)
  const mainData = suitability[dataDisplay?.type]
  
  const componentContext = useContext(ComponentContext);

  const onChange = (val: {key?: string, value?: string | number}) => {
    componentContext?.onChange({ key: val.key || "", value: val.value })
  };

  const onNext = () => {
    dispatch(previewSuitabilityTestResult())
  }

  const getChoiceStep = () => {
    const allChoice = 
      dataOfPage.filter((item: IFormsShema) => item.type !== ISubPage.STEP_SUITABILITY_SUMMARY_TEST 
      && item.type !== ISubPage.STEP_SUITABILITY_TEST)
    const choiceNumber = allChoice.length === 0 ? 0 : +index
    let total = allChoice.length === 0 ? 0 : allChoice.length
    if (allChoice.length === 0) {
      total = 0
    }
    return `${choiceNumber}/${total}`
  }

  if (dataDisplay?.type === ISubPage.STEP_SUITABILITY_SUMMARY_TEST) {
    return <CardResult title={dataDisplay?.title} data={dataDisplay} />
  }
  if (dataDisplay?.type === ISubPage.STEP_SUITABILITY_TEST) {
    return (
      <PreTest title={dataDisplay?.title} subTitles={dataDisplay?.subTitles} />
    )
  }

  return (
    <Card>
      
      <div style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between", 
      }}
      >
        <Texts style={{ 
          color: "#0C67B1", 
          padding: "5px 0px 5px 0px", 
          fontSize: mainFontSize.l, 
          fontFamily: fonts.SarabunMedium,
        }}
        >
          {dataDisplay?.title && `${+index}. ${dataDisplay?.title}`}
        </Texts>
        <Texts style={{ 
          color: "#70cde3", 
          padding: "5px 0px 5px 0px", 
          fontSize: mainFontSize.l, 
          fontFamily: fonts.SarabunMedium,
        }}
        >
          {getChoiceStep()}
        </Texts>
      </div>

      {dataDisplay?.inputs[0]?.image && (
        <div style={{
          justifyContent: "center",
          display: "flex",
          marginTop: 30,
          marginBottom: 30,
          
        }}
        >
          <Images style={{ width: "400px", height: "240px" }} src={dataDisplay?.inputs[0]?.image} />
        </div>
      )}

      <Inputs 
        config={{ 
          type: dataDisplay?.inputs[0]?.type as InputEnum, 
          field: dataDisplay?.inputs[0]?.field,
        }}
        value={dataDisplay?.inputs.map((inputSelected) => {
          if (mainData?.answer.includes(inputSelected.title)) {
            return {
              ...inputSelected,
              selected: true,
            }
          }
          return inputSelected
        })}
      />
      
      <div style={{
        display: "flex", 
        flexDirection: "row", 
        justifyContent: "flex-end", 
      }}
      >
        <Buttons onClick={() => onChange({ key: "BACK", value: 0 })}>กลับ</Buttons>
        <Buttons
          disabled={!(mainData?.answer?.length > 0)} 
          style={{ marginLeft: 16 }}
          onClick={onNext}
        >
          ยืนยัน
        </Buttons>
      </div>
    </Card>
  )
}