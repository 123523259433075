import { 
  OPENING_ACCOUNT_SET_ANSWER_SUIT_REDUCER, 
  OPENING_ACCOUNT_SAVE_QUESTION_SUIT_REDUCER, 
  SUITTEST_SET_PREVIEW_TEST_SCORE,
} from "../actions"

const INIT = {
  question: [],
  answer: {
    score: 0,
    investorLevel: "",
  },
  previewTestScore: {
    order: 0,
    investorLevel: "",
    suggestAssetAllocation: [],
    suggestFundLevel: 0,
  },
}

export default (state = INIT, { type, payload }: any) => {
  switch (type) {
    case OPENING_ACCOUNT_SAVE_QUESTION_SUIT_REDUCER:
      return { ...state, question: payload }

    case OPENING_ACCOUNT_SET_ANSWER_SUIT_REDUCER:
      return { ...state, answer: payload }

    case SUITTEST_SET_PREVIEW_TEST_SCORE:
      return { ...state, previewTestScore: payload }

    default:
      return state
  }
}