import {
  OPENING_ACCOUNT_SET_REDUCER,
  OPENING_ACCOUNT_SET_WITH_PAGE_REDUCER,
} from "../actions";
import {
  IOpeningAccountReducerPayload,
  IGetObjPage,
  getObjPage,
  getObjSubPage,
} from "../interfaces"

const initialState: IGetObjPage = {
  createPassword: {},
  termAndConditoin: {
    STEP_TERM_CONDITION_PDPA: {
      acceptOpenInfo: [
        {
          title: "ข้าพเจ้ายินยอมให้มีการเก็บรวบรวม ใช้ และ/หรือเปิดเผยข้อมูลส่วนบุคคลของข้าพเจ้า เพื่อวัตถุประสงค์ทางการตลาด ดังนี้",
          subTitle: "<ul style=\"list-style-type: square;\"><li>ส่งข่าวสารการลงทุน และประชาสัมพันธ์กิจกรรมของบริษัท งานสัมมนา ผ่านช่องทางต่างๆ ได้แก่ ไลน์กลุ่ม (Line Groups), อีเมล์ (Email), โทรศัพท์</li><li>เสนอโปรโมชั่น (Promotion) เพื่อการส่งเสริมการขายผลิตภัณฑ์และบริการอื่นๆ หรือทำการตลาดแบบ Cross- Selling กับบุคคลภายนอก ผ่านช่องทางต่างๆ ได้แก่ ไลน์กลุ่ม (Line Groups), อีเมล์ (Email), โทรศัพท์</li></ul>",
          // value: "acceptTermBankFinancialGroup",
        },
        {
          title: "ข้าพเจ้ายินยอมให้มีการเก็บรวบรวม ใช้ และ/หรือเปิดเผยข้อมูลที่ละเอียดอ่อนของข้าพเจ้า ดังนี้",
          subTitle: "<ul style=\"list-style-type: square;\"><li>ข้อมูลชีวมาตร ข้อมูลสุขภาพและ ข้อมูลที่ละเอียดอ่อน เพื่อการยืนยันและพิสูจน์ตัวบุคคล</li></ul>",
          // value: "acceptTermPersonalInformation",
        },
      ],
    },
    STEP_TERM_CONDITION_WEB: {
      acceptTermWebSite: [
        { title: "ฉันได้อ่านและทำความเข้าใจในข้อความทั้งหมดโดยที่ฉันได้ยอมรับและเห็นด้วย" },
      ],
    },
    STEP_SELECT_OPEN_TYPE_ACCOUNT: {
      selectOpenTypeList: [],
      codeMaketingRecomment: "",
      nameMaketingRecomment: "",
      codeIbaRecomment: "",
      nameIbaRecomment: "",
    },
    STEP_TERM_CONDITION_EQUITY_AND_MUTUAL_FUND: {
      EQUITY_AND_MUTUAL_FUND: [
        { title: "ฉันได้อ่านและทำความเข้าใจในข้อความทั้งหมดโดยที่ฉันได้ยอมรับและเห็นด้วย" },
      ],
    },
    // STEP_TERM_CONDITION_CASH_BALANCE_ACCOUNT: {
    //   CASH_BALANCE_ACCOUNT: [
    //     { title: "ฉันได้อ่านและทำความเข้าใจในข้อความทั้งหมดโดยที่ฉันได้ยอมรับและเห็นด้วย" },
    //   ],
    // },
    // STEP_TERM_CONDITION_MUTUAL_FUND_ACCOUNT: {
    //   MUTUAL_FUND_ACCOUNT: [
    //     { title: "ฉันได้อ่านและทำความเข้าใจในข้อความทั้งหมดโดยที่ฉันได้ยอมรับและเห็นด้วย" },
    //   ],
    // },
    STEP_TERM_CONDITION_CREDIT_BALANCE_ACCOUNT: {
      CREDIT_BALANCE_ACCOUNT: [
        { title: "ฉันได้อ่านและทำความเข้าใจในข้อความทั้งหมดโดยที่ฉันได้ยอมรับและเห็นด้วย" },
      ],
    },
    STEP_TERM_CONDITION_SAVING_ACCOUNT: {
      SAVING_ACCOUNT: [
        { title: "ฉันได้อ่านและทำความเข้าใจในข้อความทั้งหมดโดยที่ฉันได้ยอมรับและเห็นด้วย" },
      ],
    },
    STEP_TERM_CONDITION_TFEX: {
      TFEX: [
        { title: "ฉันได้อ่านและทำความเข้าใจในข้อความทั้งหมดโดยที่ฉันได้ยอมรับและเห็นด้วย" },
      ],
    },
    STEP_TERM_CONDITION_CONSENT_CLAUSE_OF_RETAIL_CUSTOMER: {
      CONSENT_CLAUSE_OF_RETAIL_CUSTOMER: [
        { title: "ฉันได้อ่านและทำความเข้าใจในข้อความทั้งหมดโดยที่ฉันได้ยอมรับและเห็นด้วย" },
      ],
    },
    STEP_TERM_CONDITION_TFEX_MOU_BLOCK_TRADE: {
      TFEX_MOU_BLOCK_TRADE: [
        { title: "ฉันได้อ่านและทำความเข้าใจในข้อความทั้งหมดโดยที่ฉันได้ยอมรับและเห็นด้วย" },
      ],
    },

    STEP_VERIFY_IDENTITY: {
      citizenId: "",
      laserId: "",
      firstNameTh: "",
      lastNameTh: "",
      birthDate: "",
    },
    STEP_VERIFY_FATCA: {
      isAmerican: false,
      birthplace: {
        id: "",
        place: "",
        city: "",
        countryCode: "",
        countryName: "",
      },
      hasTaxResidencesOtherThanUsaOrThailand: false,
      taxResidences: [
        {
          id: "",
          countryCode: "",
          countryName: "",
          tin: "",
          noTinReasonId: "",
          noTinExplanation: "",
        },
      ],
      noTinReasons: [],
    },
    STEP_VERIFY_PEP: {
      isPep: [
        { title: "ไม่ใช่", selected: true },
        { title: "ใช่ (โปรดระบุ)", requireOther: true, selected: false },
      ],
      otherPep: "",
    },
  },
  connectBank: {
    STEP_PROPERTY_LIST_NDID: {
      acceptTermNDID: [
        {
          title: "ฉันได้อ่านและทำความเข้าใจในข้อความทั้งหมดโดยที่ฉันได้ยอมรับและเห็นด้วย",
          selected: false,
        },
      ],
    },
    STEP_TERM_CONDITION_NDID: {
      acceptTermFATCA: [
        { title: "ฉันได้อ่านและทำความเข้าใจในข้อความทั้งหมดโดยที่ฉันได้ยอมรับและเห็นด้วย" },
      ],
    },
    STEP_IDP_LIST: {},
    NDID_CONFIRM_IDP_INFO: {},
    STEP_WAITING_NDID_VERIFY: {},
    STEP_PREVIEW_DATA_FROM_NDID: {},
  },
  idCardInformation: {
    STEP_ID_CARD_INFO: {
      citizenId: "",
      expiryDate: "",
      isExpiryDate: [
        { title: "ตลอดชีพ", selected: false },
      ],
      title: "",
      titleEn: "",
      firstNameTh: "",
      lastNameTh: "",
      firstNameEn: "",
      lastNameEn: "",
      birthDate: "",
      gender: "",
      nationality: "",
    },
    STEP_IMAGE_ID_CARD: {
      IMAGE_ID_CARD: [],
    },
    STEP_IMAGE_SELFIE_WITH_ID_CARD: {
      IMAGE_SELFIE_WITH_ID_CARD: [],
    },
  },
  personalInformation: {
    STEP_MARITAL_STATUS: {
      isSpouse: [
        { title: "โสด", selected: true },
        { title: "สมรส (โปรดระบุ)", requireOther: true },
      ],
      firstNameTh: "",
      lastNameTh: "",
      firstNameEn: "",
      lastNameEn: "",
      isRelatedPEP: [
        { title: "ไม่มี", selected: true },
        { title: "มี (โปรดระบุ)", requireOther: true },
      ],
      position: "",
      BC: "",
    },
    STEP_FRAUD: {
      isFraud: [
        { title: "ไม่มี", selected: true },
        { title: "มี (โปรดระบุ)", requireOther: true },
      ],
      predicateOftence: "",
      BC: "",
    },
    STEP_INCOME: {
      countryOfIncome: [
        { title: "ประเทศไทย", selected: true },
        { title: "ประเทศอื่นๆ (โปรดระบุ)", requireOther: true, selected: false },
      ],
      otherCountryOfIncome: "",
      sourceOfIncome: [],
      otherSourceOfIncome: "",
      salary: [],
      purposeOfInvest: [],
      otherPurposeOfInvest: "",
      propertyValue: "",
      codeCountry: "",
    },
    STEP_ID_CARD_ADDRESS: {
      houseNumber: "",
      moo: "",
      building: "",
      roomNumber: "",
      floor: "",
      alley: "",
      road: "",
      postalCode: "",
      province: "",
      district: "",
      subDistrict: "",
      codeAddress: "",
    },
    STEP_CURRENT_ADDRESS: {
      isSameHomeAddress: [
        { title: "ที่อยู่ตามทะเบียนบ้าน", selected: true },
        { title: "อื่นๆ (โปรดระบุข้อมูลด้านล่างนี้)", requireOther: true, selected: false },
      ],
      houseNumber: "",
      moo: "",
      building: "",
      roomNumber: "",
      floor: "",
      alley: "",
      road: "",
      postalCode: "",
      province: "",
      district: "",
      subDistrict: "",
      codeAddress: "",
    },
    STEP_WORKPLACE_ADDRESS: {
      codeCareer: "",
      codeTypeBusiness: "",
      career: "",
      position: "",
      typeBusiness: "",
      typeBusinessOther: "",
      nameWorkplace: "",
      houseNumber: "",
      moo: "",
      building: "",
      roomNumber: "",
      floor: "",
      alley: "",
      road: "",
      postalCode: "",
      province: "",
      district: "",
      subDistrict: "",
      codeAddress: "",
    },
    STEP_MAILING_ADDRESS: {
      sameSomeAddress: [
        { title: "ที่อยู่ตามทะเบียนบ้าน", id: "IDENTIFICATION_CARD", selected: true },
        { title: "ที่อยู่ปัจจุบัน", id: "CURRENT" },
        { title: "ที่อยู่ทำงาน", id: "WORKPLACE" },
        { title: "อื่นๆ (โปรดระบุข้อมูลด้านล่างนี้)", id: "OTHER", requireOther: true },
      ],
      houseNumber: "",
      moo: "",
      building: "",
      roomNumber: "",
      floor: "",
      alley: "",
      road: "",
      postalCode: "",
      province: "",
      district: "",
      subDistrict: "",
      codeAddress: "",
    },
    STEP_IMAGE_HOUSE_REGISTRATION: {
      IMAGE_HOUSE_REGISTRATION: [],
    },
    STEP_EMERGENCY_CONTACT: {
      emergencyFirstNameTh: "",
      emergencyLastNameTh: "",
      emergencyRelate: "",
      emergencyMobileNumber: "",
      spouseFirstNameTh: "",
      spouseLastNameTh: "",
      spouseMobileNumber: "",
      rhbFirstNameTh: "",
      rhbLastNameTh: "",
      rhbRelate: "",
      rhbMobileNumber: "",
      beneficialOwner: [
        { title: "ตนเอง", selected: true },
        { title: "มี (โปรดระบุ)", requireOther: true, selected: false },
      ],
      boFirstNameTh: "",
      boLastNameTh: "",
      boRelate: "",
      boMobileNumber: "",
      controllingPerson: [
        { title: "ตนเอง", selected: true },
        { title: "มี (โปรดระบุ)", requireOther: true, selected: false },
      ],
      cpFirstNameTh: "",
      cpLastNameTh: "",
      cpRelate: "",
      cpMobileNumber: "",
    },
  },
  accountInformation: {
    STEP_TERM_CONDITION_SAVING_ACCOUNT: {
      acceptTermConditionSavingAccount: [
        { title: "ฉันได้อ่านและทำความเข้าใจในข้อความทั้งหมดโดยที่ฉันได้ยอมรับและเห็นด้วย" },
      ],
    },
    STEP_SAVING_ACCOUNT_SUBSCRIPTION: {
      bankName: "",
      branch: "",
      accountNo: "",
      accountName: "",
      IMAGE_BOOKBANK_SUBSCRIPTION: [],
    },
    STEP_SAVING_ACCOUNT_REDEMPTION: {
      political: [
        { title: "ใช้บัญชีธนาคารสำหรับหักเงินเพื่อซื้อหลักทรัพย์", selected: true },
        { title: "อื่นๆ (โปรดระบุข้อมูลด้านล่างนี้)", requireOther: true, selected: false },
      ],
      bankName: "",
      branch: "",
      accountNo: "",
      accountName: "",
      IMAGE_BOOKBANK_REDEMPTION: [],
    },
    STEP_IMAGE_STATEMENT: {
      IMAGE_STATEMENT: [],
    },
  },
  tfexQuestionaire: {},
  suitability: {
    answer01: {
      answer: [],
    },
    answer02: {
      answer: [],
    },
    answer03: {
      answer: [],
    },
    answer04: {
      answer: [],
    },
    answer05: {
      answer: [],
    },
    answer06: {
      answer: [],
    },
    answer07: {
      answer: [],
    },
    answer08: {
      answer: [],
    },
    answer09: {
      answer: [],
    },
    answer10: {
      answer: [],
    },
    answer11: {
      answer: [],
    },
    answer12: {
      answer: [],
    },
    STEP_SUITABILITY_SUMMARY_TEST: {
      acceptSuitabilityResult: [
        {
          title: "ข้าพเจ้าได้ตรวจสอบข้อมูลแล้วว่าถูกต้อง",
          selected: false,
        },
      ],
    },
  },
  successFully: {
    STEP_IMAGE_SIGNATURE: {
      IMAGE_SIGNATURE: [],
    },
    STEP_OVERVIEW_ALL_DATA: {},
    STEP_SUBMIT_DATA: {},
    STEP_STATUS_AO: {},
  },
}

function OpeningAccountReducer(state = initialState,
  { type, payload }: IOpeningAccountReducerPayload) {
  switch (type) {
    case OPENING_ACCOUNT_SET_REDUCER:
      if (payload.page === "tfexQuestionaire") {
        return {
          ...state,
          tfexQuestionaire: {
            ...state.tfexQuestionaire,
            [payload.subPage]: payload.addValue,
          },
        }
      }

      if (payload.page in state && payload.subPage in getObjPage(state)(payload.page)) {
        const subPage = getObjPage(state)(payload.page)
        return {
          ...state,
          [payload.page]: {
            ...getObjPage(state)(payload.page),
            [payload.subPage]: { ...getObjSubPage(subPage)(payload.subPage), ...payload.addValue },
          },
        }
      }

      return state
    case OPENING_ACCOUNT_SET_WITH_PAGE_REDUCER:
      return {
        ...state, [payload.page]: payload.addValue,
      }

    default:
      return state
  }
}

export default OpeningAccountReducer
