import { fileProps, IVerification, UploadFileType } from "../interfaces"
import { getFatcaSchema, verifyRegisterSchema } from "../schemas"
import {
  useQueryAuthen,
  useUploadFile,
} from "./apiCall"

export const VerifyRegisterApi = (
  citizenId: string,
  laserId: string,
  firstNameTH: string,
  lastNameTh: string,
  dateOfBirth: string,
) => {
  const variables = {
    input: {
      citizenId,
      dateOfBirth,
      firstNameTH,
      laserId,
      lastNameTh,
    },
  }
  return useQueryAuthen(verifyRegisterSchema, variables)
}

export const GetFatcaApi = () => useQueryAuthen(getFatcaSchema)

export type SaveFatcaInput = {
  isAmerican: boolean;
  hasTaxResidenceOtherThanUsaOrThai: boolean;
  birthplace: {
    id: string
    place: string
    city: string
    countryCode: string
  }
  taxResidences: {
    id: string
    countryCode: string
    tin: string
    noTinReasonId: string
    noTinExplanation: string
  }[]
}

export const SaveFatcaApi = (input: SaveFatcaInput) => {
  const schema = `mutation ($input: SaveFatcaRequest!) {
    SaveFatca(input: $input) {
      verification{
        service
        secret
      }
      birthplace {
        id
        place
        city
        countryCode
        countryName
      }
      taxResidences {
        id
        countryCode
        countryName
        tin
        noTinReasonId
        noTinExplanation
      }
      error
    }
  }`

  const variables = {
    input,
  }

  return useQueryAuthen(schema, variables)
}

export const GetPepApi = () => {
  const schema = `query getPep {
    GetPep {
      isVerified
    	isPep
      position
    }
  }`
  return useQueryAuthen(schema)
}

export const ValidatePepApi = (isPep: boolean, position: string) => {
  const schema = `mutation validatePep($input: ValidatePepRequest!) {
    ValidatePep(input: $input) {
        verification{
            service
            secret
        }
        error
    }
  }`
  const variables = {
    input: {
      isPep,
      position,
    },
  }

  return useQueryAuthen(schema, variables)
}

export const UploadFile = (files: Array<fileProps>, type: UploadFileType) => {
  const query = `mutation UploadImage($docType: UploadImageDocumentType!, $files:[Upload]) {  
    SavePicture(input: {documentType: $docType, uploads: $files}) {    
      id
      data    
      content
      name
    }
  }`

  const fileArr = [];

  for (let i = 0; i < files.length; i += 1) {
    fileArr.push(null)
  }

  const gql = {
    query,
    variables: { files: fileArr, docType: type },
  }
  console.log("UploadFile ===> ", gql)
  return useUploadFile(files, JSON.stringify(gql))
}

export const GetConsentApi = (consentType: string) => {
  const schema = `query getConsent {
    GetConsent(input:{consentType: ${consentType}}){
      consent{
        version
        consentId
        consentEn
        consentTh
        labelTitleEn
        labelTitleTh
        labelSubEn
        labelSubTh
      }
    }
  }`
  return useQueryAuthen(schema)
}

export const GetSelectConsentApi = (consentType: string) => {
  const schema = `query GetUserConsent {
    GetUserConsent(input:{consentType: ${consentType}}){
      consent{
        version
        consentId
        consentEn
        consentTh
        labelTitleEn
        labelTitleTh
        labelSubEn
        labelSubTh
        accept
      }
    }
  }`
  return useQueryAuthen(schema)
}

export const SaveConsentApi =
  (consents: Array<{consentID: number, consentType: string, accept: boolean}>) => {
    const schema = `mutation saveConsent($input: SaveConsentInput){
      SaveConsent(input: $input){
          isSuccess
      }
    }`

    const variables = {
      input: {
        consents,
      },
    }

    return useQueryAuthen(schema, variables)
  }

export const SetAoStepApi = (input: {stepName: string, isPass: boolean}) => {
  const schema = `mutation setAoStep($input: SetAoStepInput){
    SetAoStep(input: $input) {
      step {
        stepName
        isPass
        titleEn
        titleTh
        subStep {
          stepName
          isPass
          titleEn
          titleTh
          status
          allowEdit
        }
        allowEdit
      }
      mainCurrentStep
      subCurrentStep
    }
  }`

  const variables = {
    input,
  }

  return useQueryAuthen(schema, variables)
}

export const GetAoStepApi = () => {
  const schema = `query getAoStep{
    GetAoStep {
      step {
        stepName
        isPass
        titleEn
        titleTh
        subStep {
          stepName
          isPass
          titleEn
          titleTh
          status
          allowEdit
        }
        allowEdit
      }
      mainCurrentStep
      subCurrentStep
    }
  }`

  return useQueryAuthen(schema)
}

export const GetUsers = () => {
  const gql = `query GetUserInfo{
    GetUserInfo {
      email
      mobileNumber
      citizenCardNumber
      oaStatus
    }
  }`
  return useQueryAuthen(gql)
}

export const GetImageDocument = () => {
  const gql = `query GetImageDocument {
    GetImageDocument {
      imageType
      filename
      imageBase64Encode
      status
      contentType
    }
  }`

  return useQueryAuthen(gql)
}

export const GetAccountTypeApi = () => {
  const gql = `query GetAccountType {
    GetAccountType{
      typeID
      typeNameTH
      typeNameEN
      descriptionTH
      descriptionEN
      consentType
    }
  }`
  return useQueryAuthen(gql)
}

export const LockOutApi = () => {
  const gql = `mutation Logout {
    Logout {
      isSuccess
    }
  }`
  return useQueryAuthen(gql)
}

export const GetReferralPersonByIdApi = (id: string) => {
  const schema = `query ReferralPerson {
    GetReferralPersonById(input:{
        id: "${id}"
    }){
      id
      nameTh
      nameEn
      email
    }
  }`

  return useQueryAuthen(schema)
}

// ====================================== Mutation Data ===================================

export const SaveReferralPersonApi = (
  referralPerson1: string,
  referralPerson2: string,
  generalReferrer: string,
) => {
  const gql = `mutation SaveReferralPerson($referralPerson: SaveReferralPersonInput) {
    SaveReferralPerson(input:$referralPerson){
      isSuccess
    }
  }`
  const variables = {
    referralPerson: {
      referralPerson1,
      referralPerson2,
      generalReferrer,
    },
  }
  return useQueryAuthen(gql, variables)
}

export const SavePersonalInfo = (input: any) => {
  const gql = `mutation SavePersonalInfo($input: InputPersonalInfo) {
    SavePersonalInfo(input: $input) {
      isSuccess
      message
    }
  }`
  const variables = {
    input: {
      ...input,
    },
  }
  return useQueryAuthen(gql, variables)
}

export const SaveBackgroundInfo = (input: any) => {
  const gql = `mutation SaveBackgroundInfo($input: InputBackgroundInfo) {
    SaveBackgroundInfo(input: $input) {
      isSuccess
      message
    }
  }`
  const variables = {
    input: {
      ...input,
    },
  }
  return useQueryAuthen(gql, variables)
}

export const SaveContactInfo = (input: any) => {
  const gql = `mutation SaveContactInfo($input: InputContactInfo) {
    SaveContactInfo(input: $input) {
      isSuccess
      message
    }
  }`
  const variables = {
    input: {
      ...input,
    },
  }
  return useQueryAuthen(gql, variables)
}

export const SavePersonalContacts = (contacts: Array<any>) => {
  const gql = `mutation SavePersonalContacts($input: [PersonalContactInput!]) {
    CreateOrUpdatePersonalContacts(input:$input) {
      isSuccess
      message
    }
  }`
  const variables = {
    input: contacts,
  }
  return useQueryAuthen(gql, variables)
}

export const SaveMutualFundAccountDetail = (intpu: any) => {
  const gql = `mutation SaveMutualFundAccountDetail($input: UpdateMutualFundAccountDetailInput) {
    UpdateMutualFundAccountDetail(input: $input) {
      isSuccess
      message
    }
  }`
  const variables = {
    input: {
      ...intpu,
    },
  }
  return useQueryAuthen(gql, variables)
}

export const SaveMutualFundAccount = (input: any) => {
  const gql = `mutation SaveMutualFundAccount($input: CreateOrUpdateMutualFundAccountInput) {
    CreateOrUpdateMutualFundAccount(input: $input) {
      isSuccess
      message
    }
  }`
  const variables = {
    input: {
      ...input,
    },
  }
  return useQueryAuthen(gql, variables)
}

export const SaveSavingAccountToMutualFund = (input: any) => {
  const gql = `mutation SaveSavingAccountToMutualFund($input: UpdateSavingAccountToMutualFundInput) {
    UpdateSavingAccountToMutualFund(input: $input) {
      isSuccess
      message
    }
  }`
  const variables = {
    input: {
      ...input,
    },
  }
  return useQueryAuthen(gql, variables)
}

export const GetAllImage = () => {
  const gql = `query GetImageDocument {
    GetImageDocument {
      imageType
      id
      filename
      imageBase64Encode
      contentType
      status
      comment
      createdAt
      updatedAt
    }
  }`
  return useQueryAuthen(gql)
}

export const SubmitDataApi = (certificateInput: IVerification) => {
  const gql = `mutation SubmitData($input: SubmitDataInput) {
    SubmitData(input: $input) {
      isSuccess
    }
  }`
  const variables = {
    input: {
      verification: certificateInput,
    },
  }
  console.log("variables ==>", variables)
  return useQueryAuthen(gql, variables)
}

export const SaveSelectedAccountApi = (input: any) => {
  const gql = `mutation SaveSelectedAccount($input: SaveSelectedAccountInput) {
    SaveSelectedAccount(input: $input){
      isSuccess
      message
    }
  }`

  const variables = {
    input: {
      ...input,
    },
  }
  return useQueryAuthen(gql, variables)
}

// ====================================== Query Data ===================================

export const GetQueryAllDataApi = () => {
  const GetMutualFundAccount = `mutualFundAccount {
    mutualFundType
    mutualFundAccountId
    mailingMethod
    optionalMailingMethod
    mailingAddressSameAs
    mailingAddress {
      addressNumber
      village
      building
      floor
      room
      soi
      road
      moo
      addressCode
      subDistrict
      district
      province
      zipCode
      country
    }
    subscriptionBankAccount {
      bankCode
      branchCode
      accountName
      accountNumber
      default
    }
    redemptionBankAccountSameAs
    redemptionBankAccount {
      bankCode
      branchCode
      accountName
      accountNumber
      default
    }
    approved
    investmentObjective
    investmentObjectiveOther
    icLicense
    isOmnibusForm
    accountOpenDate
    createdAt
    updatedAt
  }`
  const GetContactInfo = `contactInfo {
    identificationCardAddress {
      addressNumber
      village
      building
      floor
      room
      soi
      road
      moo
      addressCode
      subDistrict
      district
      province
      zipCode
      country
    }
    currentAddressSameAs
    currentAddress {
      addressNumber
      village
      building
      floor
      room
      soi
      road
      moo
      addressCode
      subDistrict
      district
      province
      zipCode
      country
    }
    workplaceAddressSameAs
    workplaceAddress {
      addressNumber
      village
      building
      floor
      room
      soi
      road
      moo
      addressCode
      subDistrict
      district
      province
      zipCode
      country
    }
    companyName
    workPosition
    phoneNumber
    mobileNumber
    email
    fax
  }`
  const GetBackgroundInfo = `backgroundInfo {
    occupation
    occupationOther
    businessType
    businessTypeOther
    incomeSource
    incomeSourceOther
    incomeSourceCountry
    monthlyIncomeLevel
    assetValue
    isRelatedPoliticalPerson
    politicalPosition
    isAcceptFxRisk
    isAcceptDerivativeInvestment
    isFatca
    fatcaDeclarationDate
    cddScore
    cddDate
    isVulnerable
    vulnerableDetail
    workplaceAddress {
        addressNumber
        village
        building
        floor
        room
        soi
        road
        moo
        addressCode
        subDistrict
        district
        province
        zipCode
        country
    }
    companyName
    workPosition
    isFraud
    fundamentallyWrong
    fraudYear
    hasTaxResidencesOtherThanUsaOrThailand
  }`
  const GetPersonalInfo = `personalInfo {
    gender
    title
    titleOther
    firstNameTh
    lastNameTh
    firstNameEn
    lastNameEn
    birthdate
    nationality
    maritalStatus
    identificationCardType
    identificationCardNumber
    isIdentificationCardExpiryDate
    identificationCardExpiryDate
    identificationCardIssueCountry
    spouse {
      firstNameTh
      lastNameTh
      firstNameEn
      lastNameEn
      isPep
      pepYear
      position
    }
  }`
  const GetSuitabilityTestInfo = `suitabilityTestInfo{
    answer01
    answer02
    answer03
    answer04
    answer05
    answer06
    answer07
    answer08
    answer09
    answer10
    answer11
    answer12
    score
    investorLevel
    isExpired
    expiryDate
    suggestAssetAllocation {
      order
      labelTh
      labelEn
      percentage
    }
    suggestFundLevel
  }`

  const SuitabilityQuestion = `SuitabilityQuestion {
      order
      question {
          th,
          en
      }
      questionImage
      remark {
          th
          en
      }
      subRemark {
          th
          en
      }
      answerType
      choices {
          value
          order
          label {
              th
              en
          }
      }
  }`

  const GetTfexQuestion = `GetTfexQuestion {
    tfexQuestionId
    group
    questionTh
    questionEn
    answer
    order
  }`

  const GetResultTfexTest = `tfexTestInfo {
    percent
    isPass
    correct
    incorrect
    answer01
    answer02
    answer03
    answer04
    answer05
    answer06
    answer07
    answer08
    answer09
    answer10
    answer11
    answer12
    answer13
    answer14
    answer15
    answer16
    answer17
    answer18
    answer19
    answer20
    createdAt
    updatedAt
  }`

  const GetPersonalContacts = `GetPersonalContacts {
    itHas
    firstName
    lastName
    relate
    mobileNumber
    type
  }`

  const GetCustomerInfo = `customerInfo {
    referralPerson1
    referralPerson2
    generalReferrer
  }`

  const GetUserConsent = `userConsent{
    consent{
      consentId
      consentType
      consentEn
      consentTh
      version
      labelTitleEn
      labelTitleTh
      labelSubEn
      labelSubTh
      order
      accept
    }
  }`

  const GetPep = `GetPep {
    isVerified
    isPep
    position
  }`

  const getSelectedAccounts = `selectedAccounts {
    accountType
    accountNameTh
    accountNameEn
    updatedAt
    consentInfo {
      consentID
      consentType
      order
      accept
      updatedAt
    }
  }`

  const birthplace = `birthplace {
    id
    place
    city
    countryCode
    countryName
  }`

  const taxResidences = `taxResidences {
    id
    countryCode
    countryName
    tin
    noTinReasonId
    noTinExplanation
  }`

  const gql = `query getOverviewInfo($getOverviewInfo: GetOverviewInfo) {
    GetOverviewInfo(input: $getOverviewInfo) {
      ${GetPersonalInfo}
      ${GetBackgroundInfo}
      ${GetSuitabilityTestInfo}
      ${GetContactInfo}
      ${GetMutualFundAccount}
      ${GetResultTfexTest}
      ${GetCustomerInfo}
      ${GetUserConsent}
      ${getSelectedAccounts}
      ${birthplace}
      ${taxResidences}
    }
    ${GetPersonalContacts}
    ${SuitabilityQuestion}
    ${GetTfexQuestion}
    ${GetPep}
  }`

  const variables = {
    getOverviewInfo: {
      mutualFundAccountInput: {
        channelId: "RHB",
        mutualFundType: "D",
        approved: false,
      },
      getUserConsentInput: {},
    },
  }
  return useQueryAuthen(gql, variables)
}

export function ResetAoSteps() {
  const gql = `mutation ResetAoSteps() {
    ResetAoSteps() {
      isSuccess
      message
    }
  }`

  return useQueryAuthen(gql)
}
