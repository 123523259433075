import { NDID_FLOW } from "../actions";
import { INDIDPayload, INDIDReducers, IStatusNDID } from "../interfaces";

const initialState: INDIDReducers = {
  idp: [],
  onTheFly: [],
  CHOOSE_IDP_NDID: {
    logo: "",
    idpID: "",
    displayNameEN: "",
    displayNameTH: "",
  },
  STATUS_VERIFY_NDID: {
    referenceID: "",
    status: "INVALID" as IStatusNDID,
    verifyBy: "",
    error: {
      errorMessageTH: "",
      errorMessageEN: "",
    },
  },
  RETRIEVE_DATA_NDID: {
    referenceId: "",
    identificationCard: {
      type: "",
      number: "",
      issueDate: "",
      issueCountry: "",
      expiryDate: "",
      isExpiryDate: "",
    },
    gender: "",
    title: "",
    titleOther: "",
    firstNameTh: "",
    middleNameTh: "",
    lastNameTh: "",
    firstNameEn: "",
    middleNameEn: "",
    lastNameEn: "",
    birthDate: "",
    nationality: "",
    maritalStatus: "",
    identificationCardAddress: {
      addressId: "",
      number: "",
      village: "",
      building: "",
      floor: "",
      room: "",
      soi: "",
      road: "",
      moo: "",
      postcode: "",
      fullAddress: "",
    },
    currentAddress: {
      addressId: "",
      number: "",
      village: "",
      building: "",
      floor: "",
      room: "",
      soi: "",
      road: "",
      moo: "",
      postcode: "",
      fullAddress: "",
    },
    workplaceAddress: {
      addressId: "",
      number: "",
      village: "",
      building: "",
      floor: "",
      room: "",
      soi: "",
      road: "",
      moo: "",
      postcode: "",
      fullAddress: "",
    },
    companyName: "",
    occupation: "",
    occupationOther: "",
    monthlyIncomeLevel: "",
    phoneNumber: "",
    email: "",
    transactionDate: "",
  },
}

export default (state = initialState, { type, payload }: INDIDPayload) => {
  switch (type) {
    case NDID_FLOW:
      return { 
        ...state, 
        ...{ 
          [payload?.key]: payload?.value, 
        },
      };
  
    default:
      return state
  }
}