import React from "react"
import styled from "styled-components";
import { mainFontSize } from "../../config/themes";
import { useOutsideAlerter } from "../../utilities";
import { TBold, TLight, TMedium } from "../Texts";

const Button = styled.div`
  flex: 1;
  padding: 5px 20px;
  cursor: pointer;
`

const ArrowDown = ({ color }:{color: string}) => (
  <div
    style={{
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      // borderLeftStyle: "solid",
      // borderLeftColor: color,
      // borderLeftWidth: "1px",
      // paddingLeft: 20,
    }}
  >
    <div style={{ transform: "rotate(90deg)" }}>
      <TBold color={color}>{">"}</TBold>
    </div>
  </div>
)

interface DropdownProps {
  data: Array<{ title: string }>
  value: string
  disabled?: boolean
  errorMessage?: string | Array<string>
  onChange: (value: string) => void
  onClick?: () => void
  placeholder?: string
}

export default ({
  data,
  value,
  disabled,
  errorMessage,
  onChange,
  onClick,
  placeholder,
}: DropdownProps) => {
  const wrapperRef = React.useRef<HTMLDivElement>(null);
  const textViewRef = React.useRef<HTMLDivElement>(null)
  const [posToTop, setToTop] = React.useState(false)
  const [popup, setPopup] = React.useState(false)
  const [text, setText] = React.useState("")
  const togglePopup = () => {
    if (disabled) return
    const layout: {y: number} | undefined = textViewRef.current?.getBoundingClientRect()
    if (layout) { setToTop(layout?.y > 350) }
    onClick?.()
    setPopup((val) => !val)
  }

  const onSelected = (item: string) => {
    togglePopup()
    setText(item)
    onChange(JSON.stringify(data.find((val) => val.title === item)))
  }

  useOutsideAlerter(wrapperRef, () => setPopup(false));
  React.useEffect(() => { if (typeof value === "string") setText(value) }, [value])

  const activedColor = !disabled ? "#66666680" : "#cccccc"

  let checkPositionToItems = {}
  if (popup && posToTop && data.length > 1) {
    checkPositionToItems = {
      position: "absolute",
      top: "auto",
      bottom: "105%",
      left: 0,
      width: "100%",
      zIndex: 2,
      maxHeight: 300,
      overflow: "scroll",
      border: `1px solid ${activedColor}`,
      borderRadius: 4,
    }
  } else if (popup && !posToTop && data.length > 1) {
    checkPositionToItems = {
      position: "absolute",
      top: "105%",
      left: 0,
      width: "100%",
      zIndex: 2,
      maxHeight: 300,
      overflow: "scroll",
      border: `1px solid ${activedColor}`,
      borderRadius: 4,
    }
  } else if (popup && data.length < 1) {
    checkPositionToItems = {
      position: "absolute",
      top: "105%",
      left: 0,
      width: "100%",
      zIndex: 2,
      overflow: "scroll",
      border: `1px solid ${activedColor}`,
      borderRadius: 4,
      backgroundColor: "white",
    }
  }

  return (
    <div
      ref={wrapperRef}
      style={{
        display: "flex",
        border: `1px solid ${errorMessage ? "red" : activedColor}`,
        borderRadius: 4,
        position: "relative",
      }}
    >
      <Button style={{ display: "flex", flexDirection: "row" }} onClick={togglePopup}>
        <TLight color={disabled ? "#333333" : "black"} fontSize={mainFontSize.m} style={{ flex: 1 }}>{text || placeholder || "กรุณาเลือก"}</TLight>
        <ArrowDown color={activedColor} />
      </Button>

      <div
        ref={textViewRef}
        style={checkPositionToItems}
      >
        {
          popup && (
            data.length < 1
              ? <TLight style={{ paddingTop: 10, paddingBottom: 10 }} textAlign="center">ไม่พบข้อมูล</TLight>
              : data.map((val) => (
                <Button
                  onClick={() => onSelected(val.title)}
                  style={{
                    backgroundColor: text && val.title === text ? "#cccccc" : "white",
                    borderTopWidth: "1px",
                    borderTopColor: activedColor,
                    borderTopStyle: "solid",
                  }}
                >
                  <TLight>{val.title}</TLight>
                </Button>
              ))
          )
        }
      </div>
    </div>
  )
}
