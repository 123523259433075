import {
  AUTH_SET_EMAIL, 
  AUTH_SET_MOBILE_NO, 
  AUTH_SET_EMAIL_OTP_REQUEST, 
  AUTH_SET_MOBILE_OTP_REQUEST, 
  AUTH_SET_EMAIL_OTP_VERIFY, 
  AUTH_SET_MOBILE_OTP_VERIFY, 
  AUTH_SET_MOBILE_OTP_ANSWER,
  AUTH_SET_EMAIL_OTP_ANSWER,
  AUTH_SET_REDUCER, 
} from "../actions";
import { IAuthState, IAuthReducerPayload } from "../interfaces"

const initialState: IAuthState = {
  mobileNo: "",
  email: "",
  loginField: "",
  isLogin: false,
}

function AuthReducer(state = initialState, { type, payload }: IAuthReducerPayload) {
  switch (type) {
    case AUTH_SET_REDUCER:
      return { ...state, [payload?.key]: payload?.value }
    case AUTH_SET_EMAIL:
      return { ...state, email: payload };
    case AUTH_SET_EMAIL_OTP_ANSWER:
      return { ...state, emailOtpAnswer: payload };
    case AUTH_SET_MOBILE_NO:
      return { ...state, mobileNo: payload };
    case AUTH_SET_MOBILE_OTP_ANSWER:
      return { ...state, mobileOtpAnswer: payload };
    case AUTH_SET_EMAIL_OTP_REQUEST:
      return { ...state, emailOtp: { ...state.emailOtp, otpRequest: payload } };
    case AUTH_SET_MOBILE_OTP_REQUEST:
      return { ...state, mobileOtp: { ...state.mobileOtp, otpRequest: payload } };
    case AUTH_SET_EMAIL_OTP_VERIFY:
      return { ...state, emailOtp: { ...state.emailOtp, otpVerify: payload } };
    case AUTH_SET_MOBILE_OTP_VERIFY:
      return { ...state, mobileOtp: { ...state.mobileOtp, otpVerify: payload } };
    default:
      return state
  }
}

export default AuthReducer