export const endPoint:any = {
  DEV: "",
  SIT: "https://rhb-client-sit.codefin.dev/",
  UAT: "https://rhb-client-uat.codefin.dev/",
  PROD: "https://register.th.rhbtradesmart.com/",
}

const mapEndpoint:any = {
  localhost: "SIT",
  "rhb-webapp-sit": "SIT",
  "rhb-webapp-uat": "UAT",
  "register.th.rhbtradesmart.com": "PROD",
}

const getEndpointFromMap = (value: string): string => {
  const keys = Object.keys(mapEndpoint)
  for (let i = 0; i < keys.length; i += 1) {
    if (value.startsWith(keys[i])) {
      return mapEndpoint[keys[i]]
    }
  }
  return "SIT"
}
export const authorizeApi = "api/account/v1/"

export const getEndPoint = () => {
  const appEnv = process.env.REACT_APP_ENV;
  if (appEnv && endPoint[appEnv.toUpperCase()]) {
    return endPoint[appEnv.toUpperCase()]
  }

  const env = getEndpointFromMap(window.location.host)

  return endPoint[env]
}

export default {
  debug: false,
}