import i18n from "i18next";
import detector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import resources from "../../languages"

type lang = "th" | "en"

i18n
  .use(detector)
  .use(initReactI18next)
  .init({
    lng: "th", // use en if detected lng is not available
    resources,
    fallbackLng: "th",
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export const changeLanguage = (lng: lang) => i18n.changeLanguage(lng)

export const getLanguage = () : lang => i18n.language || window.localStorage.i18nextLng

export default i18n
