import React from "react"
import { LineComponents, StepperContainer, MessageComponent } from "./index.styles"
import { stepItems, StepperOption } from "./index.types"

export default ({
  steps,
  activeStep,
  maxPageOfStep,
  styleContainer,
  onChange,
}: StepperOption) => {
  const [_activeStep, setActiveStep] = React.useState<string>("0")
  const stepsLines = steps
    .map((val, index) => ({ ...val, index }))
    .reduce(
      (pre, curr) => pre.concat({ 
        index: curr.index,
        title: curr.title,
      }, {
        index: curr.index,
        title: "line",
      }), [] as stepItems[],
    )
  stepsLines.pop()

  const onClick = (index: number) => {
    if (onChange) {
      onChange({ key: "STEPPER", value: index })
    }
  }

  React.useEffect(() => {
    setActiveStep(activeStep)
  }, [activeStep])

  return (
    <StepperContainer style={styleContainer}>
      {stepsLines.map(
        (step, i) => (i % 2
          ? (
            <LineComponents 
              key={`LineComponents-${step.index?.toString()}`}
              activeStep={_activeStep}
              indexItems={step.index || 0}
              maxPageOfStep={maxPageOfStep}
            />
          )
          : (
            <MessageComponent
              key={`MessageComponent-${step.index?.toString()}`}
              activeStep={+_activeStep}
              indexItems={step.index || 0}
              step={step.title}
              onClick={onClick}
            />
          )
        ),
      )}
    </StepperContainer>
  )
}