import React, { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components"
import Swal, { SweetAlertIcon } from "sweetalert2"
import { 
  cancelNDID,
  getCheckVerifyNDID,
  navigateNext,
  navigateSetStep,
} from "../../../actions";
import {
  Buttons,
  Card,
  Images,
  Texts,
  HistoryOfPath,
  TLight,
  TMedium,
} from "../../../components";
import ButtonLink from "../../../components/Buttons/buttonLink";
import { kBankLogo, Success } from "../../../components/Images";
import LoadingIndicator from "../../../components/LoadingIndicator";
import { showDialog } from "../../../components/Provider/dialog";
import { getEndPoint } from "../../../config";
import { fonts, mainFontSize } from "../../../config/themes";
import { ComponentContext } from "../../../contexts";
import {
  IDialogTypeEnum,
  IFormsShema, IOpenAccountShema, IStatusNDID, IStore, 
} from "../../../interfaces";
import { getLanguage } from "../../../services/languageProvider";

interface props {
  page: string
  data: IOpenAccountShema[],
  dataOfPage: IFormsShema[],
}

const ButtonHolder = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 30px;
`
const Box = styled("div")<{borderColor?: boolean}>`
  display: flex;
  border: ${(props) => (props.borderColor ? "1.2px solid #1669AF" : "1.2px solid #27b22c")};
  border-radius: 10px;
  padding: 10px 5px 10px 5px;
  margin-top: 20px;
  justify-content: center;
  align-items: center;
`

const TextHolder = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  padding: 5px 5px 5px 5px;
  flex:3;
`

export default ({
  page, 
  data, 
  dataOfPage,
}: props) => {
  const [refId, setRefId] = React.useState<string>("")
  const [showCancelButton, setShowCancelButton] = React.useState(false)
  const dispatch = useDispatch()
  const NDID = useSelector((state: IStore) => state.NdidReducer)
  const { t } = useTranslation()
  const index = +page.split(".")[1] || 0
  const dataDisplay = dataOfPage[index]
  const componentContext = useContext(ComponentContext);
  const {
    logo,
    displayNameTH,
    displayNameEN,
  } = NDID.CHOOSE_IDP_NDID
  const { 
    status,
    referenceID, 
  } = NDID.STATUS_VERIFY_NDID
  const { allowEdit } = useSelector((state: IStore) => state.StepsReducer)
  const items = useSelector((state: IStore) => state.NdidReducer)
  const onChange = (val: {key?: string, value?: string | boolean | number}) => {
    componentContext?.onChange({ key: val.key || "", value: val.value })
  }

  useEffect(() => {
    const verifyItems = items.STATUS_VERIFY_NDID.status
    if (verifyItems === "VALID") {
      setShowCancelButton(true)
    }
  }, [items.STATUS_VERIFY_NDID])
  
  useEffect(() => {
    if (allowEdit) {
      const allowItems = allowEdit.filter((i: any) => i.stepName === "STEP_VERIFY_NDID")
      
      const isPassItem = {
        allowEdit: null,
        isPass: null,
        stepName: null,
        subStep: [],
        titleEn: null,
        titleTh: null,
      }
      Object.assign(isPassItem, ...allowItems)
      if (isPassItem.subStep.length > 0) {
        const isPass : any = isPassItem.subStep.filter((i: any) => i?.stepName === "STEP_WAITING_NDID_VERIFY")
        if (isPass[0]?.isPass) {
          setShowCancelButton(isPass[0].isPass)
        }
      }
    }
  }, [showCancelButton])

  useEffect(() => {
  }, [showCancelButton])
  
  const randomString = (length: number) => {
    const chars = "0123456789"
    let result = "";
    for (let i = length; i > 0; i -= 1) {
      result += chars[Math.floor(Math.random() * chars.length)];
    }
    return result;
  }

  React.useEffect(() => {
    setRefId(randomString(9))
    console.log(status)
    
    if (status !== IStatusNDID.VALID && 
      status !== IStatusNDID.REJECTED &&
      status !== IStatusNDID.TIMEOUT &&
      status !== IStatusNDID.ERROR) {
      const runner = setInterval(() => {
        dispatch(getCheckVerifyNDID())
      }, 5000)
      return () => {
        clearInterval(runner)
      }
    }
    return () => {}
  }, [status])

  const onNext = () => {
    dispatch(navigateNext())
  }

  const alertConfirm = () => {
    const alertConfig = {
      text: "ท่านต้องการยกเลิกรายการยืนยันตัวตนนี้ใช่หรือไม่",
      icon: "warning" as SweetAlertIcon,
      showCancelButton: true,
      confirmButtonColor: "#0C67B1",
      cancelButtonColor: "red",
      confirmButtonText: "ทำรายการต่อ",
      cancelButtonText: "ยกเลิกรายการ",
      customClass: {
        confirmButton: "Hola",
      },
    }
    Swal.fire(alertConfig)
      .then((result) => {
        if (result.isConfirmed) {
          dispatch(navigateSetStep("2.2"))
        }
      })
  }
  const confirmationBox = () => {
    showDialog(IDialogTypeEnum.CANCEL_DIALOG, { message: "ท่านต้องการยกเลิกรายการยืนยันตัวตนนี้ใช่หรือไม่?" })
      .then((res) => {
        console.log("res", res)
        if (res === 2) {
          dispatch(cancelNDID())
        }
      })
  }

  const SelectBankStatus = ({ statusBank }: { statusBank: keyof typeof IStatusNDID}) => (
    <>
      {
        statusBank === IStatusNDID.VALID ? (
          <>
            <TLight color="#666666" style={{ lineHeight: "20pt" }}>{t("ndidSuccess.subTitle")}</TLight>
            <Box>
              <Images style={{ width: "60px", height: "60px", padding: "5px 25px 5px 35px" }} src={new URL(logo, getEndPoint()).toString()} />
              <TextHolder>
                <TMedium fontSize={mainFontSize.l} color="#4e4e4e">{getLanguage() === "th" ? displayNameTH : displayNameEN}</TMedium>
                <TLight color="#27b22c">{t("ndidSuccess.bankSubHeader")}</TLight>
              </TextHolder>
              <Images
                style={{
                  width: "25px",
                  height: "25px",
                  marginRight: 50,
                  marginLeft: 20,
                }}
                src={Success}
              />
            </Box>
            {
              showCancelButton === false
                ?
                (
                  <div style={{
                    alignItems: "center",
                    justifyContent: "center",
                    justifyItems: "center",
                    flexDirection: "row",
                    flex: 1,
                    textAlign: "center",
                    marginTop: "70px",
                  }}
                  >
                    <ButtonLink onClick={confirmationBox}>
                      <TMedium fontSize={mainFontSize.xs} color="#666666" style={{ lineHeight: "20pt", textDecorationLine: "underline" }}>ยกเลิก</TMedium>
                    </ButtonLink>
                  </div>
                )
                : <></>
            }
          </>
        ) : (
          <>
            <TLight color="#666666" style={{ lineHeight: "20pt" }}>{t("ndidSuccess.titleRef") + displayNameTH }</TLight>
            <TLight color="#666666" style={{ lineHeight: "20pt" }}>{t("ndidSuccess.ref") + refId}</TLight>
            <Box borderColor>
              <Images style={{ width: "60px", height: "60px", padding: "5px 25px 5px 35px" }} src={new URL(logo, getEndPoint()).toString()} />
              <TextHolder>
                <TMedium fontSize={mainFontSize.l} color="#4e4e4e">
                  {getLanguage() === "th" ? displayNameTH : displayNameEN}
                </TMedium>
                <TLight color="#F2A946">{t("ndidSuccess.desWaitting")}</TLight>
              </TextHolder>
              <LoadingIndicator 
                style={{
                  marginRight: 50,
                  marginLeft: 20,
                }}
              />
            </Box>
            {
              showCancelButton === false
                ? 
                (
                  <div style={{
                    alignItems: "center",
                    justifyContent: "center",
                    justifyItems: "center",
                    flexDirection: "row",
                    flex: 1,
                    textAlign: "center",
                    marginTop: "70px",
                  }}
                  >
                    <ButtonLink onClick={confirmationBox}>
                      <TMedium fontSize={mainFontSize.xs} color="#666666" style={{ lineHeight: "20pt", textDecorationLine: "underline" }}>ยกเลิก</TMedium>
                    </ButtonLink>
                  </div>
                )
                : <></>
            }
          </>
        )
      }
    </>
  )
  
  return (
    <Card>
      <HistoryOfPath 
        data={dataOfPage.map((val) => val.title)}
        page={page}
        activeIndex={index}
      />
      <div style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between", 
      }}
      >
        <Texts style={{ 
          color: "#0C67B1", 
          padding: "5px 0px 5px 0px", 
          fontSize: mainFontSize.l, 
          fontFamily: fonts.SarabunMedium,
        }}
        >
          {dataDisplay.title}
        </Texts>
      </div>

      {SelectBankStatus({
        statusBank: status,
      })}

      <ButtonHolder>
        <Buttons 
          disabled={!(status === IStatusNDID.VALID)}
          onClick={onNext}
        >
          {t("ndidSuccess.buttonNext")}
        </Buttons>
      </ButtonHolder>
    </Card>
  )
}