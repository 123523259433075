const size = {
  mobileSS: "280px",
  mobileS: "320px",
  mobileM: "375px",
  mobileL: "425px",
  tablet: "768px",
  laptop: "1024px",
  laptopL: "1440px",
  desktop: "2560px",
}

export const color = {
  primary: "#0C67B1",
}

export enum mainFontSize {
  xxxl="24px",
  xxl="22px",
  xl="20px",
  l="18px",
  m="16px",
  s="14px",
  xs="12px",
  xxs="10px",
  xxxs="7px",
  // xxxl="40px",
  // xxl="32px",
  // xl="28px",
  // l="24px",
  // m="20px",
  // s="16px",
  // xs="14px",
  // xxs="12px",
  
}

export const device = {
  mobileSS: `(min-width: ${size.mobileSS})`,
  mobileS: `(min-width: ${size.mobileS})`,
  mobileM: `(min-width: ${size.mobileM})`,
  mobileL: `(min-width: ${size.mobileL})`,
  tablet: `(min-width: ${size.tablet})`,
  laptop: `(min-width: ${size.laptop})`,
  laptopL: `(min-width: ${size.laptopL})`,
  desktop: `(min-width: ${size.desktop})`,
  desktopL: `(min-width: ${size.desktop})`,
};

export enum fonts {
  SarabunBold="Sarabun-Bold",
  SarabunBoldItalic="Sarabun-BoldItalic",
  SarabunExtraBold="Sarabun-ExtraBold",
  SarabunExtraBoldItalic="Sarabun-ExtraBoldItalic",
  SarabunExtraLight="Sarabun-ExtraLight",
  SarabunExtraLightItalic="Sarabun-ExtraLightItalic",
  SarabunItalic="Sarabun-Italic",
  SarabunLight="Sarabun-Light",
  SarabunLightItalic="Sarabun-LightItalic",
  SarabunMedium="Sarabun-Medium",
  SarabunMediumItalic="Sarabun-MediumItalic",

  PromptBlack="Prompt-Black",
  PromptBlackItalic="Prompt-BlackItalic",
  PromptBold="Prompt-Bold",
  PromptBoldItalic="Prompt-BoldItalic",
  PromptExtraBold="Prompt-ExtraBold",
  PromptExtraBoldItalic="Prompt-ExtraBoldItalic",
  PromptExtraLight="Prompt-ExtraLight",
  PromptExtraLightItalic="Prompt-ExtraLightItalic",
  PromptItalic="Prompt-Italic",
  PromptLight="Prompt-Light",
  PromptLightItalic="Prompt-LightItalic",
  PromptMedium="Prompt-Medium",
  PromptMediumItalic="Prompt-MediumItalic",
  PromptRegular="Prompt-Regular",
  PromptSemiBold="Prompt-SemiBold",
  PromptSemiBoldItalic="Prompt-SemiBoldItalic",
  PromptThin="Prompt-Thin",
  PromptThinItalic="Prompt-ThinItalic",

}
