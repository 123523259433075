/* eslint-disable max-len */
import { END, EventChannel, eventChannel } from "redux-saga"
import {
  call,
  cancelled,
  CancelledEffect,
  delay,
  put,
  take,
  takeEvery,
  takeLatest,
} from "redux-saga/effects"
import {
  AUTH_CHECK_TARGET_EXISTS,
  AUTH_LOGIN_WITH_OTP,
  AUTH_REGISTER,
  AUTH_SEND_EMAIL_OTP,
  AUTH_SEND_MOBOLE_OTP,
  AUTH_VALIDATE_REGISTER_FIELD,
  AUTH_VERIFY_EMAIL_OTP,
  AUTH_VERIFY_MOBILE_OTP,
  AUTH_VERIFY_OTP,
  loginSetState,
  setAuthReducer,
  setEmailOtpRequest,
  setEmailOtpVerify,
  setMobileOtpRequest,
  setMobileOtpVerify,
} from "../actions"
import {
  CheckMobileAndEmailExistsApi,
  CheckMobileAndEmailLoginApi,
  CheckMobileAndEmailRegisterApi,
  LoginWebApi,
  LoginWithOTPApi,
  RegisterApi,
  SendEmailOtpApi,
  SendMobileOtpApi,
  VerifyEmailOtpApi,
  VerifyMobileOtpApi,
} from "../apis"
import { showDialog } from "../components/Provider/dialog"
import { hideSpinner, showSpinner } from "../components/Provider/spinner"
import {
  IAuthState,
  ICheckMobileAndEmailExistsResponse,
  ICheckMobileAndEmailLoginResponse,
  ICheckMobileAndEmailRegisterResponse,
  IDialogTypeEnum,
  IGetUsers,
  ILoginWebResponse,
  ILoginWithOtpResponse,
  IRegisterResponse,
  ISendEmailOtpResponse,
  ISendMobileOtpResponse,
  IVerification,
  IVerifyEmailOtpResponse,
  IVerifyMobileOtpResponse,
  StatusOpenAccount,
} from "../interfaces"
import { routerName } from "../interfaces/router.enum"
import state from "../store"
import {
  auth as auther,
  CheckMobileNoOrEmailFormat,
  GetDeviceId,
  IsEmailFormat,
  IsMobileFormat,
} from "../utilities"
import { WorkerGetUsers } from "./masterdata.saga"
import { WorkerPushToCurrentStep } from "./openingAccount.saga"
import { handleStatusError } from "."

function* SendEmailOtp() {
  try {
    const { email } = state.store.getState().AuthReducer
    if (!email) {
      return auther.signOut()
    }
    const response: ISendEmailOtpResponse = yield call(SendEmailOtpApi, email)
    if (response?.errors) {
      yield call(handleStatusError, response?.errors);
      return { status: "fail" }
    }
    yield put(setEmailOtpRequest(response?.data.SendEmailOtp))
  } catch (error) {
    console.error(error)
  }
}

function* SendMobileOtp() {
  try {
    let number = ""
    const { mobileNo } = state.store.getState().AuthReducer;
    const { accessToken } = state.store.getState().LoginReducer

    if (accessToken) {
      const res: IGetUsers = yield call(WorkerGetUsers)
      number = res.data.GetUserInfo.mobileNumber
    } else {
      number = mobileNo
    }

    const response: ISendMobileOtpResponse = yield call(SendMobileOtpApi, number)
    if (response?.errors) {
      yield call(handleStatusError, response?.errors);
      return { status: "fail" }
    }
    yield put(setMobileOtpRequest(response?.data.SendMobileOtp))
  } catch (error) {
    console.error(error)
    return { status: "error" }
  }
}

function* VerifyMobileOtp() {
  try {
    const auth: IAuthState = state.store.getState().AuthReducer;
    const { mobileNo, mobileOtpAnswer, mobileOtp } = auth

    const response: IVerifyMobileOtpResponse = yield call(
      VerifyMobileOtpApi, 
      mobileNo as string, 
      mobileOtpAnswer as string, 
      mobileOtp?.otpRequest?.reference as string,
    )

    if (response?.errors) {
      yield call(handleStatusError, response?.errors);
      return { status: "fail" }
    }

    yield put(setMobileOtpVerify(response?.data.VerifyMobileOtp))

    return { status: "success" }
  } catch (error) {
    console.error(error)
    return { status: "error" }
  }
}

function* VerifyEmailOtp() {
  try {
    const auth: IAuthState = state.store.getState().AuthReducer;
    const { email, emailOtpAnswer, emailOtp } = auth

    const response: IVerifyEmailOtpResponse = yield call(
      VerifyEmailOtpApi, 
      email as string, 
      emailOtpAnswer as string, 
      emailOtp?.otpRequest?.reference as string,
    )

    if (response?.errors) {
      yield call(handleStatusError, response?.errors, true);
      return { status: "fail" }
    }

    yield put(setEmailOtpVerify(response?.data?.VerifyEmailOtp))
    return { status: "success" }
  } catch (error) {
    console.error(error)
    return { status: "error" }
  }
}

function* VerifyOtp({ payload }: any) {
  try {
    const emailStatus: {status: string} = yield call(VerifyEmailOtp)
    if (emailStatus.status === "success") {
      const mobileStatus: {status: string} = yield call(VerifyMobileOtp)
      if (mobileStatus.status === "success") {
        yield call(payload?.callback)
      }
    } 
  } catch (error) {
    console.error(error)
  }
}

function* Login() {
  try {
    const emailStatus: {status: string} = yield call(VerifyEmailOtp)
    const mobileStatus: {status: string} = yield call(VerifyMobileOtp)

    if (emailStatus.status === "success" && mobileStatus.status === "success") {
      const auth: IAuthState = state.store.getState().AuthReducer;
      const verification: Array<IVerification> = [
      auth.emailOtp?.otpVerify?.verification as IVerification,
      auth.mobileOtp?.otpVerify?.verification as IVerification,
      ]
      const response: ILoginWebResponse = yield call(LoginWebApi, verification)

      if (response?.errors) {
        yield call(handleStatusError, response?.errors);
        return
      }

      // yield put(loginSetState({ key: "accessToken", value: response?.data?.LoginWeb?.accessToken }))
      // eslint-disable-next-line consistent-return
      return response?.data?.LoginWeb?.accessToken
    }
  } catch (error) {
    console.error(error)
  }
}

function* Register() {
  try {
    const emailStatus: {status: string} = yield call(VerifyEmailOtp)
    if (emailStatus.status === "success") {
      const mobileStatus: {status: string} = yield call(VerifyMobileOtp)
      
      if (mobileStatus.status === "success") {
        const auth: IAuthState = state.store.getState().AuthReducer;
        const verification: Array<IVerification> = [
            auth.emailOtp?.otpVerify?.verification as IVerification,
            auth.mobileOtp?.otpVerify?.verification as IVerification,
        ]

        const response: IRegisterResponse = yield call(
          RegisterApi, 
          verification, 
        )
        
        if (response?.errors) {
          yield call(handleStatusError, response?.errors);
          return
        }
        
        // eslint-disable-next-line consistent-return
        return response?.data?.Register?.accessToken
        // yield put(loginSetState({ key: "accessToken", value: response?.data?.Register?.accessToken }))
      }
    } 
  } catch (error) {
    console.error(error)
  }
}

function* LoginHandleStatusSaga(payload: any) {
  try {
    const response: IGetUsers = yield call(WorkerGetUsers)
    switch (response.data.GetUserInfo.oaStatus) {
      case StatusOpenAccount.REGISTERED:
        yield call(payload?.callback)
        break;

      case StatusOpenAccount.VERIFIED:
        yield call(payload?.callback)
        break;

      case StatusOpenAccount.SUBMITTED:
        window.location.href = routerName.approvalStatus
        break;

      case StatusOpenAccount.APPROVED:
        window.location.href = routerName.approvalStatus
        break;

      case StatusOpenAccount.REJECTED:
        window.location.href = routerName.approvalStatus
        break;

      case StatusOpenAccount.EDITING:
        window.location.href = routerName.approvalStatus
        break;
        
      default:
        yield showDialog(IDialogTypeEnum.ERROR_DIALOG, { message: "STATUS NOT SUPPORT" })
        break;
    }
  } catch (error) {
    console.error(error)
  }
}

function* RegisterOrLogin({ payload }: any) {
  try {
    const auth: IAuthState = state.store.getState().AuthReducer;
    
    if (auth.isLogin) {
      const token: string = yield call(Login)
      if (token) {
        yield put(loginSetState({ key: "accessToken", value: token }))
        yield call(LoginHandleStatusSaga, payload)
      }
    } else {
      const token: string = yield call(Register)
      if (token) {
        yield put(loginSetState({ key: "accessToken", value: token }))
        yield call(payload?.callback)
      }
    }
  } catch (error) {
    console.error(error)
  }
}

function* CheckExistTarget({ payload }: any) {
  try {
    yield call(showSpinner)
    const auth: IAuthState = state.store.getState().AuthReducer;
    
    if (!IsEmailFormat(auth.email as string)) {
      yield call(hideSpinner)
      showDialog( 
        IDialogTypeEnum.ERROR_DIALOG, 
        { message: "รูปแบบอีเมลของคุณไม่ถูกต้อง" },
        true,
      )

      showDialog( 
        IDialogTypeEnum.ERROR_DIALOG, 
        { message: "รูปแบบอีเมลของคุณไม่ถูกต้อง" },
        true,
      )
      return
    }

    if (!IsMobileFormat(auth.mobileNo as string)) {
      yield call(hideSpinner)
      yield call(
        showDialog, 
        IDialogTypeEnum.ERROR_DIALOG, 
        { message: "รูปแบบเบอร์โทรศัพท์ของคุณไม่ถูกต้อง" },
      )
      return
    }

    const response: ICheckMobileAndEmailLoginResponse = yield call(
      CheckMobileAndEmailLoginApi,
      auth.mobileNo as string, 
      auth.email as string,
    )

    if (response?.errors) {
      yield call(hideSpinner)
      yield call(handleStatusError, response?.errors);
      return
    }

    yield put(setAuthReducer({ key: "isLogin", value: true }))

    yield call(hideSpinner)
    yield call(payload?.callback)
  } catch (error) {
    yield call(hideSpinner)
    console.error(error)
  }
}

function* LoginWithOtp() {
  try {
    const auth: IAuthState = state.store.getState().AuthReducer;
    const type: "MOBILE" | "EMAIL" | "INVALID" = yield call(CheckMobileNoOrEmailFormat, auth.loginField as string)
    
    if (type === "INVALID") return

    const response: ILoginWithOtpResponse = yield call(
      LoginWithOTPApi,
      type,
      GetDeviceId(),
      auth.loginField as string,
      (type === "MOBILE" ? auth.mobileOtp?.otpRequest?.reference : auth.emailOtp?.otpRequest?.reference) as string,
      auth.loginFieldOtp as string,
    )
    if (response?.errors) {
      yield call(handleStatusError, response?.errors);
      return
    }
    yield put(loginSetState({ key: "accessToken", value: response?.data?.LoginWithOTP?.accessToken }))
    yield delay(1000)
    window.location.href = "/"
    yield call(WorkerPushToCurrentStep)
  } catch (error) {
    console.error("LoginWithOtp error =====> ", error)
  }
}

function* validateRegisterField({ payload }: any) {
  try {
    yield call(showSpinner)
    const auth: IAuthState = state.store.getState().AuthReducer;
    
    if (!IsEmailFormat(auth.email as string)) {
      showDialog( 
        IDialogTypeEnum.ERROR_DIALOG, 
        { message: "รูปแบบอีเมลของคุณไม่ถูกต้อง" },
        true,
      )

      showDialog( 
        IDialogTypeEnum.ERROR_DIALOG, 
        { message: "รูปแบบอีเมลของคุณไม่ถูกต้อง" },
        true,
      )
      yield call(hideSpinner)
      return
    }

    if (!IsMobileFormat(auth.mobileNo as string)) {
      yield call(hideSpinner)
      yield call(
        showDialog, 
        IDialogTypeEnum.ERROR_DIALOG, 
        { message: "รูปแบบเบอร์โทรศัพท์ของคุณไม่ถูกต้อง" },
      )
      return
    }

    const responseCheckRegister: ICheckMobileAndEmailRegisterResponse = yield call(
      CheckMobileAndEmailRegisterApi,
      auth.mobileNo as string, 
      auth.email as string,
    )

    if (responseCheckRegister?.errors) {
      yield call(hideSpinner)

      if (responseCheckRegister?.errors[0].extensions.code === "1100") {
        yield call(payload?.callDialog)

        return
      }
      yield call(handleStatusError, responseCheckRegister?.errors);
      return
    }

    if (responseCheckRegister.data.CheckMobileAndEmailRegister.isPass) {
      const response: ICheckMobileAndEmailExistsResponse = yield call(
        CheckMobileAndEmailExistsApi, 
        auth.mobileNo as string, 
        auth.email as string,
      )
  
      if (response?.errors) {
        yield call(hideSpinner)
        yield call(handleStatusError, response?.errors);
        return
      }

      yield call(hideSpinner)
      yield call(payload?.callback)
    }
  } catch (error) {
    yield call(hideSpinner)
    console.error(error)
  }
}

function countdown(secs = 2) {
  return eventChannel((emitter) => {
    const iv = setInterval(() => {
      secs -= 1
      if (secs > 0) {
        emitter(secs)
      } else {
        // this causes the channel to close
        emitter(END)
      }
    }, 1000);
      // The subscriber must return an unsubscribe function
    return () => {
      clearInterval(iv)
    }
  })
}

function* WorkerCountDownSaga() {
  const chan: EventChannel<any> = yield call(countdown, 20)
  try {
    while (true) {
      const seconds: number = yield take(chan)
      console.log(`countdown: ${seconds}`)
    }
  } finally {
    const cancel: CancelledEffect = yield cancelled()
    if (cancel) {
      chan.close()
      console.log("countdown cancelled")
    }
  }
}

export default function* AuthSaga() {
  yield takeEvery(AUTH_SEND_EMAIL_OTP, SendEmailOtp)
  yield takeEvery(AUTH_SEND_MOBOLE_OTP, SendMobileOtp)
  yield takeEvery(AUTH_VERIFY_MOBILE_OTP, VerifyMobileOtp)
  yield takeEvery(AUTH_VERIFY_EMAIL_OTP, VerifyEmailOtp)
  yield takeEvery(AUTH_VERIFY_OTP, VerifyOtp)
  yield takeEvery(AUTH_REGISTER, RegisterOrLogin) // Register
  yield takeEvery(AUTH_CHECK_TARGET_EXISTS, CheckExistTarget)
  yield takeEvery(AUTH_LOGIN_WITH_OTP, LoginWithOtp)
  yield takeEvery(AUTH_VALIDATE_REGISTER_FIELD, validateRegisterField)
  yield takeLatest("COUNT_DOWN_SAGA", WorkerCountDownSaga)
}
